import "./id-confirmation.scss";

export const IdConfirmation = () => {
  return (
    <div className="verifi-id-section start-verification-spacing id-confirmation-wrap">
      <h2 className="text--h2 heading--with-icon id-confirmation--loading">
        Verifying your identity...
      </h2>
      {/* <h2 className="text--h2 heading--with-icon id-confirmation--confirmed">
                Success!
            </h2> */}
      <p className="text--body-2">
        Please keep this window open while we process your ID.
      </p>
      {/* <p className="text--body-2">
                Thanks for confirming your identity. You may now close this window and return to BMW Checkout. 
            </p> */}
    </div>
  );
};
