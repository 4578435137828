import React from "react";

const GCDMLogin = () => {
  const openPopup = () => {
    const url =
      "https://login-i.bmwusa.com/oneid/#/login?client=drus&brand=bmw&country=US&language=en&scope=authenticate_user&response_type=code&redirect_uri=https%3A%2F%2Fmybmw.bmwusa.com%2Fsso&state=%2Fterms%2Faccept%3Flanguage%3Den&email=yasir.buland%40netsoltech.com";
    const windowFeatures = "width=600,height=800,resizable=yes,scrollbars=yes";
    window.open(url, "_blank", windowFeatures);
  };

  return (
    <div>
      <button onClick={openPopup}>Open BMW Login</button>
    </div>
  );
};

export default GCDMLogin;
