// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-accordion {
  margin: 16px 0;
  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1920px) {
  .checkout-accordion {
    margin: 20px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/credit-application/checkout/utils/accordion/accordion.style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".checkout-accordion {\n  margin: 16px 0;\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n\n@media screen and (min-width: 1920px) {\n  .checkout-accordion {\n    margin: 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
