import "./provide-email.scss";
import { getLinkClickPayload } from "@src/helpers";
import { Button, Input } from "@src/components/atoms";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";
import { useVerifyRadioContext } from "@src/components/organisms/credit-application/checkout/04-verify-identity/verify-identity/verify-identity";

export const ProvideEmail = () => {
  const { dispatch } = useVerifyRadioContext();
  const { addEvent } = useAdobeDatalayerContext();

  return (
    <div className="email-container">
      <p className="email-container__header text--body-2">
        Please provide your email address so we can send you a secure link.
      </p>
      <div className="email-container__wrapper">
        <div>
          <Input
            id={"email-container--email-field"}
            name={"email-container--email-field"}
            placeholder=""
            label="Email address"
          />
        </div>
        <p className="disclaimer text--body-2 text-secondary">
          By entering my email address, I agree that the BMW Center, its
          affiliates, and service providers may send me emails relating to my
          application, vehicle purchase, and related issues. By providing us
          with your email address, you provide us with your consent to receive
          such email messages. You agree to the BMW Center Terms and Privacy
          Policy.
        </p>
        {/* <button
          className="button style-button-default style-button--default"
          type="button"
        >
          Agree &amp; send link to my email
        </button> */}
        <Button
          type="default"
          htmlType={"button"}
          text="Agree &amp; send link to my email"
          onClick={() => {
            addEvent(
              "linkClick",
              getLinkClickPayload("agree and send link to my phone", "text")
            );
            dispatch({ type: "EMAIL_SENT" });
          }}
        />
      </div>
    </div>
  );
};
