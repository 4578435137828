import { createContext, useContext, useState } from "react";

export const waitinghubContext = createContext({});
export const useWaitingHubContext = (): any => useContext(waitinghubContext);

interface StepState {
  current: string[];
  comingUp: string[];
  completed: string[];
}

export const WaitingHubContextProvider = ({ children }): any => {
  const [waitingHubState, setWaitingHubState] = useState<any>({
    esign: false,
    uploadedContract: false,
    uploadedResidenceProof: false,
    uploadedIncentiveProof: false,
    isPickUpScheduled: false,
    isSetInsuranceInfo: false,
    isPaymentSet: false,
  });
  const [currentStepState, setCurrentStepState] = useState<StepState>({
    current: ["1"],
    comingUp: ["2", "3", "4", "5"],
    completed: [],
  });

  const moveStep = (currentId, nextId) => {
    let { current, comingUp, completed } = currentStepState;
    let filteredCurrent = current.filter((item) => item !== currentId);
    let nextStep = comingUp.find((item) => item === nextId);

    comingUp = comingUp.filter((item) => item !== nextId);
    current = nextStep ? [nextStep, ...filteredCurrent] : filteredCurrent;
    completed = [currentId, ...completed];
    setCurrentStepState({
      completed: [...completed],
      current: [...current],
      comingUp: [...comingUp],
    });
  };

  const addStep = (id) => {
    let { current, comingUp, completed } = currentStepState;
    current = [id, ...current];
    comingUp = comingUp.filter((item) => item !== id);
    setCurrentStepState({
      completed: [...completed],
      current: [...current],
      comingUp: [...comingUp],
    });
  };

  const moveCompleted = (id) => {
    let { current, comingUp, completed } = currentStepState;
    current = current.filter((item) => item !== id);
    completed = [id, ...completed];
    setCurrentStepState({
      completed: [...completed],
      current: [...current],
      comingUp: [...comingUp],
    });
  };
  return (
    <waitinghubContext.Provider
      value={{
        waitingHubState,
        setWaitingHubState,
        moveStep,
        addStep,
        moveCompleted,
        currentStepState,
        setCurrentStepState,
      }}
    >
      {children}
    </waitinghubContext.Provider>
  );
};
