import {
  CONFIG_BASE_URL,
  DMS_BASE_URL,
  FNI_MONTHLY_PAYMENT_IMAPACT,
  FNI_PRODUCTS,
  GET_FNI_BUNDLES,
  GET_RATES,
  UMS_BASE_URL,
} from "@src/constants/routes";
import { http } from "@src/utilities";

export const getFNIBundles = async (body) => {
  try {
    const response = await http(CONFIG_BASE_URL).get(
      `${GET_FNI_BUNDLES}dealer_code=${body.dealerCode}&page_number=0&page_size=5&is_active=true`,
      {
        headers: { company_id: body.companyId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRates = async (ratesPayload) => {
  try {
    const response = await http(UMS_BASE_URL).post(
      `${GET_RATES}`,
      ratesPayload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFNIProducts = async (body) => {
  try {
    const response = await http(CONFIG_BASE_URL).get(
      `${FNI_PRODUCTS}dealer_code=${body.dealerCode}&page_number=0&page_size=1000&is_active=true`,
      {
        headers: { company_id: body.companyId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMonthlyImpactOnFNIProducts = async (coveragePayload) => {
  try {
    const response = await http(DMS_BASE_URL).post(
      FNI_MONTHLY_PAYMENT_IMAPACT,
      coveragePayload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
