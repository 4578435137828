import "./add-ons-drawer-footer.scss";
import { ReactNode } from "react";

export const AddOnsDrawerFooter = ({
  price,
  footerActions,
}: {
  price?: ReactNode;
  footerActions?: ReactNode;
}) => {
  return (
    <>
      <div className="add-ons-drawer__footer">
        <div className="add-ons-drawer__footer__price">{price}</div>
        <div className="add-ons-drawer__footer__action">{footerActions}</div>
      </div>
    </>
  );
};
