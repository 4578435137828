import "./product-details-drawer-content.scss";
import { Button, Image, MultiSwatchCircles } from "@src/components/atoms";
import { IncentivesListCard } from "../../incentives-list-card/incentives-list-card.component";
import { useAppContext } from "@src/context/app-context";
import { useState } from "react";

export const ProductDetailsDrawerContentAccessories = () => {
  const {
    state: {
      vehicle: { preInstalled },
      dealer: { dealer_name },
      order: {
        contract_term,
        order_asset: { mileage },
      },
    },
  } = useAppContext();
  const imageLocations = Array.isArray(preInstalled)
    ? preInstalled.map((item) => item.image?.[0]?.location || "")
    : [];
  const [expandedState, setExpandedState] = useState({});
  const handleToggle = (index) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const getSlicedDescription = (description) => {
    if (description?.length > 300) {
      const sliceIndex = description?.indexOf(" ", 300);
      return sliceIndex !== -1
        ? `${description?.slice(0, sliceIndex)}...`
        : `${description?.slice(0, 300)}...`;
    }
    return description;
  };

  return (
    <>
      <div className="product-details-sheet-header">
        <div className="product-details-sheet-header__web-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              {preInstalled?.length} pre-installed accessories by {dealer_name}.
            </div>
          </div>

          <div className="swatch-container">
            <MultiSwatchCircles images={imageLocations} maxVisible={3} />
          </div>
        </div>

        <div className="product-details-sheet-header__mobile-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              {preInstalled?.length} pre-installed accessories by {dealer_name}.
            </div>
          </div>
          {/*TODO: will be removed after confirmation from CM*/}
          {/*<div className="text--body-2">*/}
          {/*  {dealer_name} has pre-configured this bundle with coverage terms of{" "}*/}
          {/*  {contract_term} months / {mileage} miles. To adjust these settings,*/}
          {/*  please{" "}*/}
          {/*  <span className="text--link-2 text-underline ">*/}
          {/*    contact dealer.*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="product-details-sheet-protection-product__list-wrap">
        {preInstalled.map((accessory, index) => {
          const isExpanded = expandedState[index] || false;
          return accessory ? (
            <IncentivesListCard
              key={index}
              image={
                accessory?.image?.length ? accessory?.image[0]?.location : ""
              }
              title={accessory?.product_name}
              subTitle={
                <>
                  {isExpanded
                    ? accessory?.description
                    : getSlicedDescription(accessory?.description)}
                </>
              }
              className=""
              actionsArea={
                accessory?.description?.length > 300 && (
                  <Button
                    htmlType="button"
                    text={isExpanded ? "Show Less" : "Show More"}
                    linkButton
                    onClick={() => handleToggle(index)}
                    withIcon
                    dataIcon={
                      isExpanded ? "arrow_chevron_up" : "arrow_chevron_down"
                    }
                  />
                )
              }
            />
          ) : (
            <></>
          );
        })}
      </div>
    </>
  );
};
