import { Button, MessageAlert } from "@src/components/atoms";
import React from "react";

export function AgreementsTradeInExpired() {
  return (
    <div>
      <MessageAlert
        type="error"
        showCloseButton={false}
        icon={
          <div
            className="bmw-icon bmw-icon--before data-icon"
            data-icon="error_warning_sign"
          />
        }
        title="Your trade-in offer has expired."
        subTitle={
          <>
            Please renew or remove your trade-in offer to continue with your
            deal.
            <br />
            Don’t worry, we’ll save your progress and bring you back to where
            you left off once you’re done.
          </>
        }
        actionArea={
          <>
            <Button
              htmlType="button"
              text={"Review trade-in"}
              onClick={() => {}}
              size={"small"}
              type="default"
            />
          </>
        }
      />
    </div>
  );
}
