import { Button } from "@src/components/atoms";
import { UploadDocuments } from "../upload-documents/upload-documents.component";

export const ProvideInsuranceInfo = () => {
  return (
    <div className="upload-docs-info provide-insurance-info">
      <h3>Proof of insurance.</h3>
      <div className="divider"></div>
      <div className="upload-docs__inner">
        <div className="upload-docs__documents">
          <p className="text--body-2">
            Provide proof of insurance for your new BMW purchase by uploading
            one or more of the following:
          </p>
          <ul className="text--body-2">
            <li>Insurance card</li>
            <li>Policy document</li>
          </ul>
        </div>
      </div>
      <UploadDocuments />
      <div className="upload-docs__cta">
        <Button
          type="primary"
          htmlType={"button"}
          text="Save & close"
          disabled
        />
      </div>
    </div>
  );
};
