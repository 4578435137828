import "./lease-tab-content.scss";
import { Button, Input, Popover, Select, Slider } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
import { Col, Row } from "antd";
import { TotalContributionAccordionContent } from "./total-contribution-accordion-content/total-contribution-accordion-content";
import { DueAtSigningAccordionContent } from "./due-at-signing-accordion-content/due-at-signing-accordion-content";
import { AdjustedCapitalizedCostAccordionContent } from "./adjusted-capitalized-cost-accordion-content/adjusted-capitalized-cost-accordion-content";
import { useState } from "react";

export const LeaseTabContent = () => {
  const LeaseAccordionItems = [
    {
      key: "1",
      label: (
        <div className="accordion-title-with-icon">
          Total Contribution
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The total contribution consists of your cash down payment, rebates and incentives from the dealer, BMW North America, or BMW Financial Services, and trade-in values. This information is for explanatory purposes only. Actual terms and conditions may vary. Please review the detailed breakdown or contact your dealer for clarification."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <TotalContributionAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Due at Signing
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The Due at Signing amount includes upfront costs such as the down payment, taxes, fees, and the first month's payment. This figure is provided for educational purposes only and may vary based on your lease agreement. Verify the exact amount with your dealer or lender."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <DueAtSigningAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "3",
      label: (
        <div className="accordion-title-with-icon">
          Adjusted Capitalized Cost
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The Adjusted Capitalized Cost is the vehicle’s price after applying any reductions, including the Capitalized Cost Reduction. "
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <AdjustedCapitalizedCostAccordionContent />,
      extra: <span className="text--body-focus-price-1">$45,970</span>,
    },
  ];

  return (
    <>
      <div className="update-payment-tab update-payment-tab--lease">
        <div className="payment-calculator">
          <div className="payment-calculator__price-card">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    MSRP
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="Manufacturer's Suggested Retail Price (MSRP) for vehicle as shown does not necessarily represent the dealer's actual sale price and does not include tax, title, license, registration and adjusted market value. Dealer sets actual price. Please consult your selected dealer."
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Estimated finance
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    $0,000<span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
          </div>
          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__price-card payment-calculator__price-card--checkout-pricing">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Your price
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="Your Price reflects the Manufacturer’s Suggested Retail Price (MSRP) minus applicable dealer discounts, incentives, or promotions. Dealer incentives are subject to change and may vary by region or availability. Additional fees, taxes, registration, and optional equipment are not included. The final purchase price may differ based on dealer-specific factors, including but not limited to negotiated terms and applied financing. Contact your selected dealer for more details."
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Est. payments
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    $0,000<span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
            <p className="text--disclaimer disclaimer--monthly-estimate">
              Monthly estimate Includes taxes & fees.
            </p>
          </div>
          <div className="payment-calculator__secion">
            <Row gutter={[0, { xs: 24, sm: 24, md: 24, lg: 32, xl: 40 }]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="payment-calculator__secion__slider">
                  <div className="payment-calculator__secion__slider__title">
                    <span className="text--label text-secondary label">
                      Term length
                    </span>
                    <span className="text--cta-2 additional">24 months</span>
                  </div>
                  <Slider
                    min={1}
                    max={5}
                    step={1}
                    dots={true}
                    defaultValue={1}
                    tooltipFormatter={(value) => `${value}`}
                    onChange={() => {}}
                  />
                  <div className="payment-calculator__secion__slider__value text--label text-secondary">
                    <span>24 months</span>
                    <span>72 months</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Input
                  id={"idCashdown"}
                  name={""}
                  placeholder=""
                  label="Cash down"
                  showInfoIcon
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  id={"dropdownLease miles"}
                  placeholder=""
                  label="Lease miles"
                  showInfoIcon
                  options={[
                    { value: "1", label: "7,500 miles", disabled: false },
                    { value: "2", label: "Option 2", disabled: false },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  id={"dropdownCreditScore"}
                  placeholder=""
                  label="Credit score"
                  showInfoIcon
                  options={[
                    {
                      value: "1",
                      label: "Very good (700-739)",
                      disabled: false,
                    },
                    { value: "2", label: "Option 2", disabled: false },
                  ]}
                />
              </Col>
            </Row>
          </div>

          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__checkout-pricing-section">
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Lease term
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                XX months
              </span>
            </div>
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Cash down
                <Popover
                  trigger={["hover"]}
                  placement="top"
                  disclaimerText='Similar to a Down payment, money provided by the lessee at inception reduces the capitalized cost of the lease. Your total amount "Due-at-Signing" may include additional amounts. A full breakdown of your total "Due-at-Signing" is provided after all estimator steps are completed.'
                >
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </Popover>
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                -$X,XXX
              </span>
            </div>
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Lease miles
                <Popover
                  trigger={["hover"]}
                  placement="top"
                  disclaimerText="If the total mileage exceeds the agreed-upon limit stated in your lease agreement, additional charges will apply. Please review your lease terms or consult with your dealer for details on excess mileage costs."
                >
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </Popover>
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                X.XX%
              </span>
            </div>
          </div>
        </div>
        <div className="accurate-apr-banner">
          <div className="accurate-apr-banner__text">
            <p className="text--body-2">
              Get a more precise monthly payment with a{" "}
              <span className="text--link-2 accurate-apr-banner__text__link">
                soft credit check
              </span>
              .
            </p>
          </div>
          <div className="accurate-apr-banner__icon">
            <i className="bmw-icon bmw-icon--after bmw-icon--search-offer"></i>
          </div>
        </div>
        <div className="pricing-details">
          <div className="pricing-details__heading">
            <h4 className="text--subsection-1">Pricing details.</h4>
          </div>
          <div className="pricing-details__inner">
            <div className="pricing-details-accordion">
              <div className="pricing-details-accordion__checkout-pricing-estimates">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    (Est.) Monthly Payments
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment reflects the lender-approved loan terms. This amount is based on the lender's evaluation of your credit application and may differ from previously estimated monthly costs. Taxes, fees, and optional add-ons are not included unless specified. For further details, please review the terms provided by your lender or contact your dealer."
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <span className="pricing-details__item__price text--body-focus-price-1">
                    $612<span className="text--body-2">/month</span>
                  </span>
                </div>
                <p className="text--disclaimer text-muted">
                  The 36-month lease includes 12,000 miles annually, a $7,500
                  residual value (25%), with the first payment due MM/DD/2024
                  and a monthly sales tax of $0,000.
                </p>
              </div>
              <Accordion
                items={LeaseAccordionItems}
                defaultActiveKey={0}
                ghost={false}
                size="small"
                fullWidth
              />
            </div>
          </div>
          <p className="text--disclaimer pricing-details__due-payment">
            $7,500 residual value (25%), with the first payment due MM/DD/2024
            and a monthly sales tax of $0,000.The first payment is due on
            MM/DD/YYYY.{" "}
          </p>
          <p className="text--disclaimer pricing-details__due-payment pricing-details__due-payment--checkout-pricing">
            $7,500 residual value (25%), with the first payment due MM/DD/2024
            and a monthly sales tax of $0,000.The first payment is due on
            MM/DD/YYYY. Please contact dealer for more information or to make
            changes on your pricing details.
          </p>
          <div className="checkout-pricing-edit-my-deal-button">
            <Button
              htmlType="button"
              text="Edit my deal"
              dataIcon="arrow_chevron_right"
              linkButton
              withIcon
              isIconEnd
            />
          </div>
        </div>
      </div>
    </>
  );
};
