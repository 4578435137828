import "./contract-right-rail.scss";

interface ContractRightRailProps {
  icon?: string;
  title: string;
}

export const ContractRightRail = ({
  icon = "light_bulb_radiating",
  title,
}: ContractRightRailProps) => {
  return (
    <div className="contract-preview__right">
      <div className="contract-previer-right__inner">
        <span
          className={`bmw-icon bmw-icon--before data-icon`}
          data-icon={icon}
        />
        <p className="text--body-2">{title}</p>
      </div>
    </div>
  );
};
