import axios, { AxiosError } from "axios";
import { BMW_BASE, URL_BMW } from "@src/constants/routes";
import { handleRefreshToken, logout } from "@ntpkunity/controls-ums";
const LOGIN_URL = BMW_BASE + "login" + URL_BMW;
export const http = (URL, optionalHeaders = {}) => {
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const headers = {
    "Content-type": "application/json",
    ...(optionalHeaders || {}),
  };

  if (userCredentials.access_token) {
    headers["Authorization"] = `Bearer ${userCredentials.access_token}`;
  } else {
    headers["x-api-key"] = process.env.REACT_APP_API_KEY;
  }

  const axiosInstance = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: headers,
  });

  const requestInterceptor = async (request) => {
    // Here you can add additional checks or logic if needed
    const updatedUserCredentials = JSON.parse(
      localStorage.getItem("customer") || "{}"
    );
    if (updatedUserCredentials.access_token) {
      request.headers[
        "Authorization"
      ] = `Bearer ${updatedUserCredentials.access_token}`;
    } else {
      request.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
    }
    return request;
  };
  const errorInRequestInterceptor = async (error) => {};

  axiosInstance.interceptors.request.use(
    requestInterceptor,
    errorInRequestInterceptor
  );

  const responseInterceptor = (response) => {
    return response;
  };

  const errorInResponseInterceptor = async (error: AxiosError) => {
    // Handling refresh token logic if needed
    if (error.response?.status === 401) {
    }

    // Handle additional error statuses here, if needed
    switch (error.response?.status) {
      case 401:
        try {
          localStorage.clear();
          await handleRefreshToken(error, axios, logout(LOGIN_URL));
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
        // Unauthorized, handle accordingly (logout or refresh token)
        return Promise.reject("Unauthorized access");
      case 500:
        // Internal Server Error
        return Promise.reject("Server error occurred");
      default:
        return Promise.reject(error?.response?.data);
    }
  };
  axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorInResponseInterceptor
  );

  return axiosInstance;
};
