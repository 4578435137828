import { createContext, useContext, useReducer, ReactNode } from "react";

import { VerifyRadio } from "@src/components/molecules";
import "./verify-identity.scss";
import { Button } from "@src/components/atoms";

// Define the interface for the state
interface InitialState {
  isQRCodeSelected: boolean;
  isQRCodeScaned: boolean;
  isTextMessageSelected: boolean;
  isTextMessageSent: boolean;
  isEmailSelected: boolean;
  isEmailSent: boolean;
  isVerificationInProgress: boolean;
  isVerificationFailed: boolean;
}

// Define initial values
const initialValues: InitialState = {
  isQRCodeSelected: false,
  isQRCodeScaned: false,
  isTextMessageSelected: false,
  isTextMessageSent: false,
  isEmailSelected: false,
  isEmailSent: false,
  isVerificationInProgress: false,
  isVerificationFailed: false,
};

// Define action types
type VerifyRadioAction =
  | { type: "QR_CODE_SELECTED" }
  | { type: "QR_CODE_SCANNED" }
  | { type: "TEXT_MESSAGE_SELECTED" }
  | { type: "TEXT_MESSAGE_SENT" }
  | { type: "EMAIL_SELECTED" }
  | { type: "EMAIL_SENT" }
  | { type: "RESEND_MESSAGE_CLICKED" }
  | { type: "RESEND_LINK_CLICKED" }
  | { type: "VERIFICATION_IN_PROGRESS" }
  | { type: "VERIFICATION_FAILED" };

// Reducer function
const reducer = (
  state: InitialState = initialValues,
  action: VerifyRadioAction
) => {
  const resetState = { ...initialValues };

  switch (action.type) {
    case "QR_CODE_SELECTED":
      return {
        ...resetState,
        isQRCodeSelected: true,
      };
    case "QR_CODE_SCANNED":
      return {
        ...resetState,
        isQRCodeScaned: true,
      };
    case "TEXT_MESSAGE_SELECTED":
      return {
        ...resetState,
        isTextMessageSelected: true,
      };
    case "TEXT_MESSAGE_SENT":
      return {
        ...resetState,
        isTextMessageSent: true,
      };
    case "EMAIL_SELECTED":
      return {
        ...resetState,
        isEmailSelected: true,
      };
    case "EMAIL_SENT":
      return {
        ...resetState,
        isEmailSent: true,
      };
    case "RESEND_MESSAGE_CLICKED":
      return {
        ...resetState,
        isTextMessageSelected: true,
      };
    case "RESEND_LINK_CLICKED":
      return {
        ...resetState,
        isEmailSelected: true,
      };
    case "VERIFICATION_IN_PROGRESS":
      return {
        ...resetState,
        isVerificationInProgress: true,
      };
    case "VERIFICATION_FAILED":
      return {
        ...resetState,
        isVerificationFailed: true,
      };

    default:
      return state;
  }
};

// Define context type
interface VerifyRadioContextType {
  state: InitialState;
  dispatch: (action: VerifyRadioAction) => void;
}

// Create context with default values
const VerifyRadioContext = createContext<VerifyRadioContextType>({
  state: initialValues,
  dispatch: () => {},
});

// Create provider component
interface VerifyRadioProviderProps {
  children: ReactNode;
}

export const VerifyRadioProvider = ({ children }: VerifyRadioProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialValues);

  return (
    <VerifyRadioContext.Provider value={{ state, dispatch }}>
      {children}
    </VerifyRadioContext.Provider>
  );
};

// Custom hook to use the context
export const useVerifyRadioContext = () => {
  const context = useContext(VerifyRadioContext);
  if (context === undefined) {
    throw new Error(
      "useVerifyRadioContext must be used within a VerifyRadioProvider"
    );
  }
  return context;
};

import { ResendVerification } from "../resend-verification/resend-verification";
import { VerificationInProgress } from "../verification-in-progress/verification-in-progress";
import { VerificationFailed } from "../verification-failed/verification-failed";

const VarificationStates = ({
  stepFourDataReceivedEvent,
}: {
  stepFourDataReceivedEvent: () => void;
}) => {
  const { state } = useVerifyRadioContext();

  if (state.isVerificationInProgress) {
    stepFourDataReceivedEvent();
  }

  return (
    <>
      {(state.isQRCodeScaned ||
        state.isTextMessageSent ||
        state.isEmailSent) && <ResendVerification />}
      {/* {state.isVerificationInProgress && <VerificationInProgress />} */}
      {state.isVerificationFailed && <VerificationFailed />}
    </>
  );
};

export const VerifyIdentity = ({
  stepFourDataReceivedEvent,
}: {
  stepFourDataReceivedEvent: () => void;
}) => {
  return (
    <VerifyRadioProvider>
      <div className="preferred-method-container">
        <div className="preferred-method-container__content">
          <h2 className="section-header upper-label">
            <span className="section-header text--subsection-2 number">04</span>
            <span className="section-header text--subsection-2 text">
              Identity verification.
            </span>
          </h2>
          <p className="text--body-2">
            In order to continue with the credit application, we need to obtain
            your identification document and selfie photos. BMW and our service
            providers will use this information to verify your identity and
            satisfy other legal requirements.
          </p>
          <div className="sub-text image-disclaimer text--disclaimer text-secondary">
            Please note that your selfie image will not be stored.
          </div>

          <div className="verification-wrapper-web">
            <div className="list-wrapper">
              <p className="text--body-2">You'll need:</p>
              <ul className="list-wrapper list text--body-2">
                <li>A device with a camera (such as a phone or tablet)</li>
                <li>Your driver's license</li>
              </ul>
            </div>
            <VerifyRadio />
          </div>

          {/* mobile tablets view */}
          <div className="verification-wrapper-mobile">
            <div className="list-wrapper">
              <p className="text--body-2">You'll need:</p>
              <ul className="list-wrapper list text--body-2">
                <li>Your driver's license</li>
              </ul>
            </div>
            <div className="verification-wrapper--button-wrapper">
              <Button
                type="default"
                htmlType={"button"}
                text="Start verification"
                onClick={() => stepFourDataReceivedEvent()}
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
      <VarificationStates
        stepFourDataReceivedEvent={stepFourDataReceivedEvent}
      />
    </VerifyRadioProvider>
  );
};
