import { Button } from "@src/components/atoms/button/button.component";
import { Checkbox, Input, Select } from "@src/components/atoms";
import "./apr-percent-form.scss";
import { useAppContext } from "@src/context/app-context";
import {
  ActionType,
  setOrderInfo,
  updateCreditFreeze,
  updateCustomer,
  updateDateCache,
  updateProgramerror,
} from "@src/context/app-actions";
import { useGeneralContext } from "@src/context/general-context/general-context";
import { useForm, Controller } from "react-hook-form"; // Importing Controller from react-hook-form
import { useCustomerUpdate, useGetAllPrograms } from "@src/hooks";
import { useGetCustomerCreditCheck } from "@src/hooks/customer";
import { useState } from "react";
import { filterCustomerData, getAllProgramPayload } from "@src/helpers";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicValidatorSoftPull } from "@src/validators/customer/basicValidator";
import { formatDate } from "@src/helpers/financeHelper";
import { useNavigate } from "react-router-dom";
export const AprPercentForm = ({ toggleContentApr }) => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const { setOrderValues } = useComputedQuotation();
  const { mutateAsync: getProgram } = useGetAllPrograms();
  const {
    contextState: { creditfreez },
    setContextState: contextDispatch,
  } = useGeneralContext();

  const {
    user: { customer },
    lender: { defaultLender },
    order,
    states,
    tenant: { companyCode },
    dealer: { dealer_code },
  } = appState;
  const navigate = useNavigate();
  const { email, first_name, last_name, customer_addresses } = customer;
  const { estimated_monthly_payment } = order;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(basicValidatorSoftPull),
    defaultValues: {
      first_name: first_name,
      last_name: last_name,
      address_line_1: customer_addresses.garaging.address_line_1 ?? "",
      address_line_2: customer_addresses.garaging.address_line_2,
      city: customer_addresses.garaging.city ?? "",
      state_name: customer_addresses.garaging.state_name ?? "",
      zip_code: customer_addresses.garaging.zip_code ?? "",
    },
  });

  const finalizeDeal = () => {
    toggleContentApr();
    !creditfreez &&
      appDispatch({ type: ActionType.DEAL, payload: { finalize: true } });
  };
  const [handleSelect, setHandleSelect] = useState(false);
  const { mutateAsync: updateCustomerData } = useCustomerUpdate({
    reference_id: appState.user.customer.reference_id,
    dealer_code: appState.dealer.dealer_code,
  });
  const { mutate: creditCheck } = useGetCustomerCreditCheck();
  const onSubmit = (data: any) => {
    localStorage.setItem(
      "estimated_monthly_payment",
      JSON.stringify(estimated_monthly_payment)
    );
    const customerUpdate = {
      customer_profile: {
        first_name: data.first_name,
        last_name: data.last_name,
        city: data.city,
        state_name: data.state_name,
        zip_code: data.zip_code,
        updated_by: email,
      },
      customer_addresses: [
        {
          address_line_1: data.address_line_1,
          address_line_2: data.address_line_2,
          city: data.city,
          address_type: "Garaging",
          entity_type: null,
          state_name: data.state_name,
          is_garaging: true,
          zip_code: data.zip_code,
        },
      ],
    };
    if (!customer_addresses.mailing.address_line_1) {
      customerUpdate.customer_addresses.push({
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        address_type: "Mailing",
        entity_type: null,
        state_name: data.state_name,
        is_garaging: true,
        zip_code: data.zip_code,
      });
    }
    updateCustomerData(
      {
        ...customerUpdate,
        type: "",
      },
      {
        onSuccess: (customerInfo) => {
          const filterData = filterCustomerData(customerInfo);
          creditCheck(
            {
              customerReferenceId: customerInfo.reference_id,
              dealCode: dealer_code,
            },
            {
              onSuccess: (result) => {
                if (result.detail) {
                  appDispatch(updateProgramerror(true));
                  return;
                }
                if (!result.transunion.risk_model) {
                  appDispatch(updateCreditFreeze(true));
                }
                appDispatch(
                  setOrderInfo({
                    ...order,
                    credit_score: result.transunion.risk_model.score,
                  })
                );
                getProgram(
                  {
                    ...getAllProgramPayload(
                      order.order_asset,
                      companyCode,
                      defaultLender.external_code,
                      dealer_code,
                      order.finance_type,
                      order.contract_term,
                      order.annual_usage,
                      order.credit_rating,
                      result.transunion.risk_model.score
                    ),
                  },
                  {
                    onSuccess: async (programRes: any) => {
                      await setOrderValues({
                        ...order,
                        apr: programRes.final_customer_rate,
                        down_payment:
                          programRes.downpayment_chart[0]?.default_down_payment,
                        rental_mode: programRes.rental_mode,
                        rv_balloon_percentage: programRes?.assetRvAmount,
                      });
                      const customerData = {
                        ...customer,
                        ...filterData,
                        fetchedData: true,
                      };
                      const newDate = formatDate(
                        result.transunion.cache_expiry_date
                      );
                      appDispatch(updateDateCache(newDate));
                      appDispatch(updateCustomer(customerData));
                      finalizeDeal();
                    },
                  }
                );
              },
            }
          );
        },
      }
    );
  };

  return (
    <div className="fl-pricing-drawer--form-wrap">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fl-pricing-drawer--form">
          <div className="fl-pricing-drawer--form-row">
            <div className="flex-1">
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="first_name"
                    placeholder="First name"
                    label="First name"
                    error={error}
                  />
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="last_name"
                    placeholder="Last name"
                    label="Last name"
                    error={error}
                  />
                )}
              />
            </div>
          </div>
          <div className="fl-pricing-drawer--form-row">
            <div className="flex-1">
              <Controller
                name="address_line_1"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    value={field.value || ""}
                    id="address_1"
                    placeholder="Apartment, suite, unit"
                    label="Address 1"
                    error={error}
                  />
                )}
              />
            </div>
          </div>
          <div className="fl-pricing-drawer--form-row">
            <div className="flex-1">
              <Controller
                name="address_line_2"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    value={field.value || ""}
                    id="address_2"
                    placeholder="Apartment, suite, unit"
                    label="Address 2 (optional)"
                    error={error}
                  />
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                name="city"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="city"
                    value={field.value || ""}
                    placeholder="City"
                    label="City"
                    error={error}
                  />
                )}
              />
            </div>
          </div>
          <div className="fl-pricing-drawer--form-row">
            <div className="flex-1">
              <Controller
                name="state_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="state_name"
                    value={field.value}
                    options={states}
                    placeholder="Select"
                    label="State"
                    error={error}
                  />
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                name="zip_code"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="zip_code"
                    value={field.value || ""}
                    placeholder="ZIP Code"
                    label="ZIP Code"
                    error={error}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fl-pricing-drawer--checkbox-wrap">
          <Checkbox
            id={"dealer-pricing--accept-terms"}
            label="I consent to BMW Financial Services doing a soft credit pull in order to provide a more accurate APR. I understand that this does not affect my credit."
            htmlFor="dealer-pricing--accept-terms"
            onChange={(val) => setHandleSelect(val)}
            isCompact
          />
        </div>
        <Button
          type="default"
          htmlType="submit"
          disabled={!handleSelect}
          text="Unlock accurate pricing"
        />
      </form>
    </div>
  );
};
