import { Controller } from "react-hook-form";
import { useState } from "react";
import { Button, Input } from "@src/components/atoms";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";

import "../../atoms/form-inputs/form-input.style.css";
import "./personal-details.scss";
import MaskedInput from "@src/components/atoms/input/input.maskwrapped";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

export const PersonalDetails = ({
  handleSubmit,
  formControl,
  showSSNValue,
  isPending,
  errors,
  clearErrors,
}: {
  formControl;
  handleSubmit;
  showSSNValue;
  errors;
  isPending;
  clearErrors;
}) => {
  const [showSSN, setShowSSN] = useState(showSSNValue);

  return (
    <div className="form-wrapper">
      <p className="form-wrapper header text--body-2">
        To confirm your identity, please provide the following information.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="form-wrapper personal-details-form-wrapper personal-details-form">
          <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
            <Controller
              name="first_name"
              control={formControl}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id={"first_name"}
                  disabled={isPending}
                  placeholder="First name"
                  label="First name"
                  {...field}
                  error={error}
                />
              )}
            />
            <Controller
              name="middle_name"
              control={formControl}
              render={({ field }) => (
                <Input
                  id="middle_name"
                  disabled={isPending}
                  placeholder="Middle name"
                  label="Middle name (optional)"
                  {...field}
                />
              )}
            />

            <Controller
              name="last_name"
              control={formControl}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id="last_name"
                  disabled={isPending}
                  placeholder="Last name"
                  label="Last name"
                  {...field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
            <Controller
              name="date_of_birth"
              control={formControl}
              render={({ field, fieldState: { error } }) => (
                <Datepicker
                  id="date_of_birth"
                  maxDate={dayjs(new Date())}
                  placeholder="Choose"
                  disabled={isPending}
                  label="Date of birth"
                  error={error}
                  format="MMM DD, YYYY"
                  {...field}
                />
              )}
            />
            <Controller
              name="ssn"
              control={formControl}
              render={({
                field: { onChange, ...rest },
                fieldState: { error },
              }) => (
                <MaskedInput
                  id="social_security_number"
                  type={showSSN ? "password" : "text"}
                  placeholder="000-00-0000"
                  label="Social security number"
                  disabled={isPending}
                  error={error}
                  onChange={(e) => {
                    onChange(e.target.value.replace(/\D/g, "").slice(0, 9));
                    setShowSSN(false);
                  }}
                  mask="999-99-9999"
                  {...rest}
                />
              )}
            />
          </div>
          <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
            <Controller
              name="home_number"
              control={formControl}
              render={({ field, fieldState: { error } }) => (
                <Phone
                  country="us"
                  disabled={isPending}
                  label="Phone number"
                  error={error}
                  clearErrors={clearErrors}
                  formControl={formControl}
                  {...field}
                />
              )}
            />
            <Controller
              name="mobile_number"
              control={formControl}
              defaultValue=""
              render={({ field }) => (
                <Phone
                  country="us"
                  disabled={isPending}
                  label="Mobile number (optional)"
                  {...field}
                />
              )}
            />
          </div>
          <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
            <Controller
              name="updated_by"
              control={formControl}
              render={({ field }) => (
                <Input
                  id="email_address"
                  disabled
                  placeholder="Email address"
                  label="Email address"
                  type="email"
                  {...field}
                />
              )}
            />
          </div>

          <p className="form-wrapper personal-details-form-wrapper disclaimer text--disclaimer">
            By entering my phone and continuing, I agree that the BMW Center,
            its affiliates, and service providers may make autodialed calls or
            send automated or auto dialed text messages relating to my
            application and vehicle purchase, and related issues. By providing
            us with your phone number, you provide us with your prior written
            express consent to receive such calls or text messages. You
            represent that the phone number is yours. Message and data rates may
            apply. You agree to the BMW Center Terms and Privacy Policy.
          </p>
        </div>
        <div className="form-wrapper form-wrapper--button-top-spacer">
          <Button
            type="default"
            isLoading={isPending}
            disabled={!isEmpty(errors)}
            htmlType="submit"
            text="Save & continue"
            fixed
            fullWidth
          />
        </div>
      </form>
    </div>
  );
};
