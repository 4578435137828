import { Button, Modal } from "@src/components/atoms";
import "./account-management-modal.scss";

export const AccountManagementModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="account-management-modal-wrapper">
        <Modal
          size="compact"
          className="account-management-modal"
          dataIcon={""}
          title={<h3>You’re leaving the BMW Digital Retailing Platform.</h3>}
          subtitle={
            <p className="text--body-1">
              You can only manage your account through My BMW. Changing your
              email address may affect your transactions on this platform. For
              further assistance, please contact your BMW Dealer.
            </p>
          }
          open={isModalOpen}
          onClose={handleClose}
          closable
          footer={
            <>
              <div className="action-btns">
                <Button
                  htmlType="button"
                  fullWidth
                  text="Cancel"
                  onClick={() => {}}
                />

                <Button
                  type="primary"
                  htmlType={"button"}
                  onClick={() => {}}
                  text="Manage account"
                ></Button>
              </div>
            </>
          }
        ></Modal>
      </div>
    </>
  );
};
