import { useEffect, useState } from "react";
import { Button, Checkbox } from "@src/components/atoms";
import "./agreements.style.scss";
import { useNavigate } from "react-router-dom";
import {
  useAddCustomerConsent,
  useGetDefaultLender,
  useGetFraudCheck,
  useOrderSubmit,
  useUpdateDeal,
} from "@src/hooks";
import { orderFraudStatus } from "@src/context/app-actions";
import { Decision } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";

export const Agreements = ({
  stepSixDataReceivedEvent,
}: {
  stepSixDataReceivedEvent: () => void;
}) => {
  const { isPending: FraudLoading, mutate: FraudCheck } = useGetFraudCheck();
  const {
    state: {
      order,
      lender: {
        defaultLender: { id },
      },
      dealer: { disclaimer },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { isPending: OrderSubmitLoading, mutate: submitOrderRequest } =
    useOrderSubmit();
  const navigate = useNavigate();
  const {
    reference_number,
    identifier,
    customer_info: { reference_id },
  } = order;
  const [handleSelect, setHandleSelect] = useState(false);
  const { mutate: updateOrder } = useUpdateDeal();
  const { mutate: addConsent } = useAddCustomerConsent();
  const creditAppDisclaimer = disclaimer["creditApplicationDisclaimer"];
  const sumbitApplication = () => {
    const submitRequest = async () => {
      try {
        if (!creditAppDisclaimer) {
          addConsent({
            customer_reference_id: reference_id,
            disclaimer_name: creditAppDisclaimer?.name,
            disclaimer_version: creditAppDisclaimer?.version,
            order_reference_id: reference_number,
          });
        }
        updateOrder(
          { ...order, identifier: identifier },
          {
            onSuccess: () => {
              submitOrderRequest(
                {
                  referenceNumber: reference_number,
                  lender_ids: [id],
                },
                {
                  onSuccess: () => {
                    FraudCheck(
                      {
                        referenceNumber: reference_number,
                        customer_reference_id: reference_id,
                      },
                      {
                        onSuccess: (response) => {
                          if (
                            Array.isArray(response) &&
                            response[0]?.decision?.value
                          ) {
                            appDispatch(
                              orderFraudStatus(response[0].decision.value)
                            );
                            if (
                              response[0].decision.value === Decision.ACCEPT
                            ) {
                              navigate(
                                `/deal-state/deal/${reference_number}/${response[0].decision.value}`
                              );
                            } else {
                              navigate(
                                `/dealer-contact/deal/${reference_number}`
                              );
                            }
                          } else {
                            navigate(
                              `/dealer-contact/deal/${reference_number}`
                            );
                          }
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        );
      } catch (error) {
        console.error("Error submitting order request:", error);
      }
    };
    submitRequest();
  };
  return (
    <div className="unfilled-agreement-container">
      <h2 className="section-header upper-label spacing">
        <span className="section-header number text--subsection-2">06</span>
        <span className="section-header text text--subsection-2">
          Agreements.
        </span>
      </h2>
      <p className="unfilled-agreement-container text--body-2">
        Please review and accept the following terms &amp; conditions before
        submitting your application.
      </p>
      <div className="unfilled-agreement-container checkbox-wrapper">
        <Checkbox
          onChange={(val) => setHandleSelect(val)}
          id={"agreementChk"}
          label=""
          htmlFor="agreementChk"
        />
        {creditAppDisclaimer?.changesDisclaimer && (
          <div
            dangerouslySetInnerHTML={{
              __html: creditAppDisclaimer.changesDisclaimer,
            }}
          />
        )}
      </div>
      <Button
        type="primary"
        fixed
        htmlType={"button"}
        isLoading={OrderSubmitLoading || FraudLoading}
        text="Agree &amp; submit"
        disabled={!handleSelect}
        onClick={() => {
          stepSixDataReceivedEvent;
          sumbitApplication();
        }}
        fullWidth
      />
    </div>
  );
};
