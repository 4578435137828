import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Accordion } from "../../utils/accordion/accordion";
import { Address } from "../address/address";
import { Tooltip, Checkbox, Button } from "@src/components/atoms";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useAppContext } from "@src/context/app-context";
import { useCustomerUpdate, useGetDealerFees } from "@src/hooks";
import { setOrderInfo, updateCustomer } from "@src/context/app-actions";
import {
  calculateMonthsDifference,
  filterCustomerData,
  getCustomerAddress,
  parseStringBoolenStatus,
} from "@src/helpers/appHelper";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressValidator } from "@src/validators";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";

import "./current-and-past-addresses.scss";
import {
  AddressTypeSelector,
  SpouseInfoDisclosure,
} from "@src/components/molecules";
import {
  useGetCustomerDisclosure,
  useUpdateCustomerDisclosure,
  useVerifyAddress,
} from "@src/hooks/customer";
import {
  STATES_REQUIRING_ADDRESS_TYPE,
  STATES_REQUIRING_SPOUSE_INFO,
} from "@src/helpers/enum";

const generateAddress = (type: string, isSame: boolean, address: any) => {
  if (isSame) {
    return { address_type: type, entity_type: "Mailing" };
  }
  return {
    address_type: type,
    ...address,
    move_in_date: address.move_in_date
      ? dayjs(address.move_in_date).format("YYYY-MM-DD")
      : null,
    identifier: address.identifier ? address.identifier : null,
    entity_type: null,
  };
};

export const CurrentAndPastAddresses = ({
  stepTwoDataReceivedEvent,
}: {
  stepTwoDataReceivedEvent: () => void;
}) => {
  const {
    state: {
      user: { customer },
      order,
      tenant: { min_months_stay_at_address },
      dealer: { dealer_code, dealer_address },
      states,
      statesOriginalRes,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { customer_addresses, reference_id } = customer;
  const [showPrevAddress, setPrevAddressVisible] = useState<boolean>(
    !!customer_addresses.previous
  );
  const { coapplicant_info } = order;

  const [showAddressTypeSelector, setShowAddressTypeSelector] =
    useState<boolean>(false);
  const isSpouseAddressToPrimary =
    customer?.disclosure?.spouse?.address?.address_line_1 ===
    customer_addresses.mailing.address_line_1;
  const { state_name: dealerState } = dealer_address;
  const { control, handleSubmit, watch, setValue } = useForm<any>({
    resolver: yupResolver(addressValidator(showPrevAddress)),
    defaultValues: {
      previous: customer_addresses.previous || {},
      mailing: customer_addresses.mailing || {},
      billing: customer_addresses.billing || {},
      garaging: customer_addresses.garaging || {},
      billingSameAsMailing: !!customer_addresses.billing?.entity_type,
      garagingSameAsMailing: !!customer_addresses.garaging?.entity_type,
      address_type:
        customer_addresses.mailing?.address_location_type || "Residence",
      isMarried: parseStringBoolenStatus(
        customer?.disclosure?.customer?.marital_status
      ),
      isSpouseCoApplicant: false,
      isCoApplicant: coapplicant_info?.email !== "",
      spouse: customer?.disclosure?.spouse || {
        firstName: "",
        lastName: "",
        address: {
          address_line_1: "",
          address_line_2: "",
          address_location_type: "",
          city: "",
          county: "",
          state: "",
          zip_code: "",
        },
      },
      spouseAddressSameAsMailing: isSpouseAddressToPrimary,
    },
  });

  const { mutateAsync: updateCustomerData, isPending } = useCustomerUpdate({
    reference_id: reference_id,
    dealer_code: dealer_code,
  });
  const { mutateAsync: getDealerFees } = useGetDealerFees();
  const { mutate: getCustomerDisclosure } = useGetCustomerDisclosure();
  const { mutateAsync: verifyAddressByZipCode } = useVerifyAddress();
  const { setOrderValues } = useComputedQuotation();
  const { mutate: saveCustomerDisclosure } = useUpdateCustomerDisclosure();
  const billingSameAsMailing = watch("billingSameAsMailing");
  const garagingSameAsMailing = watch("garagingSameAsMailing");
  const garaging = watch("garaging");
  const mailingAddress = watch("mailing");
  const billing = watch("billing");
  const isMarried = watch("isMarried");
  const isSpouseCoApplicant = watch("isSpouseCoApplicant");
  const spouseAddressSameAsMailing = watch("spouseAddressSameAsMailing");

  useEffect(() => {
    if (coapplicant_info?.email !== "") {
      setValue("isCoApplicant", true);
    } else {
      setValue("isCoApplicant", false);
    }
  }, [coapplicant_info]);

  // Watch for address state changes to determine if we need address type and spouse info
  useEffect(() => {
    if (dealerState) {
      // Check if the state requires address type selection
      setShowAddressTypeSelector(
        STATES_REQUIRING_ADDRESS_TYPE.includes(dealerState)
      );
    } else {
      setShowAddressTypeSelector(false);
    }
  }, [dealerState]);

  useEffect(() => {
    const requiresSpouseInfo = STATES_REQUIRING_SPOUSE_INFO.includes(
      mailingAddress?.state_name
    );
    if (!requiresSpouseInfo) {
      setValue("isMarried", false);
      setValue("isSpouseCoApplicant", false);
    }
  }, [mailingAddress?.state_name]);
  useEffect(() => {
    if (spouseAddressSameAsMailing) {
      const customerAddress = getCustomerAddress(customer_addresses);
      setValue(
        "spouse.address.address_line_1",
        customerAddress.street_address_1
      );
      setValue(
        "spouse.address.address_line_2",
        customerAddress.street_address_2
      );
      setValue("spouse.address.city", customerAddress.city);
      setValue("spouse.address.state_name", customerAddress.state);
      setValue("spouse.address.zip_code", customerAddress.zip_code);
    }
    if (!spouseAddressSameAsMailing && isMarried && !isSpouseCoApplicant) {
      //Already exists data
      if (customer?.disclosure?.spouse?.address?.address_line_1 !== "") {
        setValue(
          "spouse.address.address_line_1",
          customer?.disclosure?.spouse?.address?.address_line_1
        );
        setValue(
          "spouse.address.address_line_2",
          customer?.disclosure?.spouse?.address?.address_line_2
        );
        setValue(
          "spouse.address.city",
          customer?.disclosure?.spouse?.address?.city
        );
        setValue(
          "spouse.address.county",
          customer?.disclosure?.spouse?.address?.county
        );
        setValue(
          "spouse.address.state_name",
          customer?.disclosure?.spouse?.address?.state_name
        );
        setValue(
          "spouse.address.zip_code",
          customer?.disclosure?.spouse?.address?.zip_code
        );
      } else {
        setValue("spouse.address.address_line_1", "");
        setValue("spouse.address.address_line_2", "");
        setValue("spouse.address.city", "");
        setValue("spouse.address.county", "");
        setValue("spouse.address.state_name", "");
        setValue("spouse.address.zip_code", "");
      }
    }
  }, [spouseAddressSameAsMailing, setValue, dealerState]);

  useEffect(() => {
    const move_in_date = watch("mailing")?.move_in_date;
    if (move_in_date && min_months_stay_at_address) {
      if (
        calculateMonthsDifference(move_in_date) < min_months_stay_at_address
      ) {
        setPrevAddressVisible(true);
      } else {
        setPrevAddressVisible(false);
      }
    }
  }, [watch("mailing")?.move_in_date]);

  const onSubmit: SubmitHandler<any> = (values) => {
    const selectedAddressType = showAddressTypeSelector
      ? values.address_type
      : undefined;

    // Prepare spouse info if needed
    const customerAddresses = [
      {
        ...values.mailing,
        address_type: "Mailing",
        address_location_type: selectedAddressType,
        move_in_date: values.mailing?.move_in_date
          ? dayjs(values.mailing.move_in_date).format("YYYY-MM-DD")
          : null,
        identifier: values.mailing?.identifier
          ? values.mailing.identifier
          : null,
      },
      ...(showPrevAddress
        ? [generateAddress("Previous", false, values.previous)]
        : []),

      generateAddress("Billing", billingSameAsMailing, values.billing),
      generateAddress("Garaging", garagingSameAsMailing, values.garaging),
    ];

    updateCustomerData(
      {
        customer_addresses: customerAddresses,
        type: "",
      },
      {
        onSuccess: (customerInfo) => {
          const filterData = filterCustomerData(customerInfo);
          const customerData = { ...customer, ...filterData };
          let dealerFees: any[] = order.order_fees.length
            ? order.order_fees
            : [];

          const stateName = garagingSameAsMailing
            ? values.mailing.state_name
            : values.garaging.state_name;

          const customerState = statesOriginalRes.find(
            (state: any) => state.name === stateName
          ) || { code: "" };
          if (customerState.code) {
            getDealerFees(
              {
                dealerCode: dealer_code,
                stateCode: customerState.code,
              },
              {
                onSuccess(dealerFeesRes) {
                  dealerFees = dealerFeesRes;
                },
                onError(error) {
                  console.error("Error getting dealer Fees", error);
                },
              }
            );
          }

          setOrderValues({
            ...order,
            order_fees: dealerFees,
            customerV2Address: garagingSameAsMailing
              ? {
                  ...values.mailing,
                }
              : { ...values.garaging },
          });
          const saveDisclosurePayload = {
            spouse:
              values.isMarried && !values.isSpouseCoApplicant
                ? {
                    first_name: values.spouse.first_name,
                    last_name: values.spouse.last_name,
                    address: values.spouse.address,
                  }
                : null,
            customer: {
              marital_status: isMarried,
            },
            is_spouse_as_coapplicant: isSpouseCoApplicant,
            reference_number: order.reference_number,
            customer_id: order?.customer_id,
          };
          saveCustomerDisclosure(
            { ...saveDisclosurePayload },
            {
              onSuccess: (res) => {
                getCustomerDisclosure(
                  { reference_number: order.reference_number },
                  {
                    onSuccess: (response: any) => {
                      const customerDisclosure = {
                        ...customerData,
                        disclosure: response,
                      };
                      appDispatch(updateCustomer(customerDisclosure));
                      stepTwoDataReceivedEvent();
                    },
                  }
                );
                // appDispatch(updateCustomer(customerData));
              },
            }
          );
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  };
  return (
    <div className="checkout">
      <div>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <div>
          <div className="checkout-info-wrapper">
            <p className="checkout-subtitle text--body-2">
              Please provide your current permanent address.
              {/* <i className="checkout-info-icon" />
              <Tooltip /> */}
            </p>
            <p className="checkout-info text--label">
              <i className="checkout-title-icon" />
              If you've lived at this address for less than 11 months, you'll
              need to enter your previous address as well.
            </p>
          </div>
        </div>
      </div>
      <div className="checkout-initial-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="checkout-form">
          <Address type="mailing" control={control} stateOptions={states} />

          <div className="checkout-form-spacing checkout-column-wrapper">
            <Controller
              name="mailing.move_in_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Datepicker
                  id="move_in_date"
                  placeholder="Choose"
                  maxDate={dayjs(new Date())}
                  label="Move-in date"
                  error={error}
                  format="MMM DD, YYYY"
                  {...field}
                />
              )}
            />
          </div>
          {showAddressTypeSelector && <AddressTypeSelector control={control} />}
          {showPrevAddress && (
            <>
              <p className="checkout-subtitle text--body-2">
                Please provide your previous address.
              </p>
              <Address
                type="previous"
                control={control}
                stateOptions={states}
              />
              <div className="checkout-form-spacing checkout-column-wrapper">
                <Controller
                  name="previous.move_in_date"
                  control={control}
                  render={({ field }) => (
                    <Datepicker
                      id="move_in_date"
                      placeholder="Choose"
                      maxDate={dayjs(new Date())}
                      label="Move-in date"
                      format="MMM DD, YYYY"
                      {...field}
                    />
                  )}
                />
              </div>
            </>
          )}

          {!billingSameAsMailing && billing && <hr className="checkout-hr" />}

          {/*<MoveDateAddress />*/}

          {billing && (
            <div className={billingSameAsMailing ? "billing-address-wrap" : ""}>
              <div className="checkout-billing-description">
                Please Provide your billing address
              </div>
              <Controller
                name="billingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id={"provdeBillingChk"}
                    checked={value}
                    label="Same as current (mailing) address"
                    htmlFor="provdeBillingChk"
                    {...rest}
                  />
                )}
              />
              {!billingSameAsMailing && (
                <Address
                  type="billing"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!garagingSameAsMailing && !billingSameAsMailing && (
            <hr className="checkout-hr" />
          )}

          {garaging && (
            <div className="garging-address-wrap">
              <div className="checkout-billing-description">
                Please Provide your garaging address
              </div>
              <Controller
                name="garagingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id="provdeMailingChk"
                    label="Same as current (mailing) address"
                    checked={value}
                    htmlFor="provdeMailingChk"
                    {...rest}
                  />
                )}
              />
              {!garagingSameAsMailing && (
                <Address
                  type="garaging"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!(billing || garaging) && (
            <p className="text--body-2 different-billing-garging">
              If your garaging or billing address is different from your
              permanent address, please provide them below.
              <i className="checkout-info-icon" /> <Tooltip />
            </p>
          )}
          <div className="checkout-accordion-container">
            <Accordion
              billingAddress={billing}
              garagingAddress={garaging}
              addBillingAddressHandler={() => {
                setValue("billing", {});
              }}
              addGaragingAddressHandler={() => {
                setValue("garaging", {});
              }}
            />
          </div>

          <SpouseInfoDisclosure
            control={control}
            watch={watch}
            stateOptions={states}
          />

          <div className="form-wrapper form-wrapper--button-top-spacer">
            <Button
              type="default"
              isLoading={isPending}
              htmlType="submit"
              text="Save & continue"
              fixed
              fullWidth
            />
          </div>
        </form>
      </div>
    </div>
  );
};
