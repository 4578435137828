import { Button } from "@src/components/atoms";
import "./current-and-past-addresses-completed.style.scss";
import { calculateDateDifference } from "@src/helpers/appHelper";

export const CurrentAndPastAddressesCompleted = ({
  changeStateOfEditModeHandler,
  customer: {
    customer_addresses: { mailing, billing, garaging, previous },
  },
}: {
  customer;
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  return (
    <div className="checkout-step-info">
      <div className="checkout-success-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-two")}
          size="small"
        />
      </div>
      <div className="checkout-success-info">
        <div className="checkout-success-info-container">
          <div className="checkout-success-info-section">
            <p className="checkout-success-title text--label">
              Current address
            </p>
            <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
              {mailing.address_line_1} {mailing.address_line_2},
            </p>
            <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
              {mailing.city}, {mailing.state_name} {mailing.zip_code}
              {mailing.county ? `, ${mailing.county}` : ""}
            </p>
          </div>
          {mailing.move_in_date && (
            <>
              <p className="checkout-success-title text--label">
                You lived here for
              </p>
              <p className="checkout-success-subtitle text--body-2">
                {calculateDateDifference(mailing.move_in_date)}
              </p>
            </>
          )}
        </div>
        {previous && (
          <>
            <div className="checkout-success-info-block" />
            <div className="checkout-success-info-container">
              <div className="checkout-success-info-section">
                <p className="checkout-success-title text--label">
                  Previous address
                </p>
                <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                  {previous.address_line_1} {previous.address_line_2},
                </p>
                <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                  {previous.city}, {previous.state_name} {previous.zip_code}
                  {previous.county ? `, ${previous.county}` : ""}
                </p>
              </div>
              {previous.move_in_date && (
                <>
                  <p className="checkout-success-title text--label">
                    You lived here for
                  </p>
                  <p className="checkout-success-subtitle text--body-2">
                    {calculateDateDifference(previous.move_in_date)}
                  </p>
                </>
              )}
            </div>
          </>
        )}
        <div className="checkout-success-info-block" />
        <div className="checkout-billing-mailing-container">
          <div>
            <div className="checkout-success-billing-section">
              <p className="checkout-success-title text--label">
                Billing address
              </p>
              {!!billing?.entity_type && (
                <p className="checkout-success-subtitle text--body-2">
                  Same as current address
                </p>
              )}
              {billing && !billing?.entity_type && (
                <>
                  <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                    {billing.address_line_1} {billing.address_line_2},
                  </p>
                  <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                    {billing.city}, {billing.state_name} {billing.zip_code}
                    {billing.county ? `, ${billing.county}` : ""}
                  </p>
                </>
              )}
            </div>
          </div>
          <div>
            <p className="checkout-success-title text--label">
              Garaging address
            </p>
            {!!garaging.entity_type && (
              <p className="checkout-success-subtitle text--body-2">
                Same as current address
              </p>
            )}

            {garaging && !garaging.entity_type && (
              <>
                <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                  {garaging.address_line_1} {garaging.address_line_2},
                </p>
                <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
                  {garaging.city}, {garaging.state_name} {garaging.zip_code}
                  {garaging.county ? `, ${garaging.county}` : ""}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
