import { Switch } from "@src/components/atoms";
import Logo from "../../../../../shared/assets/img/logo.jpg";

interface PaymentSwitchProps {
  isSwitchOn: boolean;
  onToggle: (checked: boolean) => void;
  disabled?: boolean;
}

export const PaymentSwitch = ({
  isSwitchOn,
  onToggle,
  disabled = false,
}: PaymentSwitchProps) => {
  return (
    <div className="payment-settings__switch">
      <div className="payment-settings-switch__content">
        <h5>
          <img className="checkout-header-logo" src={Logo} alt="BMW Logo" />
          Automatic monthly payments (EasyPay)
        </h5>
        <Switch checked={isSwitchOn} onChange={onToggle} disabled={disabled} />
      </div>
      <p className="text--body-2">
        By toggling, you are authorizing BMW FS to process payments. It will not
        be activated until the account is booked by FS.
      </p>
      <div className="divider"></div>
    </div>
  );
};
