import { Button, Image } from "@src/components/atoms";
import "./hero-section.scss";
import Vehicle from "../../../../shared/assets/img/dr-start/vehicle.png";
import { HeroContent, BannerInfoContent } from "@src/components/molecules";
import clsx from "clsx";
import { useDealContext } from "@src/context/deal-context/deal-context";

export const HeroSection = ({
  buildInProgress,
  checkoutInProgress,
  hasAlert,
  haveDeals,
  region,
}: {
  buildInProgress?: boolean;
  checkoutInProgress?: boolean;
  haveDeals?: boolean;
  hasAlert?: boolean;
  region?:
    | "western-region"
    | "central-region"
    | "southern-region"
    | "northeast-region";
}) => {
  const { no_longer_available, reservationExpired } = useDealContext();

  return (
    <div
      className={clsx({
        "dr-start-hero-section": true,
        "have-deals": haveDeals,
        "western-region": region === "western-region",
        "central-region": region === "central-region",
        "southern-region": region === "southern-region",
        "northeast-region": region === "northeast-region",
      })}
    >
      <div className="bmw-container">
        <div className="dr-start-hero__inner">
          <HeroContent
            buildInProgress={buildInProgress}
            checkoutInProgress={checkoutInProgress}
            hasAlert={hasAlert}
          />
          <div className="dr-start-hero__img">
            <Image src={Vehicle} alt="image" aspectRatio="16:9" />
          </div>
        </div>
        <div className="dr-start-hero__inner--after d-block d-lg-none">
          <BannerInfoContent
            buildInProgress={buildInProgress}
            checkoutInProgress={checkoutInProgress}
            hasAlert={hasAlert}
          />
        </div>
        {haveDeals ? (
          <div className="dr-start-hero__bottom">
            <p className="text--label text-white fw-normal">
              View additional saved deals
            </p>
            <Button
              htmlType={"button"}
              linkButton
              withIcon
              dataIcon="arrow_down"
              onDark
            />
          </div>
        ) : (
          !no_longer_available &&
          !reservationExpired && (
            <div>
              <p className="text--label text-white dr-start-hero__link">
                By clicking build deal, I agree to the BMW Center{" "}
                <a className="text-white fw-normal no-decoration" href="/">
                  Terms
                </a>{" "}
                and{" "}
                <a className="text-white fw-normal no-decoration" href="/">
                  Privacy Policy
                </a>
                . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea consequat.
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};
