import "./product-details-drawer-content.scss";
import { Button, Image, MultiSwatchCircles } from "@src/components/atoms";
import Image1 from "../../../../shared/assets/img/accessories-list-card-drawer-content-img.png";
import { IncentivesListCard } from "../../incentives-list-card/incentives-list-card.component";
import Image2 from "../../../../shared/assets/img/sheet-protection-product-1.png";
import Image3 from "../../../../shared/assets/img/sheet-protection-product-2.png";
import Image4 from "../../../../shared/assets/img/sheet-protection-product-3.png";
import Image5 from "../../../../shared/assets/img/sheet-protection-product-4.png";

import SwatchOne from "../../../../shared/assets/img/multi-swatch-circle__one.png";
import SwatchTwo from "../../../../shared/assets/img/multi-swatch-circle__two.png";
import SwatchThree from "../../../../shared/assets/img/multi-swatch-circle__three.png";

export const ProductDetailsDrawerContentAccessories = () => {
  const swatchArray = [SwatchOne, SwatchTwo, SwatchThree];

  return (
    <>
      <div className="product-details-sheet-header">
        <div className="product-details-sheet-header__web-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              6 pre-installed accessories by BMW of Manhattan.
            </div>
          </div>

          <div className="swatch-container">
            <MultiSwatchCircles images={swatchArray} maxVisible={2} />
          </div>
        </div>

        <div className="product-details-sheet-header__mobile-text">
          <div className="product-details-sheet-header__heading">
            <div className="text--h3">
              6 pre-installed accessories by BMW of Manhattan.
            </div>
          </div>

          <div className="text--body-2">
            [BMW of Manhattan] has pre-configured this bundle with coverage
            terms of 72 months / 100,000 miles. To adjust these settings, please{" "}
            <span className="text--link-2 text-underline ">
              contact dealer.
            </span>
          </div>
        </div>
      </div>
      <div className="product-details-sheet-protection-product__list-wrap">
        <IncentivesListCard
          image={Image2}
          title={"BMW M Performance Exhaust Tips in Carbon Fiber/Titanium"}
          subTitle={
            <>
              <p className="text--body-2">
                The round M Performance titanium and carbon tailpipe trims
                replace the standard round tailpipe trims and upgrade the rear
                of the car with their high quality and sporty appearance. The M
                logo embossed on the top of the tailpipe trim underlines its
                motorsport credentials and creates an additional sporty
                highlight. Jorem ipsum dolor sit amet, consectetur adipiscing
                elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                dignissim, metus nec fringilla accumsan, risus sem sollicitudin
                lacus, ut interdum tellus elit sed risus. Maecenas eget
                condimentum velit, sit amet feugiat lectus.
              </p>
              <br />
              <p className="text--body-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </>
          }
          className=""
          actionsArea={
            <Button
              htmlType={"button"}
              text="Show more"
              linkButton
              withIcon
              dataIcon="arrow_chevron_down"
            />
          }
        />

        <IncentivesListCard
          image={Image3}
          title={"BMW M Performance Fuel Filler Cap in Carbon Fiber"}
          subTitle={
            "The M Performance Fuel Filler Cap in Carbon Fiber delivers a clear optical upgrade to the standard fuel cap and is extremely resistant to fuel. This product is made of high-quality carbon, a material widely used in motorsport, to create a modern and premium look."
          }
          className=""
        />

        <IncentivesListCard
          image={Image4}
          title={"BMW M Performance Steering Wheel Trims"}
          subTitle={
            "Cover in frozen carbon fiber (openpored carbon fiber) / alcantara with M Performance lettering 3.0. For M Perfromance Steering Wheel."
          }
          className=""
        />

        <IncentivesListCard
          image={Image5}
          title={
            "BMW M Performance Complete Wheel and Tire Set, Style 669M, 20 Inch in Jet Black Matte"
          }
          subTitle={
            <span className="">
              Wheels: 8Jx20 H2 IS30, 9Jx20 H2 IS44 <br /> Tires: 245/35 R20 95Y
              RSC, 275/30 R20 97Y RSC
            </span>
          }
          className=""
        />
      </div>
    </>
  );
};
