import "./review-list-card.scss";

import { ReviewListCardProps } from "./review-list-card.props";
import clsx from "clsx";
import { Button, Image } from "@src/components/atoms";

export const ReviewListCard = ({
  className,
  image,
  smallTitle,
  title,
  subTitle,
  termsInfo,
  price,
  totalPrice,
  isCardDisabled,
  hasCheckbox,
  actionsArea,
  feedbackLable,
  coverage,
  tag,
  legend,
  itemCount,
  isDeletedButton = true,
}: ReviewListCardProps) => {
  return (
    <div
      className={clsx(className, {
        "review--card": true,
        is_row_disabled: isCardDisabled,
      })}
    >
      <div className="review--card-flexible">
        {hasCheckbox && (
          <div className="review--card-checkbox">{hasCheckbox}</div>
        )}
        {image && (
          <div className="review--card-image review--card-image__withText">
            {/* {image && <Image src={image} alt="card-image" aspectRatio="1:1" />} */}
            <Image src={image} alt="card-image" aspectRatio="1:1" />
            {itemCount && (
              <p className="text--body-2 overlay-image-text">{itemCount}</p>
            )}
          </div>
        )}

        <div className="review--card-right">
          <div className="review--card-right__flexible">
            <div className="review--card-right__text">
              <small className="small text--disclaimer">{smallTitle}</small>
              <span className="text--body-2 title">{title}</span>

              {termsInfo && termsInfo}
              {subTitle && (
                <p className="text--disclaimer">
                  <p className="text--label subtitle">{subTitle}</p>
                </p>
              )}
            </div>
            <div className="review--card-right__price">
              <div className="text--body-focus-price">{price}</div>
              {totalPrice && <p className="text--disclaimer">{totalPrice}</p>}
            </div>

            {isDeletedButton && (
              <div className="review--card-right__delete-icon">
                <Button
                  htmlType="button"
                  linkButton
                  onlyIcon
                  dataIcon="trash_can"
                  className="text-secondary"
                />
              </div>
            )}
          </div>

          {coverage && (
            <div className="coverage">
              {tag && (
                <div className="coverage__tag">
                  <span className="text--disclaimer tag">{tag}</span>
                </div>
              )}
              <div className="coverage__list">{coverage}</div>
            </div>
          )}

          {legend && (
            <div className="legend">
              <div className="legend__list">{legend}</div>
            </div>
          )}

          {actionsArea && (
            <div className="review--card_actions">{actionsArea}</div>
          )}
        </div>
      </div>
      {feedbackLable && (
        <div className="review--card_feedback">{feedbackLable}</div>
      )}
    </div>
  );
};
