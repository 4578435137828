import { Button, OverflowMenu } from "@src/components/atoms";
import "./checkout-header.scss";
import Logo from "../../shared/assets/img/logo.jpg";
import { useAppContext } from "@src/context/app-context";
import { BMW_BASE, URL_BMW } from "@src/constants/routes";
import { DisclaimersType } from "@src/constants/enum";
import { toggleDisclaimer } from "@src/context/app-actions";

export const CheckoutHeader = () => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const {
    user: {
      customer: { first_name },
    },
    dealer: { dealer_name, logo },
  } = appState;
  const theme = JSON.parse(localStorage.getItem("dealer") || "{}");
  const updatedUserCredentials = JSON.parse(
    localStorage.getItem("customer") || "{}"
  );
  const menuItemsInnerDesktop = [
    {
      key: "1",
      label: "My BMW account",
      dataIconRight: "external_link",
      disabled: false,
    },
    {
      key: "2",
      label: "Log out",
      dataIconRight: "logout",
      disabled: false,
    },
  ];
  const menuItemsInnerMobile = [
    {
      key: "1",
      label: "How it works",
      dataIconRight: "monitor_with_magnifying_glass",
      disabled: false,
    },
    {
      key: "2",
      label: "My BMW account",
      dataIconRight: "external_link",
      disabled: false,
    },
    {
      key: "3",
      label: "Log out",
      dataIconRight: "logout",
      disabled: false,
    },
  ];
  const menuItems = [
    {
      key: "1",
      label: "Finalize in person",
      dataIconRight: "dealer",
      disabled: false,
    },
    {
      key: "2",
      label: "How it works",
      dataIconRight: "monitor_with_magnifying_glass",
      disabled: false,
    },
    {
      key: "3",
      label: "Sign in",
      dataIconRight: "login",
      disabled: false,
    },
  ];
  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );
  return (
    <header className="checkout-header">
      <div className="checkout-header-left">
        <img
          className="checkout-header-logo"
          src={logo ? logo : theme.logo ?? Logo}
          alt="BMW Logo"
        />
        <div className="checkout-header-texts">
          <p className="text--body-2">
            BMW My <span className="fw-bold">Way</span>
          </p>
          <p className="text--disclaimer checkout-header-logo--location">
            <span className="text--disclaimer">
              {dealer_name
                ? dealer_name
                : theme?.dealer_name ?? "BMW of Manhatten"}
            </span>
            <div
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="location_pin"
            />
          </p>
        </div>
      </div>
      <div className="checkout-header-right">
        <div className="checkout-header-user-container d-lg-block d-none">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="Finalize in-person"
            dataIcon="dealer"
          />
        </div>

        <div className="checkout-header-user-container d-lg-block d-none">
          <Button
            linkButton
            withIcon
            htmlType={"button"}
            text="How it works"
            dataIcon="monitor_with_magnifying_glass"
          />
        </div>

        {!updatedUserCredentials?.access_token && (
          <div className="checkout-header-user-container d-lg-block d-none">
            <Button
              linkButton
              withIcon
              htmlType={"button"}
              text="Sign in"
              onClick={() => {
                if (!isDisclaimerAgreed) {
                  appDispatch(
                    toggleDisclaimer({
                      name: DisclaimersType.INCENTIVE_DISCOUNT,
                      isOpen: true,
                      openAt: new Date(),
                    })
                  );
                  return;
                } else {
                  localStorage.setItem("state", JSON.stringify(appState));
                  window.location.href = BMW_BASE + "login" + URL_BMW;
                }
              }}
              dataIcon="login"
            />
          </div>
        )}
        {!updatedUserCredentials?.access_token && (
          <div className="checkout-header-user-container d-lg-none d-block">
            <OverflowMenu
              items={menuItems}
              menuButtonView={true}
              position="topRight"
              trigger={
                <Button
                  onlyIcon
                  htmlType={"button"}
                  dataIcon="menu"
                  withoutBg
                  nogap
                />
              }
            />
          </div>
        )}
        {first_name && (
          <>
            <div className="checkout-header-user-container d-lg-block d-none">
              <OverflowMenu
                items={menuItemsInnerDesktop}
                menuButtonView={true}
                position="topRight"
                trigger={
                  <div className="checkout-header-user-container">
                    <Button
                      linkButton
                      withIcon
                      htmlType="button"
                      text={
                        first_name.slice(0, 1).toUpperCase() +
                        first_name.slice(1)
                      }
                      dataIcon="person"
                      optionalDataIcon="arrow_chevron_down"
                    />
                  </div>
                }
              />
            </div>
            <div className="checkout-header-user-container d-lg-none d-block">
              <OverflowMenu
                items={menuItemsInnerMobile}
                menuButtonView={true}
                position="topRight"
                trigger={
                  <Button
                    onlyIcon
                    htmlType={"button"}
                    dataIcon="menu"
                    withoutBg
                    nogap
                  />
                }
              />
            </div>
          </>
        )}
      </div>
    </header>
  );
};
