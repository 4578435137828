import {
  BannerSection,
  ConsentSection,
  IdConfirmation,
  VerifyCompleteId,
  VerifyIdAfterCapture,
  VerifyIdBeforeCapture,
} from "@src/components/organisms/start-verification";
import { BaseLayout } from "@src/layouts";
import "./start-verification-page.scss";

export const StartVerificationPage = () => {
  return (
    <BaseLayout hasMenu={false} hasFooter={false}>
      <BannerSection />
      {/* <ConsentSection /> */}
      {/* <VerifyIdBeforeCapture /> */}
      {/* <VerifyIdAfterCapture /> */}
      {/* <VerifyCompleteId /> */}
      {/* <IdConfirmation /> */}
    </BaseLayout>
  );
};
