import { Button } from "@src/components/atoms/button/button.component";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { BMW_BASE, URL_BMW } from "@src/constants/routes";
import { FinanceType } from "@src/constants";
import { useAppContext } from "@src/context/app-context";
import { toggleDisclaimer } from "@src/context/app-actions";
import { DisclaimersType } from "@src/constants/enum";

export const FinanceLeasePricingDrawerContentIntro = ({
  toggleContentInfo,
}) => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const {
    order: { finance_type },
  } = appState;
  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );

  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title="Unlock personalized pricing."
          description="A BMW ID is required to ensure your details and credit score are securely saved. Please sign in or register to continue."
          iconName="lock_unlocked"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <div className="action-area--group">
                <Button
                  htmlType={"button"}
                  text="Sign in"
                  type="default"
                  onClick={() => {
                    localStorage.setItem("state", JSON.stringify(appState));
                    if (!isDisclaimerAgreed) {
                      appDispatch(
                        toggleDisclaimer({
                          name: DisclaimersType.INCENTIVE_DISCOUNT,
                          isOpen: true,
                          openAt: new Date(),
                        })
                      );
                      return;
                    } else {
                      toggleContentInfo();
                      window.location.href = BMW_BASE + "login" + URL_BMW;
                    }
                  }}
                />
                <Button
                  htmlType={"button"}
                  text="Register"
                  onClick={() => {
                    localStorage.setItem("state", JSON.stringify(appState));
                    if (!isDisclaimerAgreed) {
                      appDispatch(
                        toggleDisclaimer({
                          name: DisclaimersType.INCENTIVE_DISCOUNT,
                          isOpen: true,
                          openAt: new Date(),
                        })
                      );
                      return;
                    } else {
                      window.location.href = BMW_BASE + "register" + URL_BMW;
                    }
                  }}
                />
              </div>
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title={
            finance_type === FinanceType.LEASE
              ? "Get more Precise monthly payments"
              : "Get a more accurate APR %."
          }
          iconName="lock_locked"
          disabled
        />
      </div>
    </>
  );
};
