import { Button, FileUpload, SelectButton } from "@src/components/atoms";
import "./schedule-pickup.scss";
import { Col, Row } from "antd";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";

export const SchedulePickup = () => {
  const timeSlots = [
    { label: "00:00 AM - 00:30 AM", value: "a" },
    { label: "00:00 AM - 00:30 AM", value: "b" },
    { label: "00:00 AM - 00:30 AM", value: "c" },
    { label: "00:00 AM - 00:30 AM", value: "d" },
    { label: "00:00 AM - 00:30 AM", value: "e" },
    { label: "00:00 AM - 00:30 AM", value: "f" },
  ];

  return (
    <div className="schedule-pickup-container">
      <h3>Schedule your pick up.</h3>
      <div className="schedule-pickup_inner">
        <div className="schedule-pickup__location">
          <h6 className="text--body-1">Your pickup location:</h6>
          <Row gutter={[24, 12]}>
            <Col xs={24} md={12}>
              <span className="text--label text-secondary">
                BMW of Manhattan
              </span>
              <p className="text--body-2">793 11th Ave. New York, NY 30009</p>
            </Col>
            <Col xs={24} md={12}>
              <span className="text--label text-secondary">Phone</span>
              <p className="text--body-2">(555) 555-1234</p>
            </Col>
            <Col xs={24} md={24}>
              <div className="divider"></div>
            </Col>
            <Col xs={24} md={12}>
              <span className="text--label text-secondary">
                Your sales person{" "}
              </span>
              <p className="text--body-2">Ashley Simpson</p>
            </Col>
            <Col xs={24} md={12}>
              <span className="text--label text-secondary">Phone</span>
              <p className="text--body-2">(555) 555-1234</p>
            </Col>
          </Row>
        </div>
        <div className="schedule-pickup_date">
          <h5>When would you like to pick up your new vehicle?</h5>
          <Datepicker
            id="datePicker"
            name="date-picker"
            placeholder="Choose"
            label="Select date"
            showInfoIcon
            prevIcon="arrow_left"
            nextIcon="arrow_right"
          />
        </div>
        <div>
          <h5>
            Available time slots for Sunday,{" "}
            <span className="d-block d-md-inline"> Oct. 19th:</span>
          </h5>
          <SelectButton
            options={timeSlots}
            buttonStyle="solid"
            onChange={(value) => ({})}
          />
        </div>
      </div>
      <div className="schedule-pickup__cta">
        <Button
          type="primary"
          htmlType={"button"}
          text="Request appointment"
          disabled
        />
      </div>
    </div>
  );
};
