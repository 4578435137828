import { createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

export const DealContext = createContext<
  | {
      deal: string;
      haveDeals: boolean;
      primaryBuild: boolean;
      hasAlert: boolean;
      offerAccepted: boolean;
      credit: string;
      reservationExpired: boolean;
      no_longer_available: boolean;
    }
  | undefined
>(undefined);

export const useDealContext = () => {
  const context = useContext(DealContext);
  if (!context) {
    throw new Error("useDealContext must be used within a DealProvider");
  }
  return context;
};

export const DealContextProvider: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const deal = searchParams.get("deal") || ""; // in-progress || checkout
  const no_longer_available =
    searchParams.get("no_longer_available") === "true";

  const haveDeals = searchParams.get("have_deals") === "true";
  const primaryBuild = searchParams.get("primary_build") === "true";

  const reservationExpired = searchParams.get("reservation_expired") === "true";
  const credit = searchParams.get("credit") || ""; // declined || frozen

  const offerAccepted = searchParams.get("offer_accepted") === "true";

  const hasAlert = !!credit || reservationExpired;

  return (
    <DealContext.Provider
      value={{
        deal,
        haveDeals,
        primaryBuild,
        hasAlert,
        offerAccepted,
        credit,
        reservationExpired,
        no_longer_available,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
