import { useState } from "react";
import { Dropdown, Menu } from "antd";
import clsx from "clsx";
import "../../../styles/components/_overflow-menu.scss";
import { OverflowMenuProps } from "./overflow-menu.props";
import { Checkbox } from "../checkbox/checkbox.component";

export const OverflowMenu = ({
  items,
  mode = "regular",
  position = "bottomLeft",
  trigger,
  menuButtonView = false,
}: OverflowMenuProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const handleMenuClick = (key: string) => {
    setSelectedKeys((prevSelected) =>
      prevSelected.includes(key)
        ? prevSelected.filter((item) => item !== key)
        : [...prevSelected, key]
    );
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    key: string
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleMenuClick(key);
    }
  };

  const menu = (
    <Menu
      role="menu"
      items={items.map((item) => ({
        key: item.key,
        label: (
          <div
            className={`dropdown-menu__label ${
              item.disabled ? "disabled" : ""
            } ${
              !item.checkbox && selectedKeys.includes(item.key)
                ? "selected"
                : ""
            }`}
            role="menuitem"
            tabIndex={item.disabled ? -1 : 0}
            onClick={() => !item.disabled && handleMenuClick(item.key)}
            onKeyDown={(e) => !item.disabled && handleKeyDown(e, item.key)}
          >
            <div className="dropdown-menu__content">
              {!item.checkbox && item.dataIconLeft && (
                <span
                  className="bmw-icon bmw-icon--before data-icon dropdown-menu__icon_left"
                  data-icon={item.dataIconLeft}
                  role="presentation"
                ></span>
              )}
              {item.checkbox && (
                <Checkbox aria-label={`Checkbox for ${item.label}`} />
              )}
              <span className="text--body-2">{item.label}</span>
            </div>
            {!item.checkbox && item.dataIconRight && (
              <span
                className="bmw-icon bmw-icon--before data-icon dropdown-menu__icon_right"
                data-icon={item.dataIconRight}
                role="presentation"
              ></span>
            )}
            {selectedKeys.includes(item.key) &&
              !item.checkbox &&
              !item.dataIconRight && (
                <span
                  className="bmw-icon bmw-icon--before data-icon dropdown-menu__checkmark"
                  data-icon="checkmark"
                  role="presentation"
                ></span>
              )}
          </div>
        ),
        disabled: item.disabled,
      }))}
    />
  );

  return (
    <div
      className={clsx("overflow-menu-container", {
        "menu-condensed": mode === "condensed",
        "menu-button-view": menuButtonView === true,
      })}
    >
      <Dropdown
        menu={{ items: menu.props.items }}
        trigger={["click"]}
        placement={position}
        getPopupContainer={(triggerNode) => triggerNode.parentElement!}
        overlayClassName={clsx("overflow-menu-container", {
          "menu-condensed": mode === "condensed",
          "menu-button-view": menuButtonView === true,
        })}
      >
        {trigger}
      </Dropdown>
    </div>
  );
};

export default OverflowMenu;
