import { createContext, useContext, useReducer, ReactNode } from "react";

// Combined state interface
interface FinanceOfferState {
  BankDetails: boolean;
  CreditCardDetails: boolean;
}

// Combined action types
type FinanceOfferAction =
  | { type: "BANK_DETAILS" }
  | { type: "CREDIT_CARD_DETAILS" };

// Combined initial state
const initialState: FinanceOfferState = {
  BankDetails: false,
  CreditCardDetails: false,
};

// Combined reducer
const financeOfferReducer = (
  state: FinanceOfferState = initialState,
  action: FinanceOfferAction
) => {
  switch (action.type) {
    case "BANK_DETAILS":
      return {
        ...state,
        BankDetails: true,
        CreditCardDetails: false,
      };
    case "CREDIT_CARD_DETAILS":
      return {
        ...state,
        BankDetails: false,
        CreditCardDetails: true,
      };
    default:
      return state;
  }
};

// Context type and creation
interface FinanceOfferContextType {
  state: FinanceOfferState;
  dispatch: (action: FinanceOfferAction) => void;
}

const FinanceOfferContext = createContext<FinanceOfferContextType>({
  state: initialState,
  dispatch: () => {},
});

interface FinanceOfferProps {
  children: ReactNode;
}

// Provider component
export const FinanceOfferProvider = ({ children }: FinanceOfferProps) => {
  const [state, dispatch] = useReducer(financeOfferReducer, initialState);

  return (
    <FinanceOfferContext.Provider value={{ state, dispatch }}>
      {children}
    </FinanceOfferContext.Provider>
  );
};

// Custom hook for context usage
export const useFinanceOfferContext = () => {
  const context = useContext(FinanceOfferContext);
  if (context === undefined) {
    console.error(
      "useFinanceOfferContext must be used within a FinanceOfferProvider"
    );
  }
  return context;
};
