import { Button } from "@src/components/atoms";
import "./verification-failed.scss";

export const VerificationFailed = () => {
  return (
    <div className="message-container">
      <div className="message-container__message-wrapper">
        <div className="message-container__message-wrapper__icon" />
        <p className="text--body-2">
          There was an issue with your ID verification. Please contact your
          dealer.
        </p>
      </div>
      <Button htmlType={"button"} text="Contact Dealer" size="small" />
    </div>
  );
};
