import { useEffect } from "react";
import mitt from "mitt";

const ICOSuperComponent = () => {
  useEffect(() => {
    const addScript = () => {
      const script = document.createElement("script");
      script.src = `https://ui.awskbbico.kbb.com/ico-components-funnel/prod/ico-components-funnel/integration-scripts/super-component.js?${Math.floor(
        Math.random() * 9999
      )}`;
      script.id = "ico-sc-script";
      script.setAttribute("dealer-group-id", "71547877"); // Replace with actual dealer group ID
      script.setAttribute("cobranding-group-id", "chevrolet"); // Replace with actual cobranding group ID
      script.setAttribute("theme", "honda"); // Replace with actual theme
      script.setAttribute("type", "module");
      // @ts-ignore
      document.body.appendChild(script);
    };

    const handlePostOfferComplete = (event) => {
      // eslint-disable-next-line no-console
      console.log("Post Offer Complete Event:", event);

      const { status, value, markupamount, offerexpirationdate } = event.detail;

      // Handle the event payload here
      // eslint-disable-next-line no-console
      console.log("Post Offer Complete Event:", {
        status,
        value,
        markupamount,
        offerexpirationdate,
      });

      // You can add further logic based on the event data
      if (status === "Cleared") {
        // Process the successful trade-in offer
      } else {
        // Handle cases where the offer was not successful
      }
    };

    window.onload = () => {
      addScript();

      // Setup event bus
      // @ts-ignore
      const caiEventBus = mitt();
      // @ts-ignore
      window.caiEventBus = caiEventBus;

      caiEventBus.on("*", (event, value) => {
        // eslint-disable-next-line no-console
        console.log("-- *****:", event, value);
      });

      // Add event listener for the custom event
      window.addEventListener(
        "PostOfferCompleteCobrandingEvent",
        handlePostOfferComplete
      );
    };

    // Cleanup on unmount
    return () => {
      const existingScript = document.getElementById("ico-sc-script");
      if (existingScript) {
        existingScript.remove();
      }
      window.removeEventListener(
        "PostOfferCompleteCobrandingEvent",
        handlePostOfferComplete
      );
    };
  }, []);

  return <div id="icoSuperComponentContent"></div>;
};

export default ICOSuperComponent;
