import { Drawer } from "@src/components/atoms";
import {
  PaymentSettings,
  PaymentSettingsReview,
  ProofOfIncentive,
  ProofOfResidence,
  ProvideInsuranceInfo,
  SchedulePickup,
} from "@src/components/molecules";
import "./offshoots-journey-drawer.scss";
import { PaymentMethod, PaymentMethodReview } from "@src/components/organisms";

export const OffshootsJourneyDrawer = ({ open, onClose }) => {
  return (
    <div className="offshoots-journey-drawer-wrapper">
      <Drawer
        width={336}
        open={open}
        maskClosable
        keyboard
        content={
          <>
            <ProofOfResidence />
            {/* <ProofOfIncentive /> */}
            {/* <SchedulePickup /> */}
            {/* <ProvideInsuranceInfo /> */}
            {/* <PaymentMethod /> */}
            {/* <PaymentSettings /> */}
            {/* <PaymentSettingsReview /> */}
          </>
        }
        onClose={onClose}
      />
    </div>
  );
};
