import { FixedVehicleCard } from "@components/molecules";
import { BaseLayout } from "@src/layouts";
import { useAppContext } from "@src/context/app-context";
import CheckoutContent from "./checkout-content";

import "./checkout-page.scss";

const CheckoutPage = () => {
  const {
    state: {
      user: {
        customer: { fetchedData },
      },
    },
  } = useAppContext();

  return (
    <BaseLayout>
      {fetchedData && <CheckoutContent />}
      <FixedVehicleCard />
    </BaseLayout>
  );
};

export default CheckoutPage;
