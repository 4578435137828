import { Checkbox } from "@src/components/atoms";
import { Controller } from "react-hook-form";

export const SpouseAddress = ({ control }) => {
  return (
    <div className="spouse-address-wrap title-fields-spacer">
      <p className="material-status-check--subtitle text--body-1">
        Please provide your spouse’s address.
      </p>
      <Controller
        control={control}
        name="spouseAddressSameAsMailing"
        render={({ field }) => (
          <Checkbox
            id={"spouseAddressCheck"}
            checked={field.value}
            onChange={field.onChange}
            label="Same as my address"
          />
        )}
      />
    </div>
  );
};
