import { http } from "../utilities";
import {
  DMS_BASE_URL,
  GET_ALL_DOCUMENT_PACKAGES,
  PRE_SIGNED_URL,
} from "../constants/routes";

export const getDocumentPackages = async ({
  referenceId,
  orderId,
  documentType,
}) => {
  const queryParams = `?reference_id=${referenceId}&external_order_id=${orderId}&document_type=${documentType[0]}&document_type=${documentType[1]}&document_type=${documentType[2]}`;
  try {
    const response = await http(DMS_BASE_URL).get(
      GET_ALL_DOCUMENT_PACKAGES + queryParams
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPreSignedUrl = async (docKey) => {
  try {
    const response = await http(DMS_BASE_URL).get(
      `${PRE_SIGNED_URL}/${docKey}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
