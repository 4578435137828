import "./fixed-vehicle-card.style.scss";
import VehicleImage from "../../../shared/assets/img/vehicle-fixed-car.png";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";

export const FixedVehicleCard = () => {
  const {
    state: {
      order: { estimated_monthly_payment, down_payment_value, order_asset },
    },
  } = useAppContext();
  return (
    <div className="fixed-vehicle-card">
      <div className="text-container">
        <p className="financing-text text--body-focus-price-1">
          Financing {formatCurrency(estimated_monthly_payment)}/month
        </p>
        <p className="price-text text--disclaimer">
          Your Price: {formatCurrency(down_payment_value)} (i)
        </p>
      </div>
      <img
        className="vehicle-image"
        src={order_asset?.photo_urls[0]?.location}
        alt="Vehicle"
      />
    </div>
  );
};
