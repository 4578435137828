import { Button } from "@src/components/atoms";
import "./accordion.style.css";

export const Accordion = ({
  billingAddress,
  garagingAddress,
  addBillingAddressHandler,
  addGaragingAddressHandler,
}: {
  billingAddress: boolean;
  garagingAddress: boolean;
  addBillingAddressHandler: () => void;
  addGaragingAddressHandler: () => void;
}) => {
  return (
    <>
      {!billingAddress && (
        <div className="checkout-accordion">
          <Button
            linkButton
            htmlType="button"
            withIcon
            dataIcon="plus"
            text="Add billing address"
            onClick={addBillingAddressHandler}
          />
        </div>
      )}
      {!garagingAddress && (
        <div className="checkout-accordion">
          <Button
            linkButton
            htmlType="button"
            withIcon
            dataIcon="plus"
            text="Add garaging address"
            onClick={addGaragingAddressHandler}
          />
        </div>
      )}
    </>
  );
};
