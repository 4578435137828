import { AddOnsDrawerContent } from "@src/components/molecules";
import "./add-ons-drawer.scss";

export const AddOnsDrawer = () => {
  return (
    <>
      <div className="add-ons-drawer">
        <div className="add-ons-drawer__headline">
          <h3>Your add-ons.</h3>
        </div>
        <div className="add-ons-drawer__inner">
          <AddOnsDrawerContent />
        </div>
      </div>
    </>
  );
};
