import { createBrowserRouter } from "react-router-dom";

import HomePage from "pages/home";
import AboutPage from "pages/about";
import ICOSuperComponent from "pages/kbb-ico-super-component";
import GCDMLogin from "pages/gcdm-poc";
import GCDMResponse from "pages/gcdm-poc-response";
import SSOPoc from "pages/sso-poc";
import UpscopeClientSide from "pages/upscope";
import UpscopeDealerSide from "pages/upscope-dealer-side";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/bmw",
    element: <HomePage />,
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/bmw/about",
    element: <AboutPage />,
  },
  {
    path: "/bmw/gcdm",
    element: <GCDMLogin />,
  },
  {
    path: "/bmw/gcdm-response",
    element: <GCDMResponse />,
  },
  {
    path: "/bmw/sso",
    element: <SSOPoc />,
  },
  {
    path: "/bmw/kbb-ico",
    element: <ICOSuperComponent />,
  },
  {
    path: "bmw/upacope",
    element: <UpscopeClientSide />,
  },
  {
    path: "bmw/upscope-deler-side",
    element: <UpscopeDealerSide />,
  },
]);

export default router;
