import { Button } from "@src/components/atoms";
import "./add-payment-method.scss";
import Stripe from "../../../../../../shared/assets/img/stripe.png";

export const AddPaymentMethod = ({ onAdd }: { onAdd: () => void }) => {
  return (
    <div className="add-payment-method">
      <p className="text--body-2">Add payment method</p>
      <img src={Stripe} alt="Stripe Payment" />
      <div className="add-payment-method__cta">
        <Button
          type="default"
          htmlType="button"
          text="Add payment method"
          onClick={onAdd}
        />
      </div>
    </div>
  );
};
