import "./payment-authorization.scss";

export const PaymentAuthorization = () => {
  return (
    <div className="deposit-payment-authorization">
      <p className="payment-authorization-subtitle text--body-2">
        Payment authorization
      </p>
      <div className="payment-authorization-text-wrapper text--disclaimer">
        <span className="payment-authorization-text">
          By [clicking the “Agree &amp; pay” button below], you authorize us on{" "}
        </span>
        <span className="payment-authorization-text payment-authorization-text--bold">
          [Add date]{" "}
        </span>
        <span className="payment-authorization-text">
          to initiate a one-time payment&nbsp;to charge the card or bank account
          specified above under “Select Payment Method” (the “Account”) in the
          amount set forth above as the amount “Due Today.” You authorize this
          payment to occur on or after{" "}
        </span>
        <span className="payment-authorization-text payment-authorization-text--bold">
          [Add date].
        </span>
      </div>
      <div className="payment-authorization-text-wrapper text--disclaimer">
        <span className="payment-authorization-text">
          You authorize us to initiate a separate electronic payment from the
          Account for any returned payment fee or late fee you may incur. You
          agree that (1) we may update missing or erroneous Account information
          and initiate an electronic debit or credit to your Account to correct
          any error we make, (2) you may terminate this authorization by
          notifying us in a time and manner so as to afford us and your bank
          with a reasonable opportunity to act on your notice, and (3) we may
          reinitiate a returned electronic debit up to two additional times. You
          agree that if you have questions regarding your payment or desire to
          cancel it, you may contact us at{" "}
        </span>
        <span className="payment-authorization-text payment-authorization-text--underlined">
          [1-800-578-5000]
        </span>
        <span className="payment-authorization-text">
          {" "}
          during normal business hours.
        </span>
      </div>
      <div className="payment-authorization-text-wrapper text--disclaimer payment-authorization-text-wrapper--last">
        <span className="payment-authorization-text">
          The words "we," "us," and "our" used in this authorization mean{" "}
        </span>
        <span className="payment-authorization-text payment-authorization-text--bold">
          [BMW Center Name]{" "}
        </span>
        <span className="payment-authorization-text">
          and its successors, assigns, agents, and service providers. You
          understand and agree that we may use service providers, such as
          Stripe, to process payments. Finally, you agree to our{" "}
        </span>
        <span className="payment-authorization-text payment-authorization-text--bold-underlined">
          Terms{" "}
        </span>
        <span className="payment-authorization-text">and </span>
        <span className="payment-authorization-text payment-authorization-text--bold-underlined">
          Privacy Policy.
        </span>
      </div>
    </div>
  );
};
