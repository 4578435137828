import { getIntegrationProvider } from "@src/services/integration";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";

export const useIntegrationProvider = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      dealerCode: string;
      companyId: string;
      providerName: string;
      integrationType: string;
    }
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      dealerCode: string;
      companyId: string;
      providerName: string;
      integrationType: string;
    }
  >({
    mutationFn: async (body: any) => {
      return await getIntegrationProvider(body);
    },
  });
  return {
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
    mutate,
  };
};
