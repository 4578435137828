import { Controller } from "react-hook-form";
import { Input, Select } from "@src/components/atoms";
import { startCase } from "lodash";
import "./address.scss";

export const Address = ({
  type,
  control,
  stateOptions,
}: {
  type: string;
  control: any;
  stateOptions: any[];
}) => {
  const getTypeText = ["spouse", "previous"].includes(type)
    ? startCase(type)
    : "";

  return (
    <>
      <div className="checkout-form-spacing">
        <Controller
          name={`${type}.address_line_1`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--streetAddress"}
              placeholder="Street address"
              error={error}
              label={`${getTypeText} Address 1`}
              {...field}
            />
          )}
        />
      </div>
      <Controller
        name={`${type}.address_line_2`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            id={"checkout-form--streetAddress2"}
            error={error}
            placeholder="Street address"
            label={`${getTypeText} Address 2 (optional)`}
            {...field}
          />
        )}
      />
      <div className="checkout-column-wrapper">
        <Controller
          name={`${type}.city`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--city"}
              placeholder="City"
              error={error}
              label={`${type === "previous" ? "Previous" : ""} City`}
              {...field}
            />
          )}
        />

        <Controller
          name={`${type}.state_name`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Select
              label={`${type === "previous" ? "Previous" : ""} State`}
              options={stateOptions}
              error={error}
              {...field}
            />
          )}
        />
        {type !== "spouse.address" ? (
          <Controller
            name={`${type}.county`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                id="checkout-form--county"
                placeholder="County"
                label={`${
                  type === "previous" ? "Previous" : ""
                } County (optional)`}
                error={error}
                {...field}
              />
            )}
          />
        ) : null}
        <Controller
          name={`${type}.zip_code`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              id={"checkout-form--zipcode"}
              placeholder="ZIP Code"
              label={`${type === "previous" ? "Previous" : ""} ZIP Code`}
              error={error}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
};
