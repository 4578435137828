import { useState, useEffect } from "react";
import {
  Button,
  FeedbackLabel,
  Image,
  Popover,
  MultiSwatchCircles,
  Drawer,
} from "@src/components/atoms";
import Vehicle from "../../../shared/assets/img/dr-start/vehicle.png";
import "./right-rail-fixed.scss";
import { RightRailFixedProps } from "./right-rail-props";
import SwatchOne from "../../../shared/assets/img/right-rail/swatch1.png";
import SwatchTwo from "../../../shared/assets/img/right-rail/swatch2.png";
import SwatchThree from "../../../shared/assets/img/right-rail/swatch3.png";
import SwatchFour from "../../../shared/assets/img/right-rail/swatch4.png";
import SwatchFive from "../../../shared/assets/img/right-rail/swatch5.png";
import SwatchSix from "../../../shared/assets/img/right-rail/swatch6.png";
import { AddOnsDrawerFooter } from "../add-ons-drawer";
import { AddOnsDrawer } from "@src/components/organisms";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";

export const RightRailFixed = ({
  hasFinance,
  financeSoftCredit,
  financeNoCart,
  hasLease,
  leaseSoftCredit,
  leaseNoCart,
  cashAuth,
  cashUnAuth,
  cashNoCart,
  onlineReservation,
  expiredReservation,
}: RightRailFixedProps) => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth > 786);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth > 786);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const hasAnyOption =
    hasFinance ||
    financeSoftCredit ||
    financeNoCart ||
    hasLease ||
    leaseSoftCredit ||
    leaseNoCart ||
    cashAuth ||
    cashUnAuth ||
    cashNoCart;

  const swatchArray = [
    SwatchOne,
    SwatchTwo,
    SwatchThree,
    SwatchFour,
    SwatchFive,
    SwatchSix,
  ];

  const [isAddOnsDrawerOpen, setIsAddOnsDrawerOpen] = useState(false);

  const {
    contextState: { financetype },
  } = useGeneralContext();

  const toggleAddons = () => {
    setIsAddOnsDrawerOpen(!isAddOnsDrawerOpen);
  };

  return (
    <div className="right-rail-fixed-container">
      {hasAnyOption ? (
        <>
          <div
            className={`right-rail__info ${isContentVisible ? "visible" : ""}`}
          >
            <Button
              htmlType={"button"}
              type="default"
              onlyIcon
              dataIcon={`${
                isContentVisible ? "arrows_minimize" : "arrows_maximize"
              }`}
              size="small"
              className="right-rail-icon__maximize"
              onClick={toggleContentVisibility}
            />

            {/* Payment */}
            <div className="right-rail__payment">
              <div className="right-rail-payment__inner">
                <div className="right-rail-payment__content">
                  {hasFinance || financeSoftCredit || financeNoCart ? (
                    <>
                      <div className="right-rail-payment__info">
                        <p className="text--body-2">Est. payments</p>
                        <Popover
                          trigger={["hover"]}
                          disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                        >
                          <span
                            className="bmw-icon bmw-icon--before data-icon"
                            data-icon="information"
                          />
                        </Popover>
                      </div>
                      <h3 className="fw-bold">
                        $612.32 <span className="fw-light">/month</span>
                      </h3>

                      <p
                        className={`text--label text-muted right-rail-hidden__block ${
                          isContentVisible ? "visible" : ""
                        }`}
                      >
                        Finance for 60 months, $15,000 down, 7.09% est. APR
                      </p>
                    </>
                  ) : hasLease || leaseSoftCredit || leaseNoCart ? (
                    <>
                      <div className="right-rail-payment__info">
                        <p className="text--body-2">Est. payments</p>
                        <Popover
                          trigger={["hover"]}
                          disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                        >
                          <span
                            className="bmw-icon bmw-icon--before data-icon"
                            data-icon="information"
                          />
                        </Popover>
                      </div>
                      <h6 className="text--body-1 fw-bold">
                        $624.32 <span className="fw-light">/month</span>
                      </h6>
                      <p
                        className={`text--label text-muted right-rail-hidden__block ${
                          isContentVisible ? "visible" : ""
                        }`}
                      >
                        Lease for 36 months, $5,000 down, 12,000mi
                      </p>
                    </>
                  ) : cashAuth || cashUnAuth || cashNoCart ? (
                    <>
                      <div className="right-rail-payment__info">
                        <p className="text--body-2"> Cash offer</p>
                        <Popover
                          trigger={["hover"]}
                          disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                        >
                          <span
                            className="bmw-icon bmw-icon--before data-icon"
                            data-icon="information"
                          />
                        </Popover>
                      </div>
                      <h6 className="text--body-1 fw-bold">$48,670.00</h6>
                    </>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div
                  className={`right-rail-hidden__block right-rail-payment__relative ${
                    isMobileView || !isContentVisible ? "visible" : ""
                  }`}
                >
                  <div className="right-rail--addons__total">
                    <span className="text--label fw-normal text-white">+6</span>
                  </div>
                  <Image src={Vehicle} alt="vehicle" aspectRatio="16:9" />
                </div>
              </div>
              <div
                className={`right-rail-hidden__content ${
                  isContentVisible ? "visible" : ""
                }`}
              >
                <Button
                  htmlType={"button"}
                  text="Pricing details"
                  size="small"
                  fullWidth
                ></Button>
              </div>
            </div>

            <div
              className={`right-rail-hidden__content ${
                isContentVisible ? "visible" : ""
              }`}
            >
              <div className="right-rail__divider"></div>
              <div className="right-rail__vehicle">
                <div className="right-rail-vehicle__title">
                  <p className="text--body-2">2025 BMW iX xDrive50</p>
                  <Image src={Vehicle} alt="image" aspectRatio="16:9" />
                </div>
                <div className="right-rail-vehicle__status">
                  <p className="text--body-2">Status</p>
                  <div className="right-rail-status__info">
                    <FeedbackLabel type="success" text="At dealership" />
                    <Popover
                      trigger={["hover"]}
                      disclaimerText="Vehicle is located at this BMW Center. Due to demand, availability is subject to change without notice. Please contact this BMW Center for additional, and most up to date, information about this vehicle."
                    >
                      <span
                        className="bmw-icon bmw-icon--before data-icon"
                        data-icon="information"
                      />
                    </Popover>
                  </div>
                </div>
                <div className="right-rail-vehicle__addons">
                  <p className="text--body-2">Add-ons</p>
                  <div className="right-rail-addons__swatch">
                    {!financeNoCart ? (
                      <>
                        <MultiSwatchCircles
                          images={swatchArray}
                          maxVisible={4}
                        />
                      </>
                    ) : (
                      <p className="text--body-2 fw-normal">0 Items</p>
                    )}
                    <Button
                      type="link"
                      htmlType="button"
                      onlyIcon
                      dataIcon="arrow_chevron_right"
                      onClick={toggleAddons}
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          {!financeSoftCredit &&
            !leaseSoftCredit &&
            !cashNoCart &&
            financetype !== FinanceType.CASH && (
              <div className="right-rail__footer">
                <p className="text--body-2">
                  Do a{" "}
                  <a href="/credit" className="text-underline fw-normal">
                    soft credit check
                  </a>{" "}
                  for better estimates.
                </p>
                {/* <p className="text--body-2">
                    <a href="/sign" className="text-underline fw-normal">
                      Sign in
                    </a>{" "}
                    <span className="d-md-inline-flex">or</span>{" "}
                    <a
                      href="/sign"
                      className="text-underline fw-normal d-md-inline-flex"
                    >
                      register
                    </a>{" "}
                    to unlock your personalized pricing.
                  </p> */}

                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="search_offer"
                />
              </div>
            )}

          {onlineReservation && (
            <div className="right-rail__header">
              <span
                className="bmw-icon bmw-icon--before data-icon right-rail-icon__clock"
                data-icon="clock"
              />
              Online reservation timer:
              <span className="fw-normal">XXm XXs</span>
              <Popover
                trigger={["hover"]}
                disclaimerText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
              >
                <span
                  className="bmw-icon bmw-icon--before data-icon right-rail-icon__info"
                  data-icon="information"
                />
              </Popover>
            </div>
          )}
          {expiredReservation && (
            <div className="right-rail__header expired">
              <div className="right-rail-header__inner">
                <span
                  className="bmw-icon bmw-icon--before data-icon right-rail-icon__clock"
                  data-icon="clock"
                />
                Reservation expired.
              </div>
              <div className="text--body-2 right-rail-header__inner">
                <span className="fw-normal">Contact dealer</span>
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="arrow_chevron_right"
                />
              </div>
            </div>
          )}
          <div className="add-ons-drawer-main">
            <Drawer
              width={336}
              open={isAddOnsDrawerOpen}
              maskClosable={true}
              keyboard={true}
              onClose={toggleAddons}
              content={
                <>
                  <AddOnsDrawer />
                </>
              }
              footer={
                <>
                  <AddOnsDrawerFooter
                    price={
                      <>
                        <small className="text--disclaimer">
                          Est. payments
                        </small>
                        <h2 className="text--body-focus-price-1">
                          $X,XXX
                          <span className="text--body-1">/month</span>{" "}
                        </h2>
                      </>
                    }
                    footerActions={
                      <>
                        <Button
                          onClick={toggleAddons}
                          htmlType={"button"}
                          text="Back to your deal"
                          type="default"
                        />
                      </>
                    }
                  />
                </>
              }
            />
          </div>
        </>
      ) : (
        <div className="right-rail__not-avilable">
          <h1>N/A</h1>
        </div>
      )}
    </div>
  );
};
