import "@styles/main.scss";

import { ModalProvider } from "react-modal-hook";

import { QueryClientProviderWrapper as QueryClientProvider } from "@src/application/application-query-client";
import { RouterProviderWrapper as RouterProvider } from "@src/application/application-router";
import { ThemeProvider } from "@src/application/application-ant-design";
import { ApplicationContextProvider } from "@src/context/app-context";
import { DataLayerProvider } from "@src/application/application-data-layer";
import "react-photo-view/dist/react-photo-view.css";

const Application = () => {
  return (
    <ThemeProvider>
      <QueryClientProvider>
        <DataLayerProvider>
          <ApplicationContextProvider>
            <ModalProvider>
              <RouterProvider />
            </ModalProvider>
          </ApplicationContextProvider>
        </DataLayerProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default Application;
