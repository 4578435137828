import { ContractPreview, EsignContracts } from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

export const EsignContractsPage = () => {
  return (
    <BaseLayout hasMenu={true} hasFooter={true}>
      <EsignContracts />
    </BaseLayout>
  );
};
