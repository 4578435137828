import { ChatSentMessageProps } from "./chat-user-sent.props";
import "./chat-user-sent.scss";

export const ChatUserSent = ({ message, time }: ChatSentMessageProps) => {
  return (
    <div className="chat-sent-wrap">
      <p className="chat-automated--time">{time}</p>
      <div className="chat-sent--box">
        <p className="chat-automated--message text--body-2">{message}</p>
      </div>
    </div>
  );
};
