import { FullScreenContent } from "@src/components/molecules";
import { Button, IconList } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { BaseLayout } from "@src/layouts";
import { Decision } from "@src/helpers";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetOrderInfo } from "@src/hooks";
import { setOrderInfo } from "@src/context/app-actions";
import { removeNullValues } from "@src/helpers/appHelper";

const DealStatePage = ({ error = false }: { error?: boolean }) => {
  const {
    state: {
      order,
      dealer: { dealer_name, logo },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const {
    status,
    finance_type,
    customer_info: { fraud_status },
  } = order;
  const { dealRefNumber, state } = useParams();
  const navigate = useNavigate();
  const { mutate: getOrderInfo } = useGetOrderInfo();
  useEffect(() => {
    if (dealer_name && logo)
      localStorage.setItem(
        "dealer",
        JSON.stringify({ dealer_name: dealer_name, logo: logo })
      );
    if (state == Decision.ACCEPT) {
      getOrderInfo(dealRefNumber, {
        onSuccess(response) {
          appDispatch(
            setOrderInfo({ ...order, ...removeNullValues(response) })
          );
        },
      });
    }
  }, []);
  return (
    <BaseLayout hasMenu={false} hasFooter={false}>
      <FullScreenContent
        error={error}
        title={
          status == Decision.FULL_RECIEVED ? (
            <h1 className="text-white">
              Congratulations, your {finance_type} offers are ready to review.
            </h1>
          ) : state === Decision.ACCEPT ? (
            <h1 className="text-white">Application received!</h1>
          ) : (
            <h1 className="text-white">Looks like we need more information.</h1>
          )
        }
        subtitle={
          status == Decision.FULL_RECIEVED ? null : state ===
            Decision.ACCEPT ? (
            <p className="text--body-1 text-white">
              Your reservation timer is paused for you while we process your
              application. Once we provide finance offers, the reservation timer
              will continue at <span className="fw-bold">XX:XX.</span>
            </p>
          ) : (
            <p className="text--body-1 text-white">
              Please contact your dealer to complete your application.
            </p>
          )
        }
        content={
          <>
            <IconList
              size="big"
              onDark
              listItems={
                status === Decision.FULL_RECIEVED
                  ? [
                      {
                        type: "checklist",
                        text: `Confirming ${finance_type} offer(s)`,
                      },
                    ]
                  : state === Decision.ACCEPT
                  ? [
                      {
                        type: "checklist",
                        text: "Application received",
                      },
                      {
                        type: "loading",
                        text: "Confirming lease offer",
                      },
                    ]
                  : []
              }
            />
          </>
        }
        actionArea={
          status == Decision.FULL_RECIEVED ? (
            <Button
              text="Continue to offer"
              htmlType="button"
              fullWidth
              fixed
              onDark
              onClick={() => {
                if (status == Decision.FULL_RECIEVED) {
                  navigate(`/finance-offer/deal/${dealRefNumber}`);
                }
              }}
            />
          ) : state !== Decision.ACCEPT ? (
            <>
              <Button
                text="Contact dealer"
                type="primary"
                htmlType="button"
                fullWidth
              />
              <Button
                text="Cancel deal"
                linkButton
                withIcon
                dataIcon="trash_can"
                onDark
                htmlType="button"
              />
            </>
          ) : null
        }
        footer={
          status == Decision.FULL_RECIEVED ? null : state ===
            Decision.ACCEPT ? (
            <p className="text--label text-white">
              This process can take between 30 seconds and {"XX"} minutes. You
              will also receive an email notification from{" "}
              <span className="fw-bold">email@email.com</span> when this is
              complete.
            </p>
          ) : null
        }
      />
    </BaseLayout>
  );
};

export default DealStatePage;
