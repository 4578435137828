import "./accessories-list-card-drawer-content.scss";
import { Button, Carousel } from "@src/components/atoms";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { formatCurrency, formatUrl } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";

export const AccessoriesListCardDrawerContent = ({ accessory }) => {
  return (
    <>
      <div className="accessories-full-details">
        <div className="accessories-full-details__left">
          <div className="accessories-full-details__image">
            <Carousel
              arrows={accessory?.image?.length > 1}
              autoplay
              autoplaySpeed={2000}
              dots={accessory?.image?.length > 1}
              infinite
              images={accessory.image?.map((img) => ({
                src: img.location,
                alt: "Slide 1",
              }))}
            />
            <PhotoProvider
              className="image-preview-wrap accessories-full-details--enlarge"
              maskClosable={false}
              pullClosable={false}
              maskOpacity={null}
              toolbarRender={({ onClose }) => (
                <Button
                  className="preview-close-btn"
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="arrows_minimize"
                  onImage
                  onClick={onClose}
                />
              )}
            >
              {accessory.image?.map((img, index) => (
                <PhotoView key={index} src={img.location}>
                  <span
                    className="bmw-icon bmw-icon--after data-icon maximize-icon"
                    data-icon="arrows_maximize"
                  />
                </PhotoView>
              ))}
            </PhotoProvider>
          </div>
        </div>
        <div className="accessories-full-details__right">
          <div className="accessories-full-details__right--inner">
            <div className="text--subsection-1">{accessory.product_name}</div>
            <div className="accessories-full-details__right--price-detail">
              <div className="accessories-full-details__right--price-section">
                <span className="card-select-default-checked text--body-2">
                  Monthly cost:
                </span>
                <div className="text--promo-focus-price">
                  {formatCurrency(accessory.monthly_impact)}{" "}
                  <span className="text--body-1">/month</span>{" "}
                </div>
                <p className="text--label">
                  {formatCurrency(accessory.price ?? 0)} total
                </p>
              </div>
              <p className="text--body-2">{accessory?.description}</p>
            </div>

            {/* <p className="text--disclaimer text-muted">
              Upgraded packages also available. Ex: (full hood, mirrors, side
              skirts).
            </p> */}
          </div>

          <div className="accessories-full-details__right--links-section">
            {accessory.video_url && (
              <Button
                htmlType={"button"}
                text="Play video"
                linkButton
                onClick={() =>
                  window.open(formatUrl(accessory.video_url), "_blank")
                }
                withIcon
                dataIcon="play"
              />
            )}

            {accessory.brochure_url && (
              <Button
                htmlType={"button"}
                text="Download brochure"
                onClick={() =>
                  window.open(formatUrl(accessory.brochure_url), "download")
                }
                linkButton
                withIcon
                dataIcon="download"
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};
