import {
  DMS_BASE_URL,
  INTEGRATION_BY_PROVIDER_NAME,
} from "@src/constants/routes";
import { http } from "@src/utilities";

export const getIntegrationProvider = async (body: any) => {
  return http(DMS_BASE_URL)
    .get(
      `${INTEGRATION_BY_PROVIDER_NAME}/${body.dealerCode}/${body.providerName}/${body.integrationType}`,
      {
        headers: { company_id: body.companyId },
      }
    )
    .then((res) => res.data);
};
