import "./contract-list.scss";
import { ContractPreviewItemProps } from "./contract-list.props";
import { StateCtaButtons } from "../../state-cta-button/state-cta-button.component";

const UploadIcon = () => (
  <svg
    width="32"
    height="32"
    className="document-uploaded__icon"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66472 4.66499H14.9998C15.8414 4.66499 16.3555 4.84744 16.6905 5.23492C17.0506 5.65137 17.3348 6.45054 17.3348 7.99999V12.665H21.9998C23.5493 12.665 24.3484 12.9492 24.7649 13.3093C25.1491 13.6415 25.3317 14.1497 25.3348 14.9788V27.335H17.2998V28.665H26.6648V14.9004C26.665 13.7416 26.6652 12.884 26.3747 12.0852C26.0699 11.247 25.4723 10.5321 24.47 9.52976L20.47 5.52976C19.4676 4.5274 18.7527 3.92983 17.9145 3.62502C17.1158 3.33457 16.2582 3.33474 15.0993 3.33497L5.33472 3.33499V14.7H6.66472V4.66499ZM23.5295 10.4702C24.0581 10.9988 24.4197 11.3976 24.6718 11.7355C23.9677 11.4595 23.0801 11.335 21.9998 11.335H18.6648V7.99999C18.6648 6.91973 18.5403 6.0321 18.2643 5.32802C18.6022 5.58013 19.0009 5.94163 19.5295 6.47021L23.5295 10.4702Z"
      fill="#262626"
    />
    <circle cx="9.1423" cy="22.8571" r="6.85714" fill="#1C69D3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5277 20.3885L8.28146 24.3311L6.72402 22.6427L6.12524 23.2923L8.31618 25.6685L12.1595 21.0005L11.5277 20.3885Z"
      fill="white"
      stroke="white"
      strokeWidth="0.365714"
    />
  </svg>
);

export const ContractList = ({
  icon = "document_text_lines",
  title,
  buttons = [],
}: ContractPreviewItemProps) => {
  return (
    <div className="contract-preview-list__item">
      <p className="text--body-2">
        {icon === "document-uploaded" ? (
          <UploadIcon />
        ) : (
          <span
            className={`bmw-icon bmw-icon--before data-icon`}
            data-icon={icon}
          />
        )}
        <span className="contract-preview-list-item__title">{title}</span>
      </p>
      <StateCtaButtons buttons={buttons} />
    </div>
  );
};
