import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  getDocumentPackages,
  getPreSignedUrl,
} from "../services/contract-management";

export const useGetDocumentPackages = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { referenceId; orderId; documentType }
  >;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    any,
    { detail: { message: string } },
    { referenceId; orderId; documentType }
  >({
    mutationFn: async (payload) => {
      const responseDocumentPackages = await getDocumentPackages(payload);
      return responseDocumentPackages;
    },
  });
  return {
    mutate,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetPreSignedUrl = (): {
  mutate: UseMutateFunction<any, { detail: { message: string } }, { docKey }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    any,
    { detail: { message: string } },
    { docKey }
  >({
    mutationFn: async (docKey) => {
      const responsePreSignedUrl = await getPreSignedUrl(docKey);
      return responsePreSignedUrl;
    },
  });
  return {
    mutate,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

// TO BE COMPLETED IN NEXT SPRINT
// export const signDocumentPackage = async (payload) => {
// 	return http(DMS_BASE_URL)
// 		.post(
// 			`${Endpoint.SIGN_DOCUMENT_BY_ROLE}/${payload?.document_package_identifier}/${payload?.document_identifier}/${payload?.signature_id}/${payload.signer_role}?date_time_format=YYYY%2FMM%2FDD&time_zone=UTC`
// 		)
// 		.then((res) => {
// 			return res.data
// 		})
// }

// export const updateCustomerSignature = async (payload) => {
// 	const query = `reference_id=${payload.reference_id}`
// 	return http(DMS_BASE_URL)
// 		.patch(`${Endpoint.CUSTOMER_SIGNATURE}?${query}`, payload)
// 		.then((res) => {
// 			return res.data
// 		})
// }

// ror: error?.message
