import {
  Button,
  Checkbox,
  Input,
  MessageAlert,
  Radio,
  SelectButton,
} from "@src/components/atoms";
import { Col, Row } from "antd";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { useState } from "react";
import "./appointment-scheduler.scss";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";

export const AppointmentScheduler = () => {
  const apptReason = [
    { label: "Test drive", value: "drive" },
    { label: "General inquiry", value: "inquiry" },
    { label: "Finalize in person", value: "finalize" },
  ];

  const timeSlots = [
    { label: "00:00 AM - 00:30 AM", value: "a" },
    { label: "00:00 AM - 00:30 AM", value: "b" },
    { label: "00:00 AM - 00:30 AM", value: "c" },
    { label: "00:00 AM - 00:30 AM", value: "d" },
  ];

  const [state, setState] = useState();

  return (
    <div className="schedule-pickup-container appointment-scheduler">
      <h3>Request an in-person appointment.</h3>
      <div className="schedule-pickup_inner appointment-scheduler__section">
        <div className="schedule-pickup__location">
          <Row
            gutter={[
              { xs: 12, sm: 24, md: 24, lg: 24, xl: 24 },
              { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
            ]}
          >
            <Col md={24}>
              <p className="text--body-1">Your BMW Center</p>
            </Col>
            <Col md={12}>
              <span className="text--label text-secondary">
                BMW of Manhattan
              </span>
              <p className="text--body-2">793 11th Ave. New York, NY 30009</p>
            </Col>
            <Col md={12}>
              <span className="text--label text-secondary">Phone</span>
              <p className="text--body-2">(555) 555-1234</p>
            </Col>
          </Row>
        </div>
        <div className="appointment-scheduler__reason">
          <h5>
            What’s the reason for your appointment? Select all that apply.
          </h5>
          <SelectButton
            options={apptReason}
            buttonStyle="solid"
            type="checkbox"
            onChange={() => ({})}
          />
        </div>
        <div className="schedule-pickup_date">
          <h5>When would you like to come in?</h5>
          <Datepicker
            id="datePicker"
            name="date-picker"
            placeholder="Choose"
            label="Select date"
            showInfoIcon
            prevIcon="arrow_left"
            nextIcon="arrow_right"
          />
        </div>
        <div className="appointment-scheduler__time-slot">
          <h5>
            Available time slots for Sunday,{" "}
            <span className="d-block d-md-inline">Oct. 19th:</span>
          </h5>
          <SelectButton
            options={timeSlots}
            buttonStyle="solid"
            onChange={() => ({})}
          />
        </div>
      </div>
      <div className="divider" />
      <div className="schedule-pickup_inner appointment-scheduler__form">
        <div>
          <h5>What’s your preferred contact method?</h5>
          <div className="appointment-scheduler__contact-method">
            <Radio
              label="Email"
              id="email"
              htmlFor="email"
              size="small"
              name="date"
              checked
            />
            <Radio
              label="SMS"
              id="sms"
              htmlFor="month_2"
              size="small"
              name="date"
            />
            <Radio
              label="Call"
              id="call"
              htmlFor="call"
              size="small"
              name="date"
            />
          </div>
        </div>
        <div className="appointment-scheduler__contact-info">
          <p className="text--body-2">
            Please confirm the driver’s contact information.
          </p>
          <MessageAlert
            type="info"
            title={
              <p className="text--body-2">
                Have a BMW ID?{" "}
                <a href="/" className="fw-normal">
                  Sign in
                </a>{" "}
                to auto-fill your contact information.
              </p>
            }
            showCloseButton={false}
          />
          <Row
            gutter={[
              { xs: 12, sm: 24, md: 24, lg: 24, xl: 24 },
              { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
            ]}
          >
            <Col xs={24} sm={24} md={12}>
              <Input id={"firstName"} name={""} label="First name" />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Input id={"lastName"} name={""} label="Last name" />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Input id={"email"} name={""} label="Email" placeholder="Email" />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Phone name="phone-input" country="us" label="Phone number" />
            </Col>
          </Row>
        </div>
        <Checkbox
          id={"idChk"}
          label="I instruct BMW of North America, LLC to provide my information to an authorized BMW dealership. I understand that an authorized BMW Dealership may contact me with offers or information about their products and services."
          htmlFor="idChk"
          isCompact
        />
      </div>
      <div className="divider" />
      <div className="schedule-pickup_inner">
        <div className="appointment-scheduler__questions">
          <h5>Any questions or special requests for your dealer?</h5>
          <Input
            id={""}
            name={""}
            placeholder="Hi there! I’m looking to learn more about this vehicle. "
            label="Inquiry (optional)"
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={400}
          />
        </div>
      </div>
      <div className="schedule-pickup__cta">
        <Button
          type="primary"
          htmlType={"button"}
          text="Request appointment"
          disabled
        />
      </div>
    </div>
  );
};
