import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import "./accessories.scss";
import {
  AccessoriesListCardDrawerContent,
  FixedVehicleCard,
  ProductDetailsDrawerContentAccessories,
  RightRail,
  RightRailFixed,
  VehicleCard,
} from "@src/components/molecules";
import { Button, Checkbox, Drawer } from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/accessories-list-img1.png";
import Image2 from "../../../../shared/assets/img/accessories-list-img2.png";
import Image3 from "../../../../shared/assets/img/accessories-list-img3.png";
import Image4 from "../../../../shared/assets/img/accessories-list-img4.png";
import Image5 from "../../../../shared/assets/img/accessories-list-img5.png";
import Image6 from "../../../../shared/assets/img/accessories-list-img6.png";
import Image7 from "../../../../shared/assets/img/accessories-list-img7.png";
import Image8 from "../../../../shared/assets/img/accessories-list-img8.png";
import { InsentivesAndDiscountsDrawerFooter } from "@src/components/molecules/incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { useState } from "react";
import { AccessoriesListCard } from "@src/components/molecules/accessories/accessories-list-card/accessories-list-card";
import { MultiSwatchCard } from "@src/components/molecules/multi-swatch-card/multi-swatch-card";
import { ROUTING_PATH } from "@application/application-router";

export const Accessories = () => {
  const navigate = useNavigate();
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  // Function to toggle the drawer visibility
  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <>
      <div className="incentives-and-discounts-wrap">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="incentives-discounts-left-content">
                <h2 className="incentives-discounts--title text--h3">
                  Vehicle accessories & add-ons
                </h2>

                <div className="accessories__list-wrap ">
                  <p className="accessories__subtitle text--subsection-2">
                    Add [port-installed] accessories.
                  </p>
                  <Row
                    gutter={[
                      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
                      { xs: 24, sm: 24, md: 24, lg: 32, xl: 40, xxl: 40 },
                    ]}
                  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image1}
                        subTitle={
                          "BMW M Performance Interior Trims in Carbon Fiber/ Alcantara"
                        }
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image2}
                        subTitle={
                          "BMW M Performance Antenna Cover in Aramid Fiber"
                        }
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image3}
                        subTitle={"BMW M Performance 18’’ Sport Brake System"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image4}
                        subTitle={"BMW M Performance Rear Wing in Carbon Fiber"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div className="accessories__list-wrap accessories__list-wrap--spacing">
                  <p className="accessories__subtitle text--subsection-2 ">
                    Add more accessories provided by BMW of Manhattan.
                  </p>
                  <Row
                    gutter={[
                      16,
                      { xs: 24, sm: 24, md: 24, lg: 32, xl: 40, xxl: 40 },
                    ]}
                  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image5}
                        subTitle={"Crystal Fusion"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image6}
                        subTitle={"Paint Protection Wrap"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image7}
                        subTitle={"BMW Reversible Luggage Compartment Mat"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <AccessoriesListCard
                        image={Image8}
                        subTitle={"BMW M Performance Exhaust System"}
                        priceNode={
                          <h2 className="text--body-focus-price-1">
                            $X,XXX <span className="text--body-1">/month</span>
                          </h2>
                        }
                        className=""
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            htmlFor="incentives-discounts--chk1"
                            onChange={() => {}}
                          />
                        }
                        actionsNode={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={toggleDrawer}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div className="multi-swatch-card-main accessories__list-wrap--spacing ">
                  <p className="accessories__subtitle text--subsection-2 accessories__list-wrap--border-bottom-none ">
                    Pre-installed by BMW of Manhattan.
                  </p>

                  <MultiSwatchCard />
                </div>

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Continue"
                        type="primary"
                        fixed
                        fullWidth
                        onClick={() => {
                          navigate(`/${ROUTING_PATH.REVIEW_PAGE}`);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        htmlType={"button"}
                        text="Sign in to save"
                        fullWidth
                      />
                    </div>
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <RightRail hasFinance />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <RightRailFixed hasFinance />
      </div>
      <div className="accessories-drawer">
        <Drawer
          width={336}
          open={isDrawerVisible}
          // open={true}
          onClose={closeDrawer}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <AccessoriesListCardDrawerContent />
              {/* <ProductDetailsDrawerContentAccessories /> */}
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                    <small className="text--disclaimer text-muted">
                      Total: $XXXX.XX
                    </small>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    type="default"
                  />
                }
              />
            </>
          }
        />
      </div>
    </>
  );
};
