import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AppError } from "single-spa";
import { State } from "@src/types";
import {
  getDealerById,
  getDealerProfile,
  getDealerPreference,
  getTheme,
  getStates,
  getSetupData,
  addCustomerConsent,
  getDisclaimerByName,
  getDealerFees,
} from "../services/dealer";

export const useGetByDealerId = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      const responseDealerData = await getDealerById(body);
      return responseDealerData;
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetDealerProfile = (): {
  mutate: UseMutateFunction<any, AppError, { dealerCode: string }>;
  error?: string;
} => {
  const { mutate, error } = useMutation<any, AppError, { dealerCode: string }>({
    mutationFn: getDealerProfile,
  });
  return { mutate, error: error?.message };
};

export const useGetDealerPreference = (): {
  mutate: UseMutateFunction<
    any,
    AppError,
    { dealerCode: string; companyId: string }
  >;
  error?: string;
} => {
  const { mutate, error } = useMutation<
    any,
    AppError,
    { dealerCode: string; companyId: string }
  >({
    mutationFn: getDealerPreference,
  });

  return { mutate, error: error?.message };
};

export const useGetTheme = (): {
  mutate: UseMutateFunction<any, AppError, { email: string }>;
  error?: string;
} => {
  const { mutate, error } = useMutation<any, AppError, { email: string }>({
    mutationFn: getTheme,
  });
  return { mutate, error: error?.message };
};

export const useGetStates = (): any => {
  const { mutateAsync, error } = useMutation<State[], AppError>({
    mutationFn: getStates,
  });
  return { mutateAsync, error: error?.message };
};

export const useGetSetupData = (): any => {
  const { mutateAsync, error } = useMutation<State[], AppError>({
    mutationFn: getSetupData,
  });
  return { mutateAsync, error: error?.message };
};

export const useAddCustomerConsent = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<any, AppError, any>({
    mutationFn: addCustomerConsent,
  });
  return { mutate, error: error?.message };
};

export const useGetDisclaimerByName = () => {
  const { isPending, mutateAsync, error } = useMutation<
    State[],
    AppError,
    { disclaimerName: string }
  >({
    mutationFn: getDisclaimerByName,
  });

  return { isPending, mutateAsync, error: error?.message };
};

export const useGetDealerFees = (): {
  isPending: boolean;
  mutateAsync: UseMutateFunction<
    any,
    AppError,
    { dealerCode: string; stateCode: string }
  >;
  error: string | undefined;
} => {
  const { isPending, mutateAsync, error } = useMutation<
    State[],
    AppError,
    { dealerCode: string; stateCode: string }
  >({
    mutationFn: getDealerFees,
  });

  return { isPending, mutateAsync, error: error?.message };
};
