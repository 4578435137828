import { Drawer as AntDrawer } from "antd";
import { DrawerProps } from "./drawer.props";
import "../../../styles/components/_drawer.scss";
import { Button } from "../button/button.component";

export const Drawer = ({
  open,
  onClose,
  onBack,
  content,
  footer,
  getContainer = false,
  placement,
  className,
  destroyOnClose,
  width,
  maskClosable,
  keyboard,
}: DrawerProps) => {
  return (
    <div className="drawer-wrapper">
      <AntDrawer
        open={open}
        onClose={onClose}
        className={className}
        destroyOnClose={destroyOnClose}
        placement={placement}
        width={width}
        getContainer={getContainer}
        maskClosable={maskClosable}
        keyboard={keyboard}
        footer={footer}
        closeIcon={
          <span
            className="bmw-icon bmw-icon--before data-icon"
            data-icon="close"
          ></span>
        }
      >
        <div className="drawer-content">
          {onBack && (
            <span className="drawer--back-button">
              <Button
                htmlType={"button"}
                type="default"
                withoutBg
                onlyIcon
                dataIcon="arrow_left"
                onClick={onClose}
              />
            </span>
          )}
          <div className="bmw-container">{content}</div>
        </div>
      </AntDrawer>
    </div>
  );
};
