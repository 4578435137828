import { Button, Input } from "@src/components/atoms";
import { Controller } from "react-hook-form";

export const AdditionalIncome = ({ control }) => {
  return (
    <div className="financial-employer-container">
      <p className="financial-subtitle text--body-2">
        Please provide information on additional income and source(s) if
        applicable.
      </p>
      <div className="checkout-column-wrapper">
        <div className="form-inner-field-container">
          <Controller
            name="annual_other_income"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                id="financial-information--annual_income"
                placeholder="0.00"
                error={error}
                prefix="$"
                type="number"
                label="Other annual income (if applicable)"
                {...field}
              />
            )}
          />
        </div>
        <div className="form-inner-field-container">
          <Controller
            name="source_of_other_income"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                error={error}
                id="source-of-other-income"
                placeholder="Source"
                label="Source"
              />
            )}
          />
        </div>
      </div>
      <div className="form-inner-field-container form-inner-additional-icome">
        <div className="form-inner--add-plus-button">
          <Button
            linkButton
            htmlType="button"
            disabled
            withIcon
            dataIcon="plus"
            text="Add additional income"
          />
        </div>
      </div>
      <hr className="checkout-hr" />
    </div>
  );
};
