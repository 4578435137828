import { Drawer } from "@src/components/atoms";
import { IncentivesAndDiscounts } from "@src/components/organisms/build-your-deal";
import { useAppContext } from "@src/context/app-context";
import { BaseLayout } from "@src/layouts";

export const IncentivesAndDiscountsPage = () => {
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");

  const {
    state: {
      user: {
        customer: { fetchedData },
      },
    },
  } = useAppContext();

  return (
    <BaseLayout>
      {!userCredentials.access_token ? (
        <IncentivesAndDiscounts />
      ) : (
        fetchedData && <IncentivesAndDiscounts />
      )}
    </BaseLayout>
  );
};
