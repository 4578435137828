import { Button, Modal } from "@src/components/atoms";
import Logo from "../../../../../shared/assets/img/logo.png";
import "./pdf-viewer.scss";
import { useEffect, useState } from "react";
import { useGetPreSignedUrl } from "@src/hooks";

function PDFViewer({ pdfFile, hide }: { pdfFile: any; hide?: () => void }) {
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl();
  const [pdfViewerState, setPdfViewerState] = useState(false);
  useEffect(() => {
    getPreSignedUrl(pdfFile, {
      onSuccess: (res) => {
        setPdfViewerState(res);
      },
      onError: (error) => {
        console.error("Error getting pre-signed URL for document:", error);
      },
    });
  }, []);

  if (!pdfViewerState) return null;
  return (
    <Modal
      className="pdf-viewer"
      content={pdfViewerState}
      onClose={hide}
      open={true}
      headerSticky
      footerSticky
      title={
        <>
          <div className="pdf-viewer__header">
            <img src={Logo} alt="BMW Logo" />
            <div>
              <span>PREPARED BY</span>
              <p className="text--body-2 fw-bold">BMW of Manhattan</p>
            </div>
          </div>
        </>
      }
      footer={
        <div className="pdf-viewer__footer">
          <p className="fw-bold">BMW_iX_Retail_Installm...</p>
          <div className="pdf-viewer-footer__pagination">
            <Button
              htmlType="button"
              onlyIcon
              dataIcon="arrow_chevron_left"
              size="small"
              type="default"
              className="pdf-viewer-footer__btn-left"
            />
            <p className="fw-bold">1/2</p>
            <Button
              htmlType="button"
              onlyIcon
              dataIcon="arrow_chevron_right"
              size="small"
              className="pdf-viewer-footer__btn-right"
            />
          </div>
          <Button
            htmlType="button"
            fullWidth
            type="default"
            text="Skip to next document"
            onClick={hide}
          />
        </div>
      }
    >
      <iframe
        title="contract-doc"
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          pdfViewerState
        )}&embedded=true`}
        width="100%"
      />
    </Modal>
  );
}

export default PDFViewer;
