import { Button, Modal } from "@src/components/atoms";
import { PaymentMethod } from "@src/components/molecules";
import "./payment-dialog.scss";

export const PaymentDialog = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="payment-method-wrap">
      <Modal
        size="compact"
        title={
          <h4 className="text--subsection-1">
            Thanks for logging in, Sarah. Your dealer requires a deposit to
            check the availability of this vehicle.
          </h4>
        }
        subtitle={
          <>
            <p className="text--body-1">
              You will pay a refundable one-time deposit today, which will apply
              toward the purchase of your vehicle. In the event your vehicle is
              not available, the deposit will be refunded or transferred to
              another deal, if applicable. BMW of Manhattan will work with you
              throughout this process.
            </p>
            <div className="deposit-info-wrapper">
              <p className="deposit-info--text text--body-focus-price-1">
                Due today
              </p>
              <p className="deposit-info--amount text--body-focus-price-1">
                $495
              </p>
            </div>
          </>
        }
        footerSticky={false}
        open={isModalOpen}
        onClose={handleClose}
        closable
        footer={
          <>
            <div className="action-btns">
              <Button
                htmlType="button"
                type="default"
                fullWidth
                fixed
                text="Agree & pay"
                onClick={() => {}}
              />
            </div>
          </>
        }
      >
        <PaymentMethod />
      </Modal>
    </div>
  );
};
