import { ChatHoursWidgetProps } from "./chat-hours-widget.props";
import clsx from "clsx";
import userImage from "@src/shared/assets/img/user.svg";
import { Accordion } from "@src/components/atoms";
import "./chat-hours-widget.scss";

export const ChatHoursWidget = ({
  dealerName,
  bannerImage,
  dealerAddress,
  workingDaysTimgs,
  saturdayTimgs,
  sundayTimgs,
}: ChatHoursWidgetProps) => {
  const exampleItems = [
    {
      key: "1",
      label: (
        <div className="text--disclaimer">
          <span className="fw-normal">Closed</span> |{" "}
          <span className="fw-light">Open tomorrow 9 AM</span>
        </div>
      ),
      children: (
        <>
          <div className="hours-widget--box">
            <div className="chat-widget--banner">{bannerImage}</div>
            <div className="chat-widget--timings">
              <p className="chat-widget--provider">{dealerName}</p>
              <p className="chat-widget--provider-address text--disclaimer">
                {dealerAddress}
              </p>
              <ul className="chat-widget--timing-weekly text--disclaimer">
                <li>
                  <span>Mon – Fri</span>
                  <span>{workingDaysTimgs}</span>
                </li>
                <li>
                  <span>Saturday</span>
                  <span>{saturdayTimgs}</span>
                </li>
                <li>
                  <span>Sunday</span>
                  <span>{sundayTimgs}</span>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div
      className={clsx({
        "hours-widget-wrap": true,
      })}
    >
      <Accordion items={exampleItems} defaultActiveKey={0} ghost={false} />
    </div>
  );
};
