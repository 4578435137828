import { useState } from "react";
import { Button, Input } from "@src/components/atoms";
import "./payment-method-amount.scss";

export const PaymentMethodAmount = () => {
  const [cards, setCards] = useState([{}]);

  const addCard = () => {
    setCards([...cards, {}]);
  };

  return (
    <div className="payment-method-amount">
      {cards.map((_, index) => (
        <div key={index} className="payment-method-amount__card">
          <div className="payment-method-amount__card-content">
            <span className="text--label text-secondary">Payment</span>
            <p className="text--body-2">[Bank name]</p>
            <p className="text--body-2">[ACH] ****1234</p>
          </div>
          <div className="payment-method-amount__card-right">
            <div className="payment-method-amount__card-input">
              <Input
                id={`idVIN-${index}`}
                name=""
                label="Amount"
                placeholder="$0.00"
              />
            </div>
            <Button
              type="link"
              htmlType="button"
              text="Change"
              linkButton
              className="text-underline"
            />
          </div>
        </div>
      ))}
      <Button
        type="link"
        htmlType="button"
        text="Add another"
        withIcon
        linkButton
        dataIcon="plus"
        onClick={addCard}
        className="payment-method-amount__add-btn"
      />
    </div>
  );
};
