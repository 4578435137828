import { ProtectionAndWarranty } from "@src/components/organisms/build-your-deal";
import { useAppContext } from "@src/context/app-context";
import { BaseLayout } from "@src/layouts";

export const ProtectionAndWarrantyPage = () => {
  const {
    state: {
      dealer: { dealer_code },
      order: { finance_type },
    },
  } = useAppContext();

  return (
    <BaseLayout>
      {dealer_code && finance_type && <ProtectionAndWarranty />}
    </BaseLayout>
  );
};
