import { ReactNode } from "react";
import { CheckoutHeader, Menu, CheckoutFooter } from "@src/layouts";

import "./base-layout.scss";
import { useAppContext } from "@src/context/app-context";
import { DisclaimerNotification } from "@src/components/organisms";

export const BaseLayout = ({
  children,
  hasMenu = true,
  hasFooter = true,
}: {
  children?: ReactNode;
  hasMenu?: boolean;
  hasFooter?: boolean;
}) => {
  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );
  const {
    state: {
      ui: {
        toggles: { disclaimer },
      },
    },
  } = useAppContext();
  return (
    <div className="base-layout-container">
      <CheckoutHeader />
      {hasMenu && (
        <div className="base-layout--menu-container">
          <Menu />
        </div>
      )}
      <div className="base-layout--main-content-area">{children}</div>

      <DisclaimerNotification
        key={
          disclaimer.isOpen && !isDisclaimerAgreed ? "disclaimer-notify" : ""
        }
      />
      {hasFooter && <CheckoutFooter />}
    </div>
  );
};
