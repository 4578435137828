import { notification } from "antd";
import clsx from "clsx";
import { ReactNode, useEffect, useCallback, useRef } from "react";

export const Notification = ({
  title,
  placement = "bottomRight",
  show = true,
  description,
  type = "info",
  icon,
  className,
  role,
  variant,
  showContent = true,
  showInfoIcon = true,
  handleClose,
}: {
  title?: string | ReactNode;
  placement?:
    | "top"
    | "topLeft"
    | "topRight"
    | "bottom"
    | "bottomLeft"
    | "bottomRight";
  show?: boolean;
  description?: ReactNode;
  type?: "success" | "info" | "warning" | "error";
  variant?: "neutral" | "default";
  icon?: ReactNode;
  className?: string;
  role?: "alert" | "status";
  showContent?: boolean;
  showInfoIcon?: boolean;
  handleClose?: () => void;
}) => {
  const [api, contextHolder] = notification.useNotification();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const configureContainer = useCallback(() => {
    if (containerRef.current) {
      notification.config({
        getContainer: () => containerRef.current!,
      });
    }
  }, []);

  const openNotification = useCallback(() => {
    api.open({
      message: title,
      placement: placement,
      duration: 0,
      type: type,
      icon: icon,
      role: role,
      className: clsx(
        className,
        type && `notification-${type}`,
        role === "alert" && "grid-notification",
        role === "status" && "status-notification",
        variant && `notification-${variant}`,
        showContent === false && "notification-closed",
        !showInfoIcon && "without-info-icon",
        handleClose && "with-close-icon"
      ),
      closeIcon: handleClose ? (
        <div
          onClick={handleClose}
          className="anticon anticon-close ant-notification-notice-close-icon"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleClose();
            }
          }}
          role="button"
          tabIndex={0}
        />
      ) : null,
      description: description ? (
        <div className="ant-notification-notice-description--inner">
          {description}
        </div>
      ) : undefined,
    });
  }, [
    api,
    title,
    placement,
    description,
    type,
    icon,
    role,
    className,
    variant,
    showContent,
    showInfoIcon,
    handleClose,
  ]);

  const closeNotification = useCallback(() => {
    api.destroy();
  }, [api]);

  useEffect(() => {
    configureContainer();
  }, [configureContainer]);

  useEffect(() => {
    if (show) {
      openNotification();
    } else {
      closeNotification();
    }
  }, [show, openNotification, closeNotification]);

  return (
    <div
      ref={containerRef}
      className={clsx({
        "notification-wrapper": true,
      })}
    >
      {contextHolder}
    </div>
  );
};
