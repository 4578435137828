import { Popover } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const PaymentAmountAccordianDetails = () => {
  const monthlyPaymentAccordionItems = [
    {
      key: "1",
      label: "First monthly payment",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  First month's payment
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XX
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Taxes
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XX
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$XXX</span>,
    },
  ];

  const protectionAccordionItems = [
    {
      key: "1",
      label: "Protection & coverage",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Essential protection bundle
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $X,XXX
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Windshield protection
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$XXX</span>,
    },
    {
      key: "2",
      label: "Accessories",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  BMW Crystal Fusion
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $X,XXX
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  BMW Paint Protection Wrap
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$XXX</span>,
    },
  ];

  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Down payment
            </span>
            <span className="pricing-details__item__price text--body-2">
              $XXX
            </span>
          </div>
          <Accordion
            items={monthlyPaymentAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
          <div className="divider"></div>
          <Accordion
            items={protectionAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
          <div className="divider"></div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Security deposit
            </span>
            <span className="pricing-details__item__price text--body-2">
              $XXX
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Initial fee & charges
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $XXX
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              <span className="d-block d-md-inline"> Sales tax</span> (ZIP Code
              XXXXX)
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $X,XXX
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
