import "./add-ons-drawer-content.scss";
import { Accordion, Button, Image } from "@src/components/atoms";
import Logo from "../../../../shared/assets/img/trade-in-drawer-logo.png";
import { NothingSelectedList, ReviewListCard } from "@src/components/molecules";
import Image1 from "../../../../shared/assets/img/incentives-discounts-list-img1.png";
import kbbImage from "../../../../shared/assets/img/add-ons-trade-in-kbb-img.png";
import addOnsImage1 from "../../../../shared/assets/img/add-ons-drawer-img-1.png";
import addOnsImage2 from "../../../../shared/assets/img/add-ons-drawer-img-2.png";
import NoImage from "../../../../shared/assets/img/dsx-img-ph.png";
import { useGeneralContext } from "@src/context/general-context/general-context";

export const AddOnsDrawerContent = () => {
  const {
    contextState: { nocart },
  } = useGeneralContext();
  const exampleItems = [
    {
      key: "1",
      label: <span className="text--cta-2">See Details </span>,
      children: (
        <>
          <div className="review-listing-section__list-wrap">
            <ReviewListCard
              image={NoImage}
              title={"Headline"}
              subTitle={"Lorem ipsum"}
              price={
                <>
                  $X,XXX<span className="text--body-2">/month</span>
                </>
              }
              totalPrice={"Lorem ipsum"}
              className=""
              actionsArea={""}
            />
          </div>
          <div className="review-listing-section__list-wrap">
            <ReviewListCard
              image={NoImage}
              title={"Headline"}
              subTitle={"Lorem ipsum"}
              price={
                <>
                  $X,XXX<span className="text--body-2">/month</span>
                </>
              }
              totalPrice={"Lorem ipsum"}
              className=""
              actionsArea={""}
            />
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="add-ons-drawer__content">
        <div className="add-ons-drawer__listing">
          {/* <div className="add-ons-drawer__listing__header">
            <NothingSelectedList title={"No incentive & rebate added"} />
            <NothingSelectedList title={"No trade-in value added"} />
            <NothingSelectedList title={"No protection added"} />
            <NothingSelectedList title={"No accessories added"} />
          </div> */}

          {nocart ? (
            <div className="add-ons-drawer__listing__header">
              <NothingSelectedList
                title={"Incentive"}
                actionArea={
                  <Button linkButton withIcon htmlType={"button"} text="Add" />
                }
              />
              <NothingSelectedList
                title={"Trade-in"}
                actionArea={
                  <Button linkButton withIcon htmlType={"button"} text="Add" />
                }
              />
              <NothingSelectedList
                title={"Protection"}
                actionArea={
                  <Button linkButton withIcon htmlType={"button"} text="Add" />
                }
              />
              <NothingSelectedList
                title={"Accessories"}
                actionArea={
                  <Button linkButton withIcon htmlType={"button"} text="Add" />
                }
              />
            </div>
          ) : (
            <div className="add-ons-drawer__list-card">
              <div className="add-ons-drawer__list-card__item">
                <NothingSelectedList
                  title={"Incentive"}
                  actionArea={
                    <Button
                      linkButton
                      withIcon
                      htmlType={"button"}
                      text="Edit"
                    />
                  }
                />
                <div className="add-ons-drawer__list-card__item__content">
                  <ReviewListCard
                    image={Image1}
                    title={"New York Incentive"}
                    subTitle={""}
                    price={"-$500"}
                    totalPrice={""}
                    className=""
                    actionsArea={""}
                  />
                </div>
              </div>
              <div className="add-ons-drawer__list-card__item">
                <NothingSelectedList
                  title={"Trade-in"}
                  actionArea={
                    <Button
                      linkButton
                      withIcon
                      htmlType={"button"}
                      text="Edit"
                    />
                  }
                />
                <div className="add-ons-drawer__list-card__item__content">
                  <ReviewListCard
                    image={kbbImage}
                    title={"2020 BMW 330i xDrive Sedan"}
                    subTitle={"Offer valid until XX/XX/20XX"}
                    price={"-$5,000"}
                    totalPrice={"Net trade-in value"}
                    className=""
                    actionsArea={""}
                  />
                </div>
              </div>
              <div className="add-ons-drawer__list-card__item">
                <NothingSelectedList
                  title={"Protection"}
                  actionArea={
                    <Button
                      linkButton
                      withIcon
                      htmlType={"button"}
                      text="Edit"
                    />
                  }
                />
                <div className="add-ons-drawer__list-card__item__content">
                  <div className="card-with-accordian">
                    <ReviewListCard
                      itemCount="+4"
                      image={Image1}
                      title={"Essential protection bundle"}
                      subTitle={"for 72 months, 12,000 miles"}
                      price={
                        <>
                          $32 <span className="text--body-2">/month</span>
                        </>
                      }
                      totalPrice={"Total: $X,XXX.XX"}
                      className=""
                      actionsArea={""}
                    />

                    <div className="review-accordian">
                      <Accordion
                        items={exampleItems}
                        defaultActiveKey={0}
                        ghost={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-ons-drawer__list-card__item">
                <NothingSelectedList
                  title={"Accessories"}
                  actionArea={
                    <Button
                      linkButton
                      withIcon
                      htmlType={"button"}
                      text="Edit"
                    />
                  }
                />
                <div className="add-ons-drawer__list-card__item__content">
                  <ReviewListCard
                    image={addOnsImage1}
                    title={"Product name"}
                    subTitle={""}
                    price={
                      <>
                        $XX<span className="text--body-2">/month</span>
                      </>
                    }
                    totalPrice={"Total: $X,XX"}
                    className=""
                    actionsArea={""}
                  />
                  <ReviewListCard
                    image={addOnsImage2}
                    title={"Product name"}
                    subTitle={""}
                    price={
                      <>
                        $XX<span className="text--body-2">/month</span>
                      </>
                    }
                    totalPrice={"Total: $X,XX"}
                    className=""
                    actionsArea={""}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
