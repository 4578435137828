import { Button, FileUpload, Image } from "@src/components/atoms";
import "./uploading-supporting-docs.scss";
import Placeholder from "../../../../../shared/assets/img/placeholder.png";
import { UploadDocuments } from "../upload-documents/upload-documents.component";

const incentives = [
  { id: 1, name: "[Name of incentive]" },
  { id: 2, name: "[Name of incentive]" },
  { id: 3, name: "[Name of incentive]" },
];

export const ProofOfIncentive = () => {
  return (
    <div className="upload-docs-info">
      <h3>Proof of eligibility for rebates & incentives.</h3>
      {incentives.map((incentive) => (
        <div key={incentive.id} className="upload-docs__inner with-image">
          <div className="divider"></div>
          <div className="upload-docs__documents">
            <Image src={Placeholder} alt="image" aspectRatio="16:9" />
            <div className="upload-docs__content">
              <div>
                <h5>{incentive.name}</h5>
                <p className="text--body-2">
                  Please upload one of the following documents:
                </p>
                <ul className="text--body-2">
                  <li>Document type lorem</li>
                  <li>Document type lorem</li>
                  <li>Document type lorem</li>
                </ul>
              </div>
              <UploadDocuments />
            </div>
          </div>
        </div>
      ))}
      <div className="upload-docs__cta">
        <Button type="primary" htmlType={"button"} text="Save" disabled />
      </div>
    </div>
  );
};
