import { IApplicationState } from "@src/types";

export const initialState: IApplicationState = {
  // UI State
  ui: {
    theme: {},
    openChat: false,
    collapseState: false,
  },

  // User Information
  user: {
    isUserLoggedIn: !!localStorage.getItem("access_token"),
    email: "",
    customer: {
      fetchedData: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      customer_type: "",
      date_of_birth: "",
      ssn: "",
      mobile_number: "",
      home_number: "",
      preferred_contact: "",
      reference_id: "",
      identifier: "",
      email: "",
      income_info: {
        employment_type: "",
        name: null,
        phone: null,
        employed_since: "",
        occupation: null,
        annual_employment_income: null,
        annual_other_income: null,
        source_of_other_income: "",
      },
      financial_profile: {
        residence: "Mortgage",
        monthly_payment: 0,
        bankruptcy: false,
        reference_id: null,
      },
      license_info: {
        first_name: "",
        last_name: "",
        status: null,
        license_number: "",
        is_pdf_417: false,
        issue_date: null,
        expiry_date: "",
        date_of_birth: "",
        is_selfie_verified: null,
      },
      payments_info: [],
      customer_addresses: {
        previous: {
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: true,
          identifier: "",
        },
        mailing: {
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: true,
          identifier: "",
        },
        garaging: {
          address_line_1: "",
          entity_type: null,
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: false,
          move_in_date: "",
          is_garaging: false,
          identifier: "",
        },
        billing: {
          entity_type: null,
          address_line_1: "",
          address_line_2: "",
          state_name: "",
          zip_code: "",
          county: "",
          city: "",
          contact_number: null,
          verified: true,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
        drivingLicense: {
          address_line_1: null,
          address_line_2: null,
          state_name: "",
          zip_code: null,
          county: null,
          city: null,
          contact_number: null,
          verified: false,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
        employment: {
          address_line_1: null,
          address_line_2: null,
          state_name: null,
          zip_code: null,
          county: null,
          city: null,
          contact_number: null,
          verified: null,
          move_in_date: null,
          is_garaging: false,
          identifier: "",
        },
      },
    },
  },
  states: [],
  employmentTypes: ["Employed", "Self Employed", "Retired", "Not Employed"],
  // Tenant Information
  tenant: {
    tenantId: 0,
    tenantName: "",
    slug: "",
    email: "",
    companyCode: "",
    mileageUnit: "",
    company_id: 0,
    defaultRadius: 0,
    min_months_stay_at_address: null,
    currency: {
      defaultCurrency: 0,
      defaultCurrencySymbol: "",
      defaultCurrencyCode: "",
    },
  },
  lender: {
    defaultLender: {
      id: 0,
      name: "",
      external_code: "",
      default_lender: false,
      credit_tier_margins: [],
    },
    lenders: [],
  },
  order: {
    status: "",
    credit_rating: "",
    credit_score: 0,
    rebate: 0,
    selling_price: 0,
    base_rate: 0,
    identifier: "",
    net_finance_amount: 0,
    order_fnI: [],
    order_options: [],
    order_asset: {
      model: "",
      make: "",
      is_new: "",
      odometer_mileage: 0,
      unit_price: 0,
      msrp: 0,
      year: 0,
      trim_description: "",
      transmission_type: "",
      engine_cylinders: "",
      mileage: 0,
      stock_number: "",
      vin: "",
      type: "",
      photo_urls: [],
    },
    annual_usage: 0,
    rental_mode: null,
    order_tradein: null,
    margin: 0,
    rv_balloon_value: 0,
    rv_balloon_percentage: 0,
    lender_id: 0,
    order_fees: [],
    estimated_monthly_payment: 0,
    monthly_sales_use_tax: 0,
    contract_term: 0,
    customer_info: {
      email: "",
      reference_id: "",
      customer_addresses: [],
      fraud_status: "",
    },
    down_payment: 0,
    down_payment_value: 0,
    apr: 0,
    finance_type: "",
    order_submissions: [],
    company_provider: [],
    Insurance_Inforation: {
      identifier: "",
      agent_phone: "",
      insurer_name: "",
      order_reference_id: "",
      created_by: "",
      updated_by: "",
    },
    dealer_id: 0,
    reference_number: "",
  },
  dealer: {
    id: 2,
    dealer_name: "",
    email: "",
    chat: true,
    contact_person: "",
    contact_person_first_name: "",
    contact_person_last_name: "",
    contact_person_email: "",
    contact_person_role: "",
    disclaimer: [],
    is_active: true,
    user_id: 0,
    company_id: 0,
    lender_id: null,
    lead_abandoned_timer: 0,
    user_name: null,
    is_deleted: false,
    available_finance_types: ["Lease", "Finance"],
    available_asset_categories: null,
    available_asset_types: null,
    schedule_option: ["pickup", "delivery"],
    contact_number: "",
    customer_support_number: "",
    time_zone: "",
    time_zone_difference: "",
    fax_number: "",
    website: "",
    contact_person_name: "",
    day_light_saving: true,
    initials: "",
    dealer_address: {
      is_active: true,
      is_deleted: false,
      id: 0.0,
      address_line_1: "",
      address_line_2: "",
      state_id: 0,
      state_name: "",
      zip_code: "",
      county: "",
      contact_number: null,
      coordinates: "",
      city: "",
      country_id: 0,
      verified: false,
      address_type: "",
    },
    market_scan_number: null,
    trading_as: "",
    contact_person_phone_number: "",
    pen_dealer_id: "",
    stripe_dealer_id: null,
    shift_digital_dealer_id: null,
    facebook: null,
    twitter: null,
    google: null,
    instagram: null,
    linkedin: null,
    logo: "",
    youtube: null,
    custom_menu_links: [],
    sub_domain: null,
    vehicle_condition: ["New"],
    payoff_dealer_id: null,
    crm_emails: null,
    home_net_dealer_id: null,
    dealer_code: "",
  },
  // Configuration
  configuration: {
    language: { placeholders: "" },
    defaultCurrency: 0,
    defaultCurrencySymbol: "",
    defaultCurrencyCode: "",
    defaultRadius: 0,
    mileageUnit: "",
    setupData: {},
    urlOrigin:
      process.env.NODE_ENV === "development"
        ? "checkout-dev.bmwdr.io"
        : window.location.origin.replace(/^https?:\/\//, ""),
  },

  // Links and Social Media
  links: {
    headerLinks: [],
    footerLinks: [],
    socialMediaIcons: {},
  },
};
