import {
  DMS_BASE_URL,
  CONFIG_BASE_URL,
  DOMAIN,
  INSURANCE_PROVIDER,
  EP_ADD_INSURANCE_DETAILS,
  EP_UPDATE_INSURANCE_DETAILS,
  DEAL_INFO,
  EP_SUBMIT_ORDER,
  DEFAULT_LENDER_CONFIGURATION,
  DMS_FRAUD_CHECK,
  LENDER_MULTIPLE,
  SAVE_DEAL,
  UPDATE_DEAL,
  REBATES_FILTER,
  DELETE_CUSTOMER_FROM_DEAL,
  INTEGRATION_BY_PROVIDER_NAME,
  APPROVE_ORDER_BY_LENDER,
  CONFIG_VEHICLE_DETAIL_BY_VIN,
  GET_ACCESSORIES_BY_MODEL,
  GET_FNI_MONTHLY_PAYMENT_IMAPACT,
  CONFIG_BASE_URL_FEAT,
} from "../constants/routes";
import { http } from "../utilities";

export const getCompanyByTenantDomain = async (origin_domain: any) => {
  return await http(CONFIG_BASE_URL).get(`${DOMAIN}/${origin_domain}`);
};

export const approveOrderByLender = async (
  referenceNumber: any,
  lenderId: any,
  orderStatus: any
) => {
  return await http(DMS_BASE_URL)
    .patch(
      `${APPROVE_ORDER_BY_LENDER}${referenceNumber}/${lenderId}?order_status=${orderStatus}`
    )
    .then((res) => res.data);
};

export const getDefaultLender = async (company_id: any) => {
  return await http(CONFIG_BASE_URL).get(`${DEFAULT_LENDER_CONFIGURATION}`, {
    headers: { Company_id: company_id.toString() },
  });
};

export const submitOrder = async (body: any) => {
  return await http(DMS_BASE_URL).post(
    `${EP_SUBMIT_ORDER}?reference_number=${body?.referenceNumber}`,
    body
  );
};

export const saveDeal = async (body: any) => {
  return await http(DMS_BASE_URL)
    .post(SAVE_DEAL, body)
    .then((res) => res.data);
};

export const getAddOns = async (body: any) => {
  return await http(CONFIG_BASE_URL)
    .get(
      `${GET_ACCESSORIES_BY_MODEL}?dealer_code=${body.dealerCode}&model_name=${body.modelName}`
    )
    .then((res) => res.data);
};

export const updateDeal = async (body: any) => {
  return await http(DMS_BASE_URL)
    .patch(UPDATE_DEAL + body.identifier, body)
    .then((res) => res.data);
};

export const getVehicle = async (body: any) => {
  return await http(CONFIG_BASE_URL)
    .get(`${CONFIG_VEHICLE_DETAIL_BY_VIN}${body.dealerCode}/${body.vin}`, {
      headers: { Company_id: body.company_id.toString() },
    })
    .then((res) => res.data);
};

export const deleteCustomerFromDeal = async (body: any) => {
  return await http(DMS_BASE_URL).delete(
    `${DELETE_CUSTOMER_FROM_DEAL}${body.orderId}/${body.customerId}`
  );
};

export const getFraudCheck = async (body: any) => {
  return await http(DMS_BASE_URL)
    .get(
      `${DMS_FRAUD_CHECK}?order_reference_id=${body?.referenceNumber}&customer_reference_id=${body.customer_reference_id}`
    )
    .then((res) => res.data);
};

export const getInsuranceProvider = async (company_id: any) => {
  return await http(CONFIG_BASE_URL).get(`${INSURANCE_PROVIDER}`, {
    headers: { Company_id: company_id.toString() },
  });
};

export const getOrderInfo = async (dealRefNumber: any) => {
  return await http(DMS_BASE_URL)
    .get(`${DEAL_INFO}/${dealRefNumber}`)
    .then((res) => res.data);
};

export const addInsuranceDetails = async (body: any) => {
  return http(DMS_BASE_URL).post(`${EP_ADD_INSURANCE_DETAILS}`, body);
};

export const updateInsurance = async (body: any) => {
  return http(DMS_BASE_URL).patch(
    `${EP_UPDATE_INSURANCE_DETAILS}?reference_id=${body.reference_id}&order_reference_id=${body.order_reference_id}`,
    body
  );
};

export const getLender = async (body: any) => {
  return await http(CONFIG_BASE_URL)
    .get(
      `${LENDER_MULTIPLE}/${body.dealerCode}?finance_type=${body.finance_type}`
    )
    .then((res) => res.data);
};

export const getRebates = async (body: any, queryParams: any) => {
  return await http(CONFIG_BASE_URL, { company_id: body.companyId })
    .get(`${REBATES_FILTER}?${queryParams}&is_active=true`)
    .then((res) => res.data);
};

export const monthlyImpact = async (body: any) => {
  return await http(DMS_BASE_URL)
    .post(`${GET_FNI_MONTHLY_PAYMENT_IMAPACT}`, body)
    .then((res) => res.data);
};
