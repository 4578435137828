import { useState } from "react";
import styled from "@emotion/styled";

import {
  Button,
  Checkbox,
  Drawer,
  Image,
  Slider,
  Input,
  Modal,
  Radio,
  Select,
  Tab,
  Tooltip,
  Switch,
  OverflowMenu,
  Carousel,
  MessageAlert,
  Popover,
  ProgressBar,
  TagLabel,
  FileUpload,
} from "../atoms";
import { Datepicker } from "../atoms/date-picker/date-picker.component";
import { Phone } from "../atoms/phone-input/phone-input.component";
import { Notification } from "../atoms/notification/notification.component";
import logoImage from "@src/shared/assets/img/logo.jpg";
import { Accordion } from "../atoms/accordion/accordion.component";
import { AutoComplete } from "../atoms/autocomplete/autocomplete.component";
import ImagePlaceholder from "../../shared/assets/img/dsx-img-ph.png";
import { Col, message, Row } from "antd";
import {
  ExitCheckoutModal,
  LegalDisclaimerSheet,
  ReservationTimerModal,
} from "../molecules";
import { Chat, DisclaimerNotification, PaymentDialog } from "../organisms";
import { AgreementsRehash } from "../organisms/credit-application/checkout/06-agreements/agreement-notifications/agreements-rehash-message";
import { AgreementsTradeInExpired } from "../organisms/credit-application/checkout/06-agreements/agreement-notifications/agreements-trade-in";

export const PreviewComponent = () => {
  const [state, setState] = useState();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onShowModal = () => {
    setShowModal(true);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };
  const onClose = () => {
    setOpen(false);
  };
  const exampleItems = [
    {
      key: "1",
      label: "Label",
      children: "Text content",
    },
    {
      key: "2",
      label: "Label",
      children: "Text content",
    },
    {
      key: "3",
      label: "Label",
      children: "Text content",
      extra: <span className="text--body-focus-price-1">Hello</span>,
    },
  ];
  const items = [
    {
      key: "1",
      label: "Content",
      children: "Content 1",
    },
    {
      key: "2",
      label: "Content",
      children: "Content 2",
    },
    {
      key: "3",
      label: "Content",
      children: "Content 3",
    },
  ];
  const [value_1, setValue_1] = useState<string>("");

  const staticOptions = [
    { value: "Burns Bay Road" },
    { value: "Downing Street" },
    { value: "Wall Street" },
  ];

  const handleSearch = (text: string) => {};

  const handleSelect = (data: string) => {};

  const handleChange = (data: string) => {
    setValue_1(data);
  };

  const menuItems = [
    {
      key: "1",
      label: "Option",
      dataIconLeft: "car_front",
      disabled: false,
      checkbox: false,
    },
    {
      key: "2",
      label: "Option",
      dataIconLeft: "car_front",
      disabled: true,
      checkbox: false,
    },
    {
      key: "3",
      label: "Option",
      dataIconRight: "car_front",
      disabled: true,
      checkbox: true,
    },
    {
      key: "4",
      label: "Option",
      dataIconRight: "car_front",
      disabled: false,
      checkbox: false,
    },
    {
      key: "5",
      label: "Option",
      dataIcon: "car_front",
      disabled: false,
      checkbox: true,
    },
  ];

  const menuItemsButton = [
    {
      key: "1",
      label: "Saved deals",
      dataIconRight: "add_car",
      disabled: false,
    },
    {
      key: "2",
      label: "My BMW account",
      dataIconRight: "external_link",
      disabled: false,
    },
    {
      key: "3",
      label: "Log out",
      dataIconRight: "logout",
      disabled: false,
    },
  ];

  const carouselImages = [
    { src: ImagePlaceholder, alt: "Slide 1" },
    { src: ImagePlaceholder, alt: "Slide 2" },
    { src: ImagePlaceholder, alt: "Slide 3" },
  ];

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopoverOpen(newOpen);
  };
  const hidePopup = () => {
    setIsPopoverOpen(false);
  };

  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isTimerModalOpen, setIsTimerModalOpen] = useState(false);

  const exitModal = () => {
    setIsExitModalOpen(true);
  };

  const timerModal = () => {
    setIsTimerModalOpen(true);
  };

  const StyledMessageAlert = styled.div`
    .message-alert__body .message-alert__content {
      padding: 16px 24px;
      color: #262626;
      text-align: center;
      border: 2px dashed #262626;
      margin-top: 4px;
    }
  `;
  const onShowPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  return (
    <div className="login-form" style={{ margin: 15 }}>
      <LegalDisclaimerSheet />
      {/* <DisclaimerNotification /> */}

      <Notification
        placement="bottomRight"
        show={true}
        title="Your trade-in offer has expired."
        showInfoIcon
        className="trade-in-notification"
        description={
          <section className="notification-content">
            <div className="notification-content--pricing-wrap">
              <div className="notification-content--pricing-inner">
                <p className="text--disclaimer">
                  Please review your trade-in to continue with your deal. Don’t
                  worry, we’ll save your progress and bring you back to where
                  you left off once you’re done.
                </p>
              </div>
              <div className="notification-content--pricing-inner">
                <Button
                  htmlType="button"
                  text={"Review trade-in"}
                  onClick={() => {}}
                  size={"small"}
                  type="default"
                />
              </div>
            </div>
          </section>
        }
        type="error"
        showContent={true}
        handleClose={() => {}}
      />

      {/* <Notification
        placement="topLeft"
        show={true}
        title={
          <>
            [BMW of Manhattan] just applied{" "}
            <span className="fw-bold">$2,000</span> incentives to your instant
            cash offer.
          </>
        }
        variant="default"
        showContent={true}
        showInfoIcon={false}
        handleClose={() => {}}
      />   */}

      {/* <Notification
        show={true}
        title="Lorem ipsum dolor sit amet consectetur. Suspendisse fames orci vestibulum amet massa mauris augue."
        variant="default"
        type="error"
        showContent={true}
        showInfoIcon={false}
        handleClose={() => { }}
        description={
          <section className="notification-content">
            <div className="notification-content--pricing-wrap">
              <div className="notification-content--pricing-inner">
                <p className="text--body-focus-price-1">$X,XXX</p>
                <p className="text--disclaimer">
                  <span>$X,XXX</span> <span>dealer credit applied</span>
                </p>
              </div>
              <div className="notification-content--pricing-inner">
                <p className="text--body-focus-price-1">
                  $X,XXX <span className="text--body-1">/month</span>
                </p>
                <p className="text--disclaimer with-pricing-arrow">
                  <span className="text--disclaimer">
                    <span
                      className="bmw-icon bmw-icon--before data-icon"
                      data-icon={"arrow_down"}
                    />
                  </span>
                  <span className="text--disclaimer">
                    <span>$X,XXX</span> <span>less per month</span>
                  </span>
                </p>
              </div>
            </div>
          </section>
        }
      /> */}

      {/* <Notification
        show={true}
        title="Based on your credit standing, we're able to give you an even more compelling rate. You'll now be saving:"
        variant="default"
        placement="topRight"
        showContent={true}
        showInfoIcon={false}
        handleClose={() => { }}
        description={
          <section className="notification-content">
            <div className="notification-content--pricing-wrap">
              <div className="notification-content--pricing-inner">
                <p className="text--body-1 with-pricing-arrow icon-size-normal">
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon={"arrow_down"}
                  />
                  <span className="text--body-1">
                    <span className="fw-bold">$X,XXX</span>{" "}
                    <span>less per month</span>
                  </span>
                </p>
              </div>
            </div>
          </section>
        }
      /> */}

      {/* <Notification
        show={true}
        title="Your vehicle reservation is expiring soon. If you need more time, you may extend your reservation by up to 10 minutes."
        variant="default"
        placement="bottomLeft"
        showContent={true}
        showInfoIcon={false}
        handleClose={() => { }}
        description={
          <section className="notification-content">
            <div className="notification-content--pricing-wrap">
              <Button
                className=" text-white"
                linkButton
                onClick={() => { }}
                htmlType="button"
                withIcon
                dataIcon="arrow_chevron_right"
                text="Extend my reservation"
                isIconEnd
              />
            </div>
          </section>
        }
      /> */}

      <div style={{ padding: 16 }}>
        <FileUpload
          name="uploadFile"
          action="https://example.com/upload"
          onChange={() => {}}
          onDrop={() => {}}
          multiple={true}
          accept="image/*"
          fileUploadTitle="Click here to upload your file, or drag and drop."
          fileUploadSubTitle="Supported formats: PDF, PNG, JPEG (10 MB limit each)"
          fileUpoadIcon="upload"
          doneActionArea={(file) => (
            <Button
              htmlType="button"
              linkButton
              withIcon
              dataIcon="trash_can"
              isIconEnd
              text="Remove"
              onClick={() => {}}
            />
          )}
          loadingActionArea={(file) => (
            <Button
              htmlType="button"
              linkButton
              withIcon
              dataIcon="arrow_chevron_right"
              isIconEnd
              text="Remove"
              onClick={() => {}}
            />
          )}
          errorActionArea={(file) => (
            <Button
              htmlType="button"
              linkButton
              withIcon
              dataIcon="trash_can"
              isIconEnd
              text="Remove"
              onClick={() => {}}
            />
          )}
          filePreviewIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M5.83184 4.08187H13.125C13.8614 4.08187 14.3112 4.24151 14.6044 4.58056C14.9195 4.94495 15.1681 5.64423 15.1681 6.99999V11.0819H19.25C20.6058 11.0819 21.305 11.3306 21.6695 11.6456C22.0056 11.9363 22.1654 12.381 22.1681 13.1065V23.9181H15.1375V25.0819H23.3319V13.0379C23.3321 12.0239 23.3322 11.2735 23.078 10.5746C22.8113 9.84113 22.2884 9.21559 21.4114 8.33854L17.9114 4.83854C17.0343 3.96148 16.4088 3.4386 15.6754 3.1719C14.9765 2.91775 14.2261 2.9179 13.2121 2.9181L4.66809 2.91812V12.8625H5.83184V4.08187ZM20.5885 9.16143C21.051 9.62395 21.3674 9.9729 21.588 10.2686C20.9719 10.0271 20.1953 9.91813 19.25 9.91813H16.3319V6.99999C16.3319 6.05477 16.2229 5.27809 15.9814 4.66202C16.2771 4.88262 16.626 5.19893 17.0885 5.66144L20.5885 9.16143Z"
                fill="#262626"
              />
              <path
                d="M15.75 14.8183L7.34878 22.75L2.625 18.2903L3.49178 17.4719L7.34878 21.1133L14.8832 14L15.75 14.8183Z"
                fill="#262626"
              />
            </svg>
          }
        />
      </div>
      <div style={{ padding: 16 }}>
        <AgreementsRehash />
      </div>
      <div style={{ padding: 16 }}>
        <AgreementsTradeInExpired />
      </div>
      <div>
        <div style={{ padding: 16, background: "#000" }}>
          <Button
            htmlType={"button"}
            type="primary"
            text="label"
            dataIcon="car_front"
            withIcon
            withoutBg
            onDark
          />
        </div>
        <div style={{ padding: 16 }}>
          <Button
            linkButton
            withIcon
            htmlType="button"
            text="Christopher"
            dataIcon="person"
            optionalDataIcon="arrow_chevron_down"
            isIconEnd
          />
        </div>
        <div style={{ padding: 16 }}>
          <Button
            htmlType={"button"}
            type="primary"
            text="label"
            dataIcon="car_front"
            withIcon
            withoutBg
          />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" withIcon iconName="car_front" type="success" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel
            text="Label"
            withIcon
            iconName="car_front"
            type="success"
            variant="light"
          />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" withIcon iconName="car_front" type="primary" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel
            text="Label"
            withIcon
            iconName="car_front"
            type="secondary"
          />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" withIcon iconName="car_front" type="default" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" withIcon iconName="car_front" type="warning" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel
            text="Label"
            withIcon
            iconName="car_front"
            type="warning"
            variant="light"
          />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" withIcon iconName="car_front" type="error" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel
            text="Label"
            withIcon
            iconName="car_front"
            type="error"
            variant="light"
          />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="success" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="success" variant="light" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="primary" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="secondary" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="default" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="warning" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="warning" variant="light" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="error" />
        </div>
        <div style={{ margin: 5 }}>
          <TagLabel text="Label" type="error" variant="light" />
        </div>
        <Input
          id={""}
          name={""}
          placeholder="Input"
          helpText="Optional helper text"
          label="Label"
          suffix={"$"}
          prefix={"00"}
          value="Hello"
          showInfoIcon
          error={{ message: "Error message" }}
        />
        <Input
          id={""}
          name={""}
          placeholder="Input"
          helpText="Optional helper text"
          label="Label"
          suffix={"$"}
          prefix={"00"}
          value="Hello"
          showInfoIcon
        />
        <Input
          id={""}
          name={""}
          placeholder="Input"
          helpText="Optional helper text"
          label="Label"
          suffix={"$"}
          prefix={"00"}
          value="Hello"
          showInfoIcon
          size="small"
          error={{ message: "Error message" }}
        />
        <Input
          id={""}
          name={""}
          placeholder="Input"
          helpText="Optional helper text"
          label="Label"
          suffix={"$"}
          prefix={"00"}
          value="Hello"
          showInfoIcon
          size="small"
        />
        <Input
          id={""}
          name={""}
          placeholder="Input"
          helpText="Optional helper text"
          label="Label"
          error={{ message: "okk " }}
          size="small"
          showInfoIcon
        />
        <div style={{ marginBottom: 16 }}>
          <ProgressBar
            percent={100}
            status="success"
            label="Label Text"
            helpText="Optional helper text"
          />
          <div style={{ marginBottom: 25 }}></div>
          <ProgressBar
            percent={30}
            label="Label Text"
            helpText="Optional helper text"
          />
          <div style={{ marginBottom: 25 }}></div>
          <ProgressBar
            percent={30}
            label="Label Text"
            helpText="Optional helper text"
            status="exception"
          />
        </div>
        <div style={{ background: "#000", padding: 16, marginBottom: 16 }}>
          <ProgressBar
            percent={10}
            label="Label Text"
            showInfo={false}
            showLabelIcon
            iconName="information"
            onDark
          />
        </div>

        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Switch label="Label" />
          <Switch label="Label" disabled />
          <Switch label="Label" value={false} />
          <Switch label="Label" loading={true} />
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Switch label="Label" size="small" />
          <Switch label="Label" disabled size="small" />
          <Switch label="Label" value={false} size="small" />
          <Switch label="Label" loading={true} size="small" />
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Popover
            title="Headline"
            trigger={["click"]}
            placement="right"
            onOpenChange={handleOpenChange}
            open={isPopoverOpen}
            closeIcon="close"
            onClose={hidePopup}
            disclaimerText="Popover displays additional information or action to the user."
            actionArea={
              <div className="popover-label">
                <span className="text--cta-2">Label</span>
                <div
                  className="bmw-icon bmw-icon--before data-icon"
                  role="img"
                  aria-label="Icon for information"
                  data-icon="arrow_chevron_right"
                />
              </div>
            }
          >
            <Button type="primary" htmlType={"button"} text="Label" />
          </Popover>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Button type="primary" htmlType={"button"} text="Label" fixed />
            <br />
            <Button type="primary" htmlType={"button"} text="Label" />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              fullWidth
            />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              fullWidth
            />
            <br />
          </div>
          <div>
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              fixed
              disabled
            />
            <br />
            <Button type="primary" htmlType={"button"} text="Label" disabled />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
            />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              disabled
            />
            <br />
          </div>
          <div>
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              fixed
              disabled
              isLoading
              loadingIconPosition="end"
            />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              disabled
              isLoading
            />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
              isLoading
            />
            <br />
            <Button
              type="primary"
              htmlType={"button"}
              text="Label"
              size="small"
              disabled
              isLoading
            />
            <br />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Button type="default" htmlType={"button"} text="Label" fixed />
            <br />
            <Button type="default" htmlType={"button"} text="Label" />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
            />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
            />
            <br />
          </div>
          <div>
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              fixed
              disabled
            />
            <br />
            <Button type="default" htmlType={"button"} text="Label" disabled />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
            />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
              disabled
            />
            <br />
          </div>
          <div>
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              fixed
              disabled
              isLoading
              loadingIconPosition="end"
            />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              disabled
              isLoading
            />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
              isLoading
            />
            <br />
            <Button
              type="default"
              htmlType={"button"}
              text="Label"
              size="small"
              disabled
              isLoading
            />
            <br />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Button htmlType={"button"} text="Label" fixed />
            <br />
            <Button htmlType={"button"} text="Label" />
            <br />
            <Button htmlType={"button"} text="Label" size="small" fixed />
            <br />
            <Button htmlType={"button"} text="Label" size="small" />
            <br />
          </div>
          <div>
            <Button htmlType={"button"} text="Label" fixed disabled />
            <br />
            <Button htmlType={"button"} text="Label" disabled />
            <br />
            <Button
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
            />
            <br />
            <Button htmlType={"button"} text="Label" size="small" disabled />
            <br />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              disabled
              isLoading
              loadingIconPosition="end"
            />
            <br />
            <Button htmlType={"button"} text="Label" disabled isLoading />
            <br />
            <Button
              htmlType={"button"}
              text="Label"
              size="small"
              fixed
              disabled
              isLoading
            />
            <br />
            <Button
              htmlType={"button"}
              text="Label"
              size="small"
              disabled
              isLoading
            />
            <br />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              linkButton
              withIcon
              dataIcon="car_front"
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              isIconEnd
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon={false}
              dataIcon="car_front"
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              isIconEnd
              dataIcon="car_front"
              disabled
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              size="small"
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              isIconEnd
              size="small"
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon={false}
              dataIcon="car_front"
              size="small"
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              isIconEnd
              dataIcon="car_front"
              disabled
              size="small"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 16,
            background: "#000",
            padding: 16,
          }}
        >
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              linkButton
              withIcon
              dataIcon="car_front"
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              isIconEnd
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon={false}
              dataIcon="car_front"
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              isIconEnd
              dataIcon="car_front"
              disabled
              onDark
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 16,
            background: "#000",
            padding: 16,
          }}
        >
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              size="small"
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              dataIcon="car_front"
              isIconEnd
              size="small"
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon={false}
              dataIcon="car_front"
              size="small"
              onDark
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              text="Label"
              fixed
              linkButton
              withIcon
              isIconEnd
              dataIcon="car_front"
              disabled
              size="small"
              onDark
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 16,
            background: "#000",
            padding: 16,
          }}
        >
          <div>
            <Button htmlType={"button"} text="Label" fixed onDark />
          </div>
          <div>
            <Button htmlType={"button"} text="Label" onDark />
          </div>
          <div>
            <Button htmlType={"button"} text="Label" onDark disabled />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 16,
            background: "#000",
            padding: 16,
          }}
        >
          <div>
            <Button
              htmlType={"button"}
              type="primary"
              text="Label"
              fixed
              onDark
            />
          </div>
          <div>
            <Button htmlType={"button"} type="primary" text="Label" onDark />
          </div>
          <div>
            <Button
              htmlType={"button"}
              type="primary"
              text="Label"
              onDark
              disabled
            />
          </div>
          <div>
            <Button
              htmlType={"button"}
              type="primary"
              text="Label"
              onDark
              isLoading
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  isLoading
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  isLoading
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button htmlType={"button"} onlyIcon dataIcon="car_front" />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  isLoading
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  isLoading
                  size="small"
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                  disabled
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                  disabled
                  isLoading
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                  disabled
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  size="small"
                  disabled
                  isLoading
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="primary"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                  size="small"
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  type="default"
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                  size="small"
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  withoutBg
                  size="small"
                />
              </div>
              <div>
                <Button
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="car_front"
                  disabled
                  withoutBg
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#000", padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    onDark
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    size="small"
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    disabled
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    disabled
                    isLoading
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    disabled
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    size="small"
                    disabled
                    isLoading
                    onDark
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    onDark
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="default"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    withoutBg
                    size="small"
                    onDark
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    onlyIcon
                    dataIcon="car_front"
                    onImage
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    onImage
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    onImage
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    onImage
                    size="small"
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    onImage
                    size="small"
                  />
                </div>
                <div>
                  <Button
                    htmlType={"button"}
                    type="primary"
                    onlyIcon
                    dataIcon="car_front"
                    disabled
                    isLoading
                    onImage
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
            />
          </div>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              disabled
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              label="Label"
              showInfoIcon
              error={{ message: "" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              size="small"
            />
          </div>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              disabled
              size="small"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              label="Label"
              showInfoIcon
              error={{ message: "" }}
              size="small"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Radio label="Label" id="id_1" htmlFor="id_1" size="small" />
          </div>
          <div>
            <Radio label="Label" id="id_2" htmlFor="id_2" checked />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Radio label="Label" id="id_3" error htmlFor="id_3" />
          </div>
          <div>
            <Radio label="Label" id="id_4" error htmlFor="id_4" checked />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Radio label="Label" id="id_5" disabled htmlFor="id_5" />
          </div>
          <div>
            <Radio label="Label" id="id_6" disabled htmlFor="id_6" checked />
          </div>
        </div>
        <div>
          <Checkbox id={"id-1"} htmlFor="id-1" />
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox id={"id-1"} label="Enabled" htmlFor="id-1" />
          </div>
          <div>
            <Checkbox id={"id-2"} label="Enabled" htmlFor="id-2" checked />
          </div>
          <div>
            <Checkbox
              id={"id-3"}
              label="Enabled"
              htmlFor="id-3"
              checked
              indeterminate
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox id={"id-4"} label="Enabled" htmlFor="id-4" error />
          </div>
          <div>
            <Checkbox
              id={"id-5"}
              label="Enabled"
              htmlFor="id-5"
              error
              checked
            />
          </div>
          <div>
            <Checkbox
              id={"id-6"}
              label="Enabled"
              htmlFor="id-6"
              error
              checked
              indeterminate
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox id={"id-1"} label="Enabled" htmlFor="id-1" disabled />
          </div>
          <div>
            <Checkbox
              id={"id-2"}
              label="Enabled"
              htmlFor="id-2"
              disabled
              checked
            />
          </div>
          <div>
            <Checkbox
              id={"id-3"}
              label="Enabled"
              htmlFor="id-3"
              disabled
              checked
              indeterminate
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox id={"id-1"} label="Enabled" htmlFor="id-1" isCompact />
          </div>
          <div>
            <Checkbox
              id={"id-2"}
              label="Enabled"
              htmlFor="id-2"
              checked
              isCompact
            />
          </div>
          <div>
            <Checkbox
              id={"id-3"}
              label="Enabled"
              htmlFor="id-3"
              checked
              indeterminate
              isCompact
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox
              id={"id-4"}
              label="Enabled"
              htmlFor="id-4"
              error
              isCompact
            />
          </div>
          <div>
            <Checkbox
              id={"id-5"}
              label="Enabled"
              htmlFor="id-5"
              error
              checked
              isCompact
            />
          </div>
          <div>
            <Checkbox
              id={"id-6"}
              label="Enabled"
              htmlFor="id-6"
              error
              checked
              indeterminate
              isCompact
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Checkbox
              id={"id-1"}
              label="Enabled"
              htmlFor="id-1"
              disabled
              isCompact
            />
          </div>
          <div>
            <Checkbox
              id={"id-2"}
              label="Enabled"
              htmlFor="id-2"
              disabled
              checked
              isCompact
            />
          </div>
          <div>
            <Checkbox
              id={"id-3"}
              label="Enabled"
              htmlFor="id-3"
              disabled
              checked
              indeterminate
              isCompact
            />
          </div>
        </div>

        <div>
          <Select
            id={""}
            placeholder="Content"
            label="Label"
            showInfoIcon
            options={[
              { value: "1", label: "Option 1", disabled: false },
              { value: "2", label: "Option 2", disabled: true },
            ]}
            helpText="Optional helper text"
          />
        </div>
        <div>
          <Select
            id={""}
            placeholder="Content"
            label="Label"
            showInfoIcon
            disabled
            options={[
              { value: "1", label: "Option 1", disabled: false },
              { value: "2", label: "Option 2", disabled: true },
            ]}
            helpText="Optional helper text"
          />
        </div>
        <div>
          <Select
            id={""}
            placeholder="Content"
            label="Label"
            showInfoIcon
            status="error"
            options={[
              { value: "1", label: "Option 1", disabled: false },
              { value: "2", label: "Option 2", disabled: true },
            ]}
            error={{ message: "Error message" }}
          />
        </div>
        <div style={{ marginBlock: 15 }} id="hello-world">
          <Datepicker
            id="datePicker"
            name="date-picker"
            placeholder="Choose"
            label="Label"
            showInfoIcon
            helpText="Optional helper text"
            prevIcon="arrow_left"
            nextIcon="arrow_right"
          />
        </div>
        <div style={{ marginBlock: 15 }}>
          <Datepicker
            id="datePickerNew"
            name="date picker"
            placeholder="Choose"
            status="error"
          />
        </div>
        <div style={{ marginBlock: 15 }}>
          <Datepicker
            id="datePickerNew"
            name="date picker"
            placeholder="Choose"
            disabled
          />
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div>
            <Phone
              name="phone-input"
              country="us"
              label="Label"
              showInfoIcon
              helpText="Optional helper text"
            />
          </div>
          <div>
            <Phone
              name="phone-input"
              country="us"
              label="Label"
              showInfoIcon
              helpText="Optional helper text"
              disabled
            />
          </div>
          <div>
            <Phone
              name="phone-input"
              country="us"
              label="Label"
              showInfoIcon
              error={{ message: "Optional helper text" }}
              status="error"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div style={{ flex: 1 }}>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              autoSize={{ minRows: 2, maxRows: 6 }}
              type="textarea"
              showCount
              onChange={(e: any) => {
                setState(e.target.value);
              }}
              value={state}
              maxLength={100}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              autoSize={{ minRows: 2, maxRows: 6 }}
              type="textarea"
              showCount
              onChange={(e: any) => {
                setState(e.target.value);
              }}
              value={state}
              maxLength={100}
              disabled
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div style={{ flex: 1 }}>
            <Input
              id={""}
              name={""}
              placeholder="Input"
              helpText="Optional helper text"
              label="Label"
              showInfoIcon
              autoSize={{ minRows: 2, maxRows: 6 }}
              type="textarea"
              showCount
              onChange={(e: any) => {
                setState(e.target.value);
              }}
              value={state}
              maxLength={100}
              // status="error"
              error={{ message: "hello" }}
            />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <div style={{ padding: 16, background: "#000" }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            onDark
          />
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            onDark
            disabled
          />
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            onDark
            error={{ message: "This is an error message!" }}
          />
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Tooltip
            text="Tooltip will show on mouse enter."
            prompt="Tooltip"
            placement="bottomRight"
            arrow={false}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          {/* <Notification
          show={true}
          title="Important information"
          description={
            <section className="notification-content">
              <p className="text--label">
                Notifications have a high degree of importance or urgency and
                are used to communicate with the user and provide feedback.
              </p>
              <Input
                id={""}
                name={""}
                placeholder="Input"
                label="Label"
                showInfoIcon
                error={{ message: "" }}
              />
              <div className="action-area">
                <Button
                  text="Take action"
                  htmlType="button"
                  size="small"
                  fixed
                  fullWidth
                />
                <Button
                  text="Dismiss"
                  linkButton
                  withIcon
                  size="small"
                  fixed
                  htmlType="button"
                />
              </div>
            </section>
          }
          type="error"
          showContent={true}
          icon={<img src={logoImage} alt="logo-img" />}
          handleClose={()=> {}}
        /> */}
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div style={{ flex: 1 }}>
            <Accordion items={exampleItems} defaultActiveKey={1} />
          </div>
          <div style={{ flex: 1 }}>
            <Accordion
              items={exampleItems}
              defaultActiveKey={1}
              ghost={false}
              accordion={true}
              expandIconPosition="start"
            />
          </div>
          <div style={{ flex: 1 }}>
            <Accordion
              items={exampleItems}
              defaultActiveKey={0}
              ghost={false}
              collapsible="disabled"
            />
          </div>
        </div>
        <Accordion
          items={exampleItems}
          defaultActiveKey={0}
          accordion
          ghost={false}
          fullWidth
        />
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <div style={{ flex: 1 }}>
            <Accordion items={exampleItems} defaultActiveKey={1} size="small" />
          </div>
          <div style={{ flex: 1 }}>
            <Accordion
              items={exampleItems}
              defaultActiveKey={1}
              ghost={false}
              accordion={true}
              expandIconPosition="start"
              size="small"
            />
          </div>
          <div style={{ flex: 1 }}>
            <Accordion
              items={exampleItems}
              defaultActiveKey={0}
              ghost={false}
              collapsible="disabled"
              size="small"
            />
          </div>
        </div>
        <Accordion
          items={exampleItems}
          defaultActiveKey={0}
          accordion
          ghost={false}
          fullWidth
          size="small"
        />
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Tab defaultActiveKey="1" items={items} mode="condensed" />
        </div>
        <div style={{ marginBottom: 16 }}>
          <AutoComplete
            label="Label"
            showInfoIcon={true}
            helpText="Optional helper text"
            onSearch={handleSearch}
            onSelect={handleSelect}
            onChange={handleChange}
            options={staticOptions}
            placeholder="Select an option"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            gridGap: "40px",
          }}
        >
          {/* Basic Slider */}
          <Slider
            label="Label"
            min={1}
            max={13}
            step={1}
            dots={true}
            defaultValue={3}
            tooltipFormatter={(value) => `${value}`}
            onChange={() => {}}
          />
          <Slider
            label="Label"
            min={1}
            max={13}
            step={1}
            dots={false}
            defaultValue={4}
            tooltipFormatter={(value) => `${value}`}
            onChange={() => {}}
          />
          {/* Range Slider */}
          <Slider
            label="Label"
            range={true}
            min={1}
            max={13}
            step={1}
            dots={true}
            defaultValue={[2, 10]}
            tooltipFormatter={(value) => `${value}`}
            onChange={() => {}}
          />
          <Slider
            label="Label"
            range={true}
            min={1}
            max={13}
            step={1}
            dots={false}
            defaultValue={[2, 10]}
            tooltipFormatter={(value) => `${value}`}
            onChange={() => {}}
          />
          <Slider
            label="Label"
            range={true}
            min={1}
            max={16}
            step={1}
            dots={true}
            defaultValue={[2, 10]}
            tooltipFormatter={(value) => `${value}`}
            onChange={() => {}}
          />
        </div>
        <div style={{ display: "flex", gap: 16, margin: "25px 0" }}>
          <Button text="Open Drawer" htmlType="button" onClick={showDrawer} />
          <Drawer
            width={710}
            open={open}
            maskClosable={true}
            keyboard={true}
            content={<>content</>}
            footer={<>footer</>}
            onClose={onClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            marginBottom: 16,
            flexWrap: "wrap",
          }}
        >
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 16:9
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="16:9" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 1:1
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="1:1" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 3:1
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="3:1" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 16:7
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="16:7" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 2:1
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="2:1" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 3:2
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="3:2" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 4:3
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="4:3" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 3:4
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="3:4" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 2:3
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="2:3" />
          </div>
          <div style={{ maxWidth: 240 }}>
            <p className="text--body-2" style={{ marginBottom: 8 }}>
              Image 9:16
            </p>
            <Image src={ImagePlaceholder} alt="image" aspectRatio="9:16" />
          </div>
        </div>
        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Button text="Open Modal" htmlType="button" onClick={onShowModal} />
          <Modal
            size="compact"
            title={<h3>Headline</h3>}
            subtitle={
              <p className="text--body-1">
                This is example text, use the option panel to add more content.
              </p>
            }
            footerSticky={true}
            open={showModal}
            onClose={onCloseModal}
            closable
            footer={
              <>
                <div className="description-text">
                  <p className="text--label">
                    Monthly instalment <sup>1</sup>{" "}
                  </p>
                  <p className="text--body-focus-price">494.244,00 Ft</p>
                </div>
                <div className="action-btns">
                  <Button
                    htmlType="button"
                    fullWidth
                    type="primary"
                    text="Label"
                    onClick={() => {}}
                  />
                  <Button
                    htmlType="button"
                    fullWidth
                    text="Label"
                    onClick={onCloseModal}
                  />
                </div>
              </>
            }
          >
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area</p>
            <p className="text--body-2">Content area last</p>
          </Modal>
        </div>

        <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
          <Button
            text="Open Payment Modal"
            htmlType="button"
            onClick={onShowPaymentModal}
          />
          <PaymentDialog
            isModalOpen={isPaymentModalOpen}
            setIsModalOpen={setIsPaymentModalOpen}
          />
        </div>

        <div style={{ display: "flex", gap: "30px", marginBottom: 16 }}>
          <OverflowMenu
            items={menuItems}
            position="bottomLeft"
            trigger={
              <Button
                linkButton
                withIcon
                htmlType="button"
                text="Christopher"
                dataIcon="person"
              />
            }
          />
          <OverflowMenu
            items={menuItems}
            position="topLeft"
            mode="condensed"
            trigger={
              <Button
                linkButton
                withIcon
                htmlType="button"
                text="Dropdown Menu"
                dataIcon="car_front"
              />
            }
          />
          <OverflowMenu
            items={menuItemsButton}
            menuButtonView={true}
            trigger={
              <Button
                linkButton
                withIcon
                htmlType="button"
                text="Dropdown Menu button"
                dataIcon="car_front"
              />
            }
          />
        </div>
        {/* Carousel */}
        <Row gutter={20}>
          <Col xs={24} sm={12} lg={6}>
            <Carousel
              arrows
              autoplay={false}
              autoplaySpeed={3000}
              dots
              infinite={false}
              images={carouselImages}
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Carousel
              arrows={false}
              autoplay
              autoplaySpeed={2000}
              dots
              infinite
              images={carouselImages}
            />
          </Col>
        </Row>
        {/* Message Alert */}

        <div style={{ margin: "20px 0" }}>
          <p>
            <strong>Notification Message Alert</strong>
          </p>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert title="Important information" titleIcon={false} />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert type="info" title="Important information" />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="error"
                  title="Important information"
                  icon={<img src={logoImage} alt="logo-img" />}
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  title="Important information"
                  showCloseButton={false}
                />
              </StyledMessageAlert>
            </Col>

            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="info"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  icon={<img src={logoImage} alt="logo-img" />}
                  closeOnClick={true}
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="error"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  closeOnClick={true}
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                      <Button
                        text="Dismiss"
                        linkButton
                        withIcon
                        htmlType="button"
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="warning"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Take action"}
                        onClick={() => {}}
                        size={"small"}
                      />
                      <Button
                        text="Dismiss"
                        linkButton
                        withIcon
                        htmlType="button"
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  subTitle="Your data has been saved successfully!"
                  titleIcon={false}
                  showCloseButton={false}
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Take action"}
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  buttonText="Take action"
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  fullWidth
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  title="Important information"
                  fullWidth
                />
              </StyledMessageAlert>
            </Col>
          </Row>
        </div>

        <div style={{ margin: "20px 0" }}>
          <p>
            <strong>Notification Message Alert Accordion</strong>
          </p>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="info"
                  viewType="accordion"
                  title="Important Info"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  icon={<img src={logoImage} alt="logo-img" />}
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="error"
                  viewType="accordion"
                  title="Important Info"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  isExpanded={true}
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="warning"
                  viewType="accordion"
                  title="Important Info"
                  subTitle="Your data has been saved successfully!"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                      <Button
                        text="Dismiss"
                        linkButton
                        withIcon
                        htmlType="button"
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  isExpanded={true}
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  viewType="accordion"
                  title="Important Info"
                  subTitle="Your data has been saved successfully!"
                  actionArea={
                    <>
                      <Button
                        htmlType="button"
                        text={"Learn More"}
                        onClick={() => {}}
                        size={"small"}
                      />
                      <Button
                        text="Dismiss"
                        linkButton
                        withIcon
                        htmlType="button"
                        onClick={() => {}}
                        size={"small"}
                      />
                    </>
                  }
                  isExpanded={true}
                />
              </StyledMessageAlert>
            </Col>

            <Col xs={24} sm={12} lg={12}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  viewType="accordion"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  contentTitle="Content area"
                  contentDetail="Swap with a local component that holds your content"
                  fullWidth
                />
              </StyledMessageAlert>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <StyledMessageAlert>
                <MessageAlert
                  type="success"
                  viewType="accordion"
                  title="Important information"
                  subTitle="Your data has been saved successfully!"
                  fullWidth
                />
              </StyledMessageAlert>
            </Col>
          </Row>
        </div>

        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            type="primary"
            htmlType={"button"}
            onClick={exitModal}
            text="Exit Checkout Modal"
          ></Button>

          <Button
            type="default"
            htmlType={"button"}
            onClick={timerModal}
            text="Reservation Timer Modal"
          ></Button>
        </div>
        <ExitCheckoutModal
          isModalOpen={isExitModalOpen}
          setIsModalOpen={setIsExitModalOpen}
        />

        <ReservationTimerModal
          isModalOpen={isTimerModalOpen}
          setIsModalOpen={setIsTimerModalOpen}
        />
        <Chat />

        <Modal
          size="compact"
          className="account-management-modal"
          dataIcon={""}
          title={
            <h3>
              The Essential Bundle already includes the product you selected.
            </h3>
          }
          subtitle={
            <p className="text--body-1">
              Comprehensive individual protection products including guaranteed
              outo protection are already part of the Essential bundle. If you
              continue, we'll remove the individual item from your deal to avoid
              a duplicative charge.
            </p>
          }
          open={false}
          onClose={() => {}}
          closable
          footer={
            <>
              <div className="action-btns">
                <Button
                  htmlType="button"
                  fullWidth
                  text="Cancel"
                  onClick={() => {}}
                />

                <Button
                  type="primary"
                  htmlType={"button"}
                  onClick={() => {}}
                  text="Label"
                ></Button>
              </div>
            </>
          }
        ></Modal>
      </div>
    </div>
  );
};
