import {
  Button,
  FeedbackLabel,
  Image,
  Popover,
  MultiSwatchCircles,
  Drawer,
} from "@src/components/atoms";
import Vehicle from "../../../shared/assets/img/dr-start/vehicle.png";
import "./right-rail.scss";
import { RightRailProps } from "./right-rail.props";
import SwatchOne from "../../../shared/assets/img/right-rail/swatch1.png";
import SwatchTwo from "../../../shared/assets/img/right-rail/swatch2.png";
import SwatchThree from "../../../shared/assets/img/right-rail/swatch3.png";
import SwatchFour from "../../../shared/assets/img/right-rail/swatch4.png";
import SwatchFive from "../../../shared/assets/img/right-rail/swatch5.png";
import SwatchSix from "../../../shared/assets/img/right-rail/swatch6.png";
import { FinanceLeasePricingDrawerContent } from "../incentives-and-discounts/fl-pricing-drawer-content/fl-pricing-drawer-content.component";
import { InsentivesAndDiscountsDrawerFooter } from "../incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { FullDetailsDrawerContent } from "../incentives-and-discounts/full-details-drawer-content/full-details-drawer-content";
import { UpdatePaymentTermsDrawerContent } from "../incentives-and-discounts/update-payment-terms-drawer-content/update-payment-terms-drawer-content";
import { useContext, useState } from "react";
import { authContext, useAuthContext } from "@src/application/authcontext";
import { FinanceType } from "@src/constants";
import { AddOnsDrawer } from "@src/components/organisms";
import { AddOnsDrawerFooter } from "../add-ons-drawer";

export const RightRail = ({
  hasFinance,
  financeSoftCredit,
  financeNoCart,
  hasLease,
  leaseSoftCredit,
  leaseNoCart,
  cashAuth,
  cashUnAuth,
  cashNoCart,
  onlineReservation,
  expiredReservation,
}: RightRailProps) => {
  const hasAnyOption =
    hasFinance ||
    financeSoftCredit ||
    financeNoCart ||
    hasLease ||
    leaseSoftCredit ||
    leaseNoCart ||
    cashAuth ||
    cashUnAuth ||
    cashNoCart;

  const swatchArray = [
    SwatchOne,
    SwatchTwo,
    SwatchThree,
    SwatchFour,
    SwatchFive,
    SwatchSix,
  ];

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const [isAddOnsDrawerOpen, setIsAddOnsDrawerOpen] = useState(false);

  const {
    contextState: { financetype },
  } = useAuthContext();

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const toggleAddons = () => {
    setIsAddOnsDrawerOpen(!isAddOnsDrawerOpen);
  };
  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  return (
    <div className="right-rail-container">
      {onlineReservation && (
        <div className="right-rail__header">
          <span
            className="bmw-icon bmw-icon--before data-icon right-rail-icon__clock"
            data-icon="clock"
          />
          <div className="text--body-2 right-rail-header__inner">
            Online reservation timer:{" "}
            <div className="right-rail-header__title">
              <span className="fw-normal">XXm XXs</span>
              <Popover
                trigger={["hover"]}
                disclaimerText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
              >
                <span
                  className="bmw-icon bmw-icon--before data-icon right-rail-icon__info"
                  data-icon="information"
                />
              </Popover>
            </div>
          </div>
        </div>
      )}
      {expiredReservation && (
        <div className="right-rail__header expired">
          <span
            className="bmw-icon bmw-icon--before data-icon right-rail-icon__clock"
            data-icon="clock"
          />
          <div className="text--body-2 right-rail-header__inner">
            Reservation expired.
            <div className="right-rail-header__title">
              <span className="fw-normal">Contact dealer</span>
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="arrow_chevron_right"
              />
            </div>
          </div>
        </div>
      )}

      {hasAnyOption ? (
        <>
          <div
            className={`right-rail__info ${
              onlineReservation || expiredReservation ? "rounded" : ""
            } `}
          >
            {/* Image */}
            <div className="right-rail__image">
              <div className="right-rail-image__content">
                <p className="text--body-2">2025 BMW iX xDrive50</p>
                <div className="right-rail-image__info">
                  <FeedbackLabel type="success" text="At dealership" />
                  <Popover
                    trigger={["hover"]}
                    disclaimerText="Vehicle is located at this BMW Center. Due to demand, availability is subject to change without notice. Please contact this BMW Center for additional, and most up to date, information about this vehicle."
                  >
                    <span
                      className="bmw-icon bmw-icon--before data-icon"
                      data-icon="information"
                    />
                  </Popover>
                </div>
              </div>
              <Image src={Vehicle} alt="image" aspectRatio="16:9" />
            </div>
            <div className="right-rail__divider"></div>
            {/* Add Ons */}
            <div className="right-rail__addons">
              <p className="text--body-2">Add-ons</p>
              <div className="right-rail-addons__swatch">
                {!financeNoCart && !leaseNoCart && !cashNoCart ? (
                  <>
                    <MultiSwatchCircles images={swatchArray} maxVisible={4} />
                  </>
                ) : (
                  <p className="text--body-2 fw-normal">0 Items</p>
                )}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="arrow_chevron_right"
                  onClick={toggleAddons}
                />
              </div>
            </div>
            <div className="right-rail__divider"></div>
            {/* Payment */}
            <div className="right-rail__payment">
              <div className="right-rail-payment__content">
                {hasFinance || financeSoftCredit || financeNoCart ? (
                  <>
                    <div className="right-rail-payment__info">
                      <p className="text--body-2">Est. payments</p>
                      <Popover
                        trigger={["hover"]}
                        disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                      >
                        <span
                          className="bmw-icon bmw-icon--before data-icon"
                          data-icon="information"
                        />
                      </Popover>
                    </div>
                    <h6 className="text--body-1 fw-bold">
                      $612.32 <span className="fw-light">/month</span>
                    </h6>
                    <p className="text--label text-muted">
                      Finance for 60 months, $15,000 down, 7.09% est. APR
                    </p>
                  </>
                ) : hasLease || leaseSoftCredit || leaseNoCart ? (
                  <>
                    <div className="right-rail-payment__info">
                      <p className="text--body-2">Est. payments</p>
                      <Popover
                        trigger={["hover"]}
                        disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                      >
                        <span
                          className="bmw-icon bmw-icon--before data-icon"
                          data-icon="information"
                        />
                      </Popover>
                    </div>
                    <h6 className="text--body-1 fw-bold">
                      $624.32 <span className="fw-light">/month</span>
                    </h6>
                    <p className="text--label text-muted">
                      Lease for 36 months, $5,000 down, 12,000mi
                    </p>
                  </>
                ) : cashAuth || cashUnAuth || cashNoCart ? (
                  <>
                    <div className="right-rail-payment__info">
                      <p className="text--body-2"> Cash offer</p>
                      <Popover
                        trigger={["hover"]}
                        disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount."
                      >
                        <span
                          className="bmw-icon bmw-icon--before data-icon"
                          data-icon="information"
                        />
                      </Popover>
                    </div>
                    <h6 className="text--body-1 fw-bold">$48,670.00</h6>
                  </>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <Button
                htmlType={"button"}
                text="Pricing details"
                size="small"
                fullWidth
                onClick={toggleDrawer}
              ></Button>
            </div>
          </div>
          {/* Footer */}
          {!financeSoftCredit &&
            !leaseSoftCredit &&
            !cashNoCart &&
            financetype !== FinanceType.CASH?.toLowerCase() && (
              <div className="right-rail__footer">
                <p className="text--body-2">
                  Do a{" "}
                  <a href="/credit" className="text-underline fw-normal">
                    soft credit check
                  </a>{" "}
                  for better estimates.
                </p>

                {/* <p className="text--body-2">
                    <a href="/sign" className="text-underline fw-normal">
                      Sign in
                    </a>{" "}
                    or{" "}
                    <a href="/sign" className="text-underline fw-normal">
                      register
                    </a>{" "}
                    to unlock your personalized pricing.
                  </p> */}

                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="search_offer"
                />
              </div>
            )}
          <div className="incentives-and-discount-drawer">
            <Drawer
              width={336}
              open={false}
              onClose={closeDrawer}
              maskClosable={true}
              keyboard={true}
              content={
                <>
                  <FinanceLeasePricingDrawerContent />
                </>
              }
              footer={
                <div className="fl-pricing-footer-wrap">
                  <InsentivesAndDiscountsDrawerFooter
                    price={
                      <>
                        <small className="text--disclaimer">
                          Est. payments
                        </small>
                        <h2 className="text--body-focus-price-1">
                          $X,XXX
                          <span className="text--body-1">/month</span>{" "}
                        </h2>
                      </>
                    }
                  />
                </div>
              }
            />
            <Drawer
              width={336}
              open={false}
              onClose={closeDrawer}
              maskClosable={true}
              keyboard={true}
              content={
                <>
                  <FullDetailsDrawerContent />
                </>
              }
              footer={
                <>
                  <InsentivesAndDiscountsDrawerFooter
                    price={
                      <>
                        <h2 className="text--body-focus-price-1">
                          $X,XXX{" "}
                          <span className="text--body-1">total credit</span>
                        </h2>
                      </>
                    }
                    footerActions={
                      <Button
                        htmlType={"button"}
                        text="Add to deal"
                        type="default"
                        onClick={toggleDrawer}
                      />
                    }
                  />
                </>
              }
            />
            <Drawer
              width={336}
              open={false}
              onClose={closeDrawer}
              maskClosable={true}
              keyboard={true}
              content={
                <>
                  <FullDetailsDrawerContent />
                </>
              }
              footer={
                <>
                  <InsentivesAndDiscountsDrawerFooter
                    price={
                      <>
                        <h2 className="text--body-focus-price-1">
                          $X,XXX{" "}
                          <span className="text--body-1">total credit</span>
                        </h2>
                      </>
                    }
                    footerActions={
                      <Button
                        htmlType={"button"}
                        text="Add to deal"
                        type="default"
                        onClick={toggleDrawer}
                      />
                    }
                  />
                </>
              }
            />
            <div className="update-payment-terms-drawer-main">
              <Drawer
                width={336}
                open={isDrawerVisible}
                onClose={closeDrawer}
                maskClosable={true}
                keyboard={true}
                content={
                  <>
                    <UpdatePaymentTermsDrawerContent />

                    {/* <CheckoutPricingDetailsDrawerContent /> */}
                  </>
                }
                footer={
                  <>
                    <InsentivesAndDiscountsDrawerFooter
                      price={
                        <>
                          <small className="text--disclaimer">
                            Est. payments
                          </small>
                          {/* <small className="text--disclaimer">Cash offer</small> */}
                          <h2 className="text--body-focus-price-1">
                            $X,XXX
                            <span className="text--body-1">/month</span>{" "}
                          </h2>
                        </>
                      }
                      footerActions={
                        <>
                          <Button
                            htmlType={"button"}
                            text="Save & close"
                            type="default"
                          />
                        </>
                      }
                    />
                  </>
                }
              />
            </div>
          </div>
          <div className="add-ons-drawer-main">
            <Drawer
              width={336}
              open={isAddOnsDrawerOpen}
              maskClosable={true}
              keyboard={true}
              onClose={toggleAddons}
              content={
                <>
                  <AddOnsDrawer />
                </>
              }
              footer={
                <>
                  <AddOnsDrawerFooter
                    price={
                      <>
                        <small className="text--disclaimer">
                          Est. payments
                        </small>
                        <h2 className="text--body-focus-price-1">
                          $X,XXX
                          <span className="text--body-1">/month</span>{" "}
                        </h2>
                      </>
                    }
                    footerActions={
                      <>
                        <Button
                          htmlType={"button"}
                          text="Back to your deal"
                          type="default"
                        />
                      </>
                    }
                  />
                </>
              }
            />
          </div>
        </>
      ) : (
        <div className="right-rail__not-avilable">
          <h1>N/A</h1>
        </div>
      )}
    </div>
  );
};
