import {
  WelcomeBackBanner,
  Deals,
  HeroSection,
} from "@src/components/organisms";
import { useDealContext } from "@src/context/deal-context/deal-context";
import { BaseLayout } from "@src/layouts";

export const DrStartPage = () => {
  const { deal, haveDeals, hasAlert } = useDealContext();

  return (
    <BaseLayout hasMenu={false} hasFooter={true}>
      <HeroSection
        buildInProgress={deal === "in-progress"}
        haveDeals={haveDeals}
        checkoutInProgress={deal === "checkout"}
        hasAlert={hasAlert}
      />
      {haveDeals && <Deals />}
    </BaseLayout>
  );
};
