import { Col, Row } from "antd";
import { Button } from "@src/components/atoms";
import { ContractList, ContractRightRail } from "@src/components/molecules";

export const EsignContracts = () => {
  return (
    <div className="bmw-container">
      <div className="contract-preview-container">
        <Row gutter={24} justify="space-between">
          <Col xs={24} lg={14}>
            <div className="contract-preview__content">
              <div>
                <div className="contract-preview-back__btn">
                  <Button
                    type="link"
                    linkButton
                    withIcon
                    dataIcon="arrow_left"
                    htmlType={"button"}
                    text="Back to [waiting hub]"
                  />
                </div>
                <h3>Contract review and signing.</h3>
                <p className="text--body-2">
                  The following items may be signed digitally, or at the dealer.
                </p>
              </div>
              <div className="contract-preview__file">
                <div className="contract-preview-file__list">
                  <ContractList
                    icon="document-uploaded"
                    title="Uploaded file name"
                    buttons={[
                      {
                        type: "link",
                        linkButton: true,
                        text: "Donwload",
                        onClick: () => ({}),
                      },
                    ]}
                  />
                  {[...Array(3)].map((_, index) => (
                    <ContractList
                      key={index}
                      icon="document_text_lines"
                      title="Uploaded file name"
                      buttons={[
                        {
                          text: (
                            <>
                              <span className="d-none d-md-inline">
                                Preview &
                              </span>{" "}
                              <span>sign</span>
                            </>
                          ),
                          onClick: () => ({}),
                        },
                      ]}
                    />
                  ))}
                </div>
                <div className="contract-preview__signature">
                  <p className="text--body-2">
                    The following item(s) will require a wet signature at
                    pickup.
                  </p>
                  <div className="contract-preview__list">
                    <ContractList
                      title="Uploaded file name"
                      buttons={[
                        {
                          text: "Preview",
                          onClick: () => ({}),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <ContractRightRail title="Your local BMW Center will also review and sign these documents, which may take up to one day." />
              </div>
              <Row>
                <Col xs={24} xxl={21}>
                  <div className="contract-preview__note">
                    <Button
                      type="primary"
                      htmlType={"button"}
                      text="Start signing"
                    />
                    <p className="text--body-2">
                      Please note that you must use the same device and browser
                      to continue this signing session.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="d-none d-lg-block">
              <ContractRightRail title="Your local BMW Center will also review and sign these documents, which may take up to one day." />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
