import { MessageAlert } from "@src/components/atoms";

export function AgreementsRehash() {
  return (
    <div>
      <MessageAlert
        type="info"
        title="Please note: Once you submit your credit application, you won’t be able to make any more changes to your deal. For any further changes, please contact your dealer. "
      />
    </div>
  );
}
