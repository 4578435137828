import { Button, FileUpload, Image } from "@src/components/atoms";
import "./uploading-supporting-docs.scss";
import { UploadDocuments } from "../upload-documents/upload-documents.component";

export const ProofOfResidence = () => {
  return (
    <div className="upload-docs-info">
      <h3>Proof of residence.</h3>
      <div className="divider"></div>
      <div className="upload-docs__inner">
        <div className="upload-docs__documents">
          <p className="text--body-2">
            Please upload one of the following documents:
          </p>
          <ul className="text--body-2">
            <li>Document type lorem</li>
            <li>Document type lorem</li>
            <li>Document type lorem</li>
          </ul>
        </div>
        <UploadDocuments />
      </div>
      <div className="upload-docs__cta">
        <Button type="primary" htmlType={"button"} text="Save" disabled />
      </div>
    </div>
  );
};
