import { Select as AntSelect } from "antd";
import clsx from "clsx";
import { SelectProps } from "./select.props";
import "../../../styles/components/_select.scss";
import { getMappedOptionValue } from "@src/helpers/appHelper";

export const Select = ({
  onChange,
  label,
  defaultValue,
  options,
  disabled,
  mode,
  placeholder,
  id,
  value,
  fieldNames,
  maxTagCount = "responsive",
  status,
  open,
  hasBg,
  hasLightBg,
  showInfoIcon,
  error,
  helpText,
  className,
}: SelectProps) => {
  const mappedValue = getMappedOptionValue(options, value);

  return (
    <div
      className={clsx({
        "select-wrap": true,
        "has-bg-color": hasBg,
        "has-light-bg": hasLightBg,
        "select--is-disabled": disabled,
      })}
    >
      {label && (
        <label
          className={clsx({
            "select-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="select-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <AntSelect
        className={clsx(className, {
          "input--input-error": !!error?.message,
        })}
        fieldNames={fieldNames}
        value={mappedValue}
        id={id}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        disabled={disabled}
        mode={mode}
        maxTagCount={maxTagCount}
        placeholder={placeholder}
        dropdownStyle={{
          boxShadow: "none",
          padding: 0,
          border: "none",
        }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        suffixIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M3.75 8.3839V6.61609L10 12.8661L16.25 6.61609V8.38384L10 14.6338L3.75 8.3839Z"
              fill="#262626"
            />
          </svg>
        }
        status={error?.message ? "error" : undefined}
        open={open}
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {error && error.message && (
        <label className="error-text text-error text--disclaimer">
          {error.message}
        </label>
      )}
    </div>
  );
};
