import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  getFNIBundles,
  getFNIProducts,
  getRates,
  getMonthlyImpactOnFNIProducts,
} from "../services/fni-products";

export const useGetFNIBundles = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >({
    mutationFn: getFNIBundles,
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetRates = (): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    { ratesPayload }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { ratesPayload }
  >({
    mutationFn: getRates,
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetFNIProducts = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { dealerCode: string; companyId: string }
  >({
    mutationFn: getFNIProducts,
  });
  return {
    mutate,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetMonthlyImpactOnFNIProducts = (): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    { coveragePayload }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    { coveragePayload }
  >({
    mutationFn: getMonthlyImpactOnFNIProducts,
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
