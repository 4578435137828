import { ReactNode, useRef } from "react";
import clsx from "clsx";
import { Popover as AntPopover } from "antd";
import "../../../styles/components/_popover.scss";

export const Popover = ({
  content,
  title,
  defaultOpen,
  placement,
  trigger,
  open,
  children,
  onOpenChange,
  closeIcon,
  disclaimerText,
  actionArea,
  onClose,
}: {
  content?: ReactNode | (() => ReactNode);
  title?: ReactNode | (() => ReactNode);
  defaultOpen?: boolean;
  placement?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  trigger?:
    | "hover"
    | "focus"
    | "click"
    | "contextMenu"
    | Array<"hover" | "focus" | "click" | "contextMenu">;
  open?: boolean;
  children: ReactNode;
  onOpenChange?: (open: boolean) => void;
  closeIcon?: string;
  disclaimerText?: ReactNode | string;
  actionArea?: ReactNode;
  onClose?: () => void;
}) => {
  const popOverWrapRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={clsx({
        "popover-wrapper": true,
        "click-popover-wrapper": onClose,
      })}
      ref={popOverWrapRef}
    >
      <AntPopover
        content={
          <div>
            {onClose && (
              <span
                className="bmw-icon bmw-icon--before data-icon close-icon"
                data-icon={closeIcon}
                role="button"
                tabIndex={0}
                onClick={onClose}
              />
            )}
            <div>
              <p className={clsx("text--disclaimer", title ? "mt-8" : "mt-0")}>
                {disclaimerText}
              </p>
            </div>
            <div className="action-area">{actionArea}</div>
          </div>
        }
        title={title}
        trigger={trigger}
        open={open}
        placement={placement}
        defaultOpen={defaultOpen}
        getPopupContainer={(triggerNode) => triggerNode.parentElement!}
        onOpenChange={onOpenChange}
      >
        <span>{children}</span>
      </AntPopover>
    </div>
  );
};
