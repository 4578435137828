import { http } from "../utilities";
import {
  CONFIG_BASE_URL,
  DMS_BASE_URL,
  EP_DMS_GET_STATES,
  DEALERS,
  CONFIG_DEALER_PROFILE,
  GET_SETUP_DATA,
  GET_DISCLAIMER_BY_NAME,
  THEME,
  DISCLAIMER_AGREEMENT,
  DEALER_PREFERENCE_BY_DEALER_CODE,
  GET_DEALER_FEES,
} from "../constants/routes";
import { AxiosResponse } from "axios";

export const getDealerById = async ({ dealerId, company_id }) => {
  try {
    const res = await http(CONFIG_BASE_URL).get<{
      dealer_code: string;
      contact_person_email: string;
    }>(`${DEALERS}/${dealerId}`, {
      headers: { Company_id: company_id.toString() },
    });

    const dealerProfileResponse: AxiosResponse<any> = await http(
      CONFIG_BASE_URL
    ).get(`${CONFIG_DEALER_PROFILE}/${res.data.dealer_code}`);
    const dealerResponse = {
      ...res.data,
      dealer_email: res.data.contact_person_email,
    };

    return { ...dealerResponse, ...dealerProfileResponse.data };
  } catch (error) {
    throw error;
  }
};

export const getDealerProfile = async ({ dealerCode }) => {
  try {
    const response = await http(CONFIG_BASE_URL).get(
      `${CONFIG_DEALER_PROFILE}/${dealerCode}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDealerPreference = async ({ dealerCode, companyId }) => {
  try {
    const response = await http(CONFIG_BASE_URL).get(
      `${DEALER_PREFERENCE_BY_DEALER_CODE}/${dealerCode}`,
      {
        headers: { company_id: companyId.toString() },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTheme = async ({ email }) => {
  try {
    const response = await http(CONFIG_BASE_URL).get(
      `${THEME}?user_email=${email}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStates = async () => {
  try {
    const response = await http(CONFIG_BASE_URL).get(EP_DMS_GET_STATES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSetupData = async () => {
  try {
    const response = await http(DMS_BASE_URL).get(
      `${GET_SETUP_DATA}?setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addCustomerConsent = async (body) => {
  try {
    const response = await http(DMS_BASE_URL).post(DISCLAIMER_AGREEMENT, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDisclaimerByName = async ({ disclaimerName }) => {
  try {
    const response = await http(DMS_BASE_URL).get(
      `${GET_DISCLAIMER_BY_NAME}/${disclaimerName}`
    );
    return await response.data;
  } catch (error) {
    return error;
  }
};

export const getDealerFees = async ({ dealerCode, stateCode }) => {
  try {
    const orderFees = await http(CONFIG_BASE_URL)
      .get(`${GET_DEALER_FEES}/${dealerCode}?state_code=${stateCode}`)
      .then((res) => res.data);

    const mappedFees: any = [];
    orderFees.forEach((fee: any) => {
      if (fee.is_active)
        mappedFees.push({
          dealer_fee_id: fee.id,
          applicable_finance_type: fee.applicable_finance_type,
          dealer_id: fee.dealer_id,
          fee_name: fee.fee_name || fee?.dealer_fee_name,
          default_amount: fee.default_amount || 0,
          fee_handling: fee.fee_handling || "Other",
          taxable: fee.taxable,
          event: fee.event,
          calculation_type: fee.calculation_type,
          multiplication_factor: fee.multiplication_factor,
          applied_price: fee.default_amount || 0,
        });
    });
    return mappedFees;
  } catch (error) {
    return error;
  }
};
