import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { getVehicleByVin, getVehicleDetails } from "@src/services/vehicle";
interface VehicleResponse {
  detail: any; // Replace 'any' with the actual type you expect for 'detail'
  // other properties from the API response
}
export const useGetVehicleDetail = (): {
  isPending: boolean;
  error: string | undefined;
  mutateAsync: UseMutateFunction<
    any | { detail?: { message: string } },
    { detail: { message: string } },
    any
  >;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    any | { detail?: { message: string } },
    { detail: { message: string } },
    any
  >({
    mutationFn: async ({ vin, company_id, dealerCode }: any) => {
      let vehicleDetailResponse = await getVehicleDetails(
        dealerCode,
        company_id,
        vin
      );
      if (vehicleDetailResponse && vehicleDetailResponse.detail) {
        return {
          vehicleDetail: vehicleDetailResponse,
          dealerPref: {},
          preInstalledAddOns: [],
        };
      }
      let vehicleByVinResponse = await getVehicleByVin(
        vehicleDetailResponse.dealer_code,
        company_id,
        vin
      );
      if (vehicleByVinResponse.detail) {
        return {
          vehicleDetail: {
            ...vehicleDetailResponse,
            ...vehicleByVinResponse,
          },
          dealerPref: {},
          preInstalledAddOns: [],
        };
      }
      vehicleDetailResponse = {
        ...vehicleDetailResponse,
        status: vehicleByVinResponse.vehicle_status,
        listing_status: vehicleByVinResponse.listing_status,
        dealer_options: vehicleByVinResponse.dealer_options,
      };

      return {
        vehicleDetail: vehicleDetailResponse,
        preInstalledAddOns: vehicleByVinResponse.dealer_options.map(
          (option: any) => ({
            product_name: option.product_name,
            price: option.price,
            applied_price: option.price,
            rv_balloon_value: option?.is_price_inclusive
              ? 0
              : option.rv_balloon_value ?? option.rv_adder,
            option_id: option.id,
            rv_balloon_percentage:
              option.rv_balloon_percentage || option.rv_adder || 0,
            installation_mode: option.installation_mode,
            is_price_inclusive: option.is_price_inclusive,
            description: option.description,
            brochure_url: option.brochure_url,
            video_url: option.video_url,
            supplier: option.supplier,
            image: option.image,
          })
        ),
      };
    },
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
