import "./provide-phone.scss";
import { Button } from "@src/components/atoms";
import { getLinkClickPayload } from "@src/helpers";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";
import { useVerifyRadioContext } from "@src/components/organisms/credit-application/checkout/04-verify-identity/verify-identity/verify-identity";

export const ProvidePhone = () => {
  const { dispatch } = useVerifyRadioContext();
  const { addEvent } = useAdobeDatalayerContext();

  return (
    <div className="phone-container">
      <p className="phone-container__header text--body-2">
        Please provide your phone number so that we can text you a link.
      </p>
      <div className="phone-container__wrapper">
        <div style={{ width: 320 }}>
          <Phone country="us" name="phone" label="Phone number" />
        </div>
        <p className="disclaimer text--body-2 text-secondary">
          By entering my phone and continuing, I agree that the BMW Center, its
          affiliates, and service providers may make autodialed calls or send
          automated or auto dialed text messages relating to my application and
          vehicle purchase, and related issues. By providing us with your phone
          number, you provide us with your prior written express consent to
          receive such calls or text messages. You represent that the phone
          number is yours. Message and data rates may apply. You agree to the
          BMW Center Terms and Privacy Policy.
        </p>

        <Button
          type="default"
          htmlType={"button"}
          text="Agree &amp; text link to my phone"
          onClick={() => {
            addEvent(
              "linkClick",
              getLinkClickPayload("agree and send link to my phone", "text")
            );
            dispatch({ type: "TEXT_MESSAGE_SENT" });
          }}
        />
      </div>
    </div>
  );
};
