import {
  DMS_BASE_URL,
  CALCULATE_ORDER_QUOTATION,
  CALCULATE_NFA,
  GET_ALL_PROGRAMS,
  CONFIG_BASE_URL,
} from "../constants/routes";
import { http } from "../utilities";

export const getAllPrograms = async (body: any) => {
  return await http(CONFIG_BASE_URL)
    .post(GET_ALL_PROGRAMS, body)
    .then((res) => res.data);
};

export const calculateNFA = async (body: any) => {
  return await http(DMS_BASE_URL)
    .post(`${CALCULATE_NFA}`, body)
    .then((res) => res.data);
};

export const calculateOrderQuotation = async (body: any) => {
  return await http(DMS_BASE_URL)
    .post(`${CALCULATE_ORDER_QUOTATION}`, body)
    .then((res) => res.data);
};
