import { useState } from "react";
import { ChatAutomatedMessage } from "../chat-automated-message/chat-automated-message.component";
import Logo from "@src/shared/assets/img/logo.jpg";
import "./chat-agent-joins.scss";
import { Button, Input } from "@src/components/atoms";
import { ChatSeparator } from "../chat-separator/chat-separator.component";
import { ChatUserSent } from "../chat-user-sent/chat-user-sent.component";

export const ChatAgentJoins = () => {
  const [text, setText] = useState("");

  return (
    <div className="chat-agent-joins-wrap chat-online-conscent-wrap">
      <div className="chat-body-wrap--overflow scroll">
        <ChatAutomatedMessage
          logo={<img className="chat-header--logo" src={Logo} alt="BMW Logo" />}
          source="Automated message"
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Thank you. Please hold as we connect you to the next available
                agent.
              </p>
              <p className="text--body-2">
                If there are no agents available in the next X min, you’ll be
                notified by email once an agent responds to your inquiry.
              </p>
            </>
          }
        />
        <ChatSeparator
          icon="person_star"
          text={
            <p className="text-center text--disclaimer">
              BMW of Manhattan joined the conversation
            </p>
          }
        />
        <ChatAutomatedMessage
          logo="personal_data_usage"
          source="BMW of Manhattan"
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Hi Christopher! This is Patrik from BMW Genius. How may I help
                you today?
              </p>
            </>
          }
        />
        <ChatUserSent
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Hi Patrik, I’m stuck on [section of DR]. Could you help?
              </p>
            </>
          }
        />
      </div>
      <div className="chat-input--wrap">
        <Input
          id={""}
          name={""}
          placeholder="Message BMW..."
          autoSize={true && { minRows: 1, maxRows: 4 }}
          type="textarea"
          showCount
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        {text && (
          <div className="chat-send--wrap">
            <Button
              htmlType={"button"}
              type="default"
              onlyIcon
              dataIcon="arrow_up"
              size="small"
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};
