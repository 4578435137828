import { Button } from "@src/components/atoms";
import "./deposit-completed.scss";
import { useFinanceOfferContext } from "@src/context/finance-offer/finance-offer-context";
import { useNavigate } from "react-router-dom";
import { ROUTING_PATH } from "@src/application/application-router";

export const DepositCompleted = ({
  handleClick,
}: {
  hasBankDetails?: boolean;
  handleClick: () => void;
}) => {
  const navigate = useNavigate();
  const { state } = useFinanceOfferContext();

  return (
    <div className="deposit-completed">
      <div className="deposit-completed-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            04
          </h3>
          <h3 className="checkout-title text--subsection-2">Deposit.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => {
            handleClick();
          }}
          size="small"
        />
      </div>
      <div className="deposit-completed-info">
        <div className="deposit-completed-info-container">
          <div className="deposit-completed-info-title">
            <p className="deposit-completed-info-title-text text--body-2">
              Deposit paid
            </p>
            <p className="deposit-completed-info-title-price text--body-2">
              $495
            </p>
          </div>
          <p className="deposit-completed-info-subtitle text--body-2">
            The remaining cash down balance, less deposit, must be paid when you
            pick up your vehicle.
          </p>
          <hr className="deposit-completed-info-divider" />
          <>
            {state.BankDetails && (
              <div className="deposit-info-row-grid">
                <div className="deposit-info-row institution-name">
                  <p className="deposit-completed-title text--disclaimer">
                    Institution name
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    Capital One 360
                  </p>
                </div>
                <div className="deposit-info-row branch-name">
                  <p className="deposit-completed-title text--disclaimer">
                    Branch name
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    Anytown USA
                  </p>
                </div>
                <div className="deposit-info-row account-number">
                  <p className="deposit-completed-title text--disclaimer">
                    Account number
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    *** ** ****
                  </p>
                </div>
                <div className="deposit-info-row institution-code">
                  <p className="deposit-completed-title text--disclaimer">
                    Financial institution code
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">004</p>
                </div>
                <div className="deposit-info-row swift-number">
                  <p className="deposit-completed-title text--disclaimer">
                    Swift number
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    NFBKUS
                  </p>
                </div>
                <div className="deposit-info-row routing-number">
                  <p className="deposit-completed-title text--disclaimer">
                    Routing number
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    ** ** ****
                  </p>
                </div>
              </div>
            )}
            {state.CreditCardDetails && (
              <div className="deposit-info-row-grid--credit">
                <div className="deposit-info-row cardholder-name">
                  <p className="deposit-completed-title text--disclaimer">
                    Cardholder name
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    Chris Shumacher
                  </p>
                </div>
                <div className="deposit-info-row card-number">
                  <p className="deposit-completed-title text--disclaimer">
                    Card number
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    **** **** **** 1234
                  </p>
                </div>
                <div className="deposit-info-row expiration-date">
                  <p className="deposit-completed-title text--disclaimer">
                    Expiration date (month/year)
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    11/2025
                  </p>
                </div>
                <div className="deposit-info-row  security-code">
                  <p className="deposit-completed-title text--disclaimer">
                    Security code/CVV
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">***</p>
                </div>
                <div className="deposit-info-row  zip-code">
                  <p className="deposit-completed-title text--disclaimer">
                    ZIP Code
                  </p>
                  <p className="deposit-completed-subtitle text--body-2">
                    10454
                  </p>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="deposit-completed-cta-wrapper">
        <Button
          htmlType={"button"}
          text="Pay &amp; submit"
          type="primary"
          onClick={() => navigate(`${ROUTING_PATH.DEAL_CONFIRMATION_PAGE}`)}
        />
      </div>
    </div>
  );
};
