import { Coverage, FinancialProduct, Rate } from "@src/types";
import { isFinite } from "lodash";
// Function to get selling price
// export const getSellingPrice = (dealerFnIProduct: any, deductible: any) => {
// 	const dealerCost = +deductible.dealer_cost
// 	const minimumRetailPrice = +deductible.min_retail_price
// 	const maximumRetailPrice = +deductible.max_retail_price
// 	const retailPrice = +deductible.retail_price

// 	// Min = max = retail => we cannot change anything
// 	if (minimumRetailPrice === maximumRetailPrice && maximumRetailPrice === dealerCost) {
// 		return dealerCost
// 	}

// 	// If there's no dealer product, return the dealer cost
// 	if (!dealerFnIProduct) {
// 		return dealerCost
// 	}

// 	const amountPercentage = +(retailPrice * (dealerFnIProduct.markup / 100)).toFixed(2)

// 	const newRetailPrice = amountPercentage + retailPrice
// 	// TODO:: SYNCING WITH LANE
// 	// If calculated retail price is greater than original retail price and we can't exceed retail, return the original retail price
// 	// if (retailPrice <= newRetailPrice && !dealerFnIProduct.isExceedRetail) {
// 	//   return retailPrice;
// 	// }

// 	// // Min and max are negative, we can update without any check
// 	// if (minimumRetailPrice < 0 && maximumRetailPrice < 0) {
// 	//   return newRetailPrice;
// 	// }

// 	// // Min and max are positive
// 	// if (newRetailPrice < minimumRetailPrice) {
// 	//   return minimumRetailPrice;
// 	// }

// 	// if (newRetailPrice > maximumRetailPrice) {
// 	//   return maximumRetailPrice;
// 	// }

// 	return newRetailPrice
// }

// const processDeductibles = (coverages: any[], singleFni: any, coverage: any, deductible: any) => {
// 	if (Array.isArray(deductible)) {
// 		// deductible.forEach((deductibleItem: any) => { //TODO:: COMMENTING FOR NOW WILL BE UPDATED ONCE CONFIRMED OF PRODUCT OWNER - 07-03-2025
// 		coverages.push({
// 			fni_product_name: `${coverage?.coverage_name} - ${coverage?.term_months} Months`,
// 			coverage_name: coverage?.coverage_name,
// 			term_months: coverage?.term_months,
// 			fni_product_amount: getSellingPrice(singleFni, deductible[0]),
// 			provider_name: singleFni.provider_name,
// 			term_miles: coverage?.term_miles,
// 			product_type: singleFni.product_type,
// 			product_name: singleFni.product_name,
// 			taxable_deal_types: singleFni.taxable_deal_types,
// 			applicable_finance_type: singleFni.applicable_finance_type,
// 			image: singleFni.image.map((item) => ({
// 				location: item.location,
// 			})),
// 			video_url: singleFni.video_url,
// 			brochure_url: singleFni.brochure_url,
// 			description: singleFni.description,
// 			category: singleFni.category,
// 			is_taxable: singleFni?.taxable,
// 			dealer_cost: deductible[0]?.retail_price ?? 0,
// 		})
// 		// });
// 	} else {
// 		coverages.push({
// 			fni_product_name: `${coverage?.coverage_name} - ${coverage?.term_months} Months`,
// 			coverage_name: coverage?.coverage_name,
// 			term_months: coverage?.term_months,
// 			fni_product_amount: getSellingPrice(singleFni, deductible),
// 			provider_name: singleFni.provider_name,
// 			term_miles: coverage?.term_miles,
// 			product_type: singleFni.product_type,
// 			product_name: singleFni.product_name,
// 			taxable_deal_types: singleFni.taxable_deal_types,
// 			applicable_finance_type: singleFni.applicable_finance_type,
// 			image: singleFni.image.map((item) => ({
// 				location: item.location,
// 			})),
// 			video_url: singleFni.video_url,
// 			brochure_url: singleFni.brochure_url,
// 			description: singleFni.description,
// 			category: singleFni.category,
// 			is_taxable: singleFni?.taxable || false,
// 			dealer_cost: deductible?.retail_price ?? 0,
// 		})
// 	}
// }

// // Function to process a single coverage (handles both array and object cases)
// const processSingleCoverage = (coverages: any[], singleFni: any, coverage: any) => {
// 	const deductibles = coverage?.deductibles?.deductible

// 	if (deductibles) {
// 		// TODO:: NEED to modify this logic as well
// 		processDeductibles(coverages, singleFni, coverage, deductibles)
// 	}
// }

// Main function where the rates and coverages are being processed
export const processRates = (
  rate: Rate[],
  fnIProductsData: FinancialProduct[]
) => {
  const coverages: any[] = [];
  if (fnIProductsData?.length) {
    fnIProductsData.forEach((item) => {
      const rates = rate?.filter(
        (fni: { product_id: any }) => fni.product_id === item.product_id
      );

      const coverageWithSmallestRate = smallestRetailPriceCoverage(rates);

      const amountPercentage = +(
        coverageWithSmallestRate.retail_price *
        (item.markup / 100)
      ).toFixed(2);

      const newRetailPrice =
        amountPercentage + coverageWithSmallestRate.retail_price;

      coverages.push({
        ...item,
        fni_product_amount: isFinite(newRetailPrice) ? newRetailPrice : 0,
        fni_product_name: item.product_name,
        ...(coverageWithSmallestRate.coverage && {
          ...coverageWithSmallestRate.coverage,
        }),
        is_taxable: item.taxable,
      });
    });
  }

  return coverages;
};

// Function to find the smallest retail price and return its corresponding coverage object
const smallestRetailPriceCoverage = (rates) =>
  rates
    .filter((product) => product.coverages !== null) // Remove any product with null coverages
    .flatMap((product) => product.coverages.coverage) // Flatten coverage array
    .flatMap((coverage) => {
      const deductibles = coverage.deductibles.deductible;

      // Check if deductible is null, an array, or an object
      if (deductibles === null) {
        return []; // Skip if it's null
      }

      // If deductible is an array, map over it
      if (Array.isArray(deductibles)) {
        return deductibles
          .map((d) => ({ coverage, retail_price: d.retail_price }))
          .filter((d) => d.retail_price !== undefined);
      }

      // If it's a single object, return its retail_price
      return deductibles.retail_price
        ? [{ coverage, retail_price: deductibles.retail_price }]
        : [];
    })
    .reduce(
      (minObj, current) => {
        if (current.retail_price < minObj.retail_price) {
          return current;
        }
        return minObj;
      },
      { retail_price: Infinity, coverage: null }
    ); // Initialize with a very high value

// // Function to find the smallest retail price handling various deductible structures
// const smallestRetailPrice =(rates)=> rates
//   .filter(product => product.coverages !== null)  // Remove any product with null coverages
//   .flatMap(product => product.coverages.coverage)  // Flatten coverage array
//   .flatMap(coverage => {
//     const deductibles = coverage.deductibles.deductible;

//     // Check if deductible is null, an array, or an object
//     if (deductibles === null) {
//       return [];  // Skip if it's null
//     }

//     // If deductible is an array, map over it
//     if (Array.isArray(deductibles)) {
//       return deductibles.map(d => d.retail_price).filter(price => price !== undefined);
//     }

//     // If it's a single object, directly return its retail_price
//     return deductibles.retail_price ? [deductibles.retail_price] : [];
//   })
//   .reduce((min, price) => (price < min ? price : min), Infinity);  // Find the minimum retail price

// Function to process all coverages (handles both single and multiple coverage cases)
// const processCoverages = (coverages: Coverage[], singleFni: FinancialProduct, coveragesData: any) => {
// 	if (Array.isArray(coveragesData)) {
// 		coveragesData.forEach((coverage: any) => {
// 			processSingleCoverage(coverages, singleFni, coverage)
// 		})
// 	} else {
// 		processSingleCoverage(coverages, singleFni, coveragesData)
// 	}
// }
