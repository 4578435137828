import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AppError } from "single-spa";
import {
  addInsuranceDetails,
  approveOrderByLender,
  deleteCustomerFromDeal,
  getAddOns,
  getCompanyByTenantDomain,
  getDefaultLender,
  getFraudCheck,
  getInsuranceProvider,
  getLender,
  getOrderInfo,
  getRebates,
  getVehicle,
  monthlyImpact,
  saveDeal,
  submitOrder,
  updateDeal,
  updateInsurance,
} from "@src/services/order";

export const useGetCompanyDomain = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { origin_domain: string }>;
  error: string | undefined;
} => {
  const { data, mutate, error } = useMutation<
    any,
    AppError,
    { origin_domain: string }
  >({
    mutationFn: async ({ origin_domain }) => {
      const response = await getCompanyByTenantDomain(origin_domain); // method name
      return response.data;
    },
  });
  return { data, mutate, error: error?.message };
};

export const useUpdateOrderStatusByLender = (): {
  data: any | undefined;
  mutate: UseMutateFunction<
    any,
    AppError,
    { referenceNumber; lenderId; orderStatus }
  >;
  error: string | undefined;
} => {
  const { data, mutate, error } = useMutation<
    any,
    AppError,
    { referenceNumber; lenderId; orderStatus }
  >({
    mutationFn: async ({ referenceNumber, lenderId, orderStatus }) => {
      return await approveOrderByLender(referenceNumber, lenderId, orderStatus);
    },
  });
  return { data, mutate, error: error?.message };
};
export const useGetDefaultLender = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await getDefaultLender(company_id);
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useOrderSubmit = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await submitOrder(body);
    },
  });
  return { data, isPending, mutate };
};
export const useSaveDeal = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await saveDeal(body);
    },
  });
  return { data, isPending, mutate };
};

export const useGetAddons = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await getAddOns(body);
    },
  });
  return { data, isPending, mutate };
};

export const useUpdateDeal = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await updateDeal(body);
    },
  });
  return { data, isPending, mutate };
};
export const useGetVehicle = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await getVehicle(body);
    },
  });
  return { data, isPending, mutate };
};
export const useDeleteCustomerFromDeal = (): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      orderId: number;
      customerId: number;
    }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      orderId: number;
      customerId: number;
    }
  >({
    mutationFn: async (body) => {
      return await deleteCustomerFromDeal(body);
    },
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
export const useGetFraudCheck = (): any => {
  const { data, isPending, mutate } = useMutation<any, any>({
    mutationFn: async (body: any) => {
      return await getFraudCheck(body);
    },
  });
  return { data, isPending, mutate };
};

export const useGetInsuranceProvider = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, { company_id: any }>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<
    any,
    AppError,
    { company_id: any }
  >({
    mutationFn: async ({ company_id }) => {
      const response = await getInsuranceProvider(company_id);
      return response.data;
    },
    onError: (error: AppError) => {
      console.error("Error fetching default lender:", error);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
export const useGetOrderInfo = (): {
  data: any | undefined;
  mutate: UseMutateFunction<any, AppError, any>;
  error: string | undefined;
  isSuccess: boolean;
} => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError, any>({
    mutationFn: async (dealRefNumber) => {
      return await getOrderInfo(dealRefNumber);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return addInsuranceDetails(body);
    },
  });
  return { data, isPending, mutate };
};

export const useUpdateInsurance = () => {
  const { data, isPending, mutate } = useMutation<any, any, any>({
    mutationFn: async (body: any) => {
      return updateInsurance(body);
    },
  });
  return { data, isPending, mutate };
};

export const useGetLenders = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await getLender(body);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};

export const useGetRebates = (): {
  isSuccess: boolean;
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    {
      companyId: number;
      applicableStates?: number;
      dealType?: string;
      financeType?: string;
      dealCode?: string;
      moduleName?: string;
    }
  >;
  error: string | undefined;
} => {
  const { isSuccess, mutate, error } = useMutation<
    any,
    { detail: { message: string } },
    {
      companyId: number;
      applicableStates?: number;
      dealType?: string;
      financeType?: string;
      dealCode?: string;
      moduleName?: string;
    }
  >({
    mutationFn: async (body) => {
      const queryParams = new URLSearchParams({
        page_number: "0",
        page_size: "100",
        ...(body.applicableStates && {
          applicable_states: String(body.applicableStates),
        }),
        ...(body.dealType && { deal_type: body.dealType }),
        ...(body.dealCode && { dealer_code: body.dealCode }),
        ...(body.financeType && { finance_type: body.financeType }),
        ...(body.moduleName && { module: body.moduleName }),
      }).toString();
      return await getRebates(body, queryParams);
    },
  });

  return {
    isSuccess,
    mutate,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetMonthlyImpact = (): any => {
  const { data, isSuccess, mutate, error } = useMutation<any, AppError>({
    mutationFn: async (body: any) => {
      return await monthlyImpact(body);
    },
  });
  return { data, isSuccess, mutate, error: error?.message };
};
