import { PickUpBanner } from "@src/components/molecules";
import {
  BmwExplore,
  HeroScheduled,
  MyBmwApp,
  ShopNow,
  StateCard,
} from "@src/components/organisms";
import { useWaitingHubContext } from "@src/context/waiting-hub-context/waitinhub-context";
import { BaseLayout } from "@src/layouts";

export const WaitingHubPage = () => {
  const {
    waitingHubState: { isPickUpScheduled },
  } = useWaitingHubContext();
  return (
    <BaseLayout hasMenu={true} hasFooter={true}>
      <HeroScheduled
        showPickUpBanner={isPickUpScheduled}
        region="southern-region"
      />
      {isPickUpScheduled && <PickUpBanner />}
      <StateCard />
      <MyBmwApp />
      <ShopNow />
      <BmwExplore />
    </BaseLayout>
  );
};
