import { Button, Image } from "@src/components/atoms";
import "./hero-scheduled.scss";
import heroVehicle from "../../../../shared/assets/img/waiting-hub/hero-vehicle.png";
import { HeroScheduledContent } from "@src/components/molecules";
import clsx from "clsx";
import { useGeneralContext } from "@src/context/general-context/general-context";

interface HeroScheduledProps {
  showPickUpBanner?: boolean;
  region?:
    | "western-region"
    | "central-region"
    | "southern-region"
    | "northeast-region";
}

export const HeroScheduled = ({
  showPickUpBanner,
  region,
}: HeroScheduledProps) => {
  const {
    contextState: { waitingHubType },
  } = useGeneralContext();
  return (
    <div
      className={clsx({
        "waiting-hub-hero-scheduled": true,
        "with-pickup-banner": showPickUpBanner,
        "western-region": region === "western-region",
        "central-region": region === "central-region",
        "southern-region": region === "southern-region",
        "northeast-region": region === "northeast-region",
      })}
    >
      <div className="bmw-container">
        <div className="waiting-hub-hero__inner">
          <HeroScheduledContent type={waitingHubType} />
          <div className="waiting-hub-hero__img">
            <Image src={heroVehicle} alt="image" aspectRatio="16:9" />
          </div>
        </div>
        <div className="waiting-hub-hero__bottom">
          <p className="text--label text-white">What to do before pickup</p>
          <Button
            htmlType={"button"}
            linkButton
            withIcon
            dataIcon="arrow_down"
            onDark
          />
        </div>
      </div>
    </div>
  );
};
