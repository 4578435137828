import { IncomeInfo } from "@src/types/customer";
import { camelCase, isEmpty } from "lodash";
import dayjs from "dayjs";
import { regexVIN } from "@src/constants";

export const cleanIncomeInfo = (income_info: IncomeInfo) => {
  const {
    employment_type,
    name,
    phone,
    employed_since,
    occupation,
    annual_employment_income,
    annual_other_income,
    source_of_other_income,
  } = income_info;

  return {
    employment_type,
    name,
    phone,
    employed_since,
    occupation,
    annual_employment_income,
    annual_other_income,
    source_of_other_income,
  };
};
const addressByType = (customer_addresses) =>
  customer_addresses.reduce((acc, address) => {
    const {
      address_type,
      created_at,
      created_by,
      updated_at,
      updated_by,
      deleted_at,
      deleted_by,
      entity_type,
      ...rest
    } = address;
    if (address_type) {
      acc[camelCase(address_type)] = {
        ...rest,
        entity_type: entity_type ?? null,
      };
    }
    return acc;
  }, {});
export const filterCustomerData = ({
  first_name,
  middle_name,
  last_name,
  ssn,
  customer_type,
  date_of_birth,
  mobile_number,
  home_number,
  preferred_contact,
  reference_id,
  identifier,
  email,
  customer_addresses,
  income_info,
  payments_info,
  license_info,
  disclaimer_info,
}) => {
  return {
    first_name,
    middle_name,
    last_name,
    customer_type,
    date_of_birth,
    ssn,
    mobile_number,
    home_number,
    preferred_contact,
    reference_id,
    identifier,
    email,
    license_info,
    payments_info,
    ...(!isEmpty(disclaimer_info) && {
      disclaimer_info: disclaimer_info,
    }),
    ...(!isEmpty(customer_addresses) && {
      customer_addresses: addressByType(customer_addresses),
    }),
    ...(income_info && { income_info: cleanIncomeInfo(income_info) }),
  };
};

export const formatUSPhoneNumberWithCountryCode = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  if (!phoneNumberString) {
    return "";
  }
  if (cleaned.startsWith("1") && cleaned.length === 11) {
    const match = cleaned.match(/^(1)(.{3})(.{3})(.{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }
  } else {
    const match = cleaned.match(/^(.{3})(.{3})(.{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  }
  return phoneNumberString;
};

export function collectionIntoSelectOptions(options, labelKey, valueKey) {
  return options.map((option) => ({
    label: option[labelKey], // Mapping dynamic label key
    value: option[valueKey], // Mapping dynamic value key
  }));
}
export const removeNullValues = (input) => {
  // If the input is an array, recursively clean its elements
  if (Array.isArray(input)) {
    return input
      .map((item) => removeNullValues(item)) // Recursively handle each item in the array
      .filter((item) => item !== null); // Remove null values after recursion
  }

  // If the input is an object (not null), recursively clean its properties
  if (typeof input === "object" && input !== null) {
    const result = {};

    // Iterate through each key in the object
    for (let key in input) {
      const value = input[key];

      // Only include the key if its value is not null, or if it's an array (which we keep even if empty)
      if (
        value !== null &&
        (Array.isArray(value) ? value.length !== 0 : true)
      ) {
        result[key] = removeNullValues(value); // Recurse on non-null values
      }
    }

    return result;
  }

  // If it's neither an array nor an object (i.e., a primitive), return the input as is
  return input;
};

export function getMappedOptionValue(
  options: any[],
  selectedStateName: string
) {
  // Find the option whose label matches the selected state_name
  const selectedOption = options.find(
    (option) => option.label === selectedStateName
  );
  // If a match is found, return the value of the selected option
  return selectedOption ? selectedOption.value : undefined;
}

export const getMoveInDate = (moveInDuration: string) => {
  let date = "";
  if (moveInDuration && moveInDuration?.toString()?.length > 3) {
    const month = parseInt(moveInDuration?.toString().slice(0, 2));
    let year = parseInt(moveInDuration?.toString().slice(2, 6));
    date = `${year}-${month}-${1}`;
    return date;
  }
  return date;
};

export const setMoveInDate = (moveInDate: string) => {
  let monthYear = "";
  if (moveInDate?.length > 0) {
    if (
      parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()
    ) {
    }
    monthYear =
      moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4);
  }
  return monthYear;
};

export const calculateMonthsDifference = (move_in_date) => {
  const givenDate = new Date(move_in_date);

  const currentDate = new Date();

  let monthsDifference =
    (currentDate.getFullYear() - givenDate.getFullYear()) * 12;
  monthsDifference += currentDate.getMonth() - givenDate.getMonth();

  if (currentDate.getDate() < givenDate.getDate()) {
    return monthsDifference--;
  }
  return monthsDifference;
};

export function calculateDateDifference(dateInput) {
  const currentDate = new Date();
  const inputDate = new Date(dateInput);

  // Calculate the difference in years
  let years = currentDate.getFullYear() - inputDate.getFullYear();
  let months = currentDate.getMonth() - inputDate.getMonth();
  let days = currentDate.getDate() - inputDate.getDate();

  // Adjust for months
  if (months < 0) {
    years--;
    months += 12;
  }

  // Adjust for days
  if (days < 0) {
    months--;
    // Get the number of days in the previous month
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      0
    );
    days += prevMonth.getDate();
  }

  return `${years} year(s), ${months} month(s), ${days} day(s)`;
}

export const formatCurrency = (value) => {
  if (value === "" || value == null) return "";
  else if (value === 0) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: sessionStorage.getItem("defaultCurrency") ?? "USD",
    });
  }
  const numberValue = parseFloat(
    Number(value) ? value : value.replace(/[^0-9.-]+/g, "") || 0
  );
  return numberValue.toLocaleString("en-US", {
    style: "currency",
    currency: sessionStorage.getItem("defaultCurrency") ?? "USD",
  });
};

export const validatePhoneNumberLength = (value, country) => {
  if (!country) return false;

  const phoneDigits = value.replace(/\D/g, ""); // Remove non-numeric characters
  const countryLength = (country.format.match(/\./g) || []).length;

  return phoneDigits.length === countryLength;
};

export const getMaxDateByNumOfYear = (numOfYear: number) => {
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() - numOfYear);
  return dayjs(maxDate);
};
export const getMinDateByYear = (year: number) => {
  const minDate = new Date();
  minDate.setFullYear(year);
  return dayjs(minDate);
};

export const extractVehicleOrderDetailsFromURL = (url) => {
  // Regex for "/deal/:dealNumber"
  const dealRefRegex = /deal\/([^/]+)/;
  const dealRefMatch = url.match(dealRefRegex);
  // TODO:: IN CASE THE VIN IS APPENDED IN URL AS CHECKOUT/:VIN/:DEALERCODE
  // Regex for "/:vin/:dealerCode" with VIN being exactly 17 characters long
  // for (let route of validRoutes) {
  //   const regex = new RegExp(`^${route}\/([A-HJ-NPR-Z0-9]{17})\/([^/]+)`);
  //   const match = url.match(regex);

  //   if (match) {
  //     // Return the matched VIN and dealer code if found
  //     return {
  //       dealRefNumber: null,
  //       vin: match[1],
  //       dealerCode: match[2],
  //     };
  //   }
  // }

  return {
    dealRefNumber: dealRefMatch ? dealRefMatch[1] : null,
    // vin: null,
    // dealerCode: null,
  };
};

export const getVinandDealorCodeFromURLParams = () => {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const vin = urlParams.get("vin");
  const dealerCode = urlParams.get("dealer-code");

  if (vin && regexVIN.test(vin)) {
    if (dealerCode) {
      return { vin, dealerCode };
    } else return { vin };
  } else {
    return null;
  }
};

export function updateUrl(referenceNumber) {
  const currentUrl = window.location.href.split("?")[0];
  const newPathname = currentUrl + "/deal/" + referenceNumber;
  window.location.replace(newPathname);
}

export const isValidUrl = (url) => {
  try {
    new URL(url); // This checks if the URL is valid
    return true;
  } catch (e) {
    return false;
  }
};
export const redirectToDealerWebsite = (website: string) => {
  const shouldRedirect = JSON.parse(
    localStorage.getItem("redirectToDealerWebsite") ?? "false"
  );

  if (shouldRedirect) {
    if (typeof website === "string" && website.trim() !== "") {
      const url = website.startsWith("http") ? website : `https://${website}`;

      if (isValidUrl(url)) {
        localStorage.setItem("redirectToDealerWebsite", "false");
        window.location.href = url;
      }
    }
  }
};

export const parseStringBoolenStatus = (status: string = ""): boolean => {
  return status?.toLowerCase() === "true"; // Convert to lowercase and compare
};

export function getCustomerAddress(customer_addresses) {
  // Check if garaging has an entity_type
  const useMailingAddress = !!customer_addresses.garaging?.entity_type;

  // Select the appropriate address source
  const addressSource = useMailingAddress
    ? customer_addresses.mailing
    : customer_addresses.garaging;

  // Build the standardized address object
  // Note: county comes from mailing address regardless of which address is used
  return {
    street_address_1: addressSource.address_line_1,
    street_address_2: addressSource.address_line_2,
    city: addressSource.city,
    county: customer_addresses.mailing.county, // County always comes from mailing
    state: addressSource.state_name,
    zip_code: addressSource.zip_code,
  };
}

export function updateDisclaimerWithDealerName(
  description: string,
  dealerName: string
): string {
  return description.replace(/BMW Center/g, dealerName);
}
