import { Button, Drawer, Input } from "@src/components/atoms";
import { AddOnsDrawerFooter } from "@src/components/molecules";
import "./calculate-trade-in.scss";

export const CalculateTradeIn = ({ open, onClose }) => {
  return (
    <div className="calculate-trade-in-drawer">
      <Drawer
        width={375}
        open={open}
        maskClosable
        keyboard
        onClose={onClose}
        content={
          <>
            <div className="calculate-trade-in-drawer__content">
              <h3>Calculate your Trade-In offer</h3>
              <p className="text--body-2 calculate-trade-in-drawer__subtitle">
                This step can also be completed before you checkout.
              </p>
              <p className="calculate-trade-in-drawer__note">
                <strong>Note:</strong> Add additional messaging here about a
                more in-depth functionality thats available in the platform
                here.
              </p>
            </div>
            <div className="calculate-trade-in-drawer__form">
              <h5>Enter Your Estimate</h5>
              <Input
                id={"tradeInValue"}
                name={""}
                label="Trade-In Value"
                placeholder="$X,XXX"
              />
              <Input
                id={"tradeInValue"}
                name={""}
                label="Remaining Balance"
                placeholder="$X,XX"
                showInfoIcon
              />
              <div className="total-equity">
                <p className="text--body-2 text-muted">
                  Total Trade-in Equity:
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <p className="text--body-1 fw-bold">$X,XXX</p>
              </div>
            </div>
          </>
        }
        footer={
          <>
            <AddOnsDrawerFooter
              price={
                <>
                  <small className="text--disclaimer">Est. payments</small>
                  <h2 className="text--body-focus-price-1">
                    $X,XXX
                    <span className="text--body-1">/month</span>{" "}
                  </h2>
                </>
              }
              footerActions={
                <>
                  <Button
                    htmlType={"button"}
                    text="Save & close"
                    type="default"
                  />
                </>
              }
            />
          </>
        }
      />
    </div>
  );
};
