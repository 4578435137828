import { Button } from "@src/components/atoms";
import "./checkout-footer.scss";
import { useAppContext } from "@src/context/app-context";

export const CheckoutFooter = () => {
  return (
    <footer className="checkout-footer-container">
      <div className="checkout-footer-content">
        <p className="checkout-footer-copyright text--disclaimer">
          &copy; BMW of [Location] 2025
        </p>
        <nav className="checkout-footer-links" aria-label="Footer Navigation">
          <a
            href="/"
            rel="noopener noreferrer"
            className="no-decoration fw-normal text-dark "
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Terms
          </a>
          <a
            href="/"
            rel="noopener noreferrer"
            className="no-decoration fw-normal text-dark"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Privacy Policy
          </a>
        </nav>
      </div>
    </footer>
  );
};
