import { Button } from "@src/components/atoms/button/button.component";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { MessageAlert } from "@src/components/atoms";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";
import { formatCurrency } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";

export const FinanceLeasePricingDrawerContentInfoError = () => {
  const {
    contextState: { financetype },
  } = useGeneralContext();

  const {
    state: {
      user: {
        customer: { first_name },
      },
      order: {
        selling_price,
        order_asset: { unit_price, msrp },
      },
    },
  } = useAppContext();
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title={`Thanks for logging in ${
            "," + first_name
          }. Dealer pricing is now unlocked.`}
          iconName="checkmark"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <div className="resulting-price-wrap">
                <div className="resulting-price-wrap--inner">
                  <p className="text--promo-focus-price">
                    {formatCurrency(unit_price)}
                  </p>
                  <p className="text--body-2">
                    {formatCurrency(unit_price)} MSRP, with a{" "}
                    {formatCurrency(selling_price - msrp)} dealer credit
                    applied.
                  </p>
                </div>
              </div>
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title={
            financetype === FinanceType.LEASE
              ? "Get more Precise monthly payments"
              : "Get a more accurate APR %."
          }
          iconName="warning_sign"
          description={
            financetype === FinanceType.LEASE
              ? "Provide your address to verify your identity with TransUnion and get a more accurate monthly payment. This soft credit pull does not impact your credit score."
              : "Provide your address to verify your identity with TransUnion and get an accurate APR %. This soft credit pull does not impact your credit score."
          }
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <MessageAlert
                type="warning"
                title="Please try again later."
                subTitle="Sorry, we’re unable to get your personalized APR %. For further need assistance, please contact your dealer."
                showCloseButton={false}
                titleIcon={false}
                actionArea={
                  <>
                    <Button
                      htmlType="button"
                      text={"Contact dealer"}
                      onClick={() => {}}
                      size={"small"}
                    />
                  </>
                }
              />
            </div>
          }
        />
      </div>
    </>
  );
};
