import "./qr-code.scss";
import { getLinkClickPayload } from "@src/helpers";
import QrImage from "../../../shared/assets/img/QR.png";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";
import { useVerifyRadioContext } from "@src/components/organisms/credit-application/checkout/04-verify-identity/verify-identity/verify-identity";

export const QrCode = () => {
  const { dispatch } = useVerifyRadioContext();
  const { addEvent } = useAdobeDatalayerContext();

  return (
    <div className="qr-code-container">
      <p className="qr-code-container__header text--body-2">
        Please scan the QR code and click on the link to start.
      </p>
      <div className="qr-code-container__wrapper">
        <div className="label text--disclaimer text-secondary">
          Scan QR code
        </div>
        <img
          className="qr-code"
          src={QrImage}
          alt={"QR code"}
          onClick={() => {
            addEvent("linkClick", getLinkClickPayload("qr code", "qr code"));
            dispatch({ type: "QR_CODE_SCANNED" });
          }}
        />
      </div>
    </div>
  );
};
