import { ChatBody, ChatHeader } from "@src/components/molecules";
import "./chat-window.scss";

interface ChatWindowProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  status?: "online" | "offline" | "agentJoins";
}

export const ChatWindow: React.FC<ChatWindowProps> = ({
  setOpen,
  open,
  status,
}) => {
  return (
    <div className={`chat-window-wrap ${open ? "open" : "closed"}`}>
      <ChatHeader setOpen={setOpen} status={status} />
      <ChatBody setOpen={setOpen} status={status} />
    </div>
  );
};
