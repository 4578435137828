import {
  LegalDisclaimer,
  OneTrustlDisclaimer,
} from "@src/components/molecules";
import "./disclaimer-notification.scss";
import { useState } from "react";

export const DisclaimerNotification = () => {
  const isMobile = window.innerWidth < 768;
  const [disclaimerState, setDisclaimerState] = useState({
    showDisclaimer: !isMobile,
    showSection: true,
    showLegalDisclaimer: true,
  });

  const onClickButton = () => {
    if (isMobile) {
      setDisclaimerState({
        ...disclaimerState,
        showLegalDisclaimer: false,
        showDisclaimer: true,
      });
    } else {
      setDisclaimerState({
        ...disclaimerState,
        showSection: false,
      });
    }
  };

  if (!disclaimerState.showSection) return null;

  return (
    <div className="disclaimer-notification-wrap">
      {disclaimerState.showLegalDisclaimer && (
        <LegalDisclaimer onClickButton={onClickButton} />
      )}
      {disclaimerState.showDisclaimer && <OneTrustlDisclaimer />}
    </div>
  );
};
