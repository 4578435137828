import { useState } from "react";
import { Button, Input } from "@src/components/atoms";
import "./chat-request-form.scss";

export const ChatRequestForm = () => {
  const [state, setState] = useState();

  return (
    <div className="chat-form-wrap">
      <Input
        id={""}
        name={""}
        placeholder="First name"
        value={"Christopher"}
        label="First name"
        // error={{ message: " " }}
        size="small"
      />
      <Input
        id={""}
        name={""}
        placeholder="Email"
        value={"chrisrunnen@bmwna.com"}
        label="Email"
        // error={{ message: " " }}
        size="small"
      />
      <Input
        id={""}
        name={""}
        label="Inquiry"
        autoSize={{ minRows: 2, maxRows: 2 }}
        type="textarea"
        showCount
        onChange={(e: any) => {
          setState(e.target.value);
        }}
        value={state}
        maxLength={400}
      />
      <div className="chat-form-buttons">
        <Button
          htmlType={"button"}
          text="Submit & continue"
          fullWidth
          type="primary"
          size="small"
          disabled
        />
        {/* <Button
          htmlType={"button"}
          text="Live chat with an agent"
          fullWidth
          type="default"
          size="small"
        /> */}
      </div>
    </div>
  );
};
