import { useAppContext } from "@src/context/app-context";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";
import { formatCurrency } from "@src/helpers";

export const FinanceLeasePricingDrawerContentApr = () => {
  const {
    contextState: { dealerPricing },
  } = useGeneralContext();
  const {
    state: {
      configuration: { cache_expiry_date },
      order: {
        finance_type,
        apr,
        estimated_monthly_payment,
        selling_price,
        order_asset: { unit_price, msrp, model },
      },
      user: {
        customer: { first_name, last_name },
      },
    },
  } = useAppContext();
  const PaymentMonthly = JSON.parse(
    localStorage.getItem("estimated_monthly_payment") || "0"
  );

  const monthlyChange = PaymentMonthly
    ? PaymentMonthly - estimated_monthly_payment
    : estimated_monthly_payment;
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title={`Thanks for logging in ${
            "," + first_name
          }. Dealer pricing is now unlocked.`}
          iconName="checkmark"
          content={
            selling_price - msrp != 0 ? (
              <div className="fl-pricing-drawer-wrap--inner">
                <div className="resulting-price-wrap">
                  <div className="resulting-price-wrap--inner resulting-price-wrap--inner-1">
                    <p className="text--promo-focus-price">
                      {formatCurrency(unit_price)}
                    </p>
                    <p className="text--body-2">
                      {formatCurrency(unit_price)} MSRP, with a{" "}
                      {formatCurrency(selling_price - msrp)} dealer credit
                      applied.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="fl-pricing-drawer-wrap--inner">
                <div className="resulting-price-wrap">
                  <div className="resulting-price-wrap--inner">
                    <p className="text--body-2">
                      Good news - you already have the most accurate MSRP for
                      this {model}, and there are no dealer adjustments.
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title={
            finance_type === FinanceType.LEASE
              ? "Your updated monthly estimate. "
              : "Your updated APR % and monthly estimate. "
          }
          iconName="checkmark"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <div className="resulting-price-wrap">
                {finance_type === FinanceType.FINANCE && (
                  <div className="resulting-price-wrap--inner">
                    <p className="text--promo-focus-price">{apr}%</p>
                    <p className="text--body-2">
                      Your APR is based on your Transunion credit score. This
                      APR be saved for you until {cache_expiry_date}.
                    </p>
                  </div>
                )}
                <div className="resulting-price-wrap--inner">
                  <p className="text--promo-focus-price resulting-price-title--per-month">
                    {formatCurrency(estimated_monthly_payment)}
                    <span>/month</span>
                  </p>
                  <div className="text--body-2 resulting-price-description--per-month">
                    <div
                      className="bmw-icon bmw-icon--before data-icon"
                      data-icon={"arrow_down"}
                    />
                    <p className="text--body-2">
                      <strong>{formatCurrency(monthlyChange)}</strong> less per
                      month
                    </p>
                  </div>
                  {finance_type === FinanceType.LEASE && (
                    <p>
                      The calculation is based on your Transunion credit score.
                    </p>
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
