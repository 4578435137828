import { Button, Checkbox, MessageAlert } from "@src/components/atoms";
import "./legal-disclaimer.scss";
import { LegalDisclaimerContent } from "../legal-disclaimer-content/legal-disclaimer-content.component";
import { useState } from "react";

export const LegalDisclaimer = ({ onClickButton }: any) => {
  const [checkbox, setCheckbox] = useState(false);
  return (
    <div className="legal-disclaimer-wrap">
      <MessageAlert
        showCloseButton={false}
        titleIcon={false}
        contentDetail={<LegalDisclaimerContent setCheckbox={setCheckbox} />}
        actionArea={
          <Button
            type="default"
            htmlType={"button"}
            onClick={onClickButton}
            text="Accept & continue"
            size="small"
            disabled={!checkbox}
          />
        }
      />
    </div>
  );
};
