import { ChatSeparatorProps } from "./chat-separator.props";
import "./chat-separator.scss";

export const ChatSeparator = ({ icon, text }: ChatSeparatorProps) => {
  return (
    <div className="chat-separator-wrap">
      <div className="chat-separator--lines">
        <div className="bmw-icon bmw-icon--before data-icon" data-icon={icon} />
      </div>
      <p className="text--disclaimer">{text}</p>
    </div>
  );
};
