import { Drawer } from "@src/components/atoms";
import {
  DisclaimerNotification,
  IncentivesAndDiscounts,
} from "@src/components/organisms/build-your-deal";
import { BaseLayout } from "@src/layouts";

export const IncentivesAndDiscountsPage = () => {
  return (
    <BaseLayout>
      <IncentivesAndDiscounts />
      <DisclaimerNotification />
    </BaseLayout>
  );
};
