import "./incentives-list-card.scss";

import { IncentivesListCardProps } from "./incentives-list-card.props";
import clsx from "clsx";
import { Button, Image } from "@src/components/atoms";

export const IncentivesListCard = ({
  className,
  image,
  smallTitle,
  title,
  subTitle,
  termsInfo,
  price,
  totalPrice,
  isCardDisabled,
  hasCheckbox,
  actionsArea,
  feedbackLable,
  coverage,
  tag,
  legend,
}: IncentivesListCardProps) => {
  return (
    <div
      className={clsx(className, {
        "incentives-discounts--card": true,
        is_row_disabled: isCardDisabled,
      })}
    >
      <div className="incentives-discounts--card-flexible">
        {hasCheckbox && (
          <div className="incentives-discounts--card-checkbox">
            {hasCheckbox}
          </div>
        )}
        <div className="incentives-discounts--card-image">
          <Image src={image} alt="card-image" aspectRatio="1:1" />
        </div>

        <div className="incentives-discounts--card-right">
          <div className="incentives-discounts--card-right__flexible">
            <div className="incentives-discounts--card-right__text">
              <small className="small text--disclaimer">{smallTitle}</small>
              <h2 className="text--body-1">{title}</h2>

              {termsInfo && termsInfo}
              <p className="text--body-2">{subTitle}</p>
            </div>
            <div className="incentives-discounts--card-right__price">
              <div>{price}</div>
              {totalPrice && <p className="text--disclaimer">{totalPrice}</p>}
            </div>
          </div>

          {coverage && (
            <div className="coverage">
              {tag && (
                <div className="coverage__tag">
                  <span className="text--disclaimer tag">{tag}</span>
                </div>
              )}
              <div className="coverage__list">{coverage}</div>
            </div>
          )}

          {legend && (
            <div className="legend">
              <div className="legend__list">{legend}</div>
            </div>
          )}

          {actionsArea && (
            <div className="incentives-discounts--card_actions">
              {actionsArea}
            </div>
          )}
        </div>
      </div>
      {feedbackLable && (
        <div className="incentives-discounts--card_feedback">
          {feedbackLable}
        </div>
      )}
    </div>
  );
};
