import { Button, Checkbox } from "@src/components/atoms";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "./fl-authenticated-user-credit.scss";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers";
import { useState } from "react";
import { updateCreditFreeze } from "@src/context/app-actions";

export const FinanceLeaseAuthenticatedUserCreditFrozen = () => {
  const {
    state: {
      order: {
        selling_price,
        order_asset: { unit_price, msrp },
      },
      user: {
        customer: { first_name },
      },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const [handleSelect, setHandleSelect] = useState(false);
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title={`Thanks for logging in, ${first_name}. Dealer pricing is now unlocked.`}
          iconName="checkmark"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <div className="resulting-price-wrap">
                <div className="resulting-price-wrap--inner">
                  <p className="text--promo-focus-price">
                    {formatCurrency(unit_price)}
                  </p>
                  <p className="text--body-2">
                    {formatCurrency(unit_price)} MSRP, with a{" "}
                    {formatCurrency(selling_price - msrp)} dealer credit
                    applied.
                  </p>
                </div>
              </div>
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title="Sorry, your credit is frozen."
          description="Provide your address to verify your identity with TransUnion and get an accurate APR %. This process does not impact your credit score."
          iconName="warning_sign"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <Button
                htmlType="button"
                className="custom-full-width"
                text="Unfreeze your credit on TransUnion"
                onClick={() => {
                  window.open(
                    "https://www.transunion.com/credit-freeze",
                    "__blank"
                  );
                }}
                fullWidth
              />
              <div className="fl-pricing-drawer--checkbox-wrap">
                <Checkbox
                  id={"dealer-pricing--accept-terms"}
                  label="By clicking here, I confirm that my credit is unfrozen."
                  htmlFor="dealer-pricing--accept-terms"
                  onChange={(val) => setHandleSelect(val)}
                  isCompact
                />
              </div>
              <div className="action-area--group action-area--group-flexible">
                <Button
                  htmlType={"button"}
                  text="Unlock accurate pricing"
                  type="default"
                  onClick={() => {
                    appDispatch(updateCreditFreeze(false));
                  }}
                  disabled={!handleSelect}
                />
                <Button linkButton htmlType="button" text="Contact dealer" />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
