import "./product-details-drawer-content.scss";
import { Button, Carousel, Image, Popover } from "@src/components/atoms";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FinancialProduct } from "@src/types";
import { formatCurrency, formatUrl } from "@src/helpers";

export const ProductDetailsDrawerContent = ({
  productDetails,
}: {
  productDetails?: FinancialProduct;
}) => {
  if (!productDetails) return null;

  return (
    <div className="product-details-drawer-content">
      <div className="product-details-drawer">
        <div className="product-details-drawer__left">
          <div className="product-details-drawer__image">
            <Carousel
              arrows={productDetails.image.length > 1}
              autoplay
              autoplaySpeed={2000}
              dots={productDetails.image.length > 1}
              infinite
              images={productDetails.image?.map((img) => ({
                src: img.location,
                alt: "Slide 1",
              }))}
            />
            <PhotoProvider
              className="image-preview-wrap"
              maskClosable={false}
              pullClosable={false}
              maskOpacity={null}
              toolbarRender={({ onClose }) => (
                <Button
                  className="preview-close-btn"
                  htmlType={"button"}
                  onlyIcon
                  dataIcon="arrows_minimize"
                  onImage
                  onClick={onClose}
                />
              )}
            >
              {productDetails.image?.map((img, index) => (
                <PhotoView key={index} src={img.location}>
                  <span
                    className="bmw-icon bmw-icon--after data-icon maximize-icon"
                    data-icon="arrows_maximize"
                  />
                </PhotoView>
              ))}
            </PhotoProvider>
          </div>
        </div>
        <div className="product-details-drawer__right">
          <div className="product-details-drawer__right--inner">
            <span className="product-details-drawer__subtitle text--disclaimer">
              Provided by {productDetails.provider_name}
            </span>
            <div className="text--subsection-1">
              {productDetails.product_name}
            </div>
            <div className="product-details-drawer__right--price-detail">
              <div className="product-details-drawer__right--price-section">
                <span className="card-select-default-checked text--body-2">
                  Monthly cost:
                </span>
                <div className="text--promo-focus-price">
                  {formatCurrency(productDetails.monthly_impact ?? 0)}
                  <span className="text--h3">/month</span>
                </div>
                <p className="text--label">
                  {" "}
                  {formatCurrency(productDetails.fni_product_amount)} total
                </p>
              </div>
              <p className="text--body-2">{productDetails.description}</p>
            </div>
            <div className="product-details-drawer__price-list">
              {productDetails?.term_months ? (
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="clock"
                    ></i>
                    Terms
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    {productDetails.term_months} Months
                  </span>
                </div>
              ) : null}
              {productDetails.term_miles ? (
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    <i
                      className="bmw-icon bmw-icon--after data-icon"
                      data-icon="street"
                    ></i>
                    Mileage
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    {productDetails.term_miles?.toLocaleString("en-US")} miles
                  </span>
                </div>
              ) : null}

              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="insurance_calculator"
                  ></i>
                  Deductible
                  <Popover
                    trigger={["hover"]}
                    placement="top"
                    disclaimerText="A deductible is the amount you must pay out of pocket before your protection plan or warranty covers the rest of the repair or replacement cost."
                  >
                    <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                  </Popover>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  <i
                    className="bmw-icon bmw-icon--after data-icon"
                    data-icon="license_plate"
                  ></i>
                  Surcharges
                  <Popover
                    trigger={["hover"]}
                    placement="right"
                    disclaimerText="A surcharge is an additional fee added to the cost of a protection plan based on specific conditions or risks."
                  >
                    <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                  </Popover>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $XXX
                </span>
              </div>
            </div>
            <p className="text--disclaimer text-muted">
              For coverage adjustments or more questions, please{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="text-dark text--disclaimer-link text-underline fw-normal"
              >
                contact dealer
              </a>
              .
            </p>
          </div>

          <div className="product-details-drawer__right--links-section">
            {productDetails.video_url && (
              <Button
                htmlType="button"
                text="Play video"
                linkButton
                withIcon
                onClick={() => window.open(productDetails.video_url, "_blank")}
                dataIcon="play"
              />
            )}
            {productDetails.brochure_url && (
              <Button
                htmlType="button"
                text="Download brochure"
                linkButton
                withIcon
                onClick={() =>
                  window.open(formatUrl(productDetails.brochure_url), "_blank")
                }
                dataIcon="download"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
