import React from "react";
import { Upload, message, Button, List } from "antd";
import {
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { FileUploadProps } from "./file-upload.props";
import "../../../styles/components/_file-upload.scss";
import { ProgressBar } from "../progress-bar/progress-bar.component";

const { Dragger } = Upload;

export const FileUpload: React.FC<FileUploadProps> = ({
  name = "file",
  multiple = true,
  action = "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange,
  onDrop,
  showUploadList = true,
  beforeUpload,
  accept = "*",
  customRequest,
  fileUploadTitle,
  fileUploadSubTitle,
  fileUpoadIcon = "upload",
  doneActionArea,
  loadingActionArea,
  errorActionArea,
  filePreviewIcon,
}) => {
  const [fileList, setFileList] = React.useState<any[]>([]);

  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);

    if (onChange) {
      onChange(info);
    }

    const { status, name } = info.file;
    if (status === "done") {
      message.success(`${name} uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${name} upload failed.`);
    }
  };

  const handleRemove = (file: any) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  const uploadProps = {
    name,
    multiple,
    action,
    onChange: handleChange,
    onDrop,
    beforeUpload,
    showUploadList: false,
    accept,
    customRequest,
  };
  const formatSize = (size: number) => (size / 1024 / 1024).toFixed(2);

  return (
    <div className="file-upload-wrapper">
      <Dragger {...uploadProps} fileList={fileList}>
        <div className="file-upload--inner-1">
          {fileUpoadIcon && (
            <span className="file-upload--icon">
              <div
                className="bmw-icon bmw-icon--before data-icon"
                data-icon={fileUpoadIcon}
              />
            </span>
          )}
          {fileUploadTitle && <p className="text--cta-2">{fileUploadTitle}</p>}
        </div>
        <div className="file-upload--inner-2">
          {fileUploadSubTitle && (
            <p className="text--label">{fileUploadSubTitle}</p>
          )}
        </div>
      </Dragger>

      {fileList.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={fileList}
          renderItem={(file) => {
            let actionComponent;
            if (file.status === "uploading" && loadingActionArea) {
              actionComponent = loadingActionArea(file);
            } else if (file.status === "done" && doneActionArea) {
              actionComponent = doneActionArea(file);
            } else if (file.status === "error" && errorActionArea) {
              actionComponent = errorActionArea(file);
            }

            const uploadedSize = file.percent
              ? (file.percent / 100) * file.size
              : 0;
            const totalSize = file.size;

            return (
              <List.Item
                className={file.status === "error" ? "file-upload-error" : ""}
                actions={[actionComponent]}
              >
                <List.Item.Meta
                  avatar={
                    file.status === "done" ? (
                      filePreviewIcon && <span>{filePreviewIcon}</span>
                    ) : file.status === "error" ? (
                      <div
                        className="bmw-icon bmw-icon--before data-icon"
                        data-icon="error_warning_sign"
                      />
                    ) : (
                      <ProgressBar
                        type="circle"
                        percent={40}
                        strokeColor="#262626"
                        size={24}
                        trailColor="#e6e6e6"
                      />
                    )
                  }
                  title={file.name}
                  description={
                    file.status === "uploading" || file.status === "done"
                      ? `${formatSize(uploadedSize)} MB of ${formatSize(
                          totalSize
                        )} MB`
                      : `Upload failed`
                  }
                />
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
};
