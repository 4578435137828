import { Col, Row } from "antd";
import "./hero-banner.scss";
import {
  Button,
  FeedbackLabel,
  Image,
  MultiSwatchCircles,
} from "@src/components/atoms";

import HeroBannerImage from "../../../../shared/assets/img/review/review-car-img.png";
import { CartPricingInfo } from "./cart-pricing-info";
import clsx from "clsx";

export const HeroBanner = ({
  region,
}: {
  region?:
    | "western-region"
    | "central-region"
    | "southern-region"
    | "northeast-region";
}) => {
  return (
    <div
      className={clsx({
        "hero-banner": true,
        "western-region": region === "western-region",
        "central-region": region === "central-region",
        "southern-region": region === "southern-region",
        "northeast-region": region === "northeast-region",
      })}
    >
      <div className="bmw-container">
        <div className="hero-banner__inner">
          <Row
            gutter={[24, { xs: 16, sm: 24 }]}
            align={"bottom"}
            className="hero-banner_row"
          >
            <Col xs={24} md={11} lg={10} style={{ alignSelf: "stretch" }}>
              <div className="hero-banner__content">
                <div className="hero-banner__content__inner">
                  <div className="hero-banner__review-deal">
                    <div className="hero-banner__review-deal__left">
                      <p className="text--body-2">Review your deal</p>
                      <h3 className="text--h3">2024 330i xDrive Sedan</h3>
                      <div className="vin-location">
                        <FeedbackLabel text={"At dealership"} type="success" />
                        {" | "}{" "}
                        <p className="text--disclaimer">
                          VIN#: 3MW89FF00P8C84861
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-lg-block">
                    <CartPricingInfo />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={13} lg={14} style={{ alignSelf: "stretch" }}>
              <div className="hero-banner__image--section">
                <Image src={HeroBannerImage} alt="image" aspectRatio="16:9" />
              </div>
            </Col>
          </Row>
          <div className="d-block d-lg-none">
            <CartPricingInfo />
          </div>
          <div className="hero-banner__scroll-tout-section">
            <Button
              htmlType={"button"}
              text=" View additional saved deals"
              linkButton
              withIcon
              isIconEnd
              dataIcon="arrow_down"
              onDark
            />
          </div>
        </div>
      </div>
    </div>
  );
};
