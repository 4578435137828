import { useAppContext } from "@src/context/app-context";
import { useEffect } from "react";

import {
  updateStates,
  updateCustomer,
  loadDefaultLenderConfigurations,
  updateLenders,
  updateDealerProfile,
  storeSetupData,
  setOrderInfo,
  updateLogo,
  updateDisclaimers,
} from "@src/context/app-actions";
import { FinanceType } from "@src/constants";
import {
  collectionIntoSelectOptions,
  filterCustomerData,
  disclaimerByType,
} from "@src/helpers";
import {
  useGetStates,
  useGetDefaultLender,
  useGetByDealerId,
  useGetCustomerByEmail,
  useGetLenders,
  useGetCustomerFinancialProfile,
  useGetSetupData,
  useCreateCustomer,
} from "@src/hooks";
import { useGetTheme } from "./dealer";
import { decodeToken } from "react-jwt";
import { useComputedQuotation } from "./useCalculateQuatation";
import { isEmpty } from "lodash";

export const useFetchAndStore = () => {
  const {
    state: {
      user: { customer },
      configuration: { urlOrigin },
      tenant: { tenantId },
      dealer: { dealer_name, disclaimer },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { finance_type, dealer_id, reference_number } = order;
  const { mutate: createCustomerByEmail } = useCreateCustomer();
  const { mutate: getCustomerByEmail } = useGetCustomerByEmail();
  const { mutate: defaultLender } = useGetDefaultLender();

  const { mutate: lenderData } = useGetLenders();
  const { mutate: dealerProfile } = useGetByDealerId();

  const { mutate: getCustomerFinancialProfile } =
    useGetCustomerFinancialProfile();
  const { mutate: getStates } = useGetStates();
  const { mutate: getSetupData } = useGetSetupData();
  const { mutate: getTheme } = useGetTheme();
  const { v2Results, setOrderValues, orderValues, setCalculated } =
    useComputedQuotation();
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const decodedData = decodeToken<{ email: string }>(
    userCredentials.access_token
  ) as any;

  useEffect(() => {
    if (!isEmpty(v2Results)) {
      appDispatch(
        setOrderInfo({
          ...order,
          ...orderValues,
          estimated_monthly_payment: v2Results.monthly_payment,
        })
      );
      setOrderValues(null);
      setCalculated({});
    }
  }, [v2Results, orderValues]);

  useEffect(() => {
    if (dealer_id && tenantId) {
      getStates(
        {},
        {
          onSuccess: (data) => {
            appDispatch(
              updateStates(collectionIntoSelectOptions(data, "name", "name"))
            );
          },
          onError: (error) => {
            console.error("Error fetching customer:", error);
          },
        }
      );
      defaultLender(
        {
          company_id: tenantId,
        },
        {
          onSuccess: (data) => {
            appDispatch(loadDefaultLenderConfigurations(data));
          },
        }
      );
      getSetupData(tenantId, {
        onSuccess: (setupData) => {
          appDispatch(
            setOrderInfo({
              ...order,
              credit_score: setupData?.["credit-ratings"]?.find(
                (item) => item.description === order?.credit_rating
              )?.score_from,
            })
          );

          appDispatch(storeSetupData(setupData));
        },
      });
      dealerProfile(
        {
          company_id: tenantId,
          dealerId: dealer_id,
        },
        {
          onSuccess: (dealerProfile) => {
            const { dealer_code, dealer_email, dealer_address } = dealerProfile;
            getTheme(
              { email: dealer_email },
              {
                onSuccess: (response) => {
                  const { logo } = response;
                  appDispatch(updateLogo(logo?.location));
                },
              }
            );
            appDispatch(updateDealerProfile(dealerProfile));
            if (finance_type == FinanceType.FINANCE) {
              lenderData(
                {
                  dealerCode: dealer_code,
                  finance_type: finance_type,
                },
                {
                  onSuccess: (response) => {
                    appDispatch(updateLenders(response));
                  },
                }
              );
            }
            getCustomerByEmail(
              { email: decodedData.email },
              {
                onSuccess: (customerInfo) => {
                  const filterData = filterCustomerData(customerInfo);
                  const result = Array.isArray(customerInfo.disclaimer_info)
                    ? customerInfo.disclaimer_info.filter(
                        (disclaimer_need) =>
                          disclaimer_need.order_reference_id ===
                          reference_number
                      )
                    : null;
                  if (result) {
                    const disclaimer_change = disclaimerByType(
                      result,
                      dealerProfile.dealer_name
                    );
                    appDispatch(
                      updateDisclaimers({ ...disclaimer, ...disclaimer_change })
                    );
                  }
                  if (filterData.customer_addresses) {
                    setOrderValues({
                      ...order,
                      customerV2Address: !!filterData.customer_addresses
                        .garaging?.entity_type
                        ? {
                            ...filterData.customer_addresses.mailing,
                          }
                        : { ...filterData.customer_addresses.garaging },
                    });
                  } else {
                    setOrderValues({
                      ...order,
                      customerV2Address: dealer_address,
                    });
                  }
                  const customerData = {
                    ...customer,
                    ...filterData,
                    fetchedData: true,
                  };
                  appDispatch(updateCustomer(customerData));

                  getCustomerFinancialProfile(
                    {
                      reference_id: customerInfo.reference_id,
                      dealer_code: dealer_code,
                    },
                    {
                      onSuccess: ({
                        bankruptcy,
                        monthly_payment,
                        residence,
                        reference_id,
                      }) => {
                        appDispatch(
                          updateCustomer({
                            ...customerData,
                            financial_profile: {
                              bankruptcy,
                              monthly_payment,
                              residence,
                              reference_id,
                            },
                          })
                        );
                      },
                      onError: (error) => {
                        console.error("Error fetching customer:", error);
                      },
                    }
                  );
                },
                onError: (error: { detail: string }) => {
                  if (error.detail === "customer record not found") {
                    createCustomerByEmail(
                      { email: decodedData.email, dealer_id: dealer_id },
                      {
                        onSuccess: (customerInfo) => {
                          const filterData = filterCustomerData(customerInfo);
                          const customerData = {
                            ...customer,
                            ...filterData,
                            fetchedData: true,
                          };
                          appDispatch(updateCustomer(customerData));
                          setOrderValues({
                            ...order,
                            customerV2Address: dealer_address,
                          });
                          getCustomerFinancialProfile(
                            {
                              reference_id: customerInfo.reference_id,
                              dealer_code: dealer_code,
                            },
                            {
                              onSuccess: ({
                                bankruptcy,
                                monthly_payment,
                                residence,
                                reference_id,
                              }) => {
                                appDispatch(
                                  updateCustomer({
                                    ...customerData,
                                    financial_profile: {
                                      bankruptcy,
                                      monthly_payment,
                                      residence,
                                      reference_id,
                                    },
                                  })
                                );
                              },
                              onError: (error) => {
                                console.error(
                                  "Error fetching customer:",
                                  error
                                );
                              },
                            }
                          );
                        },
                        onError: (error) => {
                          console.error("Error fetching customer:", error);
                        },
                      }
                    );
                  }
                  console.error("Error:", error);
                },
              }
            );
          },
        }
      );
    }
  }, [dealer_id, tenantId]);
};
