import { Checkbox } from "@src/components/atoms";
import { Controller } from "react-hook-form";

export const MaterialStatusCheck = ({ control }) => {
  return (
    <div className="material-status-check-wrap title-fields-spacer">
      <p className="material-status-check--subtitle text--body-2">
        Please confirm if the below applies to you.
      </p>
      <Controller
        name="isMarried"
        control={control}
        render={({ field }) => (
          <Checkbox
            id="materialStatusCheck"
            checked={field.value}
            onChange={field.onChange} // Ensure checkbox updates form state
            label="I am a married Wisconsin resident"
          />
        )}
      />
    </div>
  );
};
