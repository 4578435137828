import { useReducer, useMemo, useEffect } from "react";
import { CheckoutSteps } from "../../components/atoms/step/step.component";
import { VehicleCard } from "@components/molecules";

import {
  VehicleOwnership,
  VehicleOwnershipCompleted,
} from "@src/components/organisms/credit-application/checkout/01-who-is-this-for";
import {
  CurrentAndPastAddresses,
  CurrentAndPastAddressesCompleted,
} from "@src/components/organisms/credit-application/checkout/02-current-and-past-addresses";
import {
  FinancialInformation,
  FinancialInformationCompleted,
} from "@src/components/organisms/credit-application/checkout/03-financial-information";
import {
  VerifyIdentity,
  VerifyIdentityCompleted,
} from "@src/components/organisms/credit-application/checkout/04-verify-identity";
import {
  FinanceOfferProvider,
  FinanceOfferProviderCompleted,
} from "@src/components/organisms/credit-application/checkout/05-finance-offer-provider";
import { Agreements } from "@src/components/organisms/credit-application/checkout/06-agreements/agreements";

import "./checkout-page.scss";
import { reducer } from "@pages/checkout-page/checkout-page.state";
import { Col, Row } from "antd";
import { generatePayload, getLinkClickPayload } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";
import { updateCustomer } from "@src/context/app-actions";
import { useGetCustomerDisclosure } from "@src/hooks/customer";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";

function CheckoutContent() {
  const {
    state: {
      user: { customer },
      states,
      employmentTypes,
      order,
      dealer: { id, dealer_code, dealer_name, disclaimer },
      user: { isUserLoggedIn },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { addEvent } = useAdobeDatalayerContext();
  const { mutate: getCustomerDisclosure } = useGetCustomerDisclosure();

  const initialState = useMemo(() => {
    let index = 0;
    if (customer.fetchedData) {
      if (customer.ssn) index = 1;
      if (
        !!customer.customer_addresses?.garaging?.entity_type ||
        customer.customer_addresses?.garaging?.zip_code
      ) {
        index = 2;
      }
      if (!!customer.income_info?.employment_type) {
        index = 4;
      }
      if (disclaimer && disclaimer?.lenderSubmission?.customerFetch) index = 5;
      // TODO:: NEED TO DISCUSS WHETHER TO SHOW STEP 6 or not
      // if (disclaimer['creditApplicationDisclaimer']) index = 6
    }
    return {
      Index: index,
      stepZeroDataReceived: false,
      stepOneDataReceived: index > 0,
      stepTwoDataReceived: index > 1,
      stepThreeDataReceived: index > 2,
      stepFourDataReceived: index >= 3,
      stepFiveDataReceived: index > 4,
      message: false,
      stepSixDataReceived: false,
    };
  }, [customer.fetchedData]);

  const filteredFields = ["modelName", "series", "productionYear"];

  useEffect(() => {
    if (id)
      addEvent(
        "pageLoad",
        generatePayload(
          "bmwusaretail:applyforcredit:vehicleownership",
          "vehicleownership",
          isUserLoggedIn,
          { dealer_name, id },
          order,
          filteredFields
        )
      );
  }, [order, dealer_name, id, isUserLoggedIn]);

  useEffect(() => {
    getCustomerDisclosure(
      { reference_number: order.reference_number },
      {
        onSuccess: (response: any) => {
          const customerDisclosure = {
            ...customer,
            disclosure: response,
          };
          appDispatch(updateCustomer(customerDisclosure));
        },
      }
    );
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const stepOneDataReceived = () => {
    dispatch({ type: "Step-one-data-received" });

    if (state.Index <= 3) {
      dispatch({ type: "Next-step" });
      addEvent(
        "pageChange",
        generatePayload(
          "bmwusaretail:applyforcredit:addresses",
          "addresses",
          isUserLoggedIn,
          { dealer_name, id },
          order,
          filteredFields
        )
      );
    }
  };

  const stepTwoDataReceived = () => {
    dispatch({ type: "Step-two-data-received" });

    if (state.Index <= 3) {
      dispatch({ type: "Next-step" });
      addEvent(
        "pageChange",
        generatePayload(
          "bmwusaretail:applyforcredit:financialinfo",
          "financialinfo",
          isUserLoggedIn,
          { dealer_name, id },
          order,
          filteredFields
        )
      );
    }
  };

  const stepThreeDataReceived = () => {
    dispatch({ type: "Step-three-data-received" });

    if (state.Index <= 3) {
      dispatch({ type: "Next-step" });
      addEvent(
        "pageChange",
        generatePayload(
          "bmwusaretail:applyforcredit:IDverfication",
          "IDverification",
          isUserLoggedIn,
          { dealer_name, id },
          order,
          filteredFields
        )
      );
    }
  };

  const stepFourDataReceived = () => {
    dispatch({ type: "Step-four-data-received" });

    if (state.Index <= 3) {
      dispatch({ type: "Next-step" });
      addEvent(
        "pageChange",
        generatePayload(
          "bmwusaretail:applyforcredit:financialofferproviders",
          "financialofferproviders",
          isUserLoggedIn,
          { dealer_name, id },
          order,
          filteredFields
        )
      );
    }
  };

  const stepFiveDataReceived = () => {
    dispatch({ type: "Step-five-data-received" });
    dispatch({ type: "Next-step" });
    addEvent(
      "pageChange",
      generatePayload(
        "bmwusaretail:applyforcredit:agreements",
        "agreements",
        isUserLoggedIn,
        { dealer_name, id },
        order,
        filteredFields
      )
    );
  };

  const stepSixDataReceived = () => {
    addEvent(
      "linkClick",
      getLinkClickPayload("agree and submit apply for credit")
    );
    dispatch({ type: "Step-six-data-received" });
    dispatch({ type: "Next-step" });
  };

  const changeStateOfEditMode = (step: string) => {
    switch (step) {
      case "step-one":
        dispatch({ type: "Step-one-data-received-status-change" });
        break;
      case "step-two":
        dispatch({ type: "Step-two-data-received-status-change" });
        break;
      case "step-three":
        dispatch({ type: "Step-three-data-received-status-change" });
        break;
      case "step-four":
        dispatch({ type: "Step-four-data-received-status-change" });
        break;
      case "step-five":
        dispatch({ type: "Step-five-data-received-status-change" });
        break;
      default:
        break;
    }
  };
  const basicCustomerInfo = (({
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    ssn,
    mobile_number,
    home_number,
    email,
  }) => ({
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    ssn,
    mobile_number,
    home_number,
    updated_by: email,
  }))(customer);

  return (
    <div className="bmw-container address-inner-container">
      <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <div className="address-left-content">
            <p className="menu-title text--body-2 d-md-none d-block">
              Purchase Online
            </p>
            <h3 className="address-page-title text--h3">
              Complete your credit application.
            </h3>
            <p className="text--body-2">
              The details you provide allow us to submit your credit application
              and find options for you.
            </p>
            {/* If state.index is 0 and stepOneDataReceived is false then show StepOne */}
            {state.Index >= 0 && !state.stepOneDataReceived && (
              <VehicleOwnership
                customerInfo={basicCustomerInfo}
                dispatch={dispatch}
                state={state}
                stepOneDataReceivedEvent={stepOneDataReceived}
              />
            )}
            {/* Completed Step 1 */}
            {state.stepOneDataReceived && (
              <VehicleOwnershipCompleted
                customerInfo={basicCustomerInfo}
                changeStateOfEditModeHandler={changeStateOfEditMode}
              />
            )}
            {/* If state.index is 1 and stepTwoDataReceived is false then show StepTwo */}
            {state.Index >= 1 && !state.stepTwoDataReceived && (
              <CurrentAndPastAddresses
                stepTwoDataReceivedEvent={stepTwoDataReceived}
              />
            )}
            {/* Completed Step 2 */}
            {state.stepTwoDataReceived && (
              <CurrentAndPastAddressesCompleted
                customer={customer}
                changeStateOfEditModeHandler={changeStateOfEditMode}
              />
            )}
            {/* If state.index is 2 and stepThreeDataReceived is false then show StepThree */}
            {state.Index >= 2 && !state.stepThreeDataReceived && (
              <FinancialInformation
                customer={customer}
                stateOptions={states}
                employmentTypes={employmentTypes}
                appDispatch={appDispatch}
                dealerCode={dealer_code}
                stepThreeDataReceivedEvent={stepThreeDataReceived}
              />
            )}
            {/* Completed Step 3 */}
            {state.stepThreeDataReceived && (
              <FinancialInformationCompleted
                customer={customer}
                changeStateOfEditModeHandler={changeStateOfEditMode}
              />
            )}
            {/* If state.index is 3 and stepFourDataReceived is false then show StepFour */}
            {state.Index >= 3 && !state.stepFourDataReceived && (
              <VerifyIdentity
                stepFourDataReceivedEvent={stepFourDataReceived}
              />
            )}
            {/* Completed Step 4 */}
            {state.stepFourDataReceived && (
              <VerifyIdentityCompleted
                changeStateOfEditModeHandler={changeStateOfEditMode}
              />
            )}
            {/* If state.index is 4 and stepFiveDataReceived is false then show StepFive */}
            {state.Index >= 4 && !state.stepFiveDataReceived && (
              <FinanceOfferProvider
                stepFiveDataReceivedEvent={stepFiveDataReceived}
              />
            )}
            {/* Completed Step 5 */}
            {state.stepFiveDataReceived && (
              <FinanceOfferProviderCompleted
                changeStateOfEditModeHandler={changeStateOfEditMode}
              />
            )}
            {/* If state.index is 5 and stepSixDataReceived is false then show StepSix */}
            {state.Index >= 5 && !state.stepSixDataReceived && (
              <Agreements stepSixDataReceivedEvent={stepSixDataReceived} />
            )}
            <CheckoutSteps index={state.Index} />
            <p className="disclaimer text--disclaimer">
              The BMW Center Terms and Privacy Policy and BMW of North America,
              LLC (“BMW NA”) Terms and Privacy Policy apply to the use of this
              site. By using this site, you understand and agree that (1) this
              online process does not guarantee the sale or lease of this
              vehicle to you until terms are agreed and a credit application has
              been reviewed; (2) BMW NA does not sell vehicles nor finance or
              lease vehicles; (3) BMW Center sets actual price; (4) a vehicle
              may not be available for you until a BMW Center countersigns a
              Buyer’s Order and confirms both the availability of the vehicle
              and the purchase or lease offer; (5) circumstances, including,
              without limitation, pricing errors, vehicle damage, or
              unavailability, may require the termination or restart of any
              purchase or leasing transaction.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <div className="address-right-content">
            <div className="address-right-content__inner">
              <VehicleCard hasHeader vehicleFinanceImg cardPaymentInfo />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CheckoutContent;
