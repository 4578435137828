import { useEffect } from "react";
import "./step.scss";
import clsx from "clsx";

export const Step = () => {
  return (
    <h5 className="section-header padded">
      <span className="section-header text--subsection-2 number">01</span>
      <span className="section-header text--subsection-2 text">
        Vehicle ownership.
      </span>
    </h5>
  );
};

export const StepComponent = ({
  step,
  title,
}: {
  step: string;
  title: string;
}) => {
  return (
    <h5 className="section-header">
      <span className="section-header text--subsection-2 number">{step}</span>
      <span className="section-header text--subsection-2 text">{title}.</span>
    </h5>
  );
};

export const CheckoutSteps = ({ index }: { index: number }) => {
  return (
    <div
      className={`steps-container ${
        index >= 5 ? "steps-container-margin0" : ""
      }`}
    >
      {index >= 0 && index <= 0 && (
        <StepComponent step={"02"} title={"Addresses"} />
      )}

      {index >= 0 && index <= 1 && (
        <StepComponent step={"03"} title={"Financial information"} />
      )}

      {index >= 0 && index <= 2 && (
        <StepComponent step={"04"} title={"Identity verification"} />
      )}

      {index >= 0 && index <= 3 && (
        <StepComponent step={"05"} title={"Finance offer provider(s)"} />
      )}

      {index >= 0 && index <= 4 && (
        <StepComponent step={"06"} title={"Agreements"} />
      )}
    </div>
  );
};

export const Steps = ({
  financeOfferSteps,
}: {
  financeOfferSteps?: boolean;
}) => {
  return (
    <>
      {financeOfferSteps ? (
        <div className="steps-container">
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">02</span>
            <span className="section-header text--subsection-2 text">
              Insurance information.
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">03</span>
            <span className="section-header text--subsection-2 text">
              Buyer's order.
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">04</span>
            <span className="section-header text--subsection-2 text">
              Deposit.
            </span>
          </h2>
        </div>
      ) : (
        <div className="steps-container">
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">02</span>
            <span className="section-header text--subsection-2 text">
              Current &amp; past addresses.
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">03</span>
            <span className="section-header text--subsection-2 text">
              Financial information.
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">04</span>
            <span className="section-header text--subsection-2 text">
              Identity verification.
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">05</span>
            <span className="section-header text--subsection-2 text">
              Finance offer provider(s).
            </span>
          </h2>
          <h2 className="section-header">
            <span className="section-header text--subsection-2 number">06</span>
            <span className="section-header text--subsection-2 text">
              Agreements.
            </span>
          </h2>
        </div>
      )}
    </>
  );
};

export const financeOfferStep = ({
  step,
  title,
}: {
  step: string;
  title: string;
}) => {
  return (
    <h2 className="section-header">
      <span className="section-header text--subsection-2 number">{step}</span>
      <span className="section-header text--subsection-2 text">{title}</span>
    </h2>
  );
};

export const FinanceOfferSteps = ({ coutner }: { coutner: number }) => {
  return (
    <div
      className={clsx({
        "steps-container": true,
        "steps-container-mt-0": coutner >= 3,
      })}
    >
      {coutner < 1 && <StepComponent step="02" title="Insurance information" />}
      {coutner < 2 && <StepComponent step="03" title="Buyer's order" />}
      {coutner < 3 && <StepComponent step="04" title="Deposit" />}
    </div>
  );
};
