import { Image } from "@src/components/atoms";
import { PreCheckoutActionArea } from "../pre-checkout-action-area/pre-checkout-action-area";
import { PreChckoutRightContentProps } from "./pre-checkout-right-content.props";
import "./pre-checkout-right-content.scss";
import titleImage from "../../../../shared/assets/img/pre-checkout-right-title.svg";

export const PreChckoutRightContent = ({
  title,
  image,
  content,
}: PreChckoutRightContentProps) => {
  return (
    <>
      <div className="pre-checkout-right__inner">
        <div className="pre-checkout-right__content">
          <div className="pre-checkout-right__content__inner">
            <div className="pre-checkout-right__content__header">
              {title && (
                <div className="pre-checkout-right__content__title">
                  <Image src={titleImage} alt="image" aspectRatio="1:1" />
                </div>
              )}
            </div>
            {content && (
              <div className="pre-checkout-right__content__inner-content">
                {content}
              </div>
            )}
          </div>
          {image && (
            <div className="pre-checkout-right__content__image">{image}</div>
          )}
        </div>
        <PreCheckoutActionArea />
      </div>
    </>
  );
};
