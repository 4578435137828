import { Checkbox } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { updateDisclaimerWithDealerName } from "@src/helpers/appHelper";

export const LegalDisclaimerContent = ({ setCheckbox }: any) => {
  const {
    state: {
      dealer: {
        dealer_name,
        disclaimer: { incentivesDiscountsDisclaimer },
      },
    },
  } = useAppContext();
  const { changesDisclaimer } = incentivesDiscountsDisclaimer;
  const updateDisclaimer = updateDisclaimerWithDealerName(
    changesDisclaimer,
    dealer_name
  );
  return !!changesDisclaimer && dealer_name !== "" ? (
    <div className="legal-disclaimer-content--wrap">
      <Checkbox
        id={"legalDiscalimerText"}
        label={
          <div
            dangerouslySetInnerHTML={{
              __html: updateDisclaimer,
            }}
          />
        }
        htmlFor="legalDiscalimerText"
        isCompact
        onChange={(value) => {
          setCheckbox(value);
        }}
      />
    </div>
  ) : null;
};
