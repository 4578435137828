import "./multi-swatch-card.scss";
import { MultiSwatchCardProps } from "./multi-swatch-card-props";
import clsx from "clsx";
import { MultiSwatchCircles } from "@atoms/multi-swatch-circles/multi-swatch-circles";
import { useAppContext } from "@src/context/app-context";
import { Button } from "@src/components/atoms";

export const MultiSwatchCard = ({
  className,
  isDisabledCard,
  toggleDrawerPre,
}: MultiSwatchCardProps) => {
  const {
    state: {
      vehicle: { preInstalled },
      order: {
        order_asset: { model, make, year },
      },
      dealer: { dealer_name },
    },
  } = useAppContext();
  const imageLocations = Array.isArray(preInstalled)
    ? preInstalled.map((item) => item.image?.[0]?.location || item?.image || "")
    : [];
  return (
    <div
      className={clsx(className, {
        "multi-swatch-card": true,
        is_row_disabled: isDisabledCard,
      })}
    >
      <div className="">
        <MultiSwatchCircles images={imageLocations} maxVisible={2} />
      </div>
      <div>
        <div className="text--body-2">
          This {year} {make} {model} comes with {preInstalled.length}{" "}
          pre-installed accessories from {dealer_name}.
        </div>

        <Button
          htmlType="button"
          linkButton
          text="See what's included"
          className="text-underline"
          onClick={toggleDrawerPre}
        />
      </div>
    </div>
  );
};
