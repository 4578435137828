import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type Props = {
  children: ReactNode;
};

type WindowWithAdobeDataLayer = Window & {
  adobeDataLayer?: any[];
};

// Define the shape of the context data
type UserContextType = {
  addEvent: (eventName: string, payload: any) => void;
};

// Create the context with default values
const AdobeDatalayerContext = createContext<UserContextType | undefined>(
  undefined
);

export const DataLayerProvider = ({ children }: Props) => {
  const windowWithAdobeDataLayer: WindowWithAdobeDataLayer = window;
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Ensure adobeDataLayer exists before script execution
    windowWithAdobeDataLayer.adobeDataLayer =
      windowWithAdobeDataLayer.adobeDataLayer || [];

    // Check if the script already exists to avoid duplication
    if (!document.getElementById("adobe-dataLayer-script")) {
      const script = document.createElement("script");
      script.id = "adobe-dataLayer-script";
      script.type = "text/javascript";
      script.src =
        "https://assets.adobedtm.com/4ec2f85e3dde/134c837af5f5/launch-abc55d7194d2-development.min.js";

      // Use both defer and async for optimal loading
      script.defer = true;
      script.async = true;

      script.onload = script.onerror = () => {
        setIsInitialized(true); // Allow rendering even if script fails
      };

      document.head.appendChild(script);
    } else {
      setIsInitialized(true); // If script already exists, mark as initialized
    }
  }, []);

  const addEvent = (eventName: string, payload: any) => {
    if (!windowWithAdobeDataLayer.adobeDataLayer) {
      return;
    }

    windowWithAdobeDataLayer.adobeDataLayer.push({
      event: eventName,
      data: payload,
    });
  };

  // Render nothing until adobeDataLayer is initialized
  if (!isInitialized) {
    return <></>; // Show a loading message or spinner
  }

  return (
    <AdobeDatalayerContext.Provider value={{ addEvent }}>
      {children}
    </AdobeDatalayerContext.Provider>
  );
};

// Custom hook for using the context
export const useAdobeDatalayerContext = () => {
  const context = useContext(AdobeDatalayerContext);
  if (!context) {
    throw new Error(
      "useAdobeDatalayerContext must be used within an AdobeDatalayerContext provider"
    );
  }
  return context;
};
