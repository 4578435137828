import { BMW_BASE, URL_BMW } from "@src/constants/routes";
import { useEffect, ReactNode } from "react";
import { useExtractAndDecodeCode } from "@src/hooks/useChangeDomain";
import {
  useGetCompanyDomain,
  useGetOrderInfo,
  useGetToken,
  useGetFraudCheck,
  useSaveDeal,
  useCreateCustomer,
} from "@src/hooks";
import { useUrlParamsForOrderInfo } from "@src/hooks/useUrlParamsForOrderInfo";
import { useFetchAndStore } from "@src/hooks/useFetchAndStore";
import { isEmpty } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "@src/context/app-context";
import {
  loadTenantConfigurations,
  setOrderInfo,
  updateCustomer,
  orderFraudStatus,
  defaultStateUpdate,
} from "@src/context/app-actions";
import { Status, Decision, rebateCheckOrder } from "@src/helpers";
import { decodeToken } from "react-jwt";
import {
  extractVehicleOrderDetailsFromURL,
  filterCustomerData,
  getVinandDealorCodeFromURLParams,
  isValidUrl,
  redirectToDealerWebsite,
  removeNullValues,
  updateUrl,
} from "@src/helpers/appHelper";

const LOGIN_URL = BMW_BASE + "login" + URL_BMW;

const LoginAuthChecker = ({ children }: { children?: ReactNode }) => {
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const loginName = userCredentials?.login_name ?? null;
  const gcid = userCredentials?.gcid ?? null;

  const queryParams = getVinandDealorCodeFromURLParams();
  const { dealRefNumber } = extractVehicleOrderDetailsFromURL(
    useLocation().pathname
  );

  useExtractAndDecodeCode();
  useFetchAndStore(queryParams);
  useUrlParamsForOrderInfo(userCredentials, queryParams);

  const { mutate: companyData } = useGetCompanyDomain();
  const { mutate: getAuthToken } = useGetToken();
  const { mutate: getOrderInfo } = useGetOrderInfo();
  const { mutate: createCustomerByEmail } = useCreateCustomer();
  const { mutate: saveDeal } = useSaveDeal();
  const getAppContextFromStorage = JSON.parse(
    localStorage.getItem("state") || "{}"
  );
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const {
    dealer: { id, website },
    configuration: { urlOrigin },
    user: { customer },
    order,
  } = appState;

  const {
    reference_number,
    customer_info: { reference_id },
  } = order;
  const { mutate: FraudCheck } = useGetFraudCheck();
  const navigate = useNavigate();

  useEffect(() => {
    companyData(
      {
        origin_domain: urlOrigin,
      },
      {
        onSuccess: (data) => {
          appDispatch(loadTenantConfigurations(data));
          if (!userCredentials.access_token) {
            if (loginName && gcid) {
              getAuthToken(
                {
                  user_name: loginName,
                  reference_number: gcid,
                  slug: data.slug,
                },
                {
                  onSuccess: (response) => {
                    const decodedData = decodeToken<{ email: string }>(
                      response.access_token
                    );
                    if (decodedData?.email) {
                      if (!dealRefNumber) {
                        createCustomerByEmail(
                          {
                            email: decodedData?.email,
                            dealer_id:
                              getAppContextFromStorage.dealer.dealer_id,
                          },
                          {
                            onSuccess(customerInfo) {
                              const filterData =
                                filterCustomerData(customerInfo);
                              const customerData = {
                                ...customer,
                                ...filterData,
                              };
                              appDispatch(updateCustomer(customerData));
                            },
                          }
                        );
                      } else {
                        const customerData = {
                          ...customer,
                          email: decodedData.email,
                        };

                        appDispatch(updateCustomer(customerData));
                      }
                    }

                    // if (!dealRefNumber) {
                    //   return; // Do nothing if dealRefNumber is not present in the URL
                    // }

                    // Check if order info for the current dealRefNumber is already in the state
                    if (order && order.reference_number === dealRefNumber) {
                      return; // Skip the API call if order info is already available
                    }
                    if (dealRefNumber) {
                      getOrderInfo(dealRefNumber, {
                        onSuccess: (response) => {
                          appDispatch(
                            setOrderInfo({
                              ...order,
                              ...removeNullValues(response),
                            })
                          );
                        },
                        onError: (error) => {
                          console.error(
                            "error getting order information:",
                            error
                          );
                        },
                      });
                    }
                    localStorage.setItem("customer", JSON.stringify(response));
                  },
                }
              );
            }
          } else {
            const decodedData = decodeToken<{ email: string }>(
              userCredentials.access_token
            ) as any;
            if (decodedData?.email) {
              const customerData = {
                ...customer,
                email: decodedData.email,
              };

              appDispatch(updateCustomer(customerData));
            }
          }
        },
      }
    );
  }, []);
  useEffect(() => {
    if (customer.fetchedData) {
      switch (order.status) {
        case Status.Reviewed:
          FraudCheck(
            {
              referenceNumber: reference_number,
              customer_reference_id: reference_id,
            },
            {
              onSuccess: (response) => {
                if (Array.isArray(response) && response[0]?.decision?.value) {
                  appDispatch(orderFraudStatus(response[0].decision.value));
                  if (response[0].decision.value === Decision.ACCEPT) {
                    navigate(
                      `/deal-state/deal/${reference_number}/${response[0].decision.value}`
                    );
                  } else {
                    navigate(`/dealer-contact/deal/${reference_number}`);
                  }
                }
              },
              onError: (error) => {
                console.error("Error fetching fraud check:", error);
              },
            }
          );
          break;
        case Status.FULLY_RECEIVED:
          navigate(`/deal-state/deal/${reference_number}/accept`);
          break;
        case Status.Submitted:
          navigate(`/deal-state/deal/${reference_number}/accept`);
          break;
        case Status.Approved:
          if (order.documents.length) {
            return;
          }
          navigate(`/finance-offer/deal/${reference_number}`);
          break;
        default:
          break;
      }
    }
    if (
      !isEmpty(userCredentials) &&
      userCredentials.access_token &&
      !isEmpty(getAppContextFromStorage)
    ) {
      appDispatch(defaultStateUpdate(getAppContextFromStorage));
      const rebateFilter = rebateCheckOrder(
        getAppContextFromStorage?.order?.order_rebates
      );
      const ChangesRebate = {
        ...getAppContextFromStorage?.order,
        order_rebates: rebateFilter,
        id: null,
        lender_id: appState.lender.defaultLender.id,
        customer_info: appState.user.customer,
        reference_id: appState.user.customer.reference_id,
        company_id: appState.tenant.company_id,
      };
      saveDeal([ChangesRebate], {
        onSuccess: (response) => {
          localStorage.removeItem("state");
          updateUrl(response[0].reference_number);
          redirectToDealerWebsite(website);
        },
      });
    }
  }, [customer.fetchedData]);

  if (isEmpty(userCredentials) && !queryParams?.vin && dealRefNumber) {
    // Redirect to the login URL if access token is missing
    window.location.href = LOGIN_URL;
    return null; // Return null to prevent further rendering
  } else if (customer.fetchedData || queryParams?.vin) {
    return <>{children}</>;
  } else return null;
};

export default LoginAuthChecker;
