import { Accessories } from "@src/components/organisms/build-your-deal/accessories/accessories";
import { BaseLayout } from "@src/layouts";
import { useGetAddons, useGetMonthlyImpact } from "@src/hooks/order";
import { useEffect } from "react";
import { useAppContext } from "@src/context/app-context";
import { addOnsInstall, ImpactPayload } from "@src/helpers/financeHelper";
import { updateaddOns, updatePreInstalled } from "@src/context/app-actions";
import { getV2Payload } from "@src/helpers";
import { getNFAPayload } from "@src/helpers/payloadHelper";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";

export const AccessoriesPage = () => {
  const {
    state: {
      dealer: { dealer_code, dealer_address },
      order,
      vehicle: { preInstalled },
      user: {
        customer: {
          customer_addresses: { mailing },
        },
      },
      lender: {
        defaultLender: { external_code },
      },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { mutate: AddonsChange } = useGetAddons();
  const { setOrderValues } = useComputedQuotation();
  const { mutate: monthlyimapct } = useGetMonthlyImpact();
  const {
    order_asset: { model },
    contract_term,
  } = order;

  useEffect(() => {
    if (model && dealer_code && contract_term != 0) {
      AddonsChange(
        { dealerCode: dealer_code, modelName: model },
        {
          onSuccess: (response) => {
            const { addOn } = addOnsInstall([...response]);
            appDispatch(updateaddOns(addOn));

            monthlyimapct(
              {
                ...getNFAPayload(
                  { ...order, order_options: [...preInstalled, ...addOn] },
                  dealer_code
                ),
                ...getV2Payload(
                  order,
                  dealer_code,
                  mailing,
                  dealer_address,
                  external_code
                ),
              },
              {
                onSuccess: (impactResponse) => {
                  const impact_change = ImpactPayload(
                    addOn,
                    impactResponse.products
                  );
                  appDispatch(updateaddOns(impact_change));
                },
              }
            );
          },
        }
      );
    }
  }, [model, contract_term]);
  return (
    <BaseLayout>
      <Accessories />
    </BaseLayout>
  );
};
