import React, { useState, useEffect } from "react";
import { Input, Button, Table } from "antd";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";

const AdobeLaunchPage = () => {
  const [event, setEventName] = useState<string>("");
  const [eventData, setEventValue] = useState<string>("");

  const [dataForTable, setDataForTable] = useState<any[]>([]);

  const { addEvent, data } = useAdobeDatalayerContext();

  const addEventHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (event && eventData) {
      addEvent(event, eventData);
      setDataForTable((pre) => [...pre, { event, eventData }]);
    }
  };

  const columns = [
    { title: "Event", dataIndex: "event", key: "event" },
    { title: "Data", dataIndex: "eventData", key: "eventData" },
  ];

  useEffect(() => {
    console.info("Adobe Data Layer: ", data);
  }, [dataForTable]);

  return (
    <div
      style={{
        maxWidth: 600,
        margin: "20px auto",
        padding: 20,
        border: "1px solid #ddd",
        borderRadius: 8,
      }}
    >
      <Input
        placeholder="Event name"
        value={event}
        onChange={(e) => setEventName(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Input
        placeholder="Value"
        value={eventData}
        onChange={(e) => setEventValue(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Button
        type="primary"
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
          addEventHandler(e)
        }
        style={{ marginBottom: 20 }}
      >
        Add to Adobe Data Layer
      </Button>
      <Table
        dataSource={dataForTable}
        columns={columns}
        pagination={false}
        rowKey={(record, index) =>
          index !== undefined ? index.toString() : Math.random().toString()
        }
      />
    </div>
  );
};

export default AdobeLaunchPage;
