import { Radio } from "@src/components/atoms/radio/radio.component";
import "./financial-services.scss";
import CardLogo from "../../../shared/assets/img/cardLogo.png";
import { FinancialServiceCardSelect } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";

export const FinancialServiceCard = ({ setValues, values }) => {
  const {
    state: {
      order: {
        order_submissions,
        apr,
        finance_type,
        down_payment_value,
        contract_term,
        estimated_monthly_payment,
        monthly_sales_use_tax,
      },
    },
  } = useAppContext();
  const showAPR = finance_type === "Finance";

  return (
    <>
      <div className="offer-group">
        {order_submissions.length > 0 &&
          order_submissions.map((submission) => (
            <div key={submission.id} className="offer-container">
              <FinancialServiceCardSelect
                logo={CardLogo}
                title={submission.provider}
                subTitle={
                  "Tailored lease and finance options from the people who know BMW best."
                }
                price={formatCurrency(estimated_monthly_payment)}
                downCash={formatCurrency(down_payment_value)}
                apr={showAPR ? `${apr}% APR` : ""}
                term={`${contract_term} month finance term`}
                selected={values.submission.id === submission.id}
                hasCheckbox={
                  <Radio
                    label="Select"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        tier: submission.decision?.tier,
                        submission: submission,
                      }))
                    }
                    name="financialserviceRadio"
                    id="financial-service-radio1"
                    htmlFor="financial-service-radio"
                  />
                }
              />
            </div>
          ))}
      </div>
    </>
  );
};
