import { GETALLPROGRAMSPAYLOAD } from "@src/types";

export const getDownPaymentHelper = (
  downPayment: number,
  sellingPrice: number
) => {
  return (downPayment * sellingPrice) / 100;
};

export const getAllProgramPayload = (
  vehicleDetail: any,
  companyCode: string,
  externalCode: string,
  dealerCode,
  financeType: string,
  term: number,
  annualUsage: number,
  creditRating: string,
  creditScore: number
): GETALLPROGRAMSPAYLOAD => {
  return {
    ...(financeType === "Lease" && {
      annual_usage: annualUsage,
      annual_mileage: annualUsage,
    }),
    credit_rating: creditRating,
    total_score: creditScore,
    dealer_code: dealerCode,
    model_detail_name: vehicleDetail.trim_description,
    make_name: vehicleDetail.make,
    model_name: vehicleDetail.model,
    rental_frequency: "Monthly",
    rental_mode: null,
    terms: term,
    retrieve_all_programs: false,
    company_code: companyCode,
    vin: vehicleDetail.vin,
    selling_price: vehicleDetail.unit_price,
    msrp: vehicleDetail.msrp,
    is_new: vehicleDetail.type.toLowerCase() === "new",
    odometer: vehicleDetail.mileage || 0,
    finance_type: financeType,
    lender_code: externalCode,
    model_code: vehicleDetail.model_code,
  };
};
