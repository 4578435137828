import { Button, Modal } from "@src/components/atoms";
import "./esign-contract-modal.scss";

export const EsignContractModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="esign-contract-modal-wrapper">
        <Modal
          size="compact"
          className="esign-contract-modal"
          dataIcon={""}
          title={<h3>E-sign your contracts for a faster pickup.</h3>}
          subtitle={
            <p className="text--body-1">
              Your [20XX Model Name] appointment is coming up. You may e-sign
              your contracts now, or do so at the dealer.
            </p>
          }
          open={isModalOpen}
          onClose={handleClose}
          closable
          footer={
            <>
              <div className="action-btns">
                <Button
                  htmlType="button"
                  fullWidth
                  text="Close"
                  onClick={() => {}}
                />

                <Button
                  type="default"
                  htmlType={"button"}
                  onClick={() => {}}
                  text="E-sign contracts"
                ></Button>
              </div>
            </>
          }
        >
          <div className="appointment-detail-wrap">
            <div className="confirmed-section">
              <div className="label-font-size">Confirmed pickup</div>
              <div className="text--cta-2">Oct. 19, 2024</div>
              <div className="text--cta-2">8:00 AM – 9:30 AM</div>
            </div>
            <div className="location-section">
              <div className="label-font-size">Location</div>
              <div className="text--link-2 text-underline">
                200 Varick Street, Suite 610
              </div>
              <div className="address-with-icon">
                <span className="text--link-2 text-underline">
                  New York, NY 10454{" "}
                </span>
                <i
                  className="bmw-icon bmw-icon--after data-icon"
                  data-icon="duplicate"
                ></i>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
