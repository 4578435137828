import { Button } from "@src/components/atoms/button/button.component";
import { PricingEstimatesSteps } from "../../../../pricing-estimates-steps/pricing-estimates-steps.component";
import "../../fl-pricing-drawer-content.scss";
import { MessageAlert } from "@src/components/atoms";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";

export const FinanceLeasePricingDrawerContentIntroError = () => {
  const {
    contextState: { financetype },
  } = useGeneralContext();
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <PricingEstimatesSteps
          stepCount={"01."}
          title="Unlock personalized pricing."
          // description="A BMW ID is required to ensure your details and credit score are securely saved. Please sign in or register to continue."
          iconName="warning_sign"
          content={
            <div className="fl-pricing-drawer-wrap--inner">
              <MessageAlert
                type="warning"
                subTitle="Sorry, we're not able to get personalized pricing at this time. You can try again later, or contact your BMW Dealer."
                showCloseButton={false}
                titleIcon={false}
                actionArea={
                  <>
                    <Button
                      htmlType="button"
                      text={"Contact dealer"}
                      onClick={() => {}}
                      size={"small"}
                    />
                  </>
                }
              />
            </div>
          }
        />
        <PricingEstimatesSteps
          stepCount={"02."}
          title={
            financetype === FinanceType.LEASE
              ? "Get more Precise monthly payments"
              : "Get a more accurate APR %."
          }
          iconName="lock_locked"
          disabled
        />
      </div>
    </>
  );
};
