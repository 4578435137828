// import { UpscopeProvider, Masked, NoRemoteControl } from "@upscopeio/react";

const ComponentA = () => {
  return <div>Component A</div>;
};

const ComponentB = () => {
  return <div>Component B</div>;
};

const ComponentC = () => {
  return <div>Component C</div>;
};

const UpscopeClientSide = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}></div>
  );
};

export default UpscopeClientSide;

// import { useEffect } from "react";
//
// const UpscopeScriptLoader = ({ userId, identities }) => {
//   useEffect(() => {
//     // Function to load the Upscope script
//     const loadUpscope = () => {
//       (function (w, u, d) {
//         let i = function () {
//           // @ts-ignore
//           i.c(arguments);
//         };
//         // @ts-ignore
//         i.q = [];
//         // @ts-ignore
//         i.c = function (args) {
//           // @ts-ignore
//           i.q.push(args);
//         };
//         let l = function () {
//           let s = d.createElement("script");
//           s.type = "text/javascript";
//           s.async = true;
//           s.src = "https://code.upscope.io/fAwS8b34Ng.js";
//           let x = d.getElementsByTagName("script")[0];
//           // @ts-ignore
//           x.parentNode.insertBefore(s, x);
//         };
//         if (typeof u !== "function") {
//           // @ts-ignore
//           w.Upscope = i;
//           l();
//         }
//         // @ts-ignore
//       })(window, window.Upscope, document);
//
//       // Initialize Upscope
//       // @ts-ignore
//       window.Upscope("init");
//
//       // Update connection if user information is provided
//       if (userId || identities) {
//         // @ts-ignore
//         window.Upscope("updateConnection", {
//           uniqueId: userId || undefined,
//           identities: identities || [],
//         });
//       }
//     };
//
//     loadUpscope();
//   }, [userId, identities]); // Depend on userId and identities
//
//   return <div>upscope page is working...</div>;
// };
//
// export default UpscopeScriptLoader;
