import { Radio } from "@src/components/atoms";
import { QrCode } from "../qr-code/qr-code.component";

import "./varify-radio.scss";
import { ProvidePhone } from "../provide-phone/provide-phone.component";
import { ProvideEmail } from "../provide-email/provide-email.component";

import { useVerifyRadioContext } from "@src/components/organisms/credit-application/checkout/04-verify-identity/verify-identity/verify-identity";

export const VerifyRadio = () => {
  const { state, dispatch } = useVerifyRadioContext();

  return (
    <div className="verify-radio-container">
      <p className="sub-text-container sub-text text--body-2">
        Please select your preferred method to continue.
      </p>
      <div className="radio-container">
        <label htmlFor="qr-code" className="radio-container__options">
          <Radio
            label="QR Code"
            onChange={() => {
              dispatch({ type: "QR_CODE_SELECTED" });
            }}
            name="contactMethod"
            htmlFor="qr-code"
            id="qr-code"
            checked={state.isQRCodeSelected}
          />
        </label>
        <label className="radio-container__options" htmlFor="text-message">
          <Radio
            label="Text Message"
            onChange={() => {
              dispatch({ type: "TEXT_MESSAGE_SELECTED" });
            }}
            name="contactMethod"
            htmlFor="text-message"
            id="text-message"
          />
        </label>
        <label className="radio-container__options" htmlFor="email">
          <Radio
            label="Email"
            name="contactMethod"
            onChange={() => {
              dispatch({ type: "EMAIL_SELECTED" });
            }}
            htmlFor="email"
            id="email"
          />
        </label>
      </div>
      {state.isQRCodeSelected && <QrCode />}
      {state.isTextMessageSelected && <ProvidePhone />}
      {state.isEmailSelected && <ProvideEmail />}
    </div>
  );
};
