export enum Decision {
  REJECT = "reject",
  ACCEPT = "accept",
  FULL_RECIEVED = "Fully Received",
}
export enum IntegrationProvider {
  GOOGLE = "Google",
  SOCURE = "Socure",
  TRANSUNION = "TransUnion",
  KBB = "KBB",
  MITEK = "MiTek",
  PEN = "PEN",
  SHIFT_DIGITAL = "ShiftDigital",
  CDK = "Cdk",
  TEKION = "Tekion",
  STRIPE_CONNECT = "Stripe Connect",
  PLAID = "Plaid",
  UPSCOPE = "upscope",
  MARKETSCAN = "MarketScan",
  WAY = "Way",
}

export enum LenderIntegrationType {
  ADDRESS_VALIDATION = "location_services",
  FRAUD_IDENTIFICATION = "fraud_identification",
  EXISTING_CUSTOMER_CHECK = "customer_management",
  INVENTORY_UPLOAD = "inventory_management",
  FNI_PRODUCTS = "fni_products",
  IDENTITY_VERIFICATION = "identity_verification",
  TRADE_IN_VALUATION = "trade_in_valuation",
  PAYMENT_PROCESSING = "payment_processing",
  COBROWSING = "co_browsing",
  CALCULATION_AND_TAXATION = "calculation_and_taxation",
  PURCHASE_INSURANCE = "purchase_insurance",
  CREDIT_BEREAU = "credit_bureau",
}

export enum Status {
  Inquiry = "Inquiry",
  AvailableInInventory = "Available In Inventory",
  InContarct = "In Contarct",
  Available = "Available",
  Draft = "Draft",
  Submitted = "Submitted",
  Approved = "Approved",
  Conditioned = "Conditioned",
  InReview = "In Review",
  Reviewed = "Reviewed",
  ReSubmitted = "Re Submitted",
  SchedulePickup = "Awaiting Scheduling", //Schedule Pickup/delivery
  Appointment = "Appointment Scheduled", //Appointment
  Cancelled = "Cancelled", //Cancelled
  Converted = "Converted", //Completed
  Completed = "Completed", //Completed
  InProgress = "In-Progress", //In-Progress
  Declined = "Declined",
  Withdrawn = "Withdrawn",
  PendingConfirmation = "Pending Confirmation",
  Expired = "Expired",
  PendingTradeInConfirmation = "Pending Trade-In Confirmation",
  Qualified = "Qualified",
  REJECTED = "Rejected",
  FULLY_RECEIVED = "Fully Received",
  TRADE_IN_INSPECTION = "Trade-In Inspection",
  WITHDRAWN_BY_CUSTOMER = "Withdrawn by Customer",
}

export const STATES_REQUIRING_ADDRESS_TYPE = ["Wisconsin", "New York"];
export const STATES_REQUIRING_SPOUSE_INFO = ["Wisconsin"];
