import { IncentivesListCard } from "../../incentives-list-card/incentives-list-card.component";
import "./bundle-details-drawer-content.scss";
import { Button } from "@src/components/atoms";
import { Bundle } from "@src/types";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency, formatUrl } from "@src/helpers";

export const BundleDetailsDrawerContent = ({
  bundleDetails,
}: {
  bundleDetails?: Bundle;
}) => {
  const {
    state: {
      order: { contract_term },
      dealer: { dealer_name },
    },
  } = useAppContext();

  if (!bundleDetails) return null;

  return (
    <div className="bundle-details-drawer">
      <div className="bundle-details-drawer__head">
        <h3 className="bundle-details-drawer__head__title">
          {bundleDetails.bundle_name}
        </h3>
        <p className="bundle-details-drawer__head__subtitle text--body-2">
          {dealer_name} has pre-configured this bundle. To adjust these
          settings, please{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
            className="text--link-2 fw-normal text-dark text-underline"
          >
            contact dealer.
          </a>
        </p>
      </div>
      <div className="bundle-details-drawer__content">
        <div className="bundle-details-drawer__list--card">
          {/* TODO:: NEED TO DISCUSS FURTHER
          <IncentivesListCard
						image={Image}
						smallTitle='Provided by [Demo VSC]'
						title='Guaranteed auto protection'
						subTitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
						price={
							<h2 className='text--body-focus-price-1'>
								$0,000 <span className='text--body-1'>/month</span>
							</h2>
						}
						totalPrice={'Total: $XXXX.XX'}
						tag={'Whats included'}
						coverage={
						
								<IconList
									size='small'
									listItems={[
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Transfer Case (AWD Only)',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Induction System',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Steering',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Brakes and ABS',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Engine',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Heat and A/C',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Transimission',
										},
										{
											className: 'limited-coverage',
											type: 'icon',
											text: 'Electrical',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Final Drive Assembly',
										},
										{
											className: 'limited-coverage',
											type: 'icon',
											text: 'Interior Exterior',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Cooling System',
										},
										{
											className: 'no-coverage',
											type: 'icon',
											text: 'Infotainment System',
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Fuel System',
										},
									]}
								/>
						
						}
						legend={
							<div>
								<IconList
									size='small'
									listItems={[
										{
											className: 'legend__title text--body-2',
											text: 'Legend:',
										},
										{
											className: 'no-coverage',
											type: 'icon',
											text: 'No coverage',
										},
										{
											className: 'limited-coverage',
											type: 'icon',
											text: (
												<>
													<span className='limited-coverage-info'>
														Limited coverage{' '}
														<i
															className='bmw-icon bmw-icon--after data-icon'
															data-icon='information'
														></i>
													</span>
												</>
											),
										},
										{
											className: 'full-coverage',
											type: 'icon',
											text: 'Full coverage',
										},
									]}
								/>
							</div>
						}
					/> */}
        </div>
      </div>
      <div className="bundle-details-drawer__list bundle-details-drawer__list--card">
        {bundleDetails.financial_products.map((product, index) => (
          <IncentivesListCard
            key={index}
            image={product.image?.length ? product.image[0].location : ""}
            smallTitle={`Provided by ${product.provider_name}`}
            title={product.product_name}
            subTitle={product.description}
            price={
              <h2 className="text--body-focus-price-1">
                {product.monthly_impact
                  ? formatCurrency(product.monthly_impact)
                  : 0}
                <span className="text--body-1">/month</span>
              </h2>
            }
            totalPrice={`Total ${formatCurrency(product.fni_product_amount)}`}
            actionsArea={
              <>
                {product.video_url && (
                  <Button
                    htmlType="button"
                    text="Play video"
                    linkButton
                    withIcon
                    onClick={() => window.open(product.video_url, "_blank")}
                    dataIcon="play"
                  />
                )}
                {product.brochure_url && (
                  <Button
                    htmlType="button"
                    text="Download brochure"
                    linkButton
                    withIcon
                    onClick={() =>
                      window.open(formatUrl(product.brochure_url), "_blank")
                    }
                    dataIcon="download"
                  />
                )}
              </>
            }
          />
        ))}
      </div>
    </div>
  );
};
