import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "oidc-react";

import router from "./application-router";

const oidcConfig = {
  onSignIn: () => {
    // Redirect?
    // eslint-disable-next-line no-console
    console.log("Tamoor afzal");
  },
  authority: "https://auth-i.bmwgroup.com",
  clientId: "f454a4cb-14b7-4f0e-b557-d80bd15c6134",
  redirectUri: "https://dev.netsolapp.io/ccp/test-url",
  metadata: {
    issuer:
      "https://auth-i.bmwgroup.com/auth/oauth2/realms/root/realms/internetb2x",
    authorization_endpoint:
      "https://auth-i.bmwgroup.com:443/auth/oauth2/realms/root/realms/internetb2x/authorize",
    userinfo_endpoint:
      "https://auth-i.bmwgroup.com:443/auth/oauth2/realms/root/realms/internetb2x/userinfo",
    end_session_endpoint:
      "https://auth-i.bmwgroup.com:443/auth/oauth2/realms/root/realms/internetb2x/connect/endSession",
  },
};

const Application = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default Application;
