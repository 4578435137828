import { Button, Image, Modal } from "@src/components/atoms";
import DealVehicle from "../../../shared/assets/img/dr-start/deal-vehicle.png";
import Vehicle from "../../../shared/assets/img/dr-start/vehicle.png";
import "./back-to-shop.scss";

export const BackToShopModal = ({
  isModalOpen,
  setIsModalOpen,
  isAuthenticated = false,
  handleSaveAndExits = () => {},
  handleExitWithoutSaving = () => {},
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
    localStorage.setItem("redirectToDealerWebsite", "false");
  };

  return (
    <div
      className={`back-to-shop-modal ${isAuthenticated ? "authenticated" : ""}`}
    >
      <Modal open={isModalOpen} onClose={handleClose} closable>
        <div className="deal-modal__inner">
          <div className="deal-modal__img">
            <div className="d-none d-lg-block">
              <Image src={DealVehicle} alt="image" aspectRatio="1:1" />
            </div>
            <div className="d-lg-none">
              <Image src={Vehicle} alt="image" aspectRatio="2:1" />
            </div>
          </div>
          <div className="deal-modal__content">
            <div>
              {isAuthenticated ? (
                <>
                  <h4>
                    You are about to leave this platform and return to vehicle
                    selection.
                  </h4>
                  <p className="text--body-2">
                    Your progress is automatically saved, so you can easily pick
                    up where you left off when you return.
                  </p>
                </>
              ) : (
                <>
                  <h4>
                    Are you sure you want to leave this platform and return to
                    vehicle selection?
                  </h4>
                  <p className="text--body-2">
                    Leaving now will reset your changes. Sign in to save your
                    progress and unlock personalized dealer pricing.
                  </p>
                </>
              )}
            </div>
            <div className="deal-modal__cta">
              <Button
                type="primary"
                htmlType="button"
                text="Save & exit"
                fullWidth
                onClick={handleSaveAndExits ?? handleClose}
              />
              <Button
                type="default"
                htmlType="button"
                text="Exit without saving"
                onClick={handleExitWithoutSaving ?? handleClose}
                fullWidth
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
