import "./nothing-selected-list.scss";
import { ReactNode } from "react";

export const NothingSelectedList = ({
  title,
  actionArea,
}: {
  title?: string | ReactNode;
  actionArea?: ReactNode;
}) => {
  return (
    <>
      <div className="nothing-selceted-list-text">
        {title && <span className="text--body-1 title">{title}</span>}
        {actionArea && <>{actionArea}</>}
      </div>
    </>
  );
};
