import {
  PreChckoutLeftContent,
  PreChckoutRightContent,
} from "@src/components/molecules";
import "./pre-checkout.scss";
import { FeedbackLabel, IconList, Image } from "@src/components/atoms";
import preCheckoutRightImage from "../../../shared/assets/img/pre-checkout-right-car-image.png";

export const PreCheckout = () => {
  return (
    <>
      <div className="pre-checkout">
        <div className="pre-checkout__left">
          <PreChckoutLeftContent
            title={"You’re right on track to [purchasing/leasing] your BMW."}
            description={
              "Please note that your vehicle is not reserved until you begin the Checkout process. Please ensure you have the following items before you start Checkout:"
            }
            content={
              <>
                <div className="pre-checkout-left__list">
                  <IconList
                    size="small"
                    listItems={[
                      { text: "Current and past addresses" },
                      { text: "Current address" },
                      { text: "Employer information" },
                      { text: "Insurance information" },
                      { text: "Device with camera" },
                      { text: "Driver’s license" },
                      { text: "Bank account or credit card information" },
                    ]}
                  />
                </div>
              </>
            }
          />
        </div>
        <div className="pre-checkout__right">
          <PreChckoutRightContent
            title={<></>}
            content={
              <>
                <div className="pre-checkout__right__headline">
                  <h3>2024 330i xDrive Sedan</h3>
                  <span className="text--disclaimer">
                    VIN: WBA7E2C51JG741556
                  </span>
                </div>
                <div className="pre-checkout__right__list">
                  <IconList
                    size="small"
                    listItems={[
                      {
                        className: "lock-locked",
                        type: "icon",
                        text: (
                          <>
                            <span className="pre-checkout__right__list__help">
                              This vehicle is reserved for you for{" "}
                              <span className="text--cta-2">
                                [00:00:00]
                                <i
                                  className="bmw-icon bmw-icon--before data-icon"
                                  data-icon="help"
                                ></i>
                              </span>
                            </span>
                          </>
                        ),
                      },
                      {
                        className: "clock",
                        type: "icon",
                        text: "Induction System",
                      },
                    ]}
                  />
                  <FeedbackLabel
                    text={
                      <>
                        <span className="text--body-2">In production</span>
                      </>
                    }
                    type="success"
                  />
                </div>
              </>
            }
            image={
              <>
                <Image
                  src={preCheckoutRightImage}
                  alt="image"
                  aspectRatio="4:3"
                  containedImage
                />
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
