import "../../fl-pricing-drawer-content.scss";
import { AprPercentForm } from "../../fl-dealer-pricing/apr-percent-form/apr-percent-form.component";
import { PricingEstimatesSteps } from "@src/components/molecules";
import { FinanceType } from "@src/constants";
import { useGeneralContext } from "@src/context/general-context/general-context";

export const FinanceLeaseAuthenticatedUserInfo = ({ toggleContentApr }) => {
  const {
    contextState: { financetype },
  } = useGeneralContext();
  return (
    <>
      <div className="fl-pricing-drawer-wrap">
        <div className="fl-pricing-drawer-wrap--intro">
          <h3 className="text--h3">
            Get a more precise estimate for monthly payments.
          </h3>
        </div>
        <div className="pricing-estimates-steps-wrapper pricing-estimates-without-steps">
          <PricingEstimatesSteps
            title={
              financetype === FinanceType.LEASE
                ? "Get more Precise monthly payments"
                : "Get a more accurate APR %."
            }
            description={
              financetype === FinanceType.LEASE
                ? "Provide your address to verify your identity with TransUnion and get a more accurate monthly payment. This soft credit pull does not impact your credit score."
                : "Provide your address to verify your identity with TransUnion and get an accurate APR %. This soft credit pull does not impact your credit score."
            }
            content={
              <div className="fl-pricing-drawer-wrap--inner">
                <AprPercentForm toggleContentApr={toggleContentApr} />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
