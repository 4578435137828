import { useState } from "react";
import { Button, Checkbox } from "@src/components/atoms";
import "./payment-method.scss";
import {
  AddPaymentMethod,
  PaymentAmountAccordian,
  PaymentMethodAmount,
} from "@src/components/molecules";
import { PaymentMethodReview } from "./payment-method-review";

export const PaymentMethod = () => {
  const [isPaymentAdded, setIsPaymentAdded] = useState(false);

  return (
    <div className="offshoots-journey-payment-method">
      <div className="payment-method__title">
        <h3>Pay amount Due-at-Signing.</h3>
        <p className="text--body-2">
          Make a full or partial payment online today, or pay at your BMW
          dealer.
        </p>
      </div>
      <div className="payment-method__body">
        <PaymentAmountAccordian />
        <div className="payment-method__card">
          <div className="payment-method__card-title">
            <h5>Payment method(s) and amount</h5>
            <p className="text--body-2">
              Add more than one payment method if you prefer to split payments
              across accounts.
            </p>
          </div>

          {!isPaymentAdded ? (
            <AddPaymentMethod onAdd={() => setIsPaymentAdded(true)} />
          ) : (
            <PaymentMethodAmount />
          )}
        </div>
        <Checkbox
          id={"idChk"}
          label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          htmlFor="idChk"
          isCompact
        />
        {/* <PaymentMethodReview /> */}
      </div>
      <div className="payment-method__cta">
        <Button
          type="primary"
          htmlType={"button"}
          text="Submit payment"
          disabled
        />
      </div>
    </div>
  );
};
