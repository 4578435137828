import { Button, Checkbox, MessageAlert } from "@src/components/atoms";
import "./one-trust-disclaimer.scss";
import { toggleDisclaimer } from "@src/context/app-actions";
import { useAppContext } from "@src/context/app-context";
import { DisclaimersType } from "@src/constants/enum";

export const OneTrustlDisclaimer = () => {
  const { dispatch: appDispatch } = useAppContext();
  const handleCloseIconClick = () => {
    appDispatch(
      toggleDisclaimer({
        name: DisclaimersType.INCENTIVE_DISCOUNT,
        isOpen: false,
        openAt: new Date(),
      })
    );
    localStorage.setItem("incentives-discounts-disclaimer", "false");
  };
  return (
    <div className="one-trust-disclaimer-wrap">
      <MessageAlert
        showCloseButton={true}
        closeOnClick={true}
        titleIcon={false}
        onCloseIconClick={handleCloseIconClick}
        contentDetail={
          <p className="text--body-2">
            BMW uses cookies to optimize your website experience, analyze our
            website traffic and performance, and advertise to you online. You
            may review{" "}
            <a
              href="/"
              rel="noopener noreferrer"
              className="text-underline fw-normal text-dark "
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              our privacy policy
            </a>{" "}
            for more information or{" "}
            <a
              href="/"
              rel="noopener noreferrer"
              className="text-underline fw-normal text-dark "
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              opt in of the sale or sharing of your personal information
            </a>
          </p>
        }
      />
    </div>
  );
};
