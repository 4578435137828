import { useAppContext } from "@src/context/app-context";
import { useCallback, useEffect, useRef } from "react";

import {
  updateStates,
  updateCustomer,
  loadDefaultLenderConfigurations,
  updateLenders,
  updateDealerProfile,
  storeSetupData,
  setOrderInfo,
  updateLogo,
  updateDisclaimers,
  updateSocure,
  updateTransunion,
  updateScore,
  updateInfoerror,
} from "@src/context/app-actions";
import { FinanceType } from "@src/constants";
import {
  collectionIntoSelectOptions,
  filterCustomerData,
  getDisclaimerByType,
  IntegrationProvider,
  LenderIntegrationType,
} from "@src/helpers";
import {
  useGetStates,
  useGetDefaultLender,
  useGetByDealerId,
  useGetCustomerByEmail,
  useGetLenders,
  useGetCustomerFinancialProfile,
  useGetSetupData,
  useCreateCustomer,
  useIntegrationProvider,
  useGetAllPrograms,
  useGetCustomerCreditCheck,
} from "@src/hooks";
import {
  useGetDealerFees,
  useGetDisclaimerByName,
  useGetTheme,
} from "./dealer";
import { decodeToken } from "react-jwt";
import { useComputedQuotation } from "./useCalculateQuatation";
import { isEmpty } from "lodash";
import { getAllProgramPayload } from "@src/helpers/payloadHelper";

export const useFetchAndStore = (queryParams) => {
  const incentivesDisclaimer = useRef<{ incentivesDiscountsDisclaimer: any }>({
    incentivesDiscountsDisclaimer: {},
  });

  const {
    state: {
      user: { customer },
      tenant: { companyCode, tenantId },
      lender: { defaultLender },
      configuration: { setupData },
      dealer,
      order,
      statesOriginalRes,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { disclaimer, dealer_address, dealer_code, dealer_name } = dealer;
  const { finance_type, dealer_id, reference_number } = order;
  const { mutate: createCustomerByEmail } = useCreateCustomer();
  const { mutate: getCustomerByEmail } = useGetCustomerByEmail();
  const { mutateAsync: getDisclaimerByName } = useGetDisclaimerByName();
  const { mutate: getDefaultLender } = useGetDefaultLender();
  const { mutate: creditCheck } = useGetCustomerCreditCheck();
  const { mutate: getLenderData } = useGetLenders();
  const { mutate: getDealerById } = useGetByDealerId();
  const { mutateAsync: getDealerFees } = useGetDealerFees();
  const { mutate: dealerIntegration } = useIntegrationProvider();
  const { mutateAsync: getProgram } = useGetAllPrograms();

  const { mutate: getCustomerFinancialProfile } =
    useGetCustomerFinancialProfile();
  const { mutateAsync: getStates } = useGetStates();

  const { mutateAsync: getSetupData } = useGetSetupData();
  const { mutate: getTheme } = useGetTheme();
  const { setOrderValues } = useComputedQuotation();
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const decodedData = decodeToken<{ email: string }>(
    userCredentials.access_token
  ) as any;

  useEffect(() => {
    getStates(
      {},
      {
        onSuccess: (states) => {
          appDispatch(updateStates(states));
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
    getDisclaimerByName(
      { disclaimerName: "incentives-discounts-disclaimer" },
      {
        onSuccess: (response) => {
          if (Array.isArray(response) && response[0]) {
            incentivesDisclaimer.current = getDisclaimerByType(response);
            appDispatch(
              updateDisclaimers({
                ...disclaimer,
                ...getDisclaimerByType(response),
              })
            );
          }
        },
      }
    );
    getSetupData("", {
      onSuccess: async (setupData) => {
        if (order?.credit_rating) {
          appDispatch(
            setOrderInfo({
              ...order,
              credit_score: setupData?.["credit-ratings"]?.find(
                (item) => item.description === order?.credit_rating
              )?.score_from,
            })
          );
        }

        appDispatch(storeSetupData(setupData));
      },
    });
  }, []);

  useEffect(() => {
    if (tenantId) {
      getDefaultLender(
        {
          company_id: tenantId,
        },
        {
          onSuccess: (data) => {
            appDispatch(loadDefaultLenderConfigurations(data));
          },
        }
      );
    }
  }, [tenantId]);

  useEffect(() => {
    const fetchInfo = async () => {
      getDealerById(
        {
          company_id: tenantId,
          dealerId: dealer_id,
        },
        {
          onSuccess: (dealerProfile) => {
            const { dealer_code, dealer_email } = dealerProfile;

            dealerIntegration(
              {
                companyId: tenantId.toString(),
                dealerCode: dealer_code,
                integrationType: IntegrationProvider.SOCURE,
                providerName: LenderIntegrationType.FRAUD_IDENTIFICATION,
              },
              {
                onSuccess: (Integration: any) => {
                  appDispatch(updateSocure(Integration?.is_active));
                },
              }
            );
            dealerIntegration(
              {
                companyId: tenantId.toString(),
                dealerCode: dealer_code,
                integrationType: IntegrationProvider.TRANSUNION,
                providerName: LenderIntegrationType.CREDIT_BEREAU,
              },
              {
                onSuccess: (Integration: any) => {
                  appDispatch(updateTransunion(Integration?.is_active));
                },
                onError: (err) => {
                  appDispatch(updateTransunion(false));
                },
              }
            );

            getTheme(
              { email: dealer_email },
              {
                onSuccess: (response) => {
                  const { logo } = response;
                  appDispatch(updateLogo(logo?.location));
                },
              }
            );

            appDispatch(updateDealerProfile(dealerProfile));

            if (finance_type == FinanceType.FINANCE) {
              getLenderData(
                {
                  dealerCode: dealer_code,
                  finance_type: finance_type,
                },
                {
                  onSuccess: (response) => {
                    appDispatch(updateLenders(response));
                  },
                }
              );
            }
          },
        }
      );
    };
    if (dealer_id && tenantId) {
      fetchInfo();
    }
  }, [dealer_id, tenantId]);

  useEffect(() => {
    if (
      statesOriginalRes.length &&
      dealer_address &&
      dealer_code &&
      order.selling_price
    ) {
      const stateCode = statesOriginalRes.find(
        (state: any) => state.id === dealer_address?.state_id
      ) || { code: "" };

      if (stateCode.code) {
        getDealerFees(
          {
            dealerCode: dealer_code,
            stateCode: stateCode.code,
          },
          {
            onSuccess(dealerFees) {
              appDispatch(
                setOrderInfo({
                  ...order,
                  order_fees: dealerFees,
                })
              );
            },
            onError(error) {
              console.error("Error getting dealer Fees", error);
            },
          }
        );
      }
    }
  }, [statesOriginalRes, dealer_address, order.selling_price]);

  useEffect(() => {
    if (decodedData?.email && dealer_code) {
      getCustomerByEmail(
        { email: decodedData.email },
        {
          onSuccess: async (customerInfo) => {
            const filterData = filterCustomerData(customerInfo);

            creditCheck(
              {
                customerReferenceId: customerInfo.reference_id,
                dealCode: dealer_code,
              },
              {
                onSuccess: async (response: any) => {
                  if (response?.transunion?.risk_model) {
                    const matchedRange = setupData["credit_rating"]?.find(
                      (opt) => {
                        const rangeFrom = opt.score_from;
                        const rangeTo = opt.score_to;
                        return (
                          response.transunion.risk_model.score > rangeFrom &&
                          response.transunion.risk_model.score < rangeTo
                        );
                      }
                    );
                    appDispatch(updateInfoerror(!matchedRange));
                    appDispatch(updateScore(!!matchedRange));

                    await appDispatch(
                      setOrderInfo({
                        ...order,
                        credit_rating: matchedRange?.description
                          ? matchedRange.description
                          : "",
                        credit_score: response.transunion.risk_model.score,
                      })
                    );
                  }
                },
              }
            );
            if (filterData.customer_addresses) {
              setOrderValues({
                ...order,
                credit_score: order.credit_score
                  ? order.credit_score
                  : customerInfo.credit_score,
                customerV2Address: !!filterData.customer_addresses.garaging
                  ?.entity_type
                  ? {
                      ...filterData.customer_addresses.mailing,
                    }
                  : { ...filterData.customer_addresses.garaging },
              });
            } else {
              setOrderValues({
                ...order,
                credit_score: order.credit_score
                  ? order.credit_score
                  : customerInfo.credit_score,
                customerV2Address: dealer_address,
              });
            }

            appDispatch(
              updateCustomer({
                ...customer,
                ...filterData,
                fetchedData: true,
              })
            );
          },
          onError: (error: { detail: string }) => {
            if (error.detail === "customer record not found") {
              createCustomerByEmail(
                { email: decodedData.email, dealer_id: dealer_id },
                {
                  onSuccess: (customerInfo) => {
                    const filterData = filterCustomerData(customerInfo);
                    appDispatch(
                      updateCustomer({
                        ...customer,
                        ...filterData,
                        fetchedData: true,
                      })
                    );
                    setOrderValues({
                      ...order,
                      customerV2Address: dealer_address,
                    });
                  },
                  onError: (error) => {
                    console.error("Error fetching customer:", error);
                  },
                }
              );
            }
            console.error("Error:", error);
          },
        }
      );
    }
  }, [decodedData?.email && dealer_code]);

  useEffect(() => {
    if (customer.reference_id) {
      getCustomerFinancialProfile(
        {
          reference_id: customer.reference_id,
          dealer_code: dealer_code,
        },
        {
          onSuccess: ({
            bankruptcy,
            monthly_payment,
            residence,
            reference_id,
          }) => {
            appDispatch(
              updateCustomer({
                ...customer,
                financial_profile: {
                  bankruptcy,
                  monthly_payment,
                  residence,
                  reference_id,
                },
              })
            );
          },
          onError: (error) => {
            console.error("Error fetching customer:", error);
          },
        }
      );
      fetchInitialDisclaimer();
    }
  }, [customer.reference_id && dealer_code]);

  const fetchInitialDisclaimer = useCallback(async () => {
    let disclaimerToStore = {
      ...disclaimer,
      ...incentivesDisclaimer.current,
    };
    const customerDisclaimers =
      Array.isArray(customer.disclaimer_info) &&
      customer.disclaimer_info.length > 0
        ? customer.disclaimer_info.filter(
            (disclaimer_need) =>
              disclaimer_need.order_reference_id === reference_number
          )
        : [];

    const customerDisclaimerByType = customerDisclaimers.length
      ? getDisclaimerByType(customerDisclaimers, dealer_name)
      : {};

    if (!customerDisclaimerByType?.lenderSubmission) {
      await getDisclaimerByName(
        { disclaimerName: "lender-submission" },
        {
          onSuccess: (response: any) => {
            if (Array.isArray(response) && response[0]) {
              const lenderDisclaimer = getDisclaimerByType(
                response,
                dealer_name
              );
              disclaimerToStore = {
                ...disclaimerToStore,
                ...lenderDisclaimer,
              };
            }
          },
        }
      );
    }
    if (!customerDisclaimerByType?.customerCreditApplicationDisclaimer) {
      await getDisclaimerByName(
        {
          disclaimerName: "customer-credit-application-disclaimer",
        },
        {
          onSuccess: (response: any) => {
            if (Array.isArray(response) && response[0]) {
              const creditAppDisclaimer = getDisclaimerByType(
                response,
                dealer_name
              );
              disclaimerToStore = {
                ...disclaimerToStore,
                ...creditAppDisclaimer,
              };
            }
          },
        }
      );
    }
    if (!customerDisclaimerByType?.acceptanceOffer) {
      await getDisclaimerByName(
        { disclaimerName: "acceptance-offer" },
        {
          onSuccess: (response: any) => {
            if (Array.isArray(response) && response[0]) {
              const acceptOfferDisclaimer = getDisclaimerByType(
                response,
                dealer_name
              );
              disclaimerToStore = {
                ...disclaimerToStore,
                ...acceptOfferDisclaimer,
              };
            }
          },
        }
      );
    }

    appDispatch(
      updateDisclaimers({
        ...disclaimer,
        ...disclaimerToStore,
        ...customerDisclaimerByType,
      })
    );
  }, [dealer_name]);

  useEffect(() => {
    if (
      order.order_asset.vin &&
      order.order_fees &&
      dealer_code &&
      !isEmpty(setupData) &&
      defaultLender.external_code &&
      !isEmpty(queryParams) &&
      queryParams.vin &&
      queryParams.dealerCode
    ) {
      const defaultTermLease = setupData["contract-terms"]?.find(
        (term: any) => term?.id === dealer.default_term_lease_id
      )?.term;
      const defaultTermFinance = setupData["contract-terms"]?.find(
        (term: any) => term?.id === dealer.default_term_finance_id
      )?.term;
      const defaultMiles = setupData["contract-allowed-annum-mileage"]?.find(
        (mileage: any) => mileage?.id === dealer.default_mileage_id
      )?.value;
      const defaultCreditRating = setupData["credit-ratings"]?.[0];
      const defaultFinanceType = "Finance";

      getProgram(
        {
          ...getAllProgramPayload(
            order.order_asset,
            companyCode,
            defaultLender.external_code,
            queryParams.dealerCode,
            defaultFinanceType,
            defaultFinanceType === "Finance"
              ? defaultTermFinance
              : defaultTermLease,
            defaultMiles,
            defaultCreditRating.description,
            defaultCreditRating.score_from
          ),
          tier: "",
        },
        {
          onSuccess: (programRes: any) => {
            if (!isEmpty(programRes.detail) || programRes.length === 0) {
              console.error("ERROR: Sorry No Program Found");
              return;
            } else {
              setOrderValues({
                ...order,
                credit_score: order.credit_score
                  ? order.credit_score
                  : defaultCreditRating.score_from,
                credit_rating: defaultCreditRating.description,
                contract_term:
                  defaultFinanceType === "Finance"
                    ? defaultTermFinance
                    : defaultTermLease,
                customerV2Address: dealer.dealer_address,
                apr: programRes.final_customer_rate,
                down_payment:
                  programRes.downpayment_chart[0]?.default_down_payment,
                rental_mode: programRes.rental_mode,
                rv_balloon_percentage: programRes.assetRvAmount,
                finance_type: defaultFinanceType,
                annual_usage: defaultMiles,
                downpayment_chart: programRes.downpayment_chart,
              });
            }
          },
          onError: (err) => {
            console.error("err", err);
          },
        }
      );
    }
  }, [
    order.order_asset.vin,
    order.order_fees,
    defaultLender.external_code,
    setupData,
    dealer_code,
  ]);
};
