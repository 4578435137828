import { useAppContext } from "@src/context/app-context";
import { useEffect, useRef } from "react";
import { useGetOrderInfo } from "./order";

import {
  setOrderInfo,
  updateDealerProfile,
  updatePreInstalled,
} from "@src/context/app-actions";
import { useLocation } from "react-router-dom";
import {
  extractVehicleOrderDetailsFromURL,
  removeNullValues,
} from "@src/helpers/appHelper";
import { isEmpty } from "lodash";
import { useGetVehicleDetail } from "./vehicle";
import { useGetDealerPreference } from "./dealer";

export const useUrlParamsForOrderInfo = (userCredentials, queryParams) => {
  const {
    state: {
      order,
      tenant: { company_id },
    },
    dispatch: appDispatch,
  } = useAppContext();

  const { mutate: getOrderInfo } = useGetOrderInfo();
  const { mutateAsync: getVehicleInfo } = useGetVehicleDetail();
  const { mutate: getDealerPreference } = useGetDealerPreference();

  const initialFetch = useRef(false);
  // Access the current location object (URL)
  const { dealRefNumber } = extractVehicleOrderDetailsFromURL(
    useLocation().pathname
  );

  const fetchVehicleAndDealerData = async () => {
    let newOrder = { ...order };
    await getVehicleInfo(
      {
        vin: queryParams.vin,
        company_id,
        dealerCode: queryParams.dealerCode,
      },
      {
        onSuccess: async ({ vehicleDetail, preInstalledAddOns }) => {
          newOrder = {
            ...newOrder,
            order_options: Array.isArray(order.order_options)
              ? [...order.order_options, ...preInstalledAddOns]
              : [...preInstalledAddOns],
            order_asset: {
              ...vehicleDetail,
              unit_price: vehicleDetail.internet_price,
            },
            selling_price: vehicleDetail.internet_price,
          };
          appDispatch(updatePreInstalled(preInstalledAddOns));
          appDispatch(setOrderInfo({ ...order, ...newOrder }));
        },
        onError: (error) => {
          console.error("error getting vehicle information:", error);
        },
      }
    );

    await getDealerPreference(
      {
        dealerCode: queryParams.dealerCode,
        companyId: company_id.toString(),
      },
      {
        onSuccess: (dealerPref) => {
          appDispatch(
            updateDealerProfile({
              default_term_lease_id: dealerPref.default_term_lease_id,
              id: dealerPref.dealer_id,
              default_term_finance_id: dealerPref.default_term_finance_id,
              default_mileage_id: dealerPref.default_mileage_id,
            })
          );
          newOrder = { ...newOrder, dealer_id: dealerPref.dealer_id };

          appDispatch(setOrderInfo({ ...order, ...newOrder }));
        },
        onError: (error) => {
          console.error("error getting dealer preference:", error);
        },
      }
    );
  };

  useEffect(() => {
    if (!initialFetch.current && company_id && queryParams?.vin) {
      fetchVehicleAndDealerData();
      initialFetch.current = true;
    }
  }, [company_id]);

  useEffect(() => {
    if (
      !isEmpty(userCredentials) &&
      userCredentials.access_token &&
      !initialFetch.current &&
      dealRefNumber
    ) {
      initialFetch.current = true;
      // Extract VIN and order number from the current URL

      if (!dealRefNumber) {
        return; // Do nothing if dealRefNumber is not present in the URL
      }

      // Check if order info for the current dealRefNumber is already in the state
      if (order && order.reference_number === dealRefNumber) {
        return; // Skip the API call if order info is already available
      }

      getOrderInfo(dealRefNumber, {
        onSuccess: (response) => {
          appDispatch(
            setOrderInfo({ ...order, ...removeNullValues(response) })
          );
        },
        onError: (error) => {
          console.error("error getting order information:", error);
        },
      });
    }
  }, [location.pathname, userCredentials]);
};
