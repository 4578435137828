import { Button, FileUpload } from "@src/components/atoms";

export const UploadDocuments = () => {
  return (
    <FileUpload
      name="uploadFile"
      action="https://example.com/upload"
      onChange={() => {}}
      onDrop={() => {}}
      multiple={true}
      accept="image/*"
      fileUploadTitle="Click here to upload your file, or drag and drop."
      fileUploadSubTitle="Supported formats: PDF, PNG, JPEG (10 MB limit each)"
      fileUpoadIcon="upload"
      doneActionArea={(file) => (
        <Button
          htmlType="button"
          linkButton
          withIcon
          dataIcon="trash_can"
          isIconEnd
          text="Remove"
          onClick={() => {}}
        />
      )}
      loadingActionArea={(file) => (
        <Button
          htmlType="button"
          linkButton
          withIcon
          dataIcon="arrow_chevron_right"
          isIconEnd
          text="Remove"
          onClick={() => {}}
        />
      )}
      errorActionArea={(file) => (
        <Button
          htmlType="button"
          linkButton
          withIcon
          dataIcon="trash_can"
          isIconEnd
          text="Remove"
          onClick={() => {}}
        />
      )}
      filePreviewIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M5.83184 4.08187H13.125C13.8614 4.08187 14.3112 4.24151 14.6044 4.58056C14.9195 4.94495 15.1681 5.64423 15.1681 6.99999V11.0819H19.25C20.6058 11.0819 21.305 11.3306 21.6695 11.6456C22.0056 11.9363 22.1654 12.381 22.1681 13.1065V23.9181H15.1375V25.0819H23.3319V13.0379C23.3321 12.0239 23.3322 11.2735 23.078 10.5746C22.8113 9.84113 22.2884 9.21559 21.4114 8.33854L17.9114 4.83854C17.0343 3.96148 16.4088 3.4386 15.6754 3.1719C14.9765 2.91775 14.2261 2.9179 13.2121 2.9181L4.66809 2.91812V12.8625H5.83184V4.08187ZM20.5885 9.16143C21.051 9.62395 21.3674 9.9729 21.588 10.2686C20.9719 10.0271 20.1953 9.91813 19.25 9.91813H16.3319V6.99999C16.3319 6.05477 16.2229 5.27809 15.9814 4.66202C16.2771 4.88262 16.626 5.19893 17.0885 5.66144L20.5885 9.16143Z"
            fill="#262626"
          />
          <path
            d="M15.75 14.8183L7.34878 22.75L2.625 18.2903L3.49178 17.4719L7.34878 21.1133L14.8832 14L15.75 14.8183Z"
            fill="#262626"
          />
        </svg>
      }
    />
  );
};
