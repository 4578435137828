import Vehicle from "../../../../shared/assets/img/dr-start/vehicle.png";
import { Button, Image } from "@src/components/atoms";
import "./banner-section.scss";

export const BannerSection = () => {
  return (
    <div className="start-verification-hero-section">
      <div className="start-verification-hero__inner start-verification-spacing">
        <div className="start-verification-hero__content">
          <h2 className="text--h2">
            <span>Welcome!</span>
            Let’s verify your identity.
          </h2>
        </div>
        <div className="start-verification-hero__steps">
          <p className="text--subsection-2 start-verification-hero__steps--subtitle">
            Have your driver's license on hand. We’ll verify your ID in 5 steps:
          </p>
          <div className="text--subsection-2 start-verification-hero__steps--description">
            <div
              className="input-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="highlight_filled"
            />
            <p className="text--disclaimer text-secondary">
              Please note that your selfie image will not be stored.
            </p>
          </div>
          <div className="start-verification-hero__steps--list">
            <ul>
              <li>
                <p className="text--body-2">Consent </p>
              </li>
              <li>
                <p className="text--body-2">Front of ID </p>
              </li>
              <li>
                <p className="text--body-2">Back of ID </p>
              </li>
              <li>
                <p className="text--body-2">Selfie </p>
              </li>
              <li>
                <p className="text--body-2">Review & submit </p>
              </li>
            </ul>
          </div>
          <div className="start-verification-hero__bottom verification-hero__bottom--full-width">
            <Button
              htmlType={"button"}
              text="Start verification"
              type="primary"
              fixed
              onDark
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};
