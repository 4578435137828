export const EmploymentType = {
  Employed: "Employed",
  Self_Employed: "Self Employed",
  Retired: "Retired",
  Not_Employed: "Not Employed",
};

export const FinanceType = {
  FINANCE: "Finance",
  LEASE: "Lease",
  CASH: "Cash",
};

export enum FeeTypes {
  INCEPTION = "Inception",
  EOT = "End of Term",
}

export enum ResidenceType {
  Mortgage = "Mortgage",
  Renting = "Renting",
  Personal_Ownership = "Personal Ownership",
  With_Relatives = "With Relatives",
}

export enum DisclaimersType {
  INCENTIVE_DISCOUNT = "Incentive_Discount",
}

export const ShowBackButtonOnGivenURLs = [
  "incentives-and-discounts",
  "checkout",
  "finance-offer",
];
