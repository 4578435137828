import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import "./accessories.scss";
import {
  AccessoriesListCardDrawerContent,
  ProductDetailsDrawerContentAccessories,
  RightRail,
  RightRailFixed,
} from "@src/components/molecules";
import { Button, Checkbox, Drawer } from "@src/components/atoms";

import { InsentivesAndDiscountsDrawerFooter } from "@src/components/molecules/incentives-and-discounts/insentives-and-discounts-drawer-footer/insentives-and-discounts-drawer-footer.component";
import { useEffect, useState } from "react";
import { AccessoriesListCard } from "@src/components/molecules/accessories/accessories-list-card/accessories-list-card";
import { MultiSwatchCard } from "@src/components/molecules/multi-swatch-card/multi-swatch-card";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";
import { useUpdateDeal } from "@src/hooks";
import {
  generatePayload,
  getLinkClickPayload,
  getOrderOptionsPayloadMapping,
} from "@src/helpers";
import Placeholder from "../../../../shared/assets/img/placeholder.png";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";
import { FinanceLeasePricingDrawerContent } from "@src/components/molecules/incentives-and-discounts/fl-pricing-drawer-content/fl-pricing-drawer-content.component";

export const Accessories = () => {
  const navigate = useNavigate();
  const { addEvent } = useAdobeDatalayerContext();
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isDrawerVisiblePre, setDrawerVisiblePre] = useState(false);

  const {
    state: {
      order,
      vehicle: { addOns, preInstalled },
      dealer: { id, dealer_name },
      user: { isUserLoggedIn },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { setOrderValues } = useComputedQuotation();
  const { order_options } = order;
  const [optionSelect, setoptionSelect] = useState<any>();
  const [isDrawerEnable, setDrawerenable] = useState(false);
  const { mutate: updateOrder } = useUpdateDeal();
  const { dealRefNumber } = useParams();
  const optionalAddonsCount = order_options.filter(
    (accessory) => accessory.installation_mode === "Optional"
  ).length;

  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const toggleSignIn = () => {
    addEvent("linkClick", getLinkClickPayload("sign in to save"));
    setDrawerenable(!isDrawerEnable);
  };
  const windowLocation = useLocation();
  const saveOrder = () => {
    addEvent("linkClick", getLinkClickPayload("continue"));

    if (dealRefNumber) {
      updateOrder(
        { ...order, identifier: order.identifier },
        {
          onSuccess: () => {
            navigate(`/review/deal/${dealRefNumber}`);
          },
        }
      );
    } else {
      navigate(`/review${windowLocation.search}`);
    }
  };

  const handleAccessories = (options, selected) => {
    if (selected) {
      setOrderValues({
        ...order,
        order_options: Array.isArray(order.order_options)
          ? [...order.order_options, options]
          : [...options],
      });
    } else {
      setOrderValues({
        ...order,
        order_options: order.order_options.filter(
          (r) => r.option_id !== options.option_id
        ),
      });
    }
  };
  const selected = order.order_options.some(
    (optionOrder) => optionOrder.option_id === optionSelect?.option_id
  );
  const handleAccessoriesCLick = (options) => {
    const selected = order.order_options.some(
      (optionOrder) => optionOrder.option_id === options.option_id
    );
    if (!selected) {
      setOrderValues({
        ...order,
        order_options: Array.isArray(order.order_options)
          ? [...order.order_options, options]
          : [...options],
      });
    }
  };

  // Function to toggle the drawer visibility
  const toggleDrawer = (optionSelects?) => {
    if (optionSelects) setoptionSelect(optionSelects);
    setDrawerVisible(!isDrawerVisible);
  };

  const toggleDrawerPre = () => {
    setDrawerVisiblePre(!isDrawerVisiblePre);
  };

  const closeDrawer = (tagName?: string) => {
    if (tagName) addEvent("linkClick", getLinkClickPayload(tagName));
    setDrawerVisible(false);
  };

  const closeDrawerPre = () => {
    setDrawerVisiblePre(false);
  };

  useEffect(() => {
    if (order?.contract_term && id) {
      addEvent(
        "pageLoad",
        generatePayload(
          "bmwusaretail:buildyourdeal:accessories",
          "accessories",
          isUserLoggedIn,
          { dealer_name, id },
          order
        )
      );
    }
  }, [order, dealer_name, id, isUserLoggedIn]);

  return (
    <>
      <div className="incentives-and-discounts-wrap">
        <div className="bmw-container">
          <Row className="justify-content" gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={16}>
              <div className="accessories-left-content">
                <h3 className="incentives-discounts--title text--h3">
                  Vehicle accessories & add-ons
                </h3>

                <div className="accessories__list-wrap ">
                  <p className="accessories__subtitle text--subsection-2">
                    Add [port-installed] accessories.
                  </p>
                  <Row
                    gutter={[
                      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
                      { xs: 24, sm: 24, md: 24, lg: 32, xl: 40, xxl: 40 },
                    ]}
                  >
                    {addOns
                      .filter(
                        (accessory) =>
                          accessory.installation_mode === "Port-Installed"
                      )
                      .map((accessory: any) => {
                        const selected = order.order_options.some(
                          (optionOrder) =>
                            optionOrder.option_id === accessory.option_id
                        );
                        return (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            key={accessory.product_name}
                          >
                            <AccessoriesListCard
                              image={
                                accessory.image.length
                                  ? accessory.image[0].location
                                  : Placeholder
                              }
                              subTitle="BMW M Performance Interior Trims in Carbon Fiber/ Alcantara"
                              priceNode={
                                <h2 className="text--body-focus-price-1">
                                  {formatCurrency(accessory.price)}
                                  <span className="text--body-1">/month</span>
                                </h2>
                              }
                              className=""
                              hasCheckbox={
                                <Checkbox
                                  id="incentives-discounts--chk1"
                                  htmlFor="incentives-discounts--chk1"
                                  checked={selected}
                                  onChange={(e) => {
                                    handleAccessories(accessory, e);
                                  }}
                                />
                              }
                              actionsNode={
                                <Button
                                  htmlType="button"
                                  text="Full details"
                                  linkButton
                                  onClick={() => {
                                    addEvent(
                                      "linkClick",
                                      getLinkClickPayload("full details")
                                    );
                                    toggleDrawer();
                                  }}
                                />
                              }
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>

                <div className="accessories__list-wrap accessories__list-wrap--spacing">
                  <p className="accessories__subtitle text--subsection-2 ">
                    Add more accessories provided by {dealer_name}.
                  </p>
                  <Row
                    gutter={[
                      16,
                      { xs: 24, sm: 24, md: 24, lg: 32, xl: 40, xxl: 40 },
                    ]}
                  >
                    {addOns
                      .filter(
                        (accessory) =>
                          accessory.installation_mode === "Optional"
                      )
                      .map((accessory: any) => {
                        const selected = order.order_options.some(
                          (optionOrder) =>
                            optionOrder.option_id === accessory.option_id
                        );
                        return (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            key={accessory.product_name}
                          >
                            <AccessoriesListCard
                              image={
                                accessory.image.length
                                  ? accessory.image[0].location
                                  : Placeholder
                              }
                              subTitle={accessory.product_name}
                              priceNode={
                                <h2 className="text--body-focus-price-1">
                                  {formatCurrency(accessory.monthly_impact)}
                                  <span className="text--body-1">/month</span>
                                </h2>
                              }
                              totalPrice={formatCurrency(accessory.price)}
                              className=""
                              hasCheckbox={
                                <Checkbox
                                  id="incentives-discounts--chk1"
                                  htmlFor="incentives-discounts--chk1"
                                  checked={selected}
                                  onChange={(e) => {
                                    handleAccessories(accessory, e);
                                  }}
                                />
                              }
                              actionsNode={
                                <Button
                                  htmlType="button"
                                  text="Full details"
                                  linkButton
                                  onClick={() => {
                                    addEvent(
                                      "linkClick",
                                      getLinkClickPayload("full details")
                                    );
                                    toggleDrawer(accessory);
                                  }}
                                />
                              }
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>
                {preInstalled?.length > 0 && (
                  <div className="multi-swatch-card-main accessories__list-wrap--spacing ">
                    <p className="accessories__subtitle text--subsection-2 accessories__list-wrap--border-bottom-none ">
                      Pre-installed by {dealer_name}.
                    </p>

                    <MultiSwatchCard toggleDrawerPre={toggleDrawerPre} />
                  </div>
                )}

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <div>
                      {optionalAddonsCount ? (
                        <Button
                          htmlType={"button"}
                          text="Continue"
                          type="primary"
                          fixed
                          fullWidth
                          onClick={() => saveOrder()}
                        />
                      ) : (
                        <Button
                          htmlType={"button"}
                          text="Continue without accessories"
                          type="primary"
                          fullWidth
                          onClick={() => {
                            if (dealRefNumber) {
                              navigate(`/review/deal/${dealRefNumber}`);
                            } else {
                              navigate(`/review${windowLocation.search}`);
                            }
                          }}
                        />
                      )}
                    </div>
                    {!userCredentials.access_token && (
                      <div>
                        <Button
                          htmlType={"button"}
                          text="Sign in to save"
                          fullWidth
                          onClick={toggleSignIn}
                        />
                      </div>
                    )}
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <RightRail hasFinance />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <RightRailFixed hasFinance />
      </div>
      <div className="accessories-drawer">
        <Drawer
          width={336}
          open={isDrawerEnable}
          onClose={() => closeDrawer()}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <FinanceLeasePricingDrawerContent />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerVisible}
          onClose={() => closeDrawer("exit accessories sheet")}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <AccessoriesListCardDrawerContent accessory={optionSelect} />
              {/* <ProductDetailsDrawerContentAccessories /> */}
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      {formatCurrency(optionSelect?.monthly_impact)}
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                    <small className="text--disclaimer text-muted">
                      Total: ${optionSelect?.price}
                    </small>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    disabled={selected}
                    onClick={() => {
                      addEvent("linkClick", getLinkClickPayload("add to deal"));
                      handleAccessoriesCLick(optionSelect);
                      toggleDrawer();
                    }}
                    type="default"
                  />
                }
              />
            </>
          }
        />
        <Drawer
          width={336}
          open={isDrawerVisiblePre}
          onClose={closeDrawerPre}
          maskClosable={true}
          keyboard={true}
          content={
            <>
              <ProductDetailsDrawerContentAccessories />
            </>
          }
        />
      </div>
    </>
  );
};
