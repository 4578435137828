import { createContext, useContext, useState } from "react";

export const reviewContext = createContext({});
export const useReviewContext = (): any => useContext(reviewContext);

export const ReviewContextProvider = ({ children }): any => {
  const [incentiveAdded, setIncentiveAdded] = useState(false);
  const [isTradeInAdded, setIsTradeInAdded] = useState(false);
  const [isProtectionProductAdded, setIsProtectionProductAdded] =
    useState(false);
  const [isAccessoriesAdded, setIsAccessoriesAdded] = useState(false);
  const [isTradeInError, setIsTradeInError] = useState(false); // pass prop : trade_in_error = true || false

  return (
    <reviewContext.Provider
      value={{
        incentiveAdded,
        setIncentiveAdded,
        isTradeInAdded,
        setIsTradeInAdded,
        isProtectionProductAdded,
        setIsProtectionProductAdded,
        isAccessoriesAdded,
        setIsAccessoriesAdded,
        isTradeInError,
        setIsTradeInError,
      }}
    >
      {children}
    </reviewContext.Provider>
  );
};
