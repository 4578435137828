import { PreCheckoutActionArea } from "../pre-checkout-action-area/pre-checkout-action-area";
import { PreChckoutLeftContentProps } from "./pre-checkout-left-content.props";
import "./pre-checkout-left-content.scss";

export const PreChckoutLeftContent = ({
  title,
  description,
  content,
}: PreChckoutLeftContentProps) => {
  return (
    <>
      <div className="pre-checkout-left__inner">
        <div className="pre-checkout-left__content">
          <div className="pre-checkout-left__content__header">
            {title && (
              <h2 className="pre-checkout-left__content__title">{title}</h2>
            )}
            {description && (
              <p className="pre-checkout-left__content__description text--body-2">
                {description}
              </p>
            )}
          </div>
          {content && (
            <div className="pre-checkout-left__content__inner-content">
              {content}
            </div>
          )}
        </div>
        <PreCheckoutActionArea />
      </div>
    </>
  );
};
