import { formatCurrency } from "@src/helpers/appHelper";

import "./full-details-drawer-content.scss";
export const FullDetailsDrawerContent = ({ rebate }) => {
  return (
    <>
      <div className="incentives-and-discount--product-details-wrap">
        <div className="incentives-and-discount--product-details-credit">
          <h4 className="text--subsection-1 title">{rebate.rebate_name}</h4>
          <div className="incentives-and-discount--product-details-credit__total">
            <span className="text--body-2">Total credit:</span>
            <h3 className="text--promo-focus-price">
              {formatCurrency(rebate.rebate_amount)}
            </h3>
          </div>
          <div
            className="text--body-2 description"
            dangerouslySetInnerHTML={{ __html: rebate.rebate_description }}
          ></div>

          {/* <p className="text--disclaimer disclaimer">
            Note: Graduate program can not be combined with non FS cash credit.
          </p> */}
        </div>
        <div className="incentives-and-discount--product-details-eligibility">
          <h5>Requirements for eligibility:</h5>
          <div
            className="text--body-2"
            dangerouslySetInnerHTML={{
              __html: rebate.eligibility_requirements,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};
