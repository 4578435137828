import {
  Button,
  FeedbackLabel,
  MessageAlert,
  Popover,
  ProgressBar,
} from "@src/components/atoms";
import "./hero-content.scss";
import { useState } from "react";
import { TagLabel } from "@src/components/atoms/tag-label/tag-label.component";
import { BannerInfoContent } from "./banner-info-content";
import { useDealContext } from "@src/context/deal-context/deal-context";

export const HeroContent = ({
  buildInProgress,
  checkoutInProgress,
  hasAlert,
}: {
  buildInProgress?: boolean;
  checkoutInProgress?: boolean;
  hasAlert?: boolean;
}) => {
  const { offerAccepted, credit, no_longer_available } = useDealContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopoverOpen(newOpen);
  };
  const hidePopup = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div className="dr-start-hero__content">
      {buildInProgress && (
        <>
          <TagLabel
            text={
              !no_longer_available
                ? "Last updated: --/--/--"
                : "No Longer Available"
            }
            type={!no_longer_available ? "default" : "error"}
          />
          <p className="text--label text-white">VIN: WBA7E2C51JG741556</p>
          {!no_longer_available ? (
            <h2 className="text--h2">
              [Finance/Lease] your 20XX [BMW Model Name] at{" "}
              <strong>$000.00/month.</strong>
            </h2>
          ) : (
            <h2 className="text--h2">
              Apologies, this [20XX BMW Model Name] has been sold.
            </h2>
          )}
        </>
      )}
      {checkoutInProgress && (
        <>
          <TagLabel
            text={
              credit === "declined"
                ? "An issue occured"
                : "Checkout in-progress"
            }
            type={credit === "declined" ? "error" : "success"}
          />
          <p className="text--label text-white">VIN: WBA7E2C51JG741556</p>
          <h2 className="text--h2">
            {offerAccepted
              ? "You’ve been approved to finance your"
              : "[Message]"}{" "}
            your 20XX [BMW Model Name] at{" "}
            <strong>
              $000.00/month.
              <Popover
                title="Your {BMW Financial Services} offer"
                trigger={["click"]}
                onOpenChange={handleOpenChange}
                open={isPopoverOpen}
                closeIcon="close"
                onClose={hidePopup}
                disclaimerText="Approved offer: $604 / month
          36 month finance term
          $6,000 cash due at signing"
              >
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  role="img"
                  aria-label="Flag Label Icon"
                  data-icon="information"
                />
              </Popover>
            </strong>
          </h2>
        </>
      )}
      <div className="d-none d-lg-block">
        <BannerInfoContent
          buildInProgress={buildInProgress}
          hasAlert={hasAlert}
          checkoutInProgress={checkoutInProgress}
        />
      </div>
    </div>
  );
};
