import { Button } from "@src/components/atoms";
import "./menu.scss";

export const Menu = () => {
  return (
    <div className="menu">
      <div className="menu-desktop">
        <div className="menu-title-wrap d-md-block d-none">
          <p className="menu-title text--body-2">Purchase Online</p>
        </div>
        <div className="menu-divider  d-md-block d-none"></div>
        <div className="menu-item-container">
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Apply for Credit"
              linkButton
              withIcon
              className=""
              dataIcon="checkmark"
              isIconEnd
            />
          </div>

          <div className="menu-item">
            <Button
              htmlType="button"
              text="Submit order"
              linkButton
              withIcon
              isIconEnd
              className="menu-item--active"
              dataIcon="lock_locked"
            />
          </div>
          <div className="menu-item">
            <Button
              htmlType="button"
              text="Finalize your deal"
              linkButton
              withIcon
              isIconEnd
              className=""
              dataIcon="lock_locked"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
