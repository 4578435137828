import {
  Button,
  FeedbackLabel,
  MessageAlert,
  ProgressBar,
} from "@src/components/atoms";
import { useDealContext } from "@src/context/deal-context/deal-context";

export const BannerInfoContent = ({
  buildInProgress,
  checkoutInProgress,
  hasAlert,
}: {
  buildInProgress?: boolean;
  checkoutInProgress?: boolean;
  haveDeals?: boolean;
  hasAlert?: boolean;
}) => {
  const { primaryBuild, credit, reservationExpired } = useDealContext();

  return (
    <>
      <div className="banner-info-content">
        {buildInProgress && (
          <>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="price_tag"
              ></span>
              <p className="text--label text-white fw-normal">
                XX month term, your price $XX,XXX, $X,XXX due at signing.
              </p>
            </div>
            <FeedbackLabel type="success" text="[Status]." />
          </>
        )}
        {checkoutInProgress && !hasAlert && (
          <>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="price_tag"
              ></span>
              <p className="text--label text-white fw-normal">
                XX month term, your price $XX,XXX, $X,XXX due at signing.
              </p>
            </div>
            <div className="text-with__icon">
              <span
                className="bmw-icon bmw-icon--before data-icon"
                data-icon="lock_locked"
              ></span>
              <p className="text--label text-white fw-normal">
                This vehicle is reserved for you for 00:00:00.
              </p>
            </div>
            <FeedbackLabel type="success" text="[Status]." />
            <ProgressBar
              percent={75}
              label="[Prior Step] Completed"
              showInfo={false}
              showLabelIcon
              iconName="checkmark"
              onDark
            />
          </>
        )}
        {hasAlert && (credit || reservationExpired) && (
          <MessageAlert
            type="warning"
            title={
              reservationExpired
                ? "Your vehicle reservation has expired. Right now, your vehicle is still available and you may continue Checkout. To check availability and re-reserve, please contact your dealer."
                : credit === "declined"
                ? "An issue occurred with your credit application. Please contact your dealer for support."
                : "Sorry, your credit is frozen. Please resume your checkout to take action."
            }
            showCloseButton={false}
          />
        )}
        <div className="dr-start-hero__button">
          {buildInProgress && (
            <>
              <div className="button__innner">
                <Button
                  type="primary"
                  htmlType={"button"}
                  text="Continue building deal"
                />

                {/* {"Asdsa" + primaryBuild} */}
                {!primaryBuild && (
                  <Button
                    htmlType={"button"}
                    type="primary"
                    text="Contact dealer"
                    onDark
                  />
                )}
              </div>
              {!primaryBuild && !reservationExpired && (
                <Button
                  linkButton
                  htmlType={"button"}
                  text="Remove deal"
                  withIcon
                  dataIcon="trash_can"
                  onDark
                />
              )}
            </>
          )}
          {checkoutInProgress && (
            <>
              <div className="button__innner">
                <Button
                  type="primary"
                  htmlType={"button"}
                  text="Proceed to [next step]"
                />
                {reservationExpired && (
                  <Button
                    htmlType={"button"}
                    type="primary"
                    text="Contact dealer"
                    onDark
                  />
                )}
              </div>
              {credit === "declined" && (
                <Button
                  linkButton
                  htmlType={"button"}
                  text="Remove deal"
                  withIcon
                  dataIcon="trash_can"
                  onDark
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
