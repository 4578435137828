import { Radio, Checkbox } from "antd";
import "../../../styles/components/_select-button.scss";
import { SelectButtonProps } from "./select-button.props";

export const SelectButton = ({
  options,
  defaultValue,
  buttonStyle = "solid",
  onChange,
  type = "radio",
}: SelectButtonProps & { type?: "radio" | "checkbox" }) => {
  return (
    <div className="select-button-wrapper">
      {type === "radio" ? (
        <Radio.Group
          defaultValue={defaultValue as string}
          buttonStyle={buttonStyle}
          onChange={(e) => onChange?.(e.target.value)}
        >
          {options.map((option) => (
            <Radio.Button key={option.value} value={option.value}>
              <p className="text--body-2 fw-normal"> {option.label} </p>
            </Radio.Button>
          ))}
        </Radio.Group>
      ) : (
        <Checkbox.Group
          defaultValue={defaultValue as string[]}
          onChange={(checkedValues) => onChange?.(checkedValues)}
        >
          {options.map((option) => (
            <Checkbox key={option.value} value={option.value}>
              <p className="text--body-2 fw-normal"> {option.label} </p>
            </Checkbox>
          ))}
        </Checkbox.Group>
      )}
    </div>
  );
};
