import { useEffect, useState } from "react";
import { FinancialServiceCard } from "@src/components/molecules";
import { useAppContext } from "@src/context/app-context";
import { Button, Checkbox } from "@src/components/atoms";
import "./select-offer.scss";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";
import { setOrderInfo } from "@src/context/app-actions";
import { IOrderSubmission } from "@src/types";
import { useGetAllPrograms, useUpdateDeal } from "@src/hooks";
import { isEmpty } from "lodash";
import { getAllProgramPayload } from "@src/helpers/payloadHelper";

export const SelectOffer = ({ handleClick }: { handleClick: () => void }) => {
  const [values, setValues] = useState<{
    tier: string;
    disclaimerCheckbox: boolean;
    submission: IOrderSubmission;
  }>({
    tier: "",
    disclaimerCheckbox: false,
    submission: {},
  });
  const {
    state: {
      order,
      lender: { defaultLender },
      tenant: { companyCode, company_id },
      dealer: { dealer_code, disclaimer },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { v2Results, setOrderValues, orderValues, setCalculated } =
    useComputedQuotation();
  const { mutate: updateOrder, isPending } = useUpdateDeal();
  const { mutate: getProgram, isPending: programLoading } = useGetAllPrograms();
  const acceptanceOffer = disclaimer["acceptanceOffer"];
  useEffect(() => {
    if (!isEmpty(v2Results)) {
      updateOrder(
        {
          ...orderValues,
          estimated_monthly_payment: v2Results.monthly_payment,
        },
        {
          onSuccess: (deal) => {
            appDispatch(
              setOrderInfo({
                ...order,
                ...orderValues,
                estimated_monthly_payment: v2Results.monthly_payment,
              })
            );
            setCalculated({});
            handleClick();
          },
          onError: (err) => {
            console.error("err", err);
          },
        }
      );
    }
  }, [v2Results, orderValues]);

  const handleSubmit = () => {
    if (values.disclaimerCheckbox) {
      if (values.tier) {
        const creditTierMargin = defaultLender.credit_tier_margins.find(
          (margin) =>
            margin.finance_type === order.finance_type &&
            values.tier === margin.tier_name
        );

        getProgram(
          {
            ...getAllProgramPayload(
              order.order_asset,
              companyCode,
              defaultLender.external_code,
              dealer_code,
              order.finance_type,
              order.contract_term,
              order.annual_usage,
              order.credit_rating,
              order.credit_score
            ),
            tier: values.tier,
          },
          {
            onSuccess: (programRes: any) => {
              if (!isEmpty(programRes.detail) || programRes.length === 0) {
                console.error("ERROR: Sorry No Program Found");
                return;
              } else {
                setOrderValues({
                  ...order,
                  apr:
                    programRes.final_customer_rate +
                    (creditTierMargin?.margin_rate ?? 0),
                  lender_id: values.submission.lender_id ?? order.lender_id,
                  reference_id: order.customer_info.reference_id,
                  company_id: company_id,
                  status: "Approved",
                  rv_balloon_percentage: programRes.assetRvAmount,
                });
              }
            },
            onError: (err) => {
              console.error("err", err);
            },
          }
        );
      } else {
        updateOrder(
          {
            ...order,
            status: "Approved",
            reference_id: order.customer_info.reference_id,
            company_id: company_id,
          },

          {
            onSuccess: (deal) => {
              appDispatch(
                setOrderInfo({
                  ...order,
                  status: "Approved",
                  lender_id: values.submission.lender_id ?? order.lender_id,
                })
              );
              handleClick();
            },
            onError: (err) => {
              console.error("err", err);
            },
          }
        );
      }
    }
  };
  return (
    <div className="select-offer-container">
      <div className="offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            01
          </h3>
          <h3 className="checkout-title text--subsection-2">Finance offer.</h3>
        </div>
      </div>
      <FinancialServiceCard setValues={setValues} values={values} />
      {acceptanceOffer?.changesDisclaimer && (
        <div className="offer-checkbox">
          <Checkbox
            id="chk--accept-terms"
            onChange={(e) =>
              setValues((prev) => ({ ...prev, disclaimerCheckbox: e }))
            }
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: acceptanceOffer.changesDisclaimer,
                }}
              ></div>
            }
            htmlFor="chk--accept-terms"
          />
        </div>
      )}

      <div className="offer-cta-wrapper">
        <Button
          type="default"
          isLoading={isPending || programLoading}
          disabled={isEmpty(values.submission) || !values.disclaimerCheckbox}
          id="acceptoffer"
          htmlType={"button"}
          text="Accept offer"
          fullWidth
          fixed
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
