import { Button, Image, Modal } from "@src/components/atoms";
import DealVehicle from "../../../../shared/assets/img/dr-start/deal-vehicle.png";
import "./deals-dialog.scss";

export const DealsDialog = ({
  isModalOpen,
  setIsModalOpen,
  alreadySubmit = false,
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className={`back-to-shop-modal deal-dialog ${
        alreadySubmit ? "already-submit" : ""
      }`}
    >
      <Modal open={isModalOpen} onClose={handleClose} closable>
        <div className="deal-modal__inner">
          <div className="deal-modal__img">
            <Image src={DealVehicle} alt="image" aspectRatio="1:1" />
          </div>
          <div className="deal-modal__content">
            <div>
              <div className="deal-modal-content__title">
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="warning_sign"
                />
                <h3>You're already working with [BMW Center Name].</h3>
              </div>
              {!alreadySubmit ? (
                <p className="text--body-2 deal-dialog__subtitle">
                  Sorry, but you can only work with one dealer at a time. If you
                  decide to continue with this deal, your previous deals will be
                  removed. Exiting will keep the previous deals. Would you like
                  to continue?
                </p>
              ) : (
                <p className="text--body-2 deal-dialog__subtitle">
                  Sorry, but you can only work with one dealer at a time.
                  Because [BMW Center Name] has your credit application, you
                  will need to contact them for further assistance.
                </p>
              )}
            </div>
            <div className="deal-modal__footer">
              <div className="deal-modal__cta">
                {!alreadySubmit ? (
                  <>
                    <Button
                      type="primary"
                      htmlType="button"
                      text="Continue & remove previous"
                      fullWidth
                    />
                    <Button
                      type="default"
                      htmlType="button"
                      text="Cancel"
                      onClick={handleClose}
                      fullWidth
                    />
                  </>
                ) : (
                  <Button
                    type="primary"
                    htmlType="button"
                    text="Contact dealer"
                    fullWidth
                  />
                )}
              </div>
              <p className="text--label deal-modal__bottom">
                <span>
                  For more information you can{" "}
                  <a href="/" className="text-underline fw-normal">
                    find it here.
                  </a>
                </span>
                <span
                  className="bmw-icon bmw-icon--before data-icon"
                  data-icon="information"
                />
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
