import { Accordion } from "@src/components/atoms";
import { PaymentAmountAccordianDetails } from "./payment-amount-accordian-details.component";
import "./payment-amount-accordian.scss";

export const PaymentAmountAccordian = () => {
  const paymentAmountAccordionItems = [
    {
      key: "1",
      label: <div className="accordion-title-with-icon">Amount due today</div>,
      children: <PaymentAmountAccordianDetails />,
      extra: <span className="text--body-focus-price-1">$X,XXX</span>,
    },
  ];

  return (
    <div className="payment-amount-accordian">
      <Accordion
        items={paymentAmountAccordionItems}
        defaultActiveKey={0}
        ghost={false}
        size="small"
        fullWidth
      />
    </div>
  );
};
