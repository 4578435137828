import { Accordion, Button } from "@src/components/atoms";
import { ReactNode, useState } from "react";
import "./chat-accordion.scss";

interface ChatAccordionProps {
  label?: string | ReactNode;
  message?: string | ReactNode;
}

export const ChatAccordion: React.FC<ChatAccordionProps> = ({
  label,
  message,
}) => {
  const [open, setOpen] = useState(false);

  const exampleItems = [
    {
      key: "1",
      label: <p className="text--disclaimer">{label}</p>,
      children: <p className="text--disclaimer">{message}</p>,
    },
  ];

  return (
    <div className="chat-accordion-wrap">
      <Accordion items={exampleItems} defaultActiveKey={0} ghost={false} />
    </div>
  );
};
