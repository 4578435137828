import { Popover } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const DueAtSigningAccordionContent = () => {
  const customAddOnsAccordionItems = [
    {
      key: "3",
      label: "Protection & Coverage",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Essential protection bundle
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $0,000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Windshield protection
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$1,200</span>,
    },
    {
      key: "4",
      label: "Accessories",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Essential protection bundle
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $0,000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Windshield protection
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$1,200</span>,
    },
  ];
  const taxFeesAccordionItems = [
    {
      key: "1",
      label: "Fees",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Placeholder
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Placeholder
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$X,XXX</span>,
    },
  ];
  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Capitalized Cost Reduction
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Capitalized Cost Reduction represents any payments or trade-in credits that reduce the amount financed in a lease. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $7,000
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              First Month’s Payment
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Custom Add-Ons
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <Accordion
            items={customAddOnsAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Taxes & fees
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <div className="pricing-details__item pr-0">
            <Accordion
              items={taxFeesAccordionItems}
              defaultActiveKey={0}
              ghost={false}
              size="small"
              fullWidth
            />
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Acquisition Fee
            </span>
            <span className="pricing-details__item__price text--body-2">
              $900
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Refundable Security Deposit
            </span>
            <span className="pricing-details__item__price text--body-2">
              $000
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Sales Tax represents the tax applied to the vehicle's purchase or lease price, as determined by state and local regulations. This information may vary by state or local tax laws. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $000
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax on Capitalized Cost Reduction
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Sales Tax on Capitalized Cost Reduction refers to taxes applied to any upfront payments or credits that reduce the lease amount. This information may vary by state or local tax laws. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $000
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
