import { MultiSwatchCircles } from "@src/components/atoms";
import SwatchOne from "../../../../shared/assets/img/right-rail/swatch1.png";
import SwatchTwo from "../../../../shared/assets/img/right-rail/swatch2.png";
import SwatchThree from "../../../../shared/assets/img/right-rail/swatch3.png";
import SwatchFour from "../../../../shared/assets/img/right-rail/swatch4.png";
import SwatchFive from "../../../../shared/assets/img/right-rail/swatch5.png";
import SwatchSix from "../../../../shared/assets/img/right-rail/swatch6.png";

export const CartPricingInfo = () => {
  const swatchArray = [
    SwatchOne,
    SwatchTwo,
    SwatchThree,
    SwatchFour,
    SwatchFive,
    SwatchSix,
  ];
  return (
    <div className="hero-banner__cart-pricing-section">
      <div className="hero-banner__cart">
        <span className="text--body-2">
          Your cart{" "}
          <div
            className="bmw-icon bmw-icon--before data-icon"
            data-icon="information"
          ></div>
        </span>
        <div>
          <MultiSwatchCircles images={swatchArray} maxVisible={4} />
        </div>
      </div>
      <div className="hero-banner__pricing-detail">
        <div>
          <p className="text--label custom-height">Estimated financing</p>
          <div className="text--h2">
            $618.32 <span className="text--body-1">/month</span>
          </div>
        </div>
        <div className="hero-banner__pricing-text">
          <div className="text--disclaimer">XX months</div>
          <div className="text--disclaimer">X.XX% APR</div>
          <div className="text--disclaimer">$X,XXX cash down</div>
        </div>
      </div>
    </div>
  );
};
