import { useState } from "react";
import { Button, Drawer } from "@src/components/atoms";
import "./legal-disclaimer-sheet.scss";
import { LegalDisclaimerContent } from "../legal-disclaimer-content/legal-disclaimer-content.component";

export const LegalDisclaimerSheet = () => {
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  const showDisclaimerDrawer = () => {
    setOpenDisclaimer(true);
  };
  const onClose = () => {
    setOpenDisclaimer(false);
  };
  return (
    <div className="legal-disclaimer-sheet-wrap">
      <Button
        text="Disclaimer sheet drawer"
        htmlType="button"
        onClick={showDisclaimerDrawer}
      />
      <Drawer
        open={openDisclaimer}
        maskClosable={true}
        keyboard={true}
        content={
          <div className="legal-disclaimer-sheet--inner">
            <h3 className="text--h3">
              It seems you haven’t accepted the terms and policies yet. Please
              agree to them before signing in or registering.
            </h3>
            <LegalDisclaimerContent />
            <Button
              type="default"
              htmlType={"button"}
              onClick={() => {}}
              text="Accept & continue"
              fullWidth
              disabled
            />
          </div>
        }
        onClose={onClose}
        onBack={onClose}
      />
    </div>
  );
};
