import "./lease-tab-content.scss";
import { Button, Input, Popover, Select, Slider } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
import { Col, Row } from "antd";
import { TotalContributionAccordionContent } from "./total-contribution-accordion-content/total-contribution-accordion-content";
import { DueAtSigningAccordionContent } from "./due-at-signing-accordion-content/due-at-signing-accordion-content";
import { AdjustedCapitalizedCostAccordionContent } from "./adjusted-capitalized-cost-accordion-content/adjusted-capitalized-cost-accordion-content";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";
import { FeeTypes } from "@src/constants";
import { getAllProgramPayload } from "@src/helpers";
import { useCalculateNfa, useGetAllPrograms } from "@src/hooks";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";
import { debounce, isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
const formReducer = (state, action) => {
  return { ...state, [action.field]: action.value };
};
export const LeaseTabContent = ({
  openSoftpullDrawer,
}: {
  openSoftpullDrawer?: () => void;
}) => {
  const { mutate: calculateNfa } = useCalculateNfa();
  const {
    state: {
      configuration: {
        setupData,
        transunion,
        cache_expiry_date,
        softPullFetch,
      },
      order: {
        credit_rating,
        down_payment,
        finance_type,
        estimated_monthly_payment,
        order_asset: { unit_price, ...restOrderAsset },
        selling_price,
        contract_term,
        annual_usage,
        credit_score,
        ...restOrder
      },
      lender: { defaultLender },
      tenant: { companyCode },
      dealer: { dealer_code, dealer_address },
      user: { isUserLoggedIn },
    },
  } = useAppContext();

  const { control, setValue } = useForm<any>({
    defaultValues: {
      cashdown: "",
    },
  });
  const { mutateAsync: getProgram } = useGetAllPrograms();
  const { setOrderValues } = useComputedQuotation();
  const isFirstLoad = useRef(true);

  // Form state
  const [formState, formDispatch] = useReducer(formReducer, {
    financeType: finance_type,
    term: contract_term,
    mileage: annual_usage,
    downpayment: down_payment,
    creditRating: credit_rating,
    creditScore: credit_score,
  });

  const [downpaymentFieldError, setDownpaymentFieldError] = useState("");

  const fetchProgramData = (
    payload: any,
    formState: { creditScore: any; creditRating: any; term: any; mileage: any }
  ) => {
    getProgram(
      {
        ...payload,
      },
      {
        onSuccess: (programRes: any) => {
          if (!isEmpty(programRes.detail) || programRes.length === 0) {
            console.error("ERROR: Sorry No Program Found");
            return;
          } else {
            setOrderValues({
              ...restOrder,
              selling_price: unit_price,
              order_asset: { unit_price, ...restOrderAsset },
              credit_score: formState.creditScore,
              credit_rating: formState.creditRating,
              contract_term: formState.term,
              customerV2Address: dealer_address,
              apr: programRes.final_customer_rate,
              down_payment:
                programRes.downpayment_chart[0]?.default_down_payment ?? 0,
              rental_mode: programRes?.rental_mode,
              rv_balloon_percentage: programRes?.assetRvAmount,
              finance_type: finance_type,
              annual_usage: formState.mileage,
              downpayment_chart: programRes.downpayment_chart,
              estimated_monthly_payment: estimated_monthly_payment,
            });
          }
        },
        onError: (err) => {
          console.error("err", err);
        },
      }
    );
  };
  const debounceFetchProgram = useCallback(debounce(fetchProgramData, 500), []);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return; // Skip debounced fetch on the initial load
    }
    const payload = {
      ...getAllProgramPayload(
        { unit_price, ...restOrderAsset },
        companyCode,
        defaultLender.external_code,
        dealer_code,
        finance_type,
        formState.term,
        formState.mileage,
        formState.creditRating,
        formState.creditScore
      ),
      tier: "",
    };
    if (downpaymentFieldError === "") {
      debounceFetchProgram(payload, formState);
    }
  }, [formState]);

  //** Term Section  */

  const terms = useMemo(
    () =>
      setupData["contract-terms"]
        ?.map((item) => item.term)
        .sort((a, b) => a - b) || [],
    [setupData["contract-terms"]]
  );

  useEffect(() => {
    setValue("cashdown", ((down_payment ?? 0) * unit_price) / 100);
  }, [down_payment, setValue, unit_price]);

  const handleCashDownChange = (ev) => {
    const chartData = restOrder.downpayment_chart ?? [];

    const down_payment_value = (ev.currentTarget.value * 100) / unit_price;
    formDispatch({ field: "downpayment", value: down_payment_value });
    const minValue = chartData[0].minimum_down_payment;
    const maxValue = chartData[0].maximum_down_payment;
    if (down_payment_value < minValue || down_payment_value > maxValue) {
      setDownpaymentFieldError(
        `Cash Down payment must be between ${
          (minValue * unit_price) / 100
        } and ${(maxValue * unit_price) / 100}.`
      );
    } else {
      setDownpaymentFieldError("");
      formDispatch({ field: "downpayment", value: down_payment_value });
    }
  };

  const handleSliderChange = (index: number) => {
    formDispatch({ field: "term", value: terms[index] });
  };

  const handleCreditScoreChange = (ev) => {
    const selectedCreditRating = setupData["credit-ratings"]?.find(
      (r) => r.id === +ev
    );
    formDispatch({
      field: "creditRating",
      value: selectedCreditRating?.description || "",
    });
    formDispatch({
      field: "creditScore",
      value: selectedCreditRating?.score_from || 0,
    });
  };
  //** Term Section End */

  //** Lease Miles Section */

  const handleLeaseMileagesChange = (ev) => {
    const selectedMileages = setupData["contract-allowed-annum-mileage"].find(
      (item) => item.is_active && parseInt(item.id) === parseInt(ev)
    ).value;
    formDispatch({ field: "mileage", value: selectedMileages || 0 });
  };
  // Filter active options and map them to the required format
  const leaseMilesSelectOptions = setupData["contract-allowed-annum-mileage"]
    .filter((item) => item.is_active) // Get only active ones
    .map((item) => ({
      value: item.id.toString(),
      label: `${item.value.toLocaleString()} miles`, // Format number with commas
      disabled: false,
    }));
  //** Lease miles section end */

  //** Credit Score Section */
  // Map the active credit scores into select options
  const creditScoreOptions = useMemo(
    () =>
      setupData["credit-ratings"]
        .filter((item) => item.is_active)
        .map((item) => ({
          value: item.id.toString(),
          label: `${item.description} (${item.score_from}-${item.score_to})`,
          disabled: false,
        })),
    [setupData["credit-ratings"]]
  );

  const LeaseAccordionItems = [
    {
      key: "1",
      label: (
        <div className="accordion-title-with-icon">
          Total Contribution
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The total contribution consists of your cash down payment, rebates and incentives from the dealer, BMW North America, or BMW Financial Services, and trade-in values. This information is for explanatory purposes only. Actual terms and conditions may vary. Please review the detailed breakdown or contact your dealer for clarification."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <TotalContributionAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Due at Signing
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The Due at Signing amount includes upfront costs such as the down payment, taxes, fees, and the first month's payment. This figure is provided for educational purposes only and may vary based on your lease agreement. Verify the exact amount with your dealer or lender."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <DueAtSigningAccordionContent />,
      extra: <span className="text--body-focus-price-1">$10,000</span>,
    },
    {
      key: "3",
      label: (
        <div className="accordion-title-with-icon">
          Adjusted Capitalized Cost
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="The Adjusted Capitalized Cost is the vehicle’s price after applying any reductions, including the Capitalized Cost Reduction. "
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: <AdjustedCapitalizedCostAccordionContent />,
      extra: <span className="text--body-focus-price-1">$45,970</span>,
    },
  ];

  return (
    <>
      <div className="update-payment-tab update-payment-tab--lease">
        <div className="payment-calculator">
          <div className="payment-calculator__price-card">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    {isUserLoggedIn ? "Your price" : "MSRP"}
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText={`Manufacturer's Suggested Retail Price (${
                        isUserLoggedIn ? "Your price" : "MSRP"
                      }) for vehicle as shown does not necessarily represent the dealer's actual sale price and does not include tax, title, license, registration and adjusted market value. Dealer sets actual price. Please consult your selected dealer.`}
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    {formatCurrency(unit_price)}
                  </div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Estimated finance
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    {formatCurrency(estimated_monthly_payment)}
                    <span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
          </div>
          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__price-card payment-calculator__price-card--checkout-pricing">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Your price
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText={`Your Price reflects the Manufacturer’s Suggested Retail Price (${
                        isUserLoggedIn ? "Your price" : "MSRP"
                      }) minus applicable dealer discounts, incentives, or promotions. Dealer incentives are subject to change and may vary by region or availability. Additional fees, taxes, registration, and optional equipment are not included. The final purchase price may differ based on dealer-specific factors, including but not limited to negotiated terms and applied financing. Contact your selected dealer for more details.`}
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Est. payments
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    $0,000<span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
            <p className="text--disclaimer disclaimer--monthly-estimate">
              Monthly estimate Includes taxes & fees.
            </p>
          </div>
          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__price-card payment-calculator__price-card--checkout-pricing">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Your price
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="Your Price reflects the Manufacturer’s Suggested Retail Price (MSRP) minus applicable dealer discounts, incentives, or promotions. Dealer incentives are subject to change and may vary by region or availability. Additional fees, taxes, registration, and optional equipment are not included. The final purchase price may differ based on dealer-specific factors, including but not limited to negotiated terms and applied financing. Contact your selected dealer for more details."
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
              <div className="payment-calculator__price__inner__right">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Est. payments
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <div className="text--promo-focus-price price">
                    $0,000<span className="text--h3">/month</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Includes taxes & fees, destination, on approved credit.
            </p>
            <p className="text--disclaimer disclaimer--monthly-estimate">
              Monthly estimate Includes taxes & fees.
            </p>
          </div>
          <div className="payment-calculator__secion">
            <Row gutter={[0, { xs: 24, sm: 24, md: 24, lg: 32, xl: 40 }]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="payment-calculator__secion__slider">
                  <div className="payment-calculator__secion__slider__title">
                    <span className="text--label text-secondary label">
                      Term length
                    </span>
                    <span className="text--cta-2 additional">
                      {formState.term} months
                    </span>
                  </div>
                  <Slider
                    min={0}
                    max={terms.length - 1}
                    step={1}
                    dots={true}
                    defaultValue={1}
                    tooltipFormatter={(index) => `${terms[index]} months`}
                    onChange={handleSliderChange}
                  />
                  <div className="payment-calculator__secion__slider__value text--label text-secondary">
                    <span>{terms[0]} months</span>
                    <span>{terms[terms.length - 1]} months</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Controller
                  name="cashdown"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      id={"idCashdown"}
                      error={
                        downpaymentFieldError
                          ? { message: downpaymentFieldError }
                          : null
                      }
                      placeholder="0.00"
                      label="Cash down"
                      showInfoIcon
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      onBlur={(e) => {
                        handleCashDownChange(e);
                      }}
                    />
                  )}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  id={"dropdownLease miles"}
                  placeholder=""
                  label="Lease miles"
                  showInfoIcon
                  options={leaseMilesSelectOptions}
                  defaultValue={
                    leaseMilesSelectOptions.find((opt) =>
                      opt.label.includes(annual_usage.toLocaleString())
                    )?.value
                  }
                  onChange={handleLeaseMileagesChange}
                />
              </Col>
              {!softPullFetch && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Select
                    id={"dropdownCreditScore"}
                    placeholder=""
                    label="Credit score"
                    showInfoIcon
                    defaultValue={
                      creditScoreOptions.find((opt) =>
                        opt.label.includes(credit_rating)
                      )?.value
                    }
                    options={creditScoreOptions}
                    onChange={handleCreditScoreChange}
                  />
                </Col>
              )}
            </Row>
            {softPullFetch && (
              <p>
                The monthly payment calculation is based on your credit score
                obtained through TransUnion, it will be saved for you until
                {cache_expiry_date}.
              </p>
            )}
          </div>

          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__checkout-pricing-section">
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Lease term
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                XX months
              </span>
            </div>
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Cash down
                <Popover
                  trigger={["hover"]}
                  placement="top"
                  disclaimerText='Similar to a Down payment, money provided by the lessee at inception reduces the capitalized cost of the lease. Your total amount "Due-at-Signing" may include additional amounts. A full breakdown of your total "Due-at-Signing" is provided after all estimator steps are completed.'
                >
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </Popover>
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                -$X,XXX
              </span>
            </div>
            <div className="pricing-details__item">
              <span className="pricing-details__item__label text--body-2">
                Lease miles
                <Popover
                  trigger={["hover"]}
                  placement="top"
                  disclaimerText="If the total mileage exceeds the agreed-upon limit stated in your lease agreement, additional charges will apply. Please review your lease terms or consult with your dealer for details on excess mileage costs."
                >
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </Popover>
              </span>
              <span className="pricing-details__item__price text--body-focus-price">
                X.XX%
              </span>
            </div>
          </div>
        </div>
        {!softPullFetch && transunion && (
          <div className="accurate-apr-banner">
            <div className="accurate-apr-banner__text">
              <p className="text--body-2">
                Get a more precise monthly payment with a{" "}
                {/* eslint-disable-next-line */}
                <span
                  onClick={openSoftpullDrawer}
                  className="text--link-2 accurate-apr-banner__text__link"
                >
                  soft credit check
                </span>
                .
              </p>
            </div>
            <div className="accurate-apr-banner__icon">
              <i className="bmw-icon bmw-icon--after bmw-icon--search-offer"></i>
            </div>
          </div>
        )}
        <div className="pricing-details">
          <div className="pricing-details__heading">
            <h4 className="text--subsection-1">Pricing details.</h4>
          </div>
          <div className="pricing-details__inner">
            <div className="pricing-details-accordion">
              <div className="pricing-details-accordion__checkout-pricing-estimates">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    (Est.) Monthly Payments
                    <Popover
                      trigger={["hover"]}
                      placement="top"
                      disclaimerText="The monthly payment reflects the lender-approved loan terms. This amount is based on the lender's evaluation of your credit application and may differ from previously estimated monthly costs. Taxes, fees, and optional add-ons are not included unless specified. For further details, please review the terms provided by your lender or contact your dealer."
                    >
                      <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                    </Popover>
                  </span>
                  <span className="pricing-details__item__price text--body-focus-price-1">
                    $612<span className="text--body-2">/month</span>
                  </span>
                </div>
                <p className="text--disclaimer text-muted">
                  The 36-month lease includes 12,000 miles annually, a $7,500
                  residual value (25%), with the first payment due MM/DD/2024
                  and a monthly sales tax of $0,000.
                </p>
              </div>
              <Accordion
                items={LeaseAccordionItems}
                defaultActiveKey={0}
                ghost={false}
                size="small"
                fullWidth
              />
            </div>
          </div>
          <p className="text--disclaimer pricing-details__due-payment">
            $7,500 residual value (25%), with the first payment due MM/DD/2024
            and a monthly sales tax of $0,000.The first payment is due on
            MM/DD/YYYY.{" "}
          </p>
          <p className="text--disclaimer pricing-details__due-payment pricing-details__due-payment--checkout-pricing">
            $7,500 residual value (25%), with the first payment due MM/DD/2024
            and a monthly sales tax of $0,000.The first payment is due on
            MM/DD/YYYY. Please contact dealer for more information or to make
            changes on your pricing details.
          </p>
          <div className="checkout-pricing-edit-my-deal-button">
            <Button
              htmlType="button"
              text="Edit my deal"
              dataIcon="arrow_chevron_right"
              linkButton
              withIcon
              isIconEnd
            />
          </div>
        </div>
      </div>
    </>
  );
};
