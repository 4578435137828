import "./multi-swatch-card.scss";
import { MultiSwatchCardProps } from "./multi-swatch-card-props";
import clsx from "clsx";
import { MultiSwatchCircles } from "@atoms/multi-swatch-circles/multi-swatch-circles";
import SwatchOne from "../../../shared/assets/img/multi-swatch-circle__one.png";
import SwatchTwo from "../../../shared/assets/img/multi-swatch-circle__two.png";
import SwatchThree from "../../../shared/assets/img/multi-swatch-circle__three.png";

export const MultiSwatchCard = ({
  className,
  isDisabledCard,
}: MultiSwatchCardProps) => {
  const swatchArray = [SwatchOne, SwatchTwo, SwatchThree];
  return (
    <div
      className={clsx(className, {
        "multi-swatch-card": true,
        is_row_disabled: isDisabledCard,
      })}
    >
      <div className="">
        <MultiSwatchCircles images={swatchArray} maxVisible={2} />
      </div>
      <div>
        <div className="text--body-2">
          This 330i xDrive Sedan comes with 6 pre-installed accessories from BMW
          of Manhattan.
        </div>

        <div className="text--link-2 text-underline ">See what's included</div>
      </div>
    </div>
  );
};
