import { BMW_BASE, URL_BMW } from "@src/constants/routes";
import { useEffect, ReactNode } from "react";
import { useExtractAndDecodeCode } from "@src/hooks/useChangeDomain";
import { useGetCompanyDomain, useGetOrderInfo, useGetToken } from "@src/hooks";
import {
  extractOrderParams,
  useUrlParamsForOrderInfo,
} from "@src/hooks/useUrlParamsForOrderInfo";
import { useFetchAndStore } from "@src/hooks/useFetchAndStore";
import { isEmpty } from "lodash";
import { useAppContext } from "@src/context/app-context";
import {
  loadTenantConfigurations,
  setOrderInfo,
  updateCustomer,
} from "@src/context/app-actions";
import { decodeToken } from "react-jwt";
import { removeNullValues } from "@src/helpers/appHelper";

const LOGIN_URL = BMW_BASE + "login" + URL_BMW;

const LoginAuthChecker = ({ children }: { children?: ReactNode }) => {
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");

  useExtractAndDecodeCode();
  useFetchAndStore();
  useUrlParamsForOrderInfo(userCredentials);
  const { mutate: companyData } = useGetCompanyDomain();
  const { mutate: get_token } = useGetToken();
  const { mutate: getOrderInfo } = useGetOrderInfo();
  const {
    state: {
      dealer: { id },
      configuration: { urlOrigin },
      user: { customer },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();

  useEffect(() => {
    if (!!userCredentials) {
      companyData(
        {
          origin_domain: urlOrigin,
        },
        {
          onSuccess: (data) => {
            appDispatch(loadTenantConfigurations(data));
            if (!userCredentials.access_token) {
              get_token(
                {
                  user_name: userCredentials.login_name,
                  reference_number: userCredentials.gcid,
                  slug: data.slug,
                },
                {
                  onSuccess: (response) => {
                    const decodedData = decodeToken<{ email: string }>(
                      response.access_token
                    );
                    if (decodedData?.email) {
                      const customerData = {
                        ...customer,
                        email: decodedData.email,
                      };

                      appDispatch(updateCustomer(customerData));
                    }
                    const { dealRefNumber } = extractOrderParams(
                      location.pathname
                    );
                    if (!dealRefNumber) {
                      return; // Do nothing if dealRefNumber is not present in the URL
                    }

                    // Check if order info for the current dealRefNumber is already in the state
                    if (order && order.reference_number === dealRefNumber) {
                      return; // Skip the API call if order info is already available
                    }

                    getOrderInfo(dealRefNumber, {
                      onSuccess: (response) => {
                        appDispatch(
                          setOrderInfo({
                            ...order,
                            ...removeNullValues(response),
                          })
                        );
                      },
                      onError: (error) => {
                        console.error(
                          "error getting order information:",
                          error
                        );
                      },
                    });
                    localStorage.setItem("customer", JSON.stringify(response));
                  },
                }
              );
            } else {
              const decodedData = decodeToken<{ email: string }>(
                userCredentials.access_token
              ) as any;
              if (decodedData?.email) {
                const customerData = {
                  ...customer,
                  email: decodedData.email,
                };

                appDispatch(updateCustomer(customerData));
              }
            }
          },
        }
      );
    }
  }, []);

  if (isEmpty(userCredentials)) {
    // Redirect to the login URL if access token is missing
    window.location.href = LOGIN_URL;
    return null; // Return null to prevent further rendering
  }
  return <>{children}</>;
};

export default LoginAuthChecker;
