import { Controller } from "react-hook-form";
import { Input } from "@src/components/atoms";
import "./spouse-name.scss";

export const SpouseName = ({ control }) => {
  return (
    <div className="spouse-name-wrap title-fields-spacer">
      <p className="material-status-check--subtitle text--body-1">
        Please provide your spouse’s name.
      </p>
      <div className="dsx--m-0 checkout-column-wrapper">
        {/* Spouse First Name */}
        <Controller
          name="spouse.first_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              id="spouseFirstName"
              placeholder="First name"
              label="Spouse’s first name"
              error={error}
            />
          )}
        />

        {/* Spouse Last Name */}
        <Controller
          name="spouse.last_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              id="spouseLastName"
              placeholder="Last name"
              label="Spouse’s last name"
              error={error}
            />
          )}
        />
      </div>
    </div>
  );
};
