import { ContractPreview } from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

export const ContractPreviewPage = () => {
  return (
    <BaseLayout hasMenu={true} hasFooter={true}>
      <ContractPreview />
    </BaseLayout>
  );
};
