import {
  AdditionalInsuranceInfo,
  ContractInfo,
  DocumentInfo,
  PaymentInfo,
  VehicleAppointmentPickup,
} from "@src/components/molecules";
import "./state-card.scss";
import { useWaitingHubContext } from "@src/context/waiting-hub-context/waitinhub-context";
import { ROUTING_PATH } from "@src/application/application-router";
import { useNavigate } from "react-router-dom";
import { OffshootsJourneyDrawer } from "../offshoots-journey/offshoots-journey-drawer/offshoots-journey-drawer";
import { useState } from "react";

export const StateCard = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [caseState, setCaseState] = useState("");
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const {
    waitingHubState: {
      esign,
      uploadedContract,
      uploadedResidenceProof,
      uploadedIncentiveProof,
      isPickUpScheduled,
      isSetInsuranceInfo,
      isPaymentSet,
    },
    setWaitingHubState,
    currentStepState,
    moveStep,
    addStep,
    moveCompleted,
  } = useWaitingHubContext();

  const vehicleApptItems = [
    {
      key: "1",
      label: "What do I need to bring to pickup?",
      children:
        "You need to bring your ID, vehicle registration, and any paperwork provided.",
    },
  ];

  const listOfSteps = [
    {
      key: "1",
      component: (
        <ContractInfo
          key="contract-info"
          statusText={esign ? "Completed" : "To-do"}
          statusType={esign ? "success" : "secondary"}
          headerTitle="Contracts & documents."
          headerIcon="register"
          bodyTitle={
            esign
              ? "All required contracts have been e-signed. Your dealer may have additional documents."
              : currentStepState.current.includes("1") && !isSetInsuranceInfo
              ? "Your contracts will be available to e-sign 24 hours before pickup."
              : "Your contracts are ready for e-sign."
          }
          buttons={
            esign
              ? [
                  {
                    linkButton: true,
                    withIcon: true,
                    type: "link",
                    dataIcon: "download",
                    text: "View & download",
                  },
                ]
              : [
                  {
                    text: "E-sign ",
                    type: "default",
                    disabled: !isSetInsuranceInfo,
                    onClick: () => {
                      setWaitingHubState((prevstate) => {
                        return { ...prevstate, esign: true };
                      });
                      moveStep("1", "5");
                      navigate(`${ROUTING_PATH.CONTRACT_PREVIEW_PAGE}`);
                    },
                  },
                  {
                    text: "Preview ",
                    disabled: false,
                    onClick: () => {
                      setWaitingHubState((prevstate) => {
                        return { ...prevstate, uploadedContract: true };
                      });
                      currentStepState.completed.length === 0 && addStep("2");
                      navigate(`${ROUTING_PATH.CONTRACT_PREVIEW_PAGE}`);
                    },
                  },
                ]
          }
          additionalInfo={
            !esign &&
            "You may provide supporting documents online or at the dealer."
          }
        />
      ),
    },
    {
      key: "2",
      component: (
        <DocumentInfo
          key="document-info"
          statusText={uploadedResidenceProof ? "Completed" : "To-do"}
          statusType={uploadedResidenceProof ? "success" : "secondary"}
          headerIcon="document_text_lines"
          headerTitle="Supporting documents."
          contentBlocks={
            currentStepState.comingUp.includes("2")
              ? [
                  {
                    bodyTitle:
                      "Once the dealer finalizes your order, you'll be able to upload supporting documents.Please have your proof of eligibility for incentives and discounts and proof of address on hand.",
                    buttons: [
                      {
                        disabled: true,
                        type: "default",
                        text: "Upload",
                      },
                    ],
                  },
                ]
              : [
                  {
                    bodySubtitle: uploadedIncentiveProof
                      ? undefined
                      : "First Document",
                    bodyTitle: uploadedIncentiveProof
                      ? "Thank you, proof of eligibility for incentives & discounts is provided."
                      : " Proof of eligibility for incentives & discounts.",

                    buttons: [
                      {
                        linkButton: uploadedResidenceProof,
                        withIcon: uploadedResidenceProof,
                        type: uploadedResidenceProof ? "link" : "default",
                        dataIcon: "edit",
                        text: uploadedResidenceProof
                          ? "Review & edit"
                          : "Upload",
                        onClick: () => {
                          setCaseState("Incetive");
                          setWaitingHubState((prevstate) => {
                            return {
                              ...prevstate,
                              uploadedIncentiveProof: true,
                            };
                          });
                          openDrawer();
                        },
                      },
                    ],
                  },
                  {
                    bodyTitle: uploadedResidenceProof
                      ? "Thank you, proof of address is provided."
                      : "Proof of address.",
                    buttons: [
                      {
                        linkButton: uploadedResidenceProof,
                        withIcon: uploadedResidenceProof,
                        type: uploadedResidenceProof ? "link" : "default",
                        dataIcon: "edit",
                        text: uploadedResidenceProof
                          ? "Review & edit"
                          : "Upload",
                        disabled: !uploadedIncentiveProof,
                        onClick: () => {
                          setCaseState("Residence");
                          setWaitingHubState((prevstate) => {
                            return {
                              ...prevstate,
                              uploadedResidenceProof: true,
                            };
                          });
                          moveStep("2", "3");
                          openDrawer();
                        },
                      },
                    ],
                  },
                ]
          }
          additionalInfo={
            !currentStepState.comingUp.includes("2") &&
            "You may provide supporting documents online or at the dealer."
          }
        />
      ),
    },
    {
      key: "3",
      component: (
        <VehicleAppointmentPickup
          key="vehicle-appointment"
          headerIcon="clock"
          headerTitle="Vehicle pickup appointment."
          statusText={isPickUpScheduled ? "Completed" : "To-do"}
          statusType={isPickUpScheduled ? "success" : "secondary"}
          bodyTitle={
            currentStepState.comingUp.includes("3")
              ? "Once your vehicle is at the dealership and your dealer confirms your order, you'll be able to schedule your pickup time."
              : "Saturday, October 19, 8:00 AM - 9:30 AM"
          }
          bodySubtitle={
            isPickUpScheduled
              ? "Confirmed pickup time:"
              : currentStepState.comingUp.includes("3")
              ? ""
              : "Next available timeslot:"
          }
          buttons={
            isPickUpScheduled
              ? [
                  {
                    linkButton: true,
                    withIcon: true,
                    type: "link",
                    dataIcon: "calendar",
                    text: "Reschedule",
                  },
                ]
              : [
                  {
                    text: "Request appointment",
                    type: "default",
                    disabled:
                      currentStepState.comingUp.includes("3") ||
                      isPickUpScheduled,
                    onClick: () => {
                      setCaseState("RequestAppointment");
                      openDrawer();
                    },
                  },
                  {
                    text: "Pick another time",
                    disabled:
                      currentStepState.comingUp.includes("3") ||
                      isPickUpScheduled,
                    onClick: () => {
                      setCaseState("Pickup");
                      setWaitingHubState((prevstate) => {
                        return { ...prevstate, isPickUpScheduled: true };
                      });
                      moveStep("3", "4");
                      openDrawer();
                    },
                  },
                ]
          }
          accordionItems={vehicleApptItems}
          accordionDefaultActiveKey="0"
        />
      ),
    },
    {
      key: "4",
      component: (
        <AdditionalInsuranceInfo
          key="insurance-info"
          headerTitle="Additional insurance information."
          statusText={isSetInsuranceInfo ? "Completed" : "To-do"}
          statusType={isSetInsuranceInfo ? "success" : "secondary"}
          bodyTitle={
            currentStepState.comingUp.includes("4")
              ? "Once your vehicle pickup appointment is confirmed, you'll be able to provide additional insurance information."
              : "State Farm"
          }
          bodySubtitle={
            currentStepState.comingUp.includes("4") ? "" : "Insurance provider"
          }
          buttons={
            isSetInsuranceInfo
              ? [
                  {
                    linkButton: true,
                    withIcon: true,
                    type: "link",
                    dataIcon: "edit",
                    text: "Review & edit",
                  },
                ]
              : [
                  {
                    text: isSetInsuranceInfo ? "complete" : "Provide now",
                    type: "default",
                    disabled:
                      currentStepState.comingUp.includes("4") ||
                      isSetInsuranceInfo,
                    onClick: () => {
                      setCaseState("Insurance");
                      openDrawer();
                      setWaitingHubState((prevstate) => {
                        return { ...prevstate, isSetInsuranceInfo: true };
                      });
                      moveStep("4", "1");
                    },
                  },
                ]
          }
          additionalInfo={
            !currentStepState.comingUp.includes("4") &&
            !isSetInsuranceInfo &&
            "You may provide additional information online or at the dealer."
          }
        />
      ),
    },
    {
      key: "5",
      component: (
        <PaymentInfo
          key="payment-info"
          headerTitle="Payment."
          headerIcon="credit_card"
          bodySubtitle="Due-at-Signing:"
          bodyTitle={
            isPaymentSet ? (
              <p>
                You have paid your amount Due-at-Signing of{" "}
                <span className="fw-bold">$X,XXX.XX.</span>
              </p>
            ) : currentStepState.current.includes("5") ? (
              <p>
                you may pay your amount Due-at-Signing of{" "}
                <span className="fw-bold">$X,XXX.XX</span> online or at the
                dealer.
              </p>
            ) : (
              <p className="text--body-1">
                Once your contracts are signed, you may pay your amount
                Due-at-Signing of <span className="fw-bold">$X,XXX.XX</span>{" "}
                online or at the dealer.
              </p>
            )
          }
          bodyButtons={
            isPaymentSet
              ? [
                  {
                    linkButton: true,
                    withIcon: true,
                    type: "link",
                    dataIcon: "eye",
                    text: "Review",
                  },
                ]
              : [
                  {
                    text: "Pay now",
                    type: "default",
                    disabled:
                      currentStepState.comingUp.includes("5") || isPaymentSet,
                    onClick: () => {
                      setCaseState("paymentMethod");
                      setWaitingHubState((prevstate) => {
                        return { ...prevstate, isPaymentSet: true };
                      });
                      moveCompleted("5");
                      openDrawer();
                    },
                  },
                ]
          }
          footerSubtitle={
            isPaymentSet ? "Payment options:" : "Additional payment settings:"
          }
          footerTitle={
            isPaymentSet
              ? "Thank you for setting up EasyPay."
              : currentStepState.comingUp.includes("5")
              ? "Opt in to automatic recurring payments with EasyPay, which lets you set your payment schedule for automatic withdrawal."
              : "Automatic recurring payments with EasyPay (optional)."
          }
          footerButtons={
            isPaymentSet
              ? [
                  {
                    linkButton: true,
                    withIcon: true,
                    type: "link",
                    dataIcon: "eye",
                    text: "Review",
                  },
                ]
              : [
                  {
                    text: "Set up",
                    disabled:
                      currentStepState.comingUp.includes("5") || isPaymentSet,
                    onClick: () => {
                      setCaseState("paymentSettings");
                      openDrawer();
                    },
                  },
                ]
          }
        />
      ),
    },
  ];

  return (
    <>
      <div className="state-card-wrapper">
        <div className="bmw-container">
          <div className="state-card__main">
            <div className="state-card__inner">
              <div className="state-card__title">
                {isPaymentSet ? (
                  <h3>Completed</h3>
                ) : (
                  <>
                    <h3 className="text--h3">Get a head start today.</h3>
                    <span
                      className="bmw-icon bmw-icon--before data-icon"
                      data-icon="lock_unlocked"
                    />
                  </>
                )}
              </div>
              <div className="state-card__content">
                {currentStepState.current.map((id) => {
                  const step = listOfSteps.find((item) => item.key === id);
                  return step?.component;
                })}
              </div>
            </div>
            {!isPaymentSet && (
              <div className="state-card__inner">
                <div className="state-card__title">
                  <h3 className="text--h3">Coming up next.</h3>
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="lock_locked"
                  />
                </div>
                <div className="state-card__content">
                  {currentStepState.comingUp.map((id) => {
                    const step = listOfSteps.find((item) => item.key === id);
                    return step?.component;
                  })}
                </div>
              </div>
            )}
            {currentStepState.completed.length > 0 && (
              <div className="state-card__inner">
                <div className="state-card__title">
                  <h3 className="text--h3">Completed</h3>
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="checkmark"
                  />
                </div>
                <div className="state-card__content">
                  {currentStepState.completed.map((id) => {
                    const step = listOfSteps.find((item) => item.key === id);
                    return step?.component;
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <OffshootsJourneyDrawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        caseState={caseState}
      />
    </>
  );
};
