import { Popover } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const TotalContributionAccordionContent = () => {
  const cashDownAccordionItems = [
    {
      key: "1",
      label: "Incentives & Rebates",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Loyalty Credit
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  New York Incentive
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">-$1,000</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Trade-in Equity
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="Trade-in equity is the value of your current vehicle that is applied toward your new purchase. Positive equity reduces the amount you finance, while negative equity may increase your financial arrangement balance."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Net offer value
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Dealer Contribution
                  <Popover
                    trigger={["hover"]}
                    placement="top"
                    disclaimerText='Similar to a Down payment, money provided by the lessee at inception reduces the capitalized cost of the lease. Your total amount "Due-at-Signing" may include additional amounts. A full breakdown of your total "Due-at-Signing" is provided after all estimator steps are completed.'
                  >
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </Popover>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Adjusted Payoff Amount
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">-$500</span>,
    },
  ];

  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Cash down
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText='Similar to a Down payment, money provided by the lessee at inception reduces the capitalized cost of the lease. Your total amount "Due-at-Signing" may include additional amounts. A full breakdown of your total "Due-at-Signing" is provided after all estimator steps are completed.'
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              -$8,500
            </span>
          </div>
          <Accordion
            items={cashDownAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
      </div>
    </>
  );
};
