import "./multi-swatch-circles.scss";
import { MultiSwatchCircleProps } from "./multi-swatch-circles-props";
import clsx from "clsx";
import { Image } from "@src/components/atoms";

export const MultiSwatchCircles = ({
  className,
  images = [],
  maxVisible = 3,
}: MultiSwatchCircleProps) => {
  const visibleImages = images.slice(0, maxVisible);
  const extraCount = images.length - maxVisible;
  const showExtra = extraCount > 0;

  return (
    <div className={clsx(className, "multi-swatch-wrapper")}>
      {visibleImages.map((imgSrc, index) => {
        const isLast = index === visibleImages.length - 1 && showExtra;
        return (
          <div
            key={index}
            className={`multi-swatch__circles multi-swatch-circles--${
              index + 1
            }`}
          >
            <div className="multi-swatch-circles__image">
              <Image
                src={imgSrc}
                alt={`swatch-${index + 1}`}
                aspectRatio="1:1"
              />
            </div>
            {isLast && (
              <span className="multi-swatch-circles__count text--label fw-normal">
                +{extraCount}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
