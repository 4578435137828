import { useAppContext } from "@src/context/app-context";
import { useEffect, useRef } from "react";
import { useGetOrderInfo } from "./order";
import { setOrderInfo } from "@src/context/app-actions";
import { useLocation, useNavigate } from "react-router-dom";
import { removeNullValues } from "@src/helpers/appHelper";
import { isEmpty } from "lodash";

export const extractOrderParams = (url) => {
  const dealRefRegex = /deal\/([^/]+)/;
  const dealRefMatch = url.match(dealRefRegex);

  return {
    dealRefNumber: dealRefMatch ? dealRefMatch[1] : null,
  };
};

export const useUrlParamsForOrderInfo = (userCredentials) => {
  const {
    state: { order },
    dispatch: appDispatch,
  } = useAppContext();
  const { mutate: getOrderInfo } = useGetOrderInfo();
  const initialFetch = useRef(false);
  const location = useLocation(); // Access the current location object (URL)

  useEffect(() => {
    if (
      !isEmpty(userCredentials) &&
      userCredentials.access_token &&
      !initialFetch.current
    ) {
      initialFetch.current = true;
      // Extract VIN and order number from the current URL
      const { dealRefNumber } = extractOrderParams(location.pathname);
      if (!dealRefNumber) {
        return; // Do nothing if dealRefNumber is not present in the URL
      }

      // Check if order info for the current dealRefNumber is already in the state
      if (order && order.reference_number === dealRefNumber) {
        return; // Skip the API call if order info is already available
      }

      getOrderInfo(dealRefNumber, {
        onSuccess: (response) => {
          appDispatch(
            setOrderInfo({ ...order, ...removeNullValues(response) })
          );
        },
        onError: (error) => {
          console.error("error getting order information:", error);
        },
      });
    }
  }, [location.pathname, userCredentials]);
};
