import { CashTabContent } from "../update-payment-terms-drawer-content/cash-tab-content/cash-tab-content";
import { FinanceTabContent } from "../update-payment-terms-drawer-content/finance-tab-content/finance-tab-content";
import { LeaseTabContent } from "../update-payment-terms-drawer-content/lease-tab-content/lease-tab-content";
import "./checkout-pricing-details-drawer-content.scss";

export const CheckoutPricingDetailsDrawerContent = () => {
  return (
    <>
      <div className="checkout-pricing-details">
        <div className="checkout-pricing-details__lease">
          <div className="checkout-pricing-details__heading">
            <h4 className="headline">Pricing details.</h4>
            <p className="text--body-2">
              Offer approved by [BMW Financial service].
            </p>
          </div>
          <LeaseTabContent />
        </div>
        <div className="checkout-pricing-details__finance d-none">
          <div className="checkout-pricing-details__heading">
            <h4 className="headline">Pricing details.</h4>
            <p className="text--body-2">
              Offer approved by [BMW Financial service].
            </p>
          </div>
          <FinanceTabContent />
        </div>
        <div className="checkout-pricing-details__cash d-none">
          <div className="checkout-pricing-details__heading">
            <h4 className="headline">Pricing details.</h4>
          </div>
          <CashTabContent />
        </div>
      </div>
    </>
  );
};
