import { Button } from "@src/components/atoms";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./menu.scss";
import { ROUTING_PATH } from "@src/application/application-router";
import { useAppContext } from "@src/context/app-context";
import { ShowBackButtonOnGivenURLs } from "@src/constants/enum";
import { toggleModalAction } from "@src/context/app-actions";
import { generateUrl } from "@src/helpers/payloadHelper";

export const Menu = () => {
  const location = useLocation();
  const { dispatch: appDispatch } = useAppContext();
  const { dealRefNumber } = useParams();
  const windowLocation = useLocation();

  const shouldShowButton = ShowBackButtonOnGivenURLs.some((path) =>
    location.pathname.includes(path)
  );
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const MenuItems = {
    build_your_deal: {
      isBackIconEnable: true,
      isMenuIconEnable: false,
      isNavigationAllowed: true,
      mainText: "Build my deal",
      menu_paths: [
        ROUTING_PATH.INCENTIVES_AND_DISCOUNTS_PAGE,
        ROUTING_PATH.TRADE_IN_PAGE,
        ROUTING_PATH.PROTECTION_AND_WARRANTY_PAGE,
        ROUTING_PATH.ACCESSORIES_PAGE,
        ROUTING_PATH.REVIEW_PAGE,
      ],
      routes: [
        {
          text: "Incentives",
          url: generateUrl(
            "incentives-and-discounts",
            dealRefNumber,
            windowLocation
          ),
          active: false,
        },
        {
          text: "Trade-In",
          url: generateUrl("trade-in", dealRefNumber, windowLocation),
          active: false,
        },
        {
          text: "Protection",
          url: generateUrl(
            "protection-and-warranty",
            dealRefNumber,
            windowLocation
          ),
          active: false,
        },
        {
          text: "Accessories",
          url: generateUrl("accessories", dealRefNumber, windowLocation),
          active: false,
        },
        {
          text: "Review",
          url: generateUrl("review", dealRefNumber, windowLocation),
          active: false,
        },
      ],
    },
    checkout: {
      isBackIconEnable: false,
      isMenuIconEnable: true,
      isNavigationAllowed: false,
      mainText: "Purchase online",
      menu_paths: [
        ROUTING_PATH.CHECKOUT,
        ROUTING_PATH.FINANCE_OFFER,
        ROUTING_PATH.DEAL_CONFIRMATION_PAGE,
        ROUTING_PATH.CONTRACT_PREVIEW_PAGE,
      ],
      routes: [
        {
          text: "Apply for credit",
          url: `${ROUTING_PATH.CHECKOUT}`,
          active: false,
        },
        {
          text: "Submit Order",
          url: `${ROUTING_PATH.FINANCE_OFFER}`,
          active: false,
        },
        {
          text: "Finalize your deal",
          url: `${ROUTING_PATH.DEAL_CONFIRMATION_PAGE}`,
          active: false,
        },
      ],
    },
  };

  // Determine which menu section to display based on the current URL path
  const activeMenuKey = Object.keys(MenuItems).find((key) =>
    MenuItems[key].menu_paths.some((path) => {
      if (currentPath.includes(ROUTING_PATH.CONTRACT_PREVIEW_PAGE)) {
        return path === ROUTING_PATH.DEAL_CONFIRMATION_PAGE;
      }
      return currentPath.includes(path);
    })
  );
  const activeMenu = activeMenuKey
    ? MenuItems[activeMenuKey]
    : MenuItems.build_your_deal;

  // Find the index of the current route in the active menu
  const currentRouteIndex = activeMenu.routes.findIndex((route) => {
    if (currentPath.includes(ROUTING_PATH.CONTRACT_PREVIEW_PAGE)) {
      return route.url.includes(ROUTING_PATH.DEAL_CONFIRMATION_PAGE);
    }
    return route.url === currentPath;
  });

  // Update routes with active, icon, and disabled states
  const menuRoutes = activeMenu.routes.map((route, index) => {
    const isActive = currentPath.includes(ROUTING_PATH.CONTRACT_PREVIEW_PAGE)
      ? route.url.includes(ROUTING_PATH.DEAL_CONFIRMATION_PAGE)
      : route.url === currentPath;
    let icon: string | null = null;
    let isDisabled = false;

    if (activeMenu.isMenuIconEnable && currentRouteIndex !== -1) {
      if (index < currentRouteIndex) {
        icon = "checkmark";
      } else if (index === currentRouteIndex) {
        icon = "";
      } else {
        icon = "lock_locked";
        isDisabled = true;
      }
    }

    return {
      ...route,
      active: isActive,
      icon,
      disabled: isDisabled,
    };
  });

  const handleNavigation = (url: string) => {
    if (activeMenu.isNavigationAllowed) {
      navigate(url);
    }
  };

  return (
    <div
      className={clsx("menu", { "with-back-btn": activeMenu.isBackIconEnable })}
    >
      <div className="menu-desktop">
        <div className="menu-item-container menu-title-wrap">
          {activeMenu.isBackIconEnable && (
            <div
              className="bmw-icon bmw-icon--before data-icon back-btn cursor-pointer"
              data-icon="arrow_left"
              role="button"
              tabIndex={0}
              onClick={() => {
                localStorage.setItem("redirectToDealerWebsite", "true");
                appDispatch(
                  toggleModalAction({
                    name: "back-to-shop-modal",
                    isOpen: true,
                  })
                );
              }}
            />
          )}
          <p className="menu-title text--body-2 d-md-block d-none">
            {activeMenu.mainText}
          </p>
        </div>
        <div className="menu-divider d-md-block d-none"></div>
        <div className="menu-item-container menu-overflow">
          {menuRoutes.map((route) => (
            <div className="menu-item" key={route.url}>
              <Button
                htmlType="button"
                text={route.text}
                linkButton
                className={clsx({ "menu-item--active": route.active })}
                onClick={() => handleNavigation(route.url)}
                {...(activeMenu.isMenuIconEnable
                  ? {
                      withIcon: true,
                      isIconEnd: true,
                      dataIcon: route.icon,
                      disabled: route.disabled,
                    }
                  : {})}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
