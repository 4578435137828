import { ChatAutomatedMessage } from "../chat-automated-message/chat-automated-message.component";
import { ChatHoursWidget } from "../chat-hours-widget/chat-hours-widget.component";
import ChatWidgetBanner from "@src/shared/assets/img/chat-widget-banner.png";
import Logo from "@src/shared/assets/img/logo.jpg";
import { ChatRequestForm } from "../chat-request-form/chat-request-form.component";
import { Button } from "@src/components/atoms/button/button.component";
import "./chat-offline-conscent.scss";
import { ChatAccordion } from "../chat-accordion/chat-accordion.component";

export const ChatOfflineConscent = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  return (
    <div className="chat-offline-conscent-wrap">
      <div className="chat-body-wrap--overflow scroll">
        <ChatAutomatedMessage
          logo={<img className="chat-header--logo" src={Logo} alt="BMW Logo" />}
          source="Automated message"
          time="12:17 PM"
          message={
            <p className="text--body-2">
              Hello! <span className="fw-normal">BMW of Manhattan</span> chat is
              currently offline. You may leave a message and an agent will
              respond via email at the earliest opportunity.]
            </p>
          }
        />
        <ChatHoursWidget
          bannerImage={<img src={ChatWidgetBanner} alt="widget banner" />}
          dealerName="BMW of Manhattan"
          dealerAddress={
            <div>
              <div>793 11th Ave. New York, NY 30009</div>
              <div>(555) 555-1234</div>
            </div>
          }
          workingDaysTimgs="9 AM – 9 PM"
          saturdayTimgs="11 AM – 6 PM"
          sundayTimgs="Closed"
        />
        {/* <ChatAccordion 
          label="Why is this required?"
          message="[Reason for dealer requiring this info before customer can start Chat]"
        /> */}
        <ChatRequestForm />
      </div>
      {/* <div className="chat-body-wrap--overflow scroll">
        <div className="chat-body--message-sent">
          <ChatAutomatedMessage
            logo={<img className="chat-header--logo" src={Logo} alt="BMW Logo" />}
            source="Automated message"
            time="12:17 PM"
            message={
              <div>
                <p className="text--body-2">
                  Thank you. An agent will respond via the above email as soon as possible.
                </p>
              </div>
            }
          />
          <Button
            htmlType={"button"}
            text="Close"
            fullWidth
            type="default"
            size="small"
            onClick={() => setOpen(false)}
          />
        </div>
      </div> */}
    </div>
  );
};
