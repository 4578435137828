import { useState } from "react";
import { Button, Checkbox, Input, Radio, Select } from "@src/components/atoms";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";
import { Col, Row } from "antd";
import "./payment-settings.scss";
import { PaymentSwitch } from "./payment-settings-switch";

export const PaymentSettings = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  return (
    <div className="offshoots-journey-payment-settings">
      <h3>Payment settings</h3>
      <PaymentSwitch isSwitchOn={isSwitchOn} onToggle={setIsSwitchOn} />
      {isSwitchOn && (
        <>
          <div className="payment-settings__form">
            <div className="payment-settings__personal-info">
              <h5>Personal information</h5>
              <Row
                gutter={[
                  { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
                  { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
                ]}
              >
                <Col xs={24} sm={24} md={8}>
                  <Input
                    id={"firstName"}
                    name={""}
                    label="First name"
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Input id={"lastName"} name={""} label="Last name" disabled />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Input id={"vin"} name={""} label="VIN" disabled />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    id={"emailAddress"}
                    name={""}
                    label="Email address"
                    placeholder="Enter"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Phone name="phone-input" country="us" label="Phone number" />
                </Col>
              </Row>
            </div>
            <div className="payment-settings__bank-info">
              <h5>Bank information</h5>
              <Row
                gutter={[
                  { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
                  { xs: 12, sm: 12, md: 12, lg: 16, xl: 20 },
                ]}
              >
                <Col xs={24}>
                  <Input
                    id={"nameOnBankAccount"}
                    name={""}
                    label="Name on bank account"
                    placeholder="Enter"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    id={"bankName"}
                    name={""}
                    label="Bank name"
                    placeholder="Enter"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Phone
                    name="phone-input"
                    country="us"
                    label="Bank phone number"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    id={"bankRoutingNumber"}
                    name={""}
                    label="Bank routing number"
                    placeholder="Enter"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    id={"bankAccountNumber"}
                    name={""}
                    label="Bank account number"
                    placeholder="Enter"
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Select
                    id={"accountType"}
                    label="Account type"
                    placeholder="Select"
                    options={[
                      { value: "2", label: "Option 1" },
                      { value: "2", label: "Option 2", disabled: true },
                    ]}
                  />
                </Col>
              </Row>
            </div>
            <div className="payment-settings__easypay">
              <div className="payment-settings-easypay__title">
                <h5>EasyPay Settings</h5>
                <p className="text--body-2">
                  Please note you will need to continue making regular monthly
                  payments until EasyPay enrollment is confirmed.
                </p>
              </div>
              <div className="payment-settings-easypay__radio">
                <p className="text--body-2">EasyPay start date</p>
                <div className="payment-settings-easypay__radio-inner">
                  <Radio
                    label="[month +1], [day]"
                    id="month_1"
                    htmlFor="month_1"
                    size="small"
                    name="date"
                    checked
                  />
                  <Radio
                    label="[month +2], [day]"
                    id="month_2"
                    htmlFor="month_2"
                    size="small"
                    name="date"
                  />
                  <Radio
                    label="[month +3], [day]"
                    id="month_3"
                    htmlFor="month_3"
                    size="small"
                    name="date"
                  />
                </div>
              </div>
              <div className="payment-settings-easypay__radio">
                <p className="text--body-2">Payment amount</p>
                <div className="payment-settings-easypay__radio-inner">
                  <Radio
                    label="$X,XXX.XX /month"
                    id="amount"
                    htmlFor="amount"
                    size="small"
                    name="amount"
                    checked
                  />
                </div>
              </div>
            </div>
            <p className="text--label">
              An email confirmation will be sent to your email provided above.
              Once submitted, you will not be able to cancel your autopay setup
              during Checkout. After the deal is finalized, any changes to
              autopay, including cancellations, must be managed directly through
              the MyBMW platform.{" "}
            </p>
          </div>
          <div className="divider"></div>
          <div className="payment-settings__paperless">
            <h5>Go Paperless (optional)</h5>
            <p className="text--body-2">
              Receive your statements in paperless format lorem ipsum.
            </p>
            <Checkbox
              checked
              id={"idHerebyChk"}
              label="Opt in for paperless statements delivered via email."
              htmlFor="idHerebyChk"
              isCompact
            />
          </div>
        </>
      )}
      <div className="payment-settings__cta">
        <Button
          type="primary"
          htmlType={"button"}
          text="Agree & continue"
          disabled
        />
        <p className="text--label">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </div>
    </div>
  );
};
