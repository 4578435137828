import { ChatHeaderProps } from "./chat-header.props";
import Logo from "@src/shared/assets/img/logo.jpg";
import clsx from "clsx";
import { OverflowMenu } from "@src/components/atoms";
import userImage from "@src/shared/assets/img/user.svg";
import "./chat-header.scss";

interface ChatHeaderExtendedProps extends ChatHeaderProps {
  setOpen: (open: boolean) => void;
  status?: "online" | "offline" | "agentJoins";
}

export const ChatHeader: React.FC<ChatHeaderExtendedProps> = ({
  status,
  setOpen,
}) => {
  const menuItems = [
    {
      key: "1",
      label: <p className="text--cta-2">Request a test drive</p>,
      dataIconRight: "steering_wheel",
    },
    {
      key: "2",
      label: <p className="text--cta-2">Schedule a visit</p>,
      dataIconRight: "calendar",
    },
    {
      key: "3",
      label: <p className="text--cta-2">Request co-browsing</p>,
      dataIconRight: (
        <img src={userImage} alt="user-icon" className="user-icon" />
      ),
    },
    {
      key: "4",
      label: <p className="text--cta-2">End chat with agent</p>,
      dataIconRight: "exit",
    },
  ];

  return (
    <div
      className={clsx("chat-header-wrap", {
        "is-online": status !== "offline",
      })}
    >
      <div className="chat-header--left">
        <img className="chat-header--logo" src={Logo} alt="BMW Logo" />
        <span className="chat-header--text">
          <p className="text--body-2">
            BMW My <span className="fw-bold">Way</span>
          </p>
        </span>
        <div className="chat-header--status">
          {status === "online" || status === "agentJoins" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle cx="8" cy="8" r="4" fill="#3DB014" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle cx="8" cy="8" r="3.5" stroke="white" />
            </svg>
          )}
          {status === "online" || status === "agentJoins" ? (
            <span className="text--disclaimer">Online</span>
          ) : (
            <span className="text--disclaimer">Offline</span>
          )}
        </div>
      </div>
      <div className="chat-header--right">
        <OverflowMenu
          items={menuItems}
          menuButtonView={true}
          position="topRight"
          trigger={
            <span className="cursor-pointer chat-menu-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                  fill="#F2F2F2"
                  stroke="#F2F2F2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                  stroke="#F2F2F2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                  stroke="#F2F2F2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          }
        />

        <span
          className="bmw-icon bmw-icon--before data-icon cursor-pointer"
          data-icon="arrows_minimize"
          role="button"
          tabIndex={0}
          onClick={() => setOpen(!open)}
        ></span>
      </div>
    </div>
  );
};
