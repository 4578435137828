import { Button, Checkbox, Input } from "@src/components/atoms";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { jointCustomerValidator } from "@src/validators";
import "./co-applicant-details.scss";
import { useAppContext } from "@src/context/app-context";
import { useCreateCustomer, useCustomerUpdate } from "@src/hooks";
import { setOrderInfo } from "@src/context/app-actions";

export const CoApplicantDetails = ({ dispatch, stepOneDataReceivedEvent }) => {
  const {
    state: { order },
    dispatch: appDispatch,
  } = useAppContext();
  const { id, coapplicant_info, dealer_id } = order;
  const { mutateAsync: updateCustomerData, isPending } = useCustomerUpdate({
    order_id: id,
  });
  const { mutate: createCustomerByEmail, isPending: upsertCustomerLoading } =
    useCreateCustomer();
  const { control, handleSubmit, watch } = useForm<any>({
    resolver: yupResolver(jointCustomerValidator),
    defaultValues: {
      first_name: coapplicant_info.first_name,
      updated_by: coapplicant_info.email,
      co_applicant_check: !!coapplicant_info.email,
    },
  });

  const onSubmit: SubmitHandler<{ first_name: string; updated_by: string }> = (
    values
  ) => {
    const { first_name, updated_by } = values;
    createCustomerByEmail(
      { email: updated_by, dealer_id: dealer_id },
      {
        onSuccess: (customerBasicInfo) => {
          updateCustomerData(
            {
              customer_profile: {
                updated_by: updated_by,
                first_name: first_name,
              },
              reference_id: customerBasicInfo.reference_id,
              type: "",
            },
            {
              onSuccess: (customerInfo) => {
                appDispatch(
                  setOrderInfo({
                    ...order,
                    coapplicant_info: {
                      ...order.coapplicant_info,
                      ...customerBasicInfo,
                      first_name: first_name,
                    },
                  })
                );
              },
              onError: (error) => {
                console.error("Error fetching customer:", error);
              },
            }
          );
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  };

  return (
    <div className="co-applicant-details-wrap">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="co-applicant-details-wrap--form"
      >
        <p className="text--body-2">
          Please provide your co-applicant&apos;s email address to invite them
          to this platform.
        </p>
        <div className="co-applicant-details-wrap--form-row">
          <div className="flex-1">
            <Controller
              name="first_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id="first_name"
                  placeholder="Co-applicant name"
                  label="Co-applicant name"
                  disabled={isPending}
                  error={error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex-1">
            <Controller
              name="updated_by"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id="email"
                  placeholder="Co-applicant email address"
                  label="Co-applicant email address"
                  disabled={isPending}
                  error={error}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="co_applicant_check"
          control={control}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <Checkbox
              id="coApplicantNameCheck"
              checked={value}
              label="By entering my co-applicant's name and email address, I understand that my co-applicant will receive an email inviting them to this platform. I represent that I have their permission to provide their information for this invite."
              htmlFor="coApplicantNameCheck"
              error={error}
              {...rest}
            />
          )}
        />

        <div className="form-wrapper--button-top-spacer">
          <Button
            type="default"
            disabled={!watch("co_applicant_check")}
            isLoading={isPending || upsertCustomerLoading}
            htmlType="submit"
            text="Send Invite"
            fixed
            fullWidth
          />
        </div>
      </form>
    </div>
  );
};
