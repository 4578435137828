import { useState } from "react";
import { Col, Row } from "antd";
import "./contract-preview.scss";
import { Button } from "@src/components/atoms";
import { ContractList, ContractRightRail } from "@src/components/molecules";
import { OffshootsJourneyDrawer } from "../offshoots-journey-drawer/offshoots-journey-drawer";

export const ContractPreview = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <div className="bmw-container">
      <div className="contract-preview-container">
        <Row gutter={24} justify="space-between">
          <Col xs={24} lg={14}>
            <div className="contract-preview__content">
              <div>
                <div className="contract-preview-back__btn">
                  <Button
                    type="link"
                    linkButton
                    withIcon
                    dataIcon="arrow_left"
                    htmlType={"button"}
                    text="Back to [waiting hub]"
                  />
                </div>
                <h3>Contracts & documents.</h3>
                <p className="text--body-2">
                  The following items may be signed digitally, or at the
                  dealer.Any item marked “Preview” has not yet been reviewed by
                  your dealer, and will become available shortly.
                </p>
              </div>
              <div className="contract-preview__file">
                <div className="contract-preview-file__list">
                  {[...Array(6)].map((_, index) => (
                    <ContractList
                      key={index}
                      title="Uploaded file name"
                      buttons={[
                        {
                          text: "Preview",
                          onClick: () => ({}),
                        },
                      ]}
                    />
                  ))}
                </div>
                <div className="contract-preview__signature">
                  <p className="text--body-2">
                    The following items will require a wet signature at pickup.
                  </p>
                  <div className="contract-preview__list">
                    <ContractList
                      title="Uploaded file name"
                      buttons={[
                        {
                          text: "Preview",
                          onClick: () => ({}),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <ContractRightRail title="These contracts are subject to change. You’ll be able to review these again 24 hours from your pickup time." />
              </div>
              <Button
                type="primary"
                htmlType={"button"}
                text="Start signing"
                onClick={openDrawer}
              />
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="d-none d-lg-block">
              <ContractRightRail title="These contracts and documents are subject to change. You’ll be able to review these again 24 hours from your pickup time." />
            </div>
          </Col>
        </Row>
      </div>
      <OffshootsJourneyDrawer open={isDrawerOpen} onClose={closeDrawer} />
    </div>
  );
};
