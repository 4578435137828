import { MessageAlert } from "@src/components/atoms";
import { MaterialStatusCheck } from "./material-status-check/material-status-check.component";
import { SpouseName } from "./spouse-name/spouse-name.component";
import "./spouse-info-disclosure.scss";
import { SpouseAddress } from "./spouse-address/spouse-address.component";
import { Address } from "@src/components/organisms/credit-application/checkout/02-current-and-past-addresses";
import { SpouseCoApplicant } from "./spouse-co-applicant/spouse-co-applicant.component";
import { useEffect, useState } from "react";
import { STATES_REQUIRING_SPOUSE_INFO } from "@src/helpers/enum";

export const SpouseInfoDisclosure = ({ control, watch, stateOptions }) => {
  const [showWisconsinMartialCheck, setShowWisconsinMartialCheck] =
    useState(false);
  const spouseAddressSameAsMailing = watch("spouseAddressSameAsMailing");
  const isMarried = watch("isMarried");
  const isSpouseCoApplicant = watch("isSpouseCoApplicant");
  const isCoApplicant = watch("isCoApplicant");
  const mailingAddress = watch("mailing");

  useEffect(() => {
    const requiresSpouseInfo = STATES_REQUIRING_SPOUSE_INFO.includes(
      mailingAddress?.state_name
    );
    if (requiresSpouseInfo) {
      setShowWisconsinMartialCheck(true);
    } else {
      setShowWisconsinMartialCheck(false);
    }
  }, [mailingAddress?.state_name, isMarried]);

  return showWisconsinMartialCheck ? (
    <div className="spouse-info-wrap">
      <MessageAlert
        type="info"
        title="Per state laws, married Wisconsin residents must include their spouse’s information when applying for credit. If your spouse is not a co-applicant, please provide their information below. "
        showCloseButton={false}
      />
      {showWisconsinMartialCheck && <MaterialStatusCheck control={control} />}

      {isCoApplicant && isMarried && <SpouseCoApplicant control={control} />}
      {!isSpouseCoApplicant && isMarried && <SpouseName control={control} />}
      {!isSpouseCoApplicant && isMarried && <SpouseAddress control={control} />}
      {!isSpouseCoApplicant && !spouseAddressSameAsMailing && isMarried && (
        <div>
          <Address
            type="spouse.address"
            control={control}
            stateOptions={stateOptions}
          />
        </div>
      )}
    </div>
  ) : null;
};
