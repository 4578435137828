import { Button } from "@src/components/atoms";
import "./financial-information-completed.style.scss";
import dayjs from "dayjs";
import {
  calculateDateDifference,
  formatCurrency,
  formatUSPhoneNumberWithCountryCode,
} from "@src/helpers/appHelper";
import { ResidenceType } from "@src/constants";

export const FinancialInformationCompleted = ({
  changeStateOfEditModeHandler,
  customer: {
    income_info,
    customer_addresses: { employment },
    financial_profile,
  },
}: {
  changeStateOfEditModeHandler: (step: string) => void;
  customer;
}) => {
  const employedStatus = income_info.employment_type === "Employed";
  const selfAndEmployedStatus = ["Employed", "Self Employed"].includes(
    income_info.employment_type
  );
  return (
    <div className="checkout-step-financial-info">
      <div className="checkout-success-financial-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Financial Information.
          </h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-three")}
          size="small"
        />
      </div>
      <div className="financial-success-info">
        <div className="financial-success-info-container">
          <div className="financial-row-grid">
            {employedStatus && (
              <div className="financial-row">
                <p className="financial-success-title text--disclaimer text-secondary">
                  Occupation
                </p>
                <p className="financial-success-subtitle text--body-2">
                  {income_info.occupation}
                </p>
              </div>
            )}

            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Employment type
              </p>
              <p className="financial-success-subtitle text--body-2">
                {income_info.employment_type}
              </p>
            </div>
            {employedStatus && (
              <>
                <div className="financial-row">
                  <p className="financial-success-title text--disclaimer text-secondary">
                    Annual income
                  </p>
                  <p className="financial-success-subtitle text--body-2">
                    {formatCurrency(income_info.annual_employment_income)}
                  </p>
                </div>
                <div className="financial-row">
                  <p className="financial-success-title text--disclaimer text-secondary">
                    Start date
                  </p>
                  <p className="financial-success-subtitle text--body-2">
                    {dayjs(income_info.employed_since).format("YYYY-MM-DD")}
                  </p>
                </div>
                <div className="financial-row">
                  <p className="financial-success-title text--disclaimer text-secondary">
                    Duration of employment
                  </p>
                  <p className="financial-success-subtitle text--body-2">
                    {calculateDateDifference(income_info.employed_since)}
                  </p>
                </div>
                <div className="financial-row">
                  <p className="financial-success-title text--disclaimer text-secondary">
                    Employer name
                  </p>
                  <p className="financial-success-subtitle text--body-2">
                    {income_info.name}
                  </p>
                </div>
                <div className="financial-row">
                  <p className="financial-success-title text--disclaimer text-secondary">
                    Employer phone number
                  </p>
                  <p className="financial-success-subtitle text--body-2">
                    {formatUSPhoneNumberWithCountryCode(income_info.phone)}
                  </p>
                </div>
              </>
            )}
          </div>
          {selfAndEmployedStatus && (
            <div>
              <p className="financial-success-title text--disclaimer text-secondary">
                Address
              </p>
              <div className="financial-address">
                <p className="financial-success-subtitle text--body-2">
                  {employment.address_line_1}, {employment.city}{" "}
                  {employment.state_name}, {employment.county}
                </p>
                <p className="financial-success-subtitle text--body-2">
                  {employment.zip_code}, United States
                </p>
              </div>
            </div>
          )}
        </div>

        {(income_info.annual_other_income ||
          income_info.source_of_other_income) && (
          <>
            <div className="financial-residence-block" />
            <div className="financial-success-info-container">
              <div className="financial-row-grid">
                {income_info.annual_other_income && (
                  <div className="financial-row mb-0">
                    <p className="financial-success-title text--disclaimer text-secondary">
                      Additional annual income
                    </p>
                    <p className="financial-success-subtitle text--body-2">
                      {formatCurrency(income_info.annual_other_income)}
                    </p>
                  </div>
                )}
                {income_info.source_of_other_income && (
                  <div className="financial-row mb-0">
                    <p className="financial-success-title text--disclaimer text-secondary">
                      Source
                    </p>
                    <p className="financial-success-subtitle text--body-2">
                      {income_info.source_of_other_income}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="financial-residence-block" />
        <div className="financial-rent-container">
          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Residence status
            </p>
            <p className="financial-success-subtitle text--body-2">
              {financial_profile.residence}
            </p>
          </div>
          {[ResidenceType.Mortgage, ResidenceType.Renting].includes(
            financial_profile.residence
          ) && (
            <div>
              <p className="financial-success-title text--disclaimer text-secondary">
                Monthly Payments
              </p>
              <p className="financial-success-subtitle text--body-2">
                ${financial_profile.monthly_payment}
              </p>
            </div>
          )}

          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Have You Ever Applied for Bankruptcy?
            </p>
            <p className="financial-success-subtitle text--body-2">
              {financial_profile.bankruptcy ? "Yes" : "No"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
