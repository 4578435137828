import { Button } from "@src/components/atoms";
import "./finance-offer-provider-completed.style.scss";
import BMWLogo from "../../../../../../shared/assets/img/onwhite.svg";
import { useAppContext } from "@src/context/app-context";

export const FinanceOfferProviderCompleted = ({
  changeStateOfEditModeHandler,
}: {
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  const {
    state: {
      lender: { defaultLender },
      dealer: { dealer_name },
    },
  } = useAppContext();

  return (
    <div className="checkout-step-info-provider">
      <div className="checkout-success-header-provider">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            05
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Select finance offer provider(s).
          </h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-five")}
          size="small"
        />
      </div>
      <div className="financial-success-info-provider">
        <div className="financial-success-info-container-provider">
          <div className="financial-row-grid-provider">
            <div className="financial-row-provider">
              <div className="text-logo-provider">
                <div className="text--body-2 financial-provider-title">
                  {defaultLender.name}
                </div>
                <img src={BMWLogo} alt={"bmw logo"} />
              </div>
            </div>
            <div className="financial-row-provider">
              <div className="text-logo2-provider text--body-2">
                Have my credit run by partners of {dealer_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
