import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const generalContext = createContext({});
export const useGeneralContext = (): any => useContext(generalContext);

export const GeneralContextProvider = ({ children }): any => {
  const params = new URLSearchParams(window.location.search);
  const searchParams: any = {};
  for (const [key, value] of params.entries()) {
    searchParams[key] = value;
  }
  const [contextState, setContextState] = useState({
    estimationAdded: false,
    isauthenticated: false,
    offerErrorParam: false,
    attemptsError: false,
    softcredit: false,
    nocart: false,
    financetype: false,
    dealerPricing: false,
    creditfreez: false,
    contantintroerror: false,
    contantinfoerror: false,
    waitingHubType: "production",
  });

  useEffect(() => {
    setContextState((prevState: any) => ({
      ...prevState,
      estimationAdded:
        searchParams.estimation_added === "true" || prevState.estimationAdded,
      isauthenticated:
        searchParams.is_authenticated === "true" || prevState.isauthenticated,
      offerErrorParam:
        searchParams.offer_error === "true" || prevState.offerErrorParam,
      attemptsError:
        searchParams.attempt_error === "true" || prevState.attemptsError,
      softcredit: searchParams.soft_credit === "true" || prevState.softcredit,
      nocart: searchParams.no_cart === "true" || prevState.nocart,
      financetype:
        searchParams.finance_type || prevState.financetype || "lease",
      dealerPricing:
        searchParams.dealer_pricing === "true" || prevState.dealerPricing,
      creditfreez:
        searchParams.credit_freez === "true" || prevState.creditfreez,
      contantintroerror:
        searchParams.content_intro_error === "true" ||
        prevState.contantintroerror,
      contantinfoerror:
        searchParams.content_info_error === "true" ||
        prevState.contantinfoerror,
      waitingHubType: searchParams.waiting_hub_type || prevState.waitingHubType,
    }));
  }, [window.location.search]);

  return (
    <generalContext.Provider
      value={{
        contextState,
        setContextState,
      }}
    >
      {children}
    </generalContext.Provider>
  );
};
