const UpscopeDealerSide = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://app.upscope.io/embed/"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="Upscope Embed"
        allowFullScreen
      />
    </div>
  );
};

export default UpscopeDealerSide;
