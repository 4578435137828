import { http } from "@src/utilities";
import {
  CONFIG_BASE_URL,
  VEHICLE_DETAIL,
  VEHICLE,
} from "@src/constants/routes";

export const getVehicleDetails = async (
  dealerCode: any,
  company_id: any,
  vin: any
) => {
  return await http(CONFIG_BASE_URL)
    .get(`${VEHICLE}/${vin}?dealer_code=${dealerCode}`, {
      headers: { company_id: company_id.toString() },
    })
    .then((res) => res.data);
};

export const getVehicleByVin = async (
  dealerCode: any,
  company_id: any,
  vin: any
) => {
  return await http(CONFIG_BASE_URL)
    .get(`${VEHICLE_DETAIL}/${dealerCode}/${vin}`, {
      headers: { company_id: company_id.toString() },
    })
    .then((res) => res.data);
};
