import { Radio } from "antd";
import "../../../styles/components/_select-button.scss";
import { SelectButtonProps } from "./select-button.props";

export const SelectButton = ({
  options,
  defaultValue,
  buttonStyle = "solid",
  onChange,
}: SelectButtonProps) => {
  return (
    <div className="select-button-wrapper">
      <Radio.Group
        defaultValue={defaultValue}
        buttonStyle={buttonStyle}
        onChange={(e) => onChange?.(e.target.value)}
      >
        {options.map((option) => (
          <Radio.Button key={option.value} value={option.value}>
            <p className="text--body-2 fw-normal"> {option.label} </p>
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};
