import { Drawer } from "@src/components/atoms";
import {
  AppointmentScheduler,
  AppointmentSchedulerReview,
  PaymentSettings,
  PaymentSettingsReview,
  ProofOfIncentive,
  ProofOfResidence,
  ProvideInsuranceInfo,
  SchedulePickup,
} from "@src/components/molecules";
import "./offshoots-journey-drawer.scss";
import { PaymentMethod, PaymentMethodReview } from "@src/components/organisms";

export const OffshootsJourneyDrawer = ({ open, onClose, caseState = "" }) => {
  return (
    <div className="offshoots-journey-drawer-wrapper">
      <Drawer
        width={336}
        open={open}
        maskClosable
        keyboard
        content={
          <>
            {caseState === "Residence" && <ProofOfResidence />}
            {caseState === "Incetive" && <ProofOfIncentive />}
            {caseState === "Pickup" && <SchedulePickup />}
            {caseState === "Insurance" && <ProvideInsuranceInfo />}
            {caseState === "paymentMethod" && <PaymentMethod />}
            {caseState === "paymentSettings" && <PaymentSettings />}
            {/* <PaymentSettingsReview /> */}
            {caseState === "RequestAppointment" && <AppointmentScheduler />}
            {/* <AppointmentSchedulerReview /> */}
          </>
        }
        onClose={onClose}
      />
    </div>
  );
};
