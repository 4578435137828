import { useState } from "react";
import { ChatAutomatedMessage } from "../chat-automated-message/chat-automated-message.component";
import { ChatSeparator } from "../chat-separator/chat-separator.component";
import Logo from "@src/shared/assets/img/logo.jpg";
import { Button, Input } from "@src/components/atoms";
import { ChatUserSent } from "../chat-user-sent/chat-user-sent.component";
import "./chat-online-conscent.scss";

export const ChatOnlineConscent = () => {
  const [text, setText] = useState("");

  return (
    <div className="chat-online-conscent-wrap">
      <div className="chat-body-wrap--overflow scroll">
        <ChatAutomatedMessage
          logo={<img className="chat-header--logo" src={Logo} alt="BMW Logo" />}
          source="Automated message"
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Welcome, Christopher! We’re here to answer your questions about
                checkout, troubleshoot issues and assist with booking
                appointments.
              </p>
              <p className="text--body-2">
                Please enter your inquiry while we connect you to the next
                available agent.
              </p>
            </>
          }
        />
        <ChatSeparator
          icon="speech_bubble_answer"
          text={
            <p className="text--disclaimer">
              By using the chatbot, you agree (1) to be bound by the{" "}
              <a
                href="/"
                rel="noopener noreferrer"
                className="fw-bold text-underline text-dark"
              >
                BMW Center Terms
              </a>{" "}
              and{" "}
              <a
                href="/"
                rel="noopener noreferrer"
                className="fw-bold text-underline text-dark"
              >
                Privacy Policy
              </a>{" "}
              that govern the use of this chatbot; (2) that we may use
              third-party service providers to provide the chatbot; and (3) that
              we may record and store your interactions with the chatbot.
            </p>
          }
        />
        <ChatUserSent
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Hello! I’d like support for Agreements.{" "}
              </p>
            </>
          }
        />
        <ChatAutomatedMessage
          logo={<img className="chat-header--logo" src={Logo} alt="BMW Logo" />}
          source="Automated message"
          time="12:17 PM"
          message={
            <>
              <p className="text--body-2">
                Thank you. Please hold as we connect you to the next available
                agent.
              </p>
              <p className="text--body-2">
                If there are no agents available in the next X min, you’ll be
                notified by email once an agent responds to your inquiry.{" "}
              </p>
            </>
          }
        />
      </div>
      <div className="chat-input--wrap">
        <Input
          id={""}
          name={""}
          placeholder="Message BMW..."
          autoSize={true && { minRows: 1, maxRows: 4 }}
          type="textarea"
          showCount
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        {text && (
          <div className="chat-send--wrap">
            <Button
              htmlType={"button"}
              type="default"
              onlyIcon
              dataIcon="arrow_up"
              size="small"
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};
