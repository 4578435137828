import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

import "./review.scss";
import {
  FixedVehicleCard,
  ReviewListCard,
  RightRail,
  RightRailFixed,
  VehicleCard,
} from "@src/components/molecules";
import {
  Button,
  Checkbox,
  Drawer,
  FeedbackLabel,
  MessageAlert,
  TagLabel,
  Popover,
  Accordion,
} from "@src/components/atoms";

import Image1 from "../../../../shared/assets/img/incentives-discounts-list-img1.png";
import { useState } from "react";
import { HeroBanner } from "@src/components/molecules/review/hero-banner/hero-banner";
import Image2 from "../../../../shared/assets/img/sheet-protection-product-1.png";
import Image3 from "../../../../shared/assets/img/incentives-discounts-list-img3.png";
import NoImage from "../../../../shared/assets/img/dsx-img-ph.png";

import { ROUTING_PATH } from "@application/application-router";

export const Review = () => {
  const navigate = useNavigate();
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  // Function to toggle the drawer visibility
  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const exampleItems = [
    {
      key: "1",
      label: <span className="text--cta-2">See Details </span>,
      children: (
        <>
          <div className="review-listing-section__list-wrap">
            <ReviewListCard
              image={NoImage}
              title={"Title goes here"}
              subTitle={"Offer valid until XX/XX/20XX"}
              price={"$X,XXX"}
              totalPrice={"Net trade-in value"}
              className=""
              actionsArea={""}
            />
          </div>
          <div className="review-listing-section__list-wrap">
            <ReviewListCard
              image={NoImage}
              title={"Title goes here"}
              subTitle={"Offer valid until XX/XX/20XX"}
              price={"$X,XXX"}
              totalPrice={"Net trade-in value"}
              className=""
              actionsArea={""}
            />
          </div>
        </>
      ),
    },
  ];

  const customAddOnsAccordionItems = [
    {
      key: "1",
      label: (
        <p className="text--body-2 text-secondary">Incentives & rebates</p>
      ),
      extra: <span className="text--body-2 text-secondary">-$750</span>,

      className: "ant-collapse-item__price-accordian",
      children: (
        <>
          <div className="pricing-details__section-row">
            <div className="pricing-details__item">
              <ul>
                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Loyalty Credit
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      New York Incentive
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <p className="accordion-title-with-icon text--body-2 text-secondary incentives-discounts__with-warning-lable">
            Trade-in equity{" "}
            <Popover
              trigger={["hover"]}
              disclaimerText="Trade-in equity is the value of your current vehicle that is applied toward your new purchase. Positive equity reduces the amount you finance, while negative equity may increase your financial arrangement balance."
            >
              <i
                className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                data-icon="information"
              ></i>
            </Popover>
            <TagLabel text="Expired" type="error" variant="light" />
          </p>

          <FeedbackLabel type="error" text="Expired on DD/MM/YYYY" />
        </>
      ),
      extra: <span className="text--body-2 text-secondary">-$5,000</span>,
      className: "ant-collapse-item__price-accordian",
      children: (
        <>
          <div className="pricing-details__section-row">
            <div className="pricing-details__item">
              <ul>
                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Net offer value{" "}
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Dealer Contribution{" "}
                      <Popover
                        trigger={["hover"]}
                        disclaimerText={
                          <>
                            Similar to a Down payment, money provided by the
                            lessee at inception reduces the capitalized cost of
                            the lease. Your total amount "Due-at-Signing" may
                            include additional amounts. A full breakdown of your
                            total "Due-at-Signing" is provided after all
                            estimator steps are completed.{" "}
                          </>
                        }
                      >
                        <i
                          className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                          data-icon="information"
                        ></i>
                      </Popover>
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Payoff amount{" "}
                      <Popover
                        trigger={["hover"]}
                        disclaimerText="To ensure you have the accurate payoff quote, the system adds {15} days of interest ($XXX) to the payoff amount as a buffer for potential delays in processing."
                      >
                        <i
                          className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                          data-icon="information"
                        ></i>
                      </Popover>
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <p className="text--body-2 text-secondary">Protection & coverage</p>
      ),
      extra: <span className="text--body-2 text-secondary">$1,200</span>,

      className: "ant-collapse-item__price-accordian",
      children: (
        <>
          <div className="pricing-details__section-row">
            <div className="pricing-details__item">
              <ul>
                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Essential protection bundle
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Windshield protection
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: <p className="text--body-2 text-secondary">Accessories</p>,
      extra: <span className="text--body-2 text-secondary">$152</span>,
      className: "ant-collapse-item__price-accordian",
      children: (
        <>
          <div className="pricing-details__section-row">
            <div className="pricing-details__item">
              <ul>
                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      BMW Crystal Fusion
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      BMW Paint Protection Wrap
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $000
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  const taxFeesAccordionItems = [
    {
      key: "1",
      label: <p className="text--body-2 text-secondary">Fees</p>,
      extra: <span className="text--body-2 text-secondary">$1,000</span>,
      className: "ant-collapse-item__price-accordian",
      children: (
        <>
          <div className="pricing-details__section-row">
            <div className="pricing-details__item">
              <ul>
                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Placeholder
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $730
                    </span>
                  </div>
                </li>

                <li>
                  <div className="pricing-details__item-row">
                    <span className="pricing-details__item__label text--body-2 text-secondary">
                      Placeholder
                    </span>

                    <span className="pricing-details__item__price text--body-2 text-secondary">
                      $270
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="incentives-and-discounts-wrap">
        <HeroBanner region="northeast-region" />

        <div className="bmw-container">
          <div className="review-wrapper">
            <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <div className="review-left-content">
                  <h2 className="incentives-discounts__title text--h3">
                    Your add-ons & personalization
                  </h2>

                  <div className="review-listing-section">
                    <div className="no-addons-section">
                      <div className="incentives-discounts__text ">
                        <p className="text--body-2 text-dark">
                          No incentives added
                        </p>
                        <Button
                          linkButton
                          withIcon
                          htmlType={"button"}
                          text="Add "
                        />
                      </div>

                      <div className="incentives-discounts__text">
                        <p className="text--body-2 text-dark">
                          No trade-in value added
                        </p>
                        <Button
                          linkButton
                          withIcon
                          htmlType={"button"}
                          text="Add "
                        />
                      </div>

                      <div className="incentives-discounts__text">
                        <p className="text--body-2 text-dark">
                          No protection products added
                        </p>
                        <Button
                          linkButton
                          withIcon
                          htmlType={"button"}
                          text="Add "
                        />
                      </div>

                      <div className="incentives-discounts__text">
                        <p className="text--body-2 text-dark">
                          No accessories added
                        </p>
                        <Button
                          linkButton
                          withIcon
                          htmlType={"button"}
                          text="Add "
                        />
                      </div>
                    </div>

                    <div className="incentives-discounts__text padding-top-zero ">
                      <p className="text--subsection-2">
                        Incentives & discount
                      </p>
                      <Button
                        linkButton
                        withIcon
                        htmlType={"button"}
                        text="Edit"
                      />
                    </div>

                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={Image3}
                        title={"Lease Loyalty Credit"}
                        subTitle={""}
                        price={"$X,XXX"}
                        totalPrice={""}
                        className=""
                        actionsArea={""}
                      />
                    </div>
                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={NoImage}
                        title={"Title goes here"}
                        subTitle={""}
                        price={"$X,XXX"}
                        totalPrice={""}
                        className=""
                        actionsArea={""}
                      />
                    </div>
                  </div>

                  <div className="review-listing-section review-listing-section__expired-case ">
                    <div className="incentives-discounts__text ">
                      <div className="incentives-discounts__with-warning-lable">
                        <p className="text--subsection-2">Trade-in </p>
                        <TagLabel text="Expired" type="error" variant="light" />
                      </div>
                      <Button
                        linkButton
                        withIcon
                        htmlType={"button"}
                        text="Edit"
                      />
                    </div>

                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={""}
                        title={"You estimated trade-in value"}
                        subTitle={""}
                        price={"$X,XXX"}
                        totalPrice={""}
                        className=""
                        actionsArea={""}
                      />
                    </div>

                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={NoImage}
                        title={"Title goes here"}
                        subTitle={
                          <>
                            <FeedbackLabel
                              type="error"
                              text="Expired on DD/MM/YYYY"
                            />
                          </>
                        }
                        price={<>$XXX</>}
                        totalPrice={"Net trade-in value"}
                        className=""
                        actionsArea={""}
                      />
                    </div>

                    <div className="warning-box">
                      <Row gutter={0}>
                        <Col xs={24} sm={24} lg={24} xxl={24}>
                          <MessageAlert
                            type="warning"
                            showCloseButton={false}
                            title="Verify your estimated trade-in value before checkout."
                            subTitle="To add your trade-in to this deal, please confirm its value with Kelley Blue Book or your dealer. Or, you can remove the trade-in value to continue to the credit application."
                            actionArea={
                              <>
                                <Button
                                  htmlType="button"
                                  text={"Verify with KBB"}
                                  onClick={() => {}}
                                  size={"small"}
                                  type="default"
                                />
                                <Button
                                  htmlType="button"
                                  text={"Verify with dealer"}
                                  onClick={() => {}}
                                  size={"small"}
                                />
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="review-listing-section">
                    <div className="incentives-discounts__text ">
                      <p className="text--subsection-2">Protection</p>
                      <Button
                        linkButton
                        withIcon
                        htmlType={"button"}
                        text="Edit"
                      />
                    </div>

                    <div className="review-listing-section__list-wrap">
                      <div className="card-with-accordian">
                        <ReviewListCard
                          itemCount="+4"
                          image={Image1}
                          title={"Title goes here"}
                          subTitle={"4 items / for 72 months / 120,000 miles"}
                          price={
                            <>
                              $XXX <span className="text--body-1">/month</span>
                            </>
                          }
                          totalPrice={"Total: $X,XXX.XX"}
                          className=""
                          actionsArea={""}
                        />

                        <div className="review-accordian">
                          <Accordion
                            items={exampleItems}
                            defaultActiveKey={0}
                            ghost={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={NoImage}
                        title={"Title goes here"}
                        subTitle={"Optional label"}
                        price={
                          <>
                            $XXX <span className="text--body-1">/month</span>
                          </>
                        }
                        totalPrice={""}
                        className=""
                        actionsArea={""}
                      />
                    </div>
                  </div>

                  <div className="review-listing-section">
                    <div className="incentives-discounts__text ">
                      <p className="text--subsection-2">Accessory</p>
                      <Button
                        linkButton
                        withIcon
                        htmlType={"button"}
                        text="Edit"
                      />
                    </div>

                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={NoImage}
                        title={"Title goes here"}
                        subTitle={""}
                        price={"$X,XXX"}
                        totalPrice={"Optional label"}
                        className=""
                        actionsArea={""}
                      />
                    </div>
                    <div className="review-listing-section__list-wrap">
                      <ReviewListCard
                        image={NoImage}
                        title={"Title goes here"}
                        subTitle={""}
                        price={"$X,XXX"}
                        totalPrice={"Optional label"}
                        className=""
                        actionsArea={""}
                      />
                    </div>
                  </div>

                  <div className="review-pricing-details">
                    <div className="pricing-details__heading">
                      <h3 className="text--h3">Pricing details.</h3>
                    </div>
                    <div className="pricing-details__inner">
                      <div className="monthly-payments">
                        <div className="pricing-details__item pricing-details-accordion__monthly-payment">
                          <div className="pricing-details__item__label text--subsection-2">
                            (Est.) Monthly payments
                            <Popover
                              trigger={["hover"]}
                              disclaimerText="The monthly payment amount shown is an estimate based upon your entries, including your ZIP code as entered. Includes taxes and additional fees. This is a payment estimator and not all customers will qualify for rates shown. When you accept your approved financing offer, you will receive your final monthly payment amount. "
                            >
                              <i
                                className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                data-icon="information"
                              ></i>
                            </Popover>
                          </div>

                          <h3 className="text--h3">
                            $X,XXX{" "}
                            <span className="text--subsection-2">/month</span>
                          </h3>
                        </div>

                        <p className="text--body-2">
                          60 month financing with an estimated 7.09% APR, first
                          payment due MM/DD/2024.
                        </p>
                      </div>

                      <div className="pricing-details-row pricing-details__item ">
                        <div className="pricing-details__item__label text--subsection-2">
                          Total Amount Financed
                          <Popover
                            trigger={["hover"]}
                            disclaimerText="The Total Amount Financed represents the amount borrowed from the lender to complete the vehicle purchase, including applicable fees and charges. This figure is for educational purposes only and may vary based on lender terms and individual creditworthiness. Please consult your dealer for precise details."
                          >
                            <i
                              className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                              data-icon="information"
                            ></i>
                          </Popover>
                        </div>

                        <span className="text--body-focus-price-1">
                          $44,850
                        </span>
                      </div>
                      <div className="pricing-details-content__box">
                        <div className="pricing-details__item pricing-details__item--heading">
                          <span className="pricing-details__item__label text--body-2">
                            Your Price
                          </span>
                          <span className="pricing-details__item__price text--body-2 fw-bold">
                            $46,670
                          </span>
                        </div>

                        <div className="pricing-details__item--row">
                          <div className="pricing-details__item">
                            <span className="pricing-details__item__label text--body-2 text-secondary">
                              MSRP
                            </span>
                            <span className="pricing-details__item__price text--body-2 text-secondary">
                              $48,670
                            </span>
                          </div>
                          <div className="pricing-details__item">
                            <span className="pricing-details__item__label text--body-2 text-secondary">
                              Dealer Discount
                            </span>
                            <span className="pricing-details__item__price text--body-2 text-secondary">
                              -$2,000
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pricing-details-content__box">
                        <div className="pricing-details__item pricing-details__item--heading">
                          <span className="pricing-details__item__label text--body-2">
                            Custom add-ons
                          </span>
                          <span className="pricing-details__item__price text--body-2 fw-bold">
                            -$4,398
                          </span>
                        </div>
                        <Accordion
                          items={customAddOnsAccordionItems}
                          defaultActiveKey={0}
                          ghost={false}
                          size="small"
                          fullWidth
                        />
                      </div>
                      <div className="pricing-details-content__box">
                        <div className="pricing-details__item pricing-details__item--heading">
                          <span className="pricing-details__item__label text--body-2">
                            Taxes & fees
                          </span>
                          <span className="pricing-details__item__price text--body-2 fw-bold">
                            $0,000
                          </span>
                        </div>

                        <div className="pricing-details__item pr-0 padding-bottom">
                          <Accordion
                            items={taxFeesAccordionItems}
                            defaultActiveKey={0}
                            ghost={false}
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className="pricing-details__item pricing-details__item--row-padding ">
                          <span className="pricing-details__item__label text--body-2 text-secondary">
                            Sales Tax
                            <Popover
                              trigger={["hover"]}
                              disclaimerText="Sales Tax represents the tax applied to the vehicle's purchase or lease price, as determined by state and local regulations. This information may vary by state or local tax laws. "
                            >
                              <i
                                className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                data-icon="information"
                              ></i>
                            </Popover>
                          </span>
                          <span className="pricing-details__item__price text--body-2 text-secondary">
                            $4,200
                          </span>
                        </div>
                      </div>
                      <div className="pricing-details-content__box pricing-details-accordion-content__padding">
                        <div className="pricing-details__item pricing-details__item--heading">
                          <span className="pricing-details__item__label text--body-2">
                            Down Payment
                            <Popover
                              trigger={["hover"]}
                              disclaimerText="The Down payment reduces the total amount financed. "
                            >
                              <i
                                className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                data-icon="information"
                              ></i>
                            </Popover>
                          </span>
                          <span className="pricing-details__item__price text--body-2 fw-bold">
                            -$5,000
                          </span>
                        </div>

                        <div className="pricing-details__item total-sale-price">
                          <span className="pricing-details__item__label text--subsection-2">
                            Total sale price:
                          </span>
                          <span className="pricing-details__item__price text--body-focus-price-1">
                            $47,520
                          </span>
                        </div>
                      </div>

                      <div className="pricing-details-content__box padding-bottom-zero">
                        <div className="pricing-details__item--row">
                          <div className="pricing-details__item">
                            <span className="pricing-details__item__label text--body-2">
                              Total Financed Amount
                              <Popover
                                trigger={["hover"]}
                                disclaimerText="The Total Amount Financed represents the amount borrowed from the lender to complete the vehicle purchase, including applicable fees and charges. This figure is for educational purposes only and may vary based on lender terms and individual creditworthiness. Please consult your dealer for precise details."
                              >
                                <i
                                  className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                  data-icon="information"
                                ></i>
                              </Popover>
                            </span>
                            <span className="pricing-details__item__price text--body-2">
                              $44,850
                            </span>
                          </div>

                          <div className="pricing-details__item">
                            <span className="pricing-details__item__label text--body-2">
                              Financing Charges
                              <Popover
                                trigger={["hover"]}
                                disclaimerText="Financing Charges include the cost of borrowing, such as interest and any applicable fees. This information is for educational purposes only and may differ based on the agreed terms and the borrower’s credit profile. Confirm all financing charges with your dealer."
                              >
                                <i
                                  className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                  data-icon="information"
                                ></i>
                              </Popover>
                            </span>
                            <span className="pricing-details__item__price text--body-2">
                              $2,670
                            </span>
                          </div>

                          <div className="pricing-details__item">
                            <span className="pricing-details__item__label text--body-2">
                              Down payment
                              <Popover
                                trigger={["hover"]}
                                disclaimerText="The Down payment reduces the total amount financed."
                              >
                                <i
                                  className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                  data-icon="information"
                                ></i>
                              </Popover>
                            </span>
                            <span className="pricing-details__item__price text--body-2">
                              $2,670
                            </span>
                          </div>

                          <div className="pricing-details-accordion-content__padding">
                            <div className="pricing-details__item total-sale-price total-sale-price-final-row">
                              <span className="pricing-details__item__label text--subsection-2">
                                Final payment:
                                <Popover
                                  trigger={["hover"]}
                                  disclaimerText="The final payment (balloon) is a larger one-time payment due at the end of your financing term. This amount is determined upfront and may vary based on the financing agreement. It allows for lower monthly payments but must be paid in full to complete the loan. Please review your financing terms or consult your lender for details."
                                >
                                  <i
                                    className="bmw-icon bmw-icon--after bmw-icon--info-icon"
                                    data-icon="information"
                                  ></i>
                                </Popover>
                              </span>
                              <span className="pricing-details__item__price text--body-focus-price-1">
                                $0,000
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="comfirm-pricing-section">
                    <p className="pricing-details__item__label text--body-2">
                      Please confirm you agree to the following terms:
                    </p>

                    <div className="comfirm-pricing-section__checkbox">
                      <Checkbox
                        id={"id-1"}
                        label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
                        htmlFor="id-1"
                      />
                    </div>

                    <div className="comfirm-pricing-section__checkbox-2">
                      <Checkbox
                        id={"id-1"}
                        label={
                          <>
                            I have read and agree to the BMW Center{" "}
                            <span className="bold-underline">Terms</span> and{" "}
                            <span className="bold-underline">
                              Privacy Policy
                            </span>{" "}
                            and BMW of North America, LLC (“BMW NA”){" "}
                            <span className="bold-underline">Terms</span> and{" "}
                            <span className="bold-underline">
                              Privacy Policy.
                            </span>{" "}
                            I understand and agree that (1) this online process
                            does not guarantee the sale or lease of this vehicle
                            to me until terms are agreed and a credit
                            application has been reviewed; (2) BMW NA does not
                            sell vehicles nor finance or lease vehicles; (3) BMW
                            Center sets actual price; (4) a vehicle may not be
                            available for me until a BMW Center countersigns a
                            Buyer’s Order and confirms both the availability of
                            the vehicle and the purchase or lease offer; (5)
                            circumstances, including, without limitation,
                            pricing errors, vehicle damage, or unavailability,
                            may require the termination or restart of any
                            purchase or leasing transaction.
                          </>
                        }
                        htmlFor="id-1"
                      />
                    </div>

                    <div className="comfirm-pricing-section__checkbox-2 warning-box">
                      <Row gutter={0}>
                        <Col xs={24} sm={24} lg={24} xxl={24}>
                          <MessageAlert
                            type="warning"
                            showCloseButton={false}
                            title="Please resolve the above error(s) to continue."
                            subTitle=""
                            actionArea={<></>}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="comfirm-pricing-section__checkbox-2 warning-box">
                      <Row gutter={0}>
                        <Col xs={24} sm={24} lg={24} xxl={24}>
                          <MessageAlert
                            type="warning"
                            showCloseButton={false}
                            title="Your trade-in offer has expired."
                            subTitle={
                              <>
                                Please renew or remove your trade-in offer to
                                continue with your deal.
                                <br></br>
                                Don’t worry, we’ll save your progress and bring
                                you back to where you left off once you’re done.
                              </>
                            }
                            actionArea={
                              <>
                                <Button
                                  htmlType="button"
                                  text={"Review trade-in"}
                                  onClick={() => {}}
                                  size={"small"}
                                  type="default"
                                />
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="incentives-discounts--ctas">
                      <div className="incentives-discounts--ctas_flexible">
                        <div>
                          <Button
                            htmlType={"button"}
                            text="Check out"
                            type="primary"
                            fixed
                            fullWidth
                            onClick={() => {
                              navigate(`/${ROUTING_PATH.PRE_CHECKOUT_PAGE}`);
                            }}
                          />
                        </div>
                        <div>
                          <Button
                            htmlType={"button"}
                            text="Sign in to save"
                            fullWidth
                          />
                        </div>
                      </div>
                      <p className="text--disclaimer text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum
                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia
                        deserunt mollit anim id est laborum.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <div className="address-right-content">
                  <div className="address-right-content__inner">
                    <RightRail hasFinance />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <RightRailFixed hasFinance />
      </div>
    </>
  );
};
