import { Controller } from "react-hook-form";
import { Radio } from "@src/components/atoms";

export const SpouseCoApplicant = ({ control }) => {
  return (
    <div className="type-selector-wrap">
      <p className="text--body-2 financial-residence-text-two">
        Is your spouse your co-applicant?
      </p>

      <Controller
        name="isSpouseCoApplicant"
        control={control}
        render={({ field }) => (
          <div className="type-selector-wrap--radio-container">
            <label htmlFor="yes-co-applicant">
              <Radio
                label="Yes"
                onChange={() => field.onChange(true)}
                checked={field.value === true}
                name="isSpouseCoApplicant"
                htmlFor="yes-co-applicant"
                id="yes-co-applicant"
              />
            </label>
            <label htmlFor="no-co-applicant">
              <Radio
                label="No"
                onChange={() => field.onChange(false)}
                checked={field.value === false}
                name="isSpouseCoApplicant"
                htmlFor="no-co-applicant"
                id="no-co-applicant"
              />
            </label>
          </div>
        )}
      />
    </div>
  );
};
