import { AppError } from "single-spa";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import {
  Address,
  BasicCustomerInfo,
  Customer,
  FinancialProfile,
} from "@src/types/customer";
import {
  getCustomerByEmail,
  createCustomer,
  getToken,
  getCustomerFinancialProfile,
  updateCustomerFinancialProfile,
  customerUpdate,
  getCustomerCreditCheck,
  updateCustomerDisclosure,
  getCustomerDisclosure,
  verifyAddress,
} from "../services/customer";

export const useGetCustomerByEmail = (): {
  mutate: UseMutateFunction<any, { detail: string }, { email: string }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    { detail: string },
    { email: string }
  >({
    mutationFn: getCustomerByEmail,
  });
  return { mutate, error: error?.detail };
};

export const useCreateCustomer = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<Customer, AppError, any>({
    mutationFn: createCustomer,
  });
  return { mutate, isPending, error: error?.message };
};

export const useGetToken = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<any, AppError, any>({
    mutationFn: getToken,
  });
  return { mutate, isPending, error: error?.message };
};

export const useGetCustomerFinancialProfile = (): {
  mutate: UseMutateFunction<
    any,
    AppError,
    { reference_id: string; dealer_code: string }
  >;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    AppError,
    { reference_id: string; dealer_code: string }
  >({
    mutationFn: getCustomerFinancialProfile,
  });
  return { mutate, error: error?.message };
};

export const useUpdateCustomerFinancialProfile = (
  data
): {
  mutate: UseMutateFunction<
    any,
    AppError,
    FinancialProfile & { method?: string }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<
    Customer,
    AppError,
    FinancialProfile & { method?: string }
  >({
    mutationFn: async (payload) => {
      const { reference_id, dealer_code } = data;
      return await updateCustomerFinancialProfile(
        reference_id,
        dealer_code,
        payload
      );
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useCustomerUpdate = (
  data
): {
  mutateAsync: UseMutateFunction<
    any,
    { detail: { message: string } },
    (
      | { customer_profile: BasicCustomerInfo }
      | { customer_profile: { first_name: string; updated_by: string } }
      | { customer_addresses: Address[] }
      | FinancialProfile
    ) & {
      type: string;
      method?: string;
      reference_id?: string;
    }
  >;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutateAsync, isPending, error } = useMutation<
    Customer,
    { detail: { message: string } },
    (
      | { customer_profile: BasicCustomerInfo }
      | { customer_profile: { first_name: string; updated_by: string } }
      | { customer_addresses: Address[] }
      | FinancialProfile
    ) & {
      type: string;
      method?: string;
      reference_id?: string;
    }
  >({
    mutationFn: async (payload) => {
      const { type, method, reference_id, ...rest } = payload;
      const { reference_id: refId, dealer_code, order_id } = data;
      return await customerUpdate(
        type,
        reference_id ?? refId,
        dealer_code,
        order_id,
        method,
        rest
      );
    },
  });
  return {
    mutateAsync,
    isPending,
    error: error && error.detail ? error.detail.message : undefined,
  };
};

export const useGetCustomerCreditCheck = () => {
  const { data, isSuccess, mutate, error } = useMutation<any, any, any>({
    mutationFn: getCustomerCreditCheck,
  });
  return { mutate, error: error?.detail };
};

export const useUpdateCustomerDisclosure = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<Customer, AppError, any>({
    mutationFn: updateCustomerDisclosure,
  });
  return { mutate, isPending, error: error?.message };
};
export const useGetCustomerDisclosure = (): {
  mutate: UseMutateFunction<any, AppError, { reference_number: string }>;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    Customer,
    AppError,
    { reference_number: string }
  >({
    mutationFn: getCustomerDisclosure,
  });
  return { mutate, error: error?.message };
};

export const useVerifyAddress = (): {
  mutateAsync: UseMutateFunction<any, AppError, { zipCode: string }>;
  error: string | undefined;
} => {
  const { mutateAsync, error } = useMutation<
    any,
    AppError,
    { zipCode: string }
  >({
    mutationFn: verifyAddress,
  });
  return { mutateAsync, error: error?.message };
};
