import { ROUTING_PATH } from "@src/application/application-router";
import "./pre-checkout-action-area.scss";
import { Button } from "@src/components/atoms";
import { useNavigate } from "react-router-dom";

export const PreCheckoutActionArea = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="pre-checkout-action-area">
        <Button
          htmlType={"button"}
          text="Start checkout"
          type="primary"
          fixed
          fullWidth
          onClick={() => {
            navigate(`${ROUTING_PATH.CHECKOUT}`);
          }}
        />
        <p className="text--disclaimer">
          Once you start Checkout, your dealer will temporarily flag this
          vehicle for [X] minutes until it is secured by you, your co-applicant,
          and the BMW Center by you signing a Buyer's Order and paying a
          deposit. Subject to the applicable terms.
        </p>
      </div>
    </>
  );
};
