import * as yup from "yup";

export const addressValidator = (prevAddress) => {
  return yup.object().shape({
    mailing: yup.object({
      address_line_1: yup.string().required("Address 1 is required"),
      address_line_2: yup.string().nullable(),
      state_name: yup.string().required("State is required"),
      zip_code: yup
        .string()
        .matches(/^[0-9]{5}$/, "Invalid ZIP code")
        .required("ZIP code is required"),
      city: yup.string().required("City is required"),
      move_in_date: yup.date().required("Move-in date is required"),
    }),
    address_type: yup
      .string()
      .oneOf(
        ["Residence", "Business"],
        "Address type must be Residence or Business"
      )
      .required("Address type is required"),
    billingSameAsMailing: yup.boolean(),
    garagingSameAsMailing: yup.boolean(),
    previous: yup.object().when([], {
      is: () => prevAddress,
      then: () =>
        yup.object({
          address_line_1: yup.string().required("Address 1 is required"),
          address_line_2: yup.string().nullable(),
          state_name: yup.string().required("State is required"),
          zip_code: yup
            .string()
            .matches(/^[0-9]{5}$/, "Invalid ZIP code")
            .required("Zip code is required"),
          city: yup.string().required("City is required"),
        }),
    }),
    billing: yup.object().when("billingSameAsMailing", {
      is: false,
      then: () =>
        yup.object({
          address_line_1: yup.string().required("Address 1 is required"),
          address_line_2: yup.string().nullable(),
          state_name: yup.string().required("State is required"),
          zip_code: yup
            .string()
            .matches(/^[0-9]{5}$/, "Invalid ZIP code")
            .required("Zip code is required"),
          city: yup.string().required("City is required"),
        }),
    }),
    garaging: yup.object().when("garagingSameAsMailing", {
      is: false,
      then: () =>
        yup.object({
          address_line_1: yup.string().required("Address 1 is required"),
          address_line_2: yup.string().nullable(),
          state_name: yup.string().required("State is required"),
          zip_code: yup
            .string()
            .matches(/^[0-9]{5}$/, "Invalid ZIP code")
            .required("Zip code is required"),
          city: yup.string().required("City is required"),
        }),
    }),
    isMarried: yup.boolean().required("Please specify marital status"),
    isSpouseCoApplicant: yup.boolean().when("isMarried", {
      is: true,
      then: (schema) =>
        schema.required("Please specify if spouse is a co-applicant"),
      otherwise: (schema) => schema.notRequired(),
    }),
    spouseAddressSameAsMailing: yup.boolean().when("isMarried", {
      is: true,
      then: (schema) =>
        schema.required(
          "Please specify if spouse's address is the same as mailing address"
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    spouse: yup
      .object()
      .when(
        ["isMarried", "isSpouseCoApplicant", "spouseAddressSameAsMailing"],
        {
          is: (isMarried, isSpouseCoApplicant) =>
            isMarried === true && isSpouseCoApplicant === false,
          then: (schema) =>
            schema.shape({
              first_name: yup
                .string()
                .trim()
                .required("Spouse's first name is required"),
              last_name: yup
                .string()
                .trim()
                .required("Spouse's last name is required"),
              address: yup.object({
                address_line_1: yup
                  .string()
                  .trim()
                  .required("Spouse's address is required"),
                address_line_2: yup.string().nullable(),
                city: yup.string().trim().required("Spouse's city is required"),
                state_name: yup
                  .string()
                  .trim()
                  .required("Spouse's state is required"),
                zip_code: yup
                  .string()
                  .matches(/^[0-9]{5}$/, "Invalid ZIP code")
                  .required("Spouse's ZIP code is required"),
              }),
            }),
          otherwise: (schema) => schema,
        }
      ),
  });
};
