import clsx from "clsx";
import { ChatBodyProps } from "./chat-body.props";
import { ChatOfflineConscent } from "../chat-offline-conscent/chat-offline-conscent.component";
import { ChatAgentJoins } from "../chat-agent-joins/chat-agent-joins.component";
import { ChatOnlineConscent } from "../chat-online-conscent/chat-online-conscent.component";
import "./chat-body.scss";

interface ChatBodyExtendedProps extends ChatBodyProps {
  setOpen: (open: boolean) => void;
  status?: "online" | "offline" | "agentJoins";
}

export const ChatBody: React.FC<ChatBodyExtendedProps> = ({
  status,
  setOpen,
}) => {
  return (
    <div
      className={clsx("chat-body-wrap", { "is-online": status == "online" })}
    >
      {status === "online" && <ChatOnlineConscent />}
      {status === "offline" && <ChatOfflineConscent setOpen={setOpen} />}
      {status === "agentJoins" && <ChatAgentJoins />}
    </div>
  );
};
