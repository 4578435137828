import clsx from "clsx";
import { ApplicationDetailsProps } from "./application-details.props";
import { ProgressBar, TagLabel } from "@src/components/atoms";
import "./application-details.scss";

export const ApplicationDetails = ({
  title,
  tagLabel,
  tagLabelType,
  innerHeading,
  innerDescription,
  financeOfferProvider,
  financeOfferProviderLogo,
  isWaiting = true,
  statusText,
}: ApplicationDetailsProps) => {
  return (
    <div
      className={clsx({
        "application-details-wrapper": true,
      })}
    >
      <div className="application-details-wrapper--header">
        <h5 className="text--subsection-2">{title}</h5>
        <TagLabel text={tagLabel} type={tagLabelType} />
      </div>
      <div className="application-details-wrapper--body">
        <div className="application-details-intro">
          <p className="text--disclaimer">{innerHeading}</p>
          <p className="text--body-2">{innerDescription}</p>
        </div>
        <div className="finance-offer-providers">
          <p className="text--disclaimer">Selected finance offer provider(s)</p>
          <div className="finance-offer-providers--inner">
            <div className="finance-offer-providers--logo">
              {financeOfferProviderLogo}
            </div>
            <p className="text--subsection-2">{financeOfferProvider}</p>
          </div>
        </div>
        {isWaiting ? (
          <div className="finance-offer-status waiting-state">
            <ProgressBar
              type="circle"
              percent={25}
              strokeColor="#262626"
              size={24}
              trailColor="#e6e6e6"
            />
            <p className="text--body-2">{statusText}</p>
          </div>
        ) : (
          <div className="finance-offer-status complete-state">
            <div
              className="bmw-icon bmw-icon--before data-icon"
              data-icon="checkmark"
            />
            <p className="text--body-2">{statusText}</p>
          </div>
        )}
      </div>
    </div>
  );
};
