import { useEffect, useState } from "react";
import { Button, Checkbox } from "@src/components/atoms";
import "./agreements.style.scss";
import { useNavigate } from "react-router-dom";
import {
  useAddCustomerConsent,
  useGetDefaultLender,
  useGetFraudCheck,
  useOrderSubmit,
  useUpdateDeal,
} from "@src/hooks";
import { orderFraudStatus } from "@src/context/app-actions";
import { Decision } from "@src/helpers";
import { useAppContext } from "@src/context/app-context";
import { AgreementsRehash } from "./agreements-rehash-message";
import { isEmpty } from "lodash";

export const Agreements = ({
  stepSixDataReceivedEvent,
}: {
  stepSixDataReceivedEvent: () => void;
}) => {
  const { isPending: FraudLoading, mutate: FraudCheck } = useGetFraudCheck();
  const {
    state: {
      order,
      lender: { lenderSubmissions },
      dealer: { disclaimer },
      configuration: { socure },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { isPending: OrderSubmitLoading, mutate: submitOrderRequest } =
    useOrderSubmit();
  const navigate = useNavigate();
  const {
    reference_number,
    identifier,
    customer_info: { reference_id, customer_license },
    order_asset: { mileage },
  } = order;
  const [handleSelect, setHandleSelect] = useState(false);
  const { mutate: updateOrder } = useUpdateDeal();
  const { mutate: addConsent } = useAddCustomerConsent();
  const creditAppDisclaimer = disclaimer["customerCreditApplicationDisclaimer"];
  const sumbitApplication = () => {
    const submitRequest = () => {
      try {
        let changeOrder;
        const mileageNum = Number(mileage) || 0;
        if (mileageNum == 0) {
          changeOrder = {
            ...order,
            order_asset: {
              ...order.order_asset,
              mileage: 10,
            },
          };
        } else {
          changeOrder = { ...order };
        }
        if (!creditAppDisclaimer?.customerFetch) {
          addConsent({
            customer_reference_id: reference_id,
            disclaimer_name: creditAppDisclaimer.disclaimer_name,
            disclaimer_version: creditAppDisclaimer.disclaimer_version,
            order_reference_id: reference_number,
          });
        }
        updateOrder(
          { ...changeOrder, identifier: identifier },
          {
            onSuccess: () => {
              submitOrderRequest(
                {
                  referenceNumber: reference_number,
                  lender_ids: lenderSubmissions,
                },
                {
                  onSuccess: () => {
                    if (socure) {
                      FraudCheck(
                        {
                          referenceNumber: reference_number,
                          customer_reference_id: reference_id,
                        },
                        {
                          onSuccess: (response) => {
                            if (
                              Array.isArray(response) &&
                              response[0]?.decision?.value
                            ) {
                              appDispatch(
                                orderFraudStatus(response[0].decision.value)
                              );
                              if (
                                response[0].decision.value === Decision.ACCEPT
                              ) {
                                navigate(
                                  `/deal-state/deal/${reference_number}/${response[0].decision.value}`
                                );
                              } else {
                                navigate(
                                  `/dealer-contact/deal/${reference_number}`
                                );
                              }
                            } else {
                              navigate(
                                `/dealer-contact/deal/${reference_number}`
                              );
                            }
                          },
                        }
                      );
                    } else {
                      navigate(`/deal-state/deal/${reference_number}/accept`);
                    }
                  },
                }
              );
            },
          }
        );
      } catch (error) {
        console.error("Error submitting order request:", error);
      }
    };
    submitRequest();
  };
  return (
    <div className="agreements-step-container">
      <h2 className="section-header upper-label spacing">
        <span className="section-header number text--subsection-2">06</span>
        <span className="section-header text text--subsection-2">
          Agreements.
        </span>
      </h2>
      <p className="agreements-step-container--description text--body-2">
        Please review and accept the following terms &amp; conditions before
        submitting your application.
      </p>
      <div className="agreements-step-container--checkbox">
        <Checkbox
          onChange={(val) => setHandleSelect(val)}
          id={"agreementChk"}
          disabled={isEmpty(customer_license) || customer_license === undefined}
          label=""
          htmlFor="agreementChk"
        />
        {creditAppDisclaimer?.changesDisclaimer && (
          <div
            dangerouslySetInnerHTML={{
              __html: creditAppDisclaimer.changesDisclaimer,
            }}
          />
        )}
      </div>
      <AgreementsRehash />
      <Button
        type="primary"
        fixed
        htmlType={"button"}
        isLoading={OrderSubmitLoading || FraudLoading}
        text="Agree &amp; submit"
        disabled={!handleSelect}
        onClick={() => {
          stepSixDataReceivedEvent;
          sumbitApplication();
        }}
        fullWidth
      />
    </div>
  );
};
