import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BundleCard,
  InsentivesAndDiscountsDrawerFooter,
  BundleDetailsDrawerContent,
  ProductDetailsDrawerContent,
  RightRail,
  RightRailFixed,
} from "@src/components/molecules";
import "./protection-and-warranty.scss";
import {
  Button,
  IconList,
  Checkbox,
  Drawer,
  FeedbackLabel,
  Accordion,
  Modal,
  Popover,
} from "@src/components/atoms";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { IncentivesListCard } from "@src/components/molecules/incentives-list-card/incentives-list-card.component";

import { useAppContext } from "@src/context/app-context";
import { Bundle, FinancialProduct } from "@src/types";
import { useMappedFNIProducts } from "@src/hooks/useMappedFNIProducts";
import { toggleDisclaimer, toggleDrawerStatus } from "@src/context/app-actions";
import { DisclaimersType } from "@src/constants/enum";
import {
  formatCurrency,
  generatePayload,
  getLinkClickPayload,
} from "@src/helpers";
import { useAdobeDatalayerContext } from "@src/application/application-data-layer";
import { useUpdateDeal } from "@src/hooks";
import { FinanceLeasePricingDrawerContent } from "@src/components/molecules/incentives-and-discounts/fl-pricing-drawer-content/fl-pricing-drawer-content.component";

export const ProtectionAndWarranty = () => {
  const navigate = useNavigate();
  const windowLocation = useLocation();
  const [isDrawerEnable, setDrawerenable] = useState(false);
  const { addEvent } = useAdobeDatalayerContext();
  const { mutate: updateOrder } = useUpdateDeal();
  const {
    state: {
      fniOptions: { bundles, products },
      ui: {
        toggles: {
          drawer: { isOpen: isDrawerToggle },
        },
        isLoading,
      },
      dealer: { id, dealer_name },
      user: { isUserLoggedIn },
      order,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { dealRefNumber } = useParams();
  const { contract_term, order_fnI } = order;
  const userCredentials = JSON.parse(localStorage.getItem("customer") || "{}");
  const { handleBundleUpsert, handleProductUpsert } = useMappedFNIProducts();

  const [bundleDetails, setBundleDetails] = useState<Bundle>();
  const [productDetails, setProductDetails] = useState<FinancialProduct>();

  const closeDrawer = (tagName?: string) => {
    if (tagName) addEvent("linkClick", getLinkClickPayload(tagName));

    setBundleDetails(undefined);
    setProductDetails(undefined);
    setDrawerenable(false);
    appDispatch(
      toggleDrawerStatus({ name: "incentives-and-discount", isOpen: false })
    );
    localStorage.setItem("redirectToDealerWebsite", "false");
  };

  const isDisclaimerAgreed = JSON.parse(
    localStorage.getItem("incentives-discounts-disclaimer") || "false"
  );
  const toggleSignIn = () => {
    addEvent("linkClick", getLinkClickPayload("sign in to save"));
    if (!isDisclaimerAgreed) {
      appDispatch(
        toggleDisclaimer({
          name: DisclaimersType.INCENTIVE_DISCOUNT,
          isOpen: true,
          openAt: new Date(),
        })
      );
      return;
    } else {
      setDrawerenable(!isDrawerEnable);
    }
  };
  const saveOrder = () => {
    if (dealRefNumber) {
      updateOrder(order, {
        onSuccess: () => {
          navigate(`/accessories/deal/${dealRefNumber}`);
        },
      });
    } else {
      navigate(`/accessories${windowLocation.search}`);
    }
  };

  useEffect(() => {
    if (order?.contract_term && id) {
      addEvent(
        "pageLoad",
        generatePayload(
          "bmwusaretail:buildyourdeal:protection",
          "protection",
          isUserLoggedIn,
          { dealer_name, id },
          order
        )
      );
    }
  }, [order, dealer_name, id, isUserLoggedIn]);

  return (
    <>
      <div className="protection-warranty">
        <div className="bmw-container">
          <Row className="justify-content" gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={16}>
              <div className="protection-warranty-left-content">
                <h3 className="protection-warranty__title">
                  <span className="text--body-2 label">Build my deal</span>
                  Protection & warranty
                </h3>
                <div className="protection-warranty__include-box">
                  <span className="protection-warranty__include-box__label text--label">
                    Included with this BMW:
                  </span>
                  <p className="protection-warranty__include-box__description text--body-2">
                    4-year/50,000-mile factory warranty, whichever comes first.
                  </p>
                </div>
                <div className="protection-warranty__bundle">
                  <div className="text--subsection-2 protection-warranty__bundle--text">
                    Get more protection with an extended coverage bundle.
                  </div>
                  <div className="protection-warranty__bundle__grid">
                    {bundles.map((fniBundle, ind) => {
                      const selected = order_fnI.some(
                        (fni) => fni.bundle_id === fniBundle.id
                      );

                      return (
                        <div
                          key={ind}
                          className="protection-warranty__bundle__grid__box"
                        >
                          <BundleCard
                            headline={fniBundle.bundle_name}
                            badge={`Bundle to save ${fniBundle.percentSaving}%`}
                            price={
                              <h1 className="text--stage-focus-price">
                                {formatCurrency(fniBundle.monthlySaving)}
                                <span className="text--subsection-1">
                                  /month
                                </span>
                              </h1>
                            }
                            terms={
                              false // TODO:: BUNDLE TERMS AND MILES ARE HIDDEN AS PER COMMENT ADDED ON DESIGN
                              // <div className="isFlex text--disclaimer">
                              //   <span>{contract_term} months</span>
                              //   <span>{"/"}</span>
                              //   <span>
                              //     {fniBundle.financial_products[0]?.term_miles}
                              //     miles
                              //   </span>
                              //   <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                              // </div>
                            }
                            details={
                              <IconList
                                size="small"
                                listItems={fniBundle.financial_products
                                  .slice(0, 5)
                                  .map((individualProduct) => ({
                                    type: "checklist",
                                    text: (
                                      <div className="isFlex">
                                        {individualProduct.product_name}
                                      </div>
                                    ),
                                  }))}
                              />
                            }
                            footer={
                              <>
                                <Checkbox
                                  id="chkSelectBundleCard"
                                  label="Select"
                                  htmlFor="chkSelectBundleCard"
                                  checked={selected}
                                  onChange={(e) =>
                                    handleBundleUpsert(fniBundle, e)
                                  }
                                />
                                <Button
                                  htmlType="button"
                                  text="Full details"
                                  fixed
                                  linkButton
                                  withIcon={false}
                                  size="small"
                                  onClick={() => {
                                    addEvent(
                                      "linkClick",
                                      getLinkClickPayload("full details")
                                    );
                                    setBundleDetails(fniBundle);
                                  }}
                                />
                              </>
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="protection-warranty-local-nav">
                  <div className="protection-warranty-local-nav__inner">
                    <i
                      className="bmw-icon bmw-icon--before bmw-icon--speech-bubble-faq data-icon"
                      data-icon="speech_bubble_faq"
                    ></i>
                    <a
                      href="/"
                      className="protection-warranty-local-nav__link text-underline text--link-2"
                      onClick={(e) => {
                        e.preventDefault();
                        addEvent(
                          "linkClick",
                          getLinkClickPayload(
                            "need help finding the right protection"
                          )
                        );
                      }}
                    >
                      Need help finding the right protection?
                    </a>
                  </div>
                </div>
                <div className="protection-warranty__list-items-title">
                  <div className="text--subsection-2">
                    Add additional protection products.
                  </div>
                </div>
                <div className="incentives-discounts--list_wrap incentives-discounts--one_grid_sm protection-products-wrap">
                  {products.slice(0, 4).map((product, ind) => {
                    const selected = order_fnI.some(
                      (fni) =>
                        fni.product_name === product.product_name &&
                        fni.term === product.term_months &&
                        fni.mileage === product.term_miles &&
                        fni.applied_price === product.fni_product_amount &&
                        !fni.bundle_id
                    );

                    const bundleSelected = bundles.find((bundle) =>
                      order_fnI.some(
                        (fni) =>
                          fni.product_name === product.product_name &&
                          fni.bundle_id === bundle.id
                      )
                    );

                    return (
                      <IncentivesListCard
                        image={
                          product.image?.length ? product.image[0].location : ""
                        }
                        key={ind}
                        title={product.product_name}
                        termsInfo={
                          product.term_months && (
                            <div className="isFlex text--disclaimer">
                              <span>
                                {product.term_months} months{" "}
                                {`${
                                  product.term_miles
                                    ? "/ " +
                                      product.term_miles.toLocaleString(
                                        "en-US"
                                      ) +
                                      " miles"
                                    : ""
                                } `}
                              </span>
                              <Popover
                                trigger={["hover"]}
                                disclaimerText="This product’s coverage is pre-configured by {BMW of Manhattan} for {72 months or 100,000 miles}, whichever comes first. For more information, please contact the dealer."
                              >
                                <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                              </Popover>
                            </div>
                          )
                        }
                        subTitle={product.description}
                        price={
                          <h2 className="text--body-focus-price-1">
                            {product.monthly_impact
                              ? formatCurrency(product.monthly_impact)
                              : formatCurrency(0)}{" "}
                            <span className="text--body-1">/month</span>
                          </h2>
                        }
                        totalPrice={`Total ${formatCurrency(
                          product.fni_product_amount
                        )}`}
                        className=""
                        feedbackLable={
                          bundleSelected && (
                            <FeedbackLabel
                              text={`${product.product_name} is already included in ${bundleSelected.bundle_name} bundle`}
                              type="primary"
                            />
                          )
                        }
                        hasCheckbox={
                          <Checkbox
                            id={"incentives-discounts--chk1"}
                            label="Select"
                            htmlFor="incentives-discounts--chk1"
                            isCompact
                            disabled={!!bundleSelected}
                            onChange={(e) => handleProductUpsert(product, e)}
                            checked={selected && !bundleSelected}
                          />
                        }
                        actionsArea={
                          <Button
                            htmlType={"button"}
                            text="Full details"
                            linkButton
                            onClick={() => {
                              addEvent(
                                "linkClick",
                                getLinkClickPayload("full details")
                              );
                              setProductDetails(product);
                            }}
                          />
                        }
                      />
                    );
                  })}
                  {products.length > 4 && (
                    <div className="show-more-accordion">
                      <Accordion
                        variant="xs"
                        items={[
                          {
                            key: "Remaining Products",
                            label: `Show more products (${
                              products.length ? products.length - 4 : "XX"
                            })`,
                            children: products.slice(4).map((product, ind) => {
                              const selected = order_fnI.some(
                                (fni) =>
                                  fni.product_name === product.product_name &&
                                  fni.term === product.term_months &&
                                  fni.mileage === product.term_miles &&
                                  fni.applied_price ===
                                    product.fni_product_amount &&
                                  !fni.bundle_id
                              );

                              const bundleSelected = bundles.find((bundle) =>
                                order_fnI.some(
                                  (fni) =>
                                    fni.product_name === product.product_name &&
                                    fni.bundle_id === bundle.id
                                )
                              );

                              return (
                                <IncentivesListCard
                                  image={
                                    product.image?.length
                                      ? product.image[0].location
                                      : ""
                                  }
                                  key={ind}
                                  title={product.product_name}
                                  termsInfo={
                                    product.term_months && (
                                      <div className="isFlex text--disclaimer">
                                        {product.term_months} months{" "}
                                        {`${
                                          product.term_miles
                                            ? "/ " +
                                              product.term_miles.toLocaleString(
                                                "en-US"
                                              ) +
                                              " miles"
                                            : ""
                                        } `}
                                        <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                                      </div>
                                    )
                                  }
                                  subTitle={product.description}
                                  price={
                                    <h2 className="text--body-focus-price-1">
                                      {product.monthly_impact
                                        ? formatCurrency(product.monthly_impact)
                                        : 0}{" "}
                                      <span className="text--body-1">
                                        /month
                                      </span>
                                    </h2>
                                  }
                                  totalPrice={`Total ${formatCurrency(
                                    product.fni_product_amount
                                  )}`}
                                  className=""
                                  feedbackLable={
                                    bundleSelected && (
                                      <FeedbackLabel
                                        text={`${product.product_name} is already included in ${bundleSelected.bundle_name} bundle`}
                                        type="primary"
                                      />
                                    )
                                  }
                                  hasCheckbox={
                                    <Checkbox
                                      id={"incentives-discounts--chk1"}
                                      label="Select"
                                      htmlFor="incentives-discounts--chk1"
                                      isCompact
                                      disabled={!!bundleSelected}
                                      checked={selected && !bundleSelected}
                                      onChange={(e) =>
                                        handleProductUpsert(product, e)
                                      }
                                    />
                                  }
                                  actionsArea={
                                    <Button
                                      htmlType={"button"}
                                      text="Full details"
                                      linkButton
                                      onClick={() => {
                                        addEvent(
                                          "linkClick",
                                          getLinkClickPayload("full details")
                                        );
                                        setProductDetails(product);
                                      }}
                                    />
                                  }
                                />
                              );
                            }),
                          },
                        ]}
                        defaultActiveKey={0}
                        ghost={false}
                        expandIconPosition="start"
                      />
                    </div>
                  )}
                </div>

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <Button
                      htmlType={"button"}
                      text={
                        order_fnI.length
                          ? "Continue"
                          : "Continue without protection"
                      }
                      type="primary"
                      fixed
                      fullWidth
                      onClick={() => {
                        if (order_fnI.length) {
                          saveOrder();
                        } else {
                          addEvent(
                            "linkClick",
                            getLinkClickPayload("continue without protection")
                          );
                          navigate(`/accessories${windowLocation.search}`);
                        }
                      }}
                    />

                    {!userCredentials.access_token && (
                      <Button
                        htmlType={"button"}
                        text="Sign in to save"
                        fullWidth
                        onClick={toggleSignIn}
                      />
                    )}
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <RightRail hasFinance />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <RightRailFixed hasFinance />
      </div>
      <div className="protection-warranty-drawer">
        <Drawer
          width={336}
          open={isDrawerEnable || isDrawerToggle}
          onClose={() => closeDrawer()}
          maskClosable={true}
          keyboard={true}
          content={<FinanceLeasePricingDrawerContent />}
          footer={
            <div className="fl-pricing-footer-wrap">
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <small className="text--disclaimer">Est. payments</small>
                    <h2 className="text--body-focus-price-1">
                      ${order.estimated_monthly_payment}
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                  </>
                }
              />
            </div>
          }
        />
        <Drawer
          width={336}
          open={!!bundleDetails}
          maskClosable={true}
          keyboard={true}
          onClose={() => closeDrawer("exit protection sheet")}
          content={<BundleDetailsDrawerContent bundleDetails={bundleDetails} />}
          footer={
            <InsentivesAndDiscountsDrawerFooter
              price={
                bundleDetails && (
                  <>
                    <h2 className="text--body-focus-price-1">
                      {formatCurrency(bundleDetails.monthlySaving)}
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                    <small className="text--disclaimer">
                      Total: {formatCurrency(bundleDetails.costSaving)}
                    </small>
                  </>
                )
              }
              footerActions={
                order_fnI.some(
                  (fni) => fni.bundle_id === bundleDetails?.id
                ) && (
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    isLoading={isLoading}
                    type="default"
                    onClick={() => {
                      addEvent("linkClick", getLinkClickPayload("add to deal"));
                      handleBundleUpsert(bundleDetails);
                    }}
                  />
                )
              }
            />
          }
        />
        <div className="protection-warranty-product-details-drawer">
          <Drawer
            width={336}
            open={!!productDetails}
            maskClosable={true}
            keyboard={true}
            onClose={() => closeDrawer("exit protection sheet")}
            content={
              <ProductDetailsDrawerContent productDetails={productDetails} />
            }
            footer={
              <InsentivesAndDiscountsDrawerFooter
                price={
                  productDetails && (
                    <>
                      <h2 className="text--body-focus-price-1">
                        {formatCurrency(productDetails.monthly_impact)}
                        <span className="text--body-1">/month</span>{" "}
                      </h2>
                      <small className="text--disclaimer">
                        Total:{" "}
                        {formatCurrency(productDetails.fni_product_amount)}
                      </small>
                    </>
                  )
                }
                footerActions={
                  !order_fnI.some(
                    (fni) =>
                      (fni.product_name === productDetails?.product_name &&
                        fni.term === productDetails.term_months &&
                        fni.mileage === productDetails.term_miles &&
                        fni.applied_price ===
                          productDetails.fni_product_amount &&
                        !fni.bundle_id) ||
                      bundles.some((bundle) =>
                        order_fnI.some(
                          (fni) =>
                            fni.product_name === productDetails?.product_name &&
                            fni.bundle_id === bundle.id
                        )
                      )
                  ) && (
                    <Button
                      htmlType="button"
                      text="Add to deal"
                      isLoading={isLoading}
                      type="default"
                      onClick={() => handleProductUpsert(productDetails, true)}
                    />
                  )
                }
              />
            }
          />
        </div>
      </div>
    </>
  );
};
