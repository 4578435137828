import { createContext, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const authContext = createContext({});
export const useAuthContext = (): any => useContext(authContext);
export const AuthContextProvider = ({ children }): any => {
  const [contextState, setContextState] = useState({
    estimationAdded: false,
    isauthenticated: false,
    offerErrorParam: false,
    attemptsError: false,
    softcredit: false,
    nocart: false,
    financetype: false,
  });

  return (
    <authContext.Provider
      value={{
        contextState,
        setContextState,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
