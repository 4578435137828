import { Popover } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
export const AdjustedCapitalizedCostAccordionContent = () => {
  const yourPriceAccordionItems = [
    {
      key: "1",
      label: "Your price",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  MSRP
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $48,670
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Dealer Discount
                </span>
                <span className="pricing-details__item__price text--body-2">
                  -$2,000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: "$46,670",
    },
    {
      key: "2",
      label: "Incentives & Rebates",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  MSRP
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $48,670
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Dealer Discount
                </span>
                <span className="pricing-details__item__price text--body-2">
                  -$2,000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: "$46,670",
    },
    {
      key: "3",
      label: "Protection & Coverage",
      extra: "$1,200",
    },
    {
      key: "4",
      label: "Accessories",
      extra: "$152",
    },
    {
      key: "5",
      label: "Fees",
      extra: "$000",
    },
  ];
  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Gross Capitalized Cost
            </span>
            <span className="pricing-details__item__price text--body-2">
              $52,970
            </span>
          </div>
          <div className="pricing-details__item pr-0">
            <Accordion
              items={yourPriceAccordionItems}
              defaultActiveKey={0}
              ghost={false}
              size="small"
              fullWidth
            />
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Acquisition Fee
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Acquisition Fee is a one-time charge by the leasing company to initiate the lease agreement. This fee is provided for educational purposes only and may vary based on the leasing company’s policies. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $900
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Sales Tax represents the tax applied to the vehicle's purchase or lease price, as determined by state and local regulations. This information may vary by state or local tax laws. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $4,200
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax on Capitalized Cost Reduction
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Sales Tax on Capitalized Cost Reduction refers to taxes applied to any upfront payments or credits that reduce the lease amount. This information may vary by state or local tax laws. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $1,200
            </span>
          </div>
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Capitalized cost reduction
              <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              -$7,000
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
