import "./select-offer-completed.scss";
import CardLogo from "../../../../../../shared/assets/img/cardLogo.png";
import { Button, FinancialServiceCardSelect } from "@src/components/atoms";
import { useAppContext } from "@src/context/app-context";
import { formatCurrency } from "@src/helpers/appHelper";

export const SelectOfferCompleted = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  const {
    state: {
      order: {
        order_submissions,
        apr,
        finance_type,
        down_payment_value,
        contract_term,
        lender_id,
        estimated_monthly_payment,
        monthly_sales_use_tax,
      },
    },
  } = useAppContext();
  const showAPR = finance_type === "Finance";
  const lender = order_submissions.find(
    (submission) => submission.lender_id === lender_id
  ) || { provider: "" };
  return (
    <div className="offer-completed-container">
      <div className="select-offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            01
          </h3>
          <h3 className="checkout-title text--subsection-2">Finance offer.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={handleClick}
          size="small"
        />
      </div>
      <div className="offer-completed-content">
        <FinancialServiceCardSelect
          logo={CardLogo}
          title={lender?.provider}
          price={formatCurrency(estimated_monthly_payment)}
          downCash={formatCurrency(down_payment_value)}
          apr={showAPR ? `${apr}% APR` : ""}
          term={`${contract_term} month finance term`}
          className="financial-service-card-completed"
        />
      </div>
    </div>
  );
};
