import { Button } from "@src/components/atoms";
import "./resend-verification.scss";

import { useVerifyRadioContext } from "../verify-identity/verify-identity";

export const ResendVerification = () => {
  const { state, dispatch } = useVerifyRadioContext();
  return (
    <div className="resend-container">
      <div className="resend-container__header">
        <p className="resend-container__header__text  text--disclaimer text-secondary ">
          {state.isQRCodeScaned && "BMW ID verification in progress"}
          {state.isTextMessageSent &&
            "We've sent a link with instructions to (XXX) XXX-XXXX"}
          {state.isEmailSent &&
            "We've sent a link with instructions to [email address]"}
        </p>
        <p className=" text--subsection-1">
          {state.isQRCodeScaned && "Please use your mobile device to continue."}
          {state.isTextMessageSent &&
            "Please follow the link in your text message to begin verification."}
          {state.isEmailSent &&
            "Please follow the link in your email to begin verification."}
        </p>
      </div>
      <div className="resend-container__divider" />
      <div className="resend-container__restart-process-container">
        <p className="text--disclaimer">
          {state.isQRCodeScaned && "Having problems?"}
          {state.isTextMessageSent && "Didn’t receive the link?"}
          {state.isEmailSent && "Didn't receive an email?"}
        </p>
        <div className="resend-container__restart-process-container__button-wrapper">
          {/* <button
            className="button style-button--outline-dark style-button--default restart-button"
            type="button"
          >
            Resend link
          </button> */}
          <Button
            htmlType={"button"}
            text="Resend link"
            fixed
            onClick={() => {
              dispatch({ type: "VERIFICATION_IN_PROGRESS" });
            }}
          />
          <Button
            htmlType={"button"}
            text="Select different method"
            linkButton
            onClick={() => {
              dispatch({ type: "VERIFICATION_FAILED" });
            }}
          />
          {/* <button
            className="button style-button--outline-dark style-button--default select-different-method-button"
            type="button"
            onClick={() => {
              dispatch({ type: "VERIFICATION_FAILED" });
            }}
          >
            Select different method
          </button> */}
        </div>
      </div>
    </div>
  );
};
