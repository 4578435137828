import { Button, Drawer, Input } from "@src/components/atoms";
import { AddOnsDrawerFooter } from "@src/components/molecules";
import "./finance-pricing-details.scss";

export const FinancePricingDetails = ({ open, onClose }) => {
  return (
    <div className="calculate-trade-in-drawer finance-pricing-details">
      <Drawer
        width={375}
        open={open}
        maskClosable
        keyboard
        onClose={onClose}
        content={
          <>
            <div className="calculate-trade-in-drawer__content">
              <h3>Pricing details.</h3>
            </div>
            <ul className="finance-pricing-details__list">
              <li>
                <p>
                  [Name]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <div className="text-right">
                  <span>%X.XX</span>
                  <small className="text--label text-muted d-block">
                    for 72 months
                  </small>
                </div>
              </li>
              <li className="divider"></li>
              <li>
                <p>
                  [Name]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li>
                <p>
                  [Name]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li>
                <p>
                  [Trade in Equity]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li className="divider"></li>
              <li>
                <p>
                  [Offer/Incentive]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li>
                <p>
                  [Offer/Incentive]
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li className="divider"></li>
              <li>
                <p>
                  Taxes (NY State)
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$X,XXX</span>
              </li>
              <li>
                <p>Total Fees</p>
                <span>$X,XXX</span>
              </li>
            </ul>
            <ul className="finance-pricing-details__total-fee">
              <li>
                <p>
                  Fee name lorem
                  <span
                    className="bmw-icon bmw-icon--before data-icon"
                    data-icon="information"
                  />
                </p>
                <span>$XXX</span>
              </li>
              <li>
                <p>Fee name lorem</p>
                <span>$XXX</span>
              </li>
              <li>
                <p>Fee name lorem</p>
                <span>$XXX</span>
              </li>
              <li>
                <p>Fee name lorem</p>
                <span>$XXX</span>
              </li>
              <li>
                <p>Fee name lorem</p>
                <span>$XXX</span>
              </li>
            </ul>
          </>
        }
        footer={
          <>
            <AddOnsDrawerFooter
              price={
                <>
                  <small className="text--disclaimer">Est. payments</small>
                  <h2 className="text--body-focus-price-1">
                    $X,XXX
                    <span className="text--body-1">/month</span>
                  </h2>
                  <span className="text--label text-muted d-none d-md-block">
                    Lorem ipsum: $X,XXX.XX
                  </span>
                </>
              }
              footerActions={
                <>
                  <Button htmlType={"button"} text="Close" type="default" />
                </>
              }
            />
          </>
        }
      />
    </div>
  );
};
