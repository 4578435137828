import { PreCheckout } from "@src/components/organisms";
import { BaseLayout } from "@src/layouts";

export const PreCheckoutPage = () => {
  return (
    <BaseLayout hasMenu={false} hasFooter={true}>
      <PreCheckout />
    </BaseLayout>
  );
};
