import { FeeTypes } from "@src/constants";
import { PROGRAMSPAYLOAD, FEES, NFAPAYLOAD } from "@src/types";
import { isEmpty } from "lodash";

export const getDownPaymentHelper = (
  downPayment: number,
  sellingPrice: number
) => {
  return (downPayment * sellingPrice) / 100;
};

export const getAllProgramPayload = (
  vehicleDetail: any,
  companyCode: string,
  externalCode: string,
  dealerCode,
  financeType: string,
  term: number,
  annualUsage: number,
  creditRating: string,
  creditScore: number
): PROGRAMSPAYLOAD => {
  return {
    ...(financeType === "Lease" && {
      annual_usage: annualUsage,
      annual_mileage: annualUsage,
    }),
    credit_rating: creditRating,
    total_score: creditScore,
    dealer_code: dealerCode,
    model_detail_name: vehicleDetail.trim_description,
    make_name: vehicleDetail.make,
    model_name: vehicleDetail.model,
    rental_frequency: "Monthly",
    rental_mode: null,
    terms: term,
    retrieve_all_programs: false,
    company_code: companyCode,
    vin: vehicleDetail.vin,
    selling_price: vehicleDetail.unit_price,
    msrp: vehicleDetail.msrp,
    is_new: vehicleDetail.type.toLowerCase() === "new",
    odometer: vehicleDetail.mileage || 0,
    finance_type: financeType,
    lender_code: externalCode,
    model_code: vehicleDetail.model_code,
  };
};

export const getOrderOptionsPayloadMapping = (orderOptions: any) => {
  return orderOptions.reduce((acc: any[], option: any) => {
    if (
      (option.installation_mode === "Pre-Installed" &&
        option.is_price_inclusive === false) ||
      option.installation_mode === "Optional"
    ) {
      acc.push({
        option_id: option.option_id,
        option_name: option.product_name,
        option_amount: option.applied_price,
        applied_price: option.applied_price,
        rv_balloon_percentage: option.rv_balloon_percentage ?? 0,
        rv_balloon_value: option.rv_balloon_value ?? 0,
        installation_mode: option.installation_mode,
        is_price_inclusive: option.is_price_inclusive,
      });
    }
    return acc;
  }, []);
};
export const getNFAPayload = (orderValues, dealerCode): any => {
  return {
    //  payoff_amount: payoffAmount ?? 0,
    down_payment: (orderValues.selling_price / 100) * orderValues.down_payment,
    dealer_code: dealerCode,
    per_unit_selling_price: orderValues.selling_price,
    fees: orderValues.order_fees
      ?.filter((fee: any) => fee.event !== FeeTypes.EOT)
      .map((orderFee: any) => ({
        fee_name: orderFee.fee_name,
        fee_amount: orderFee.default_amount,
        fee_handling: orderFee.fee_handling,
        is_taxable: orderFee.taxable,
      })),
    insurance: [],
    trade_in_amount: orderValues.order_tradein?.trade_in_amount,
    fni_products: orderValues.order_fnI.map(
      (fni: {
        applied_price: number;
        product_name: string;
        taxable: boolean;
      }) => ({
        fni_product_amount: fni.applied_price,
        fni_product_name: fni.product_name,
        is_taxable: fni.taxable,
      })
    ),
    options: getOrderOptionsPayloadMapping(orderValues.order_options),
  };
};
export const getV2Payload = (
  orderValues,
  dealerCode,
  customerAddress,
  dealerAddress,
  lenderExternalCode
) => {
  return {
    dealer_code: dealerCode,
    customer_address: {
      street_address:
        orderValues.customerV2Address?.address_line_1 ??
        customerAddress.address_line_1,
      city: orderValues.customerV2Address?.city ?? customerAddress.city,
      state:
        orderValues.customerV2Address?.state_name ?? customerAddress.state_name,
      zip_code:
        orderValues.customerV2Address?.zip_code ?? customerAddress.zip_code,
      address_type: "Customer",
    },
    dealer_address: {
      street_address: dealerAddress.address_line_1,
      city: dealerAddress.city,
      state: dealerAddress.state_name,
      zip_code: dealerAddress.zip_code,
      address_type: "Dealer",
    },
    vehicle: {
      vin: orderValues.order_asset.vin,
      is_new: orderValues.order_asset.type === "New",
      odometer_mileage: isEmpty(orderValues.order_asset.mileage)
        ? 10
        : orderValues.order_asset.mileage,
      msrp: orderValues.order_asset.msrp,
      year: orderValues.order_asset.year,
      transmission_type: orderValues.order_asset.transmission_type,
    },
    asset_condition: 63,
    rebate: { price: orderValues.rebate ?? 0 },
    credit_rating: orderValues.credit_rating,
    terms: orderValues.contract_term,
    annual_usage: orderValues.annual_usage,
    is_rv_guaranteed: "False",
    rv_amount: 0,
    net_financed_amount: orderValues.net_finance_amount,
    base_rate: orderValues.base_rate,
    apr: orderValues.apr,
    finance_type: orderValues.finance_type,
    interest_chart_margin: orderValues.margin,
    rental_mode: orderValues.rental_mode,
    rental_frequency: "Monthly",
    trade_ins: Array.isArray(orderValues.order_tradein)
      ? orderValues.order_tradein
      : orderValues.order_tradein
      ? [orderValues.order_tradein]
      : [],
    calculation_method: null,
    rv_value: orderValues.rv_balloon_percentage,
    tax_on_selling_price: orderValues.tax_amount,
    lender_id: lenderExternalCode,
  };
};

export const getPenRatesPayload = (
  vehicle: any,
  penDealerId: string,
  financeType: string,
  apr: number,
  term: number
): any => {
  const currentDate = new Date(Date.now()).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return {
    deal_info: {
      pen_dealer_id: penDealerId,
      vin: vehicle.vin,
      mileage: vehicle.mileage ?? 10,
      car_status: getCarStatus(vehicle.type?.toString()?.toLowerCase()),
      effective_date: currentDate,
      in_service_date: currentDate,
      finance_type: financeType,
      vehicle_msrp: vehicle.msrp,
      vehicle_purchase_price: vehicle.internet_price,
      deal_type: financeType === "Lease" ? "LEASE" : "LOAN",
      finance_terms_mileage: [24],
      financed_amount: 33718,
      language: "ENGLISH",
      user_role: "FI_MANAGER",
      apr: apr,
      finance_terms: [term],
    },
    test_request: true,
  };
};

const getCarStatus = (type: string): string => {
  switch (type?.toLowerCase()) {
    case "pre-owned":
    case "pre installed":
      return "USED";
    case "certified pre-owned":
      return "CERTIFIEDPREOWNED";
    default:
      return type?.toUpperCase() || "";
  }
};

export const getLinkClickPayload = (
  name: string,
  type: "text" | "qr code" | "email" | "button" = "button"
) => {
  if (!name) {
    console.error("Invalid link name.");
    return null;
  }

  return {
    link: {
      name,
      type,
    },
  };
};

export const generatePayload = (
  pageName: string,
  section: string,
  isUserLoggedIn: boolean,
  dealer,
  order,
  fields?: string[]
) => {
  const allProductInfo = {
    modelName: order?.order_asset?.trim_description,
    series: order?.order_asset?.model,
    productionYear: order?.order_asset?.year,
    status: order?.order_asset?.status,
    creditScore: order?.creditScore,
    monthTerm: order?.contract_term,
    downPayment:
      (order?.order_asset?.unit_price * 100) / (order?.down_payment ?? 0),
  };

  const productInfo = fields?.length
    ? fields.reduce((acc, key) => {
        if (key in allProductInfo) acc[key] = allProductInfo[key];
        return acc;
      }, {})
    : allProductInfo;

  return {
    pageInfo: {
      pageName: pageName,
      pageSection: section,
      authenticatedStatus: isUserLoggedIn ? "yes" : "no",
    },
    dealerInfo: {
      dealerName: dealer?.dealer_name,
      dealerID: dealer?.id,
    },
    productInfo,
  };
};

export const generateUrl = (pathPrefix, dealRefNumber, windowLocation) =>
  dealRefNumber
    ? `/${pathPrefix}/deal/${dealRefNumber}`
    : `/${pathPrefix}${windowLocation.search}`;

export const rvBalloonPercentage = (order, program) => {
  return order?.finance_type == "Lease"
    ? (program?.rv_chart?.[0]?.rv_value /
        (order?.order_asset?.unit_price ?? order?.selling_price)) *
        100
    : program?.rv_chart?.[0]?.rv_value;
};
