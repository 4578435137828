import { Popover } from "@src/components/atoms";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";

export const AmountFinancedAccordionContent = () => {
  const customAddOnsAccordionItems = [
    {
      key: "1",
      label: "Incentives & Rebates",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Loyalty Credit
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  New York Incentive
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">-$750</span>,
    },
    {
      key: "2",
      label: (
        <div className="accordion-title-with-icon">
          Trade-in Equity
          <Popover
            trigger={["hover"]}
            placement="top"
            disclaimerText="Trade-in equity is the value of your current vehicle that is applied toward your new purchase. Positive equity reduces the amount you finance, while negative equity may increase your financial arrangement balance."
          >
            <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
          </Popover>
        </div>
      ),
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Net offer value
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Dealer Contribution
                  <Popover
                    trigger={["hover"]}
                    placement="top"
                    disclaimerText='Similar to a Down payment, money provided by the lessee at inception reduces the capitalized cost of the lease. Your total amount "Due-at-Signing" may include additional amounts. A full breakdown of your total "Due-at-Signing" is provided after all estimator steps are completed.'
                  >
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </Popover>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  - $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Payoff Amount
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">-$5,000</span>,
    },
    {
      key: "3",
      label: "Protection & Coverage",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Essential protection bundle
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Windshield protection
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$1,200</span>,
    },
    {
      key: "4",
      label: "Accessories",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  BMW Crystal Fusion
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  BMW Paint Protection Wrap
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$1,200</span>,
    },
  ];

  const taxFeesAccordionItems = [
    {
      key: "1",
      label: "Fees",
      children: (
        <div className="your-price-accordion-content">
          <ul>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Placeholder
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
            <li>
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-2">
                  Placeholder
                </span>
                <span className="pricing-details__item__price text--body-2">
                  $000
                </span>
              </div>
            </li>
          </ul>
        </div>
      ),
      extra: <span className="text--body-2">$000</span>,
    },
  ];

  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Your Price
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $46,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              MSRP
            </span>
            <span className="pricing-details__item__price text--body-2">
              $48,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Dealer Discount
            </span>
            <span className="pricing-details__item__price text--body-2">
              -$2,000
            </span>
          </div>
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Custom Add-Ons
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <Accordion
            items={customAddOnsAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Taxes & fees
            </span>
            <span className="pricing-details__item__price text--body-2 fw-bold">
              $0,000
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Sales Tax
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Sales Tax represents the tax applied to the vehicle's purchase or lease price, as determined by state and local regulations. This information may vary by state or local tax laws. "
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $4,200
            </span>
          </div>
          <Accordion
            items={taxFeesAccordionItems}
            defaultActiveKey={0}
            ghost={false}
            size="small"
            fullWidth
          />
        </div>
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Down Payment
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Down payment reduces the total amount financed."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              -$5,000
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
