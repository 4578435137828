import {
  DMS_BASE_URL,
  CUSTOMER,
  UMS_BASE_URL,
  AUTH_TOKEN,
  CUSTOMER_EMAIL_CHECK,
  CREDIT_CHECK,
  CUSTOMER_SAVE_DISCLOSURE,
  GET_CUSTOMER_DISCLOSURE,
  HUBEX_BASE_URL,
  VERIFY_ADDRESS,
} from "../constants/routes";
import { http } from "../utilities";
import {
  Address,
  BasicCustomerInfo,
  Customer,
  FinancialProfile,
} from "@src/types/customer";

export const getCustomerByEmail = async ({ email }) => {
  try {
    const response = await http(DMS_BASE_URL).get(`${CUSTOMER}?email=${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCustomer = async (body) => {
  try {
    const response = await http(DMS_BASE_URL).patch(
      `${CUSTOMER_EMAIL_CHECK}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async (body) => {
  try {
    const response = await http(UMS_BASE_URL).post(`${AUTH_TOKEN}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerFinancialProfile = async ({
  reference_id,
  dealer_code,
}) => {
  try {
    const response = await http(DMS_BASE_URL).get(
      `${CUSTOMER}/personal-finance?reference_id=${reference_id}&dealer_code=${dealer_code}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomerFinancialProfile = async (
  reference_id: string,
  dealer_code: string,
  payload: FinancialProfile & { method?: string }
) => {
  const { method, ...rest } = payload;
  const httpMethod = method || "PATCH";

  try {
    const response = await http(DMS_BASE_URL)[httpMethod.toLowerCase()](
      `${CUSTOMER}/personal-finance?reference_id=${reference_id}&dealer_code=${dealer_code}`,
      rest
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const customerUpdate = async (
  type: string,
  reference_id: string,
  dealer_code: string | undefined,
  order_id: string | undefined,
  method: string | undefined,
  rest: any
) => {
  const httpMethod = method || "PATCH";
  const url = `${CUSTOMER}${type}?reference_id=${reference_id}&${
    order_id ? `order_id=${order_id}` : `dealer_code=${dealer_code}`
  }`;

  const payload =
    type === "/employment-info" ? { ...rest, reference_id } : rest;

  try {
    const response = await http(DMS_BASE_URL)[httpMethod.toLowerCase()](
      url,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerCreditCheck = async (body) => {
  try {
    const queryParams = new URLSearchParams({
      customer_reference_id: String(body.customerReferenceId),
      dealer_code: String(body.dealCode),
    }).toString();
    const response = await http(DMS_BASE_URL).post(
      `${CREDIT_CHECK}?${queryParams}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomerDisclosure = async (body) => {
  try {
    const response = await http(DMS_BASE_URL).post(
      `${CUSTOMER_SAVE_DISCLOSURE}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerDisclosure = async ({ reference_number }) => {
  try {
    const response = await http(DMS_BASE_URL).get(
      `${GET_CUSTOMER_DISCLOSURE}?reference_number=${reference_number}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyAddress = async (zipCode) => {
  try {
    const response = await http(HUBEX_BASE_URL).get(
      `${VERIFY_ADDRESS}${zipCode}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
