import { ChatAutomatedMessageProps } from "./chat-automated-message.props";
import clsx from "clsx";
import "./chat-automated-message.scss";

export const ChatAutomatedMessage = ({
  logo,
  source,
  time,
  message,
}: ChatAutomatedMessageProps) => {
  return (
    <div
      className={clsx({
        "chat-automated-wrap": true,
      })}
    >
      {logo &&
        (typeof logo === "string" ? (
          <span
            className="bmw-icon bmw-icon--before data-icon chat-conscent-logo"
            data-icon={logo}
            role="presentation"
          ></span>
        ) : (
          <span className="chat-conscent-logo">{logo}</span>
        ))}
      <div>
        <div className="chat-automated--header">
          <p className="chat-automated--source">{source}</p>
          <span>-</span>
          <p className="chat-automated--time">{time}</p>
        </div>
        <div className="chat-automated--message text--body-2">{message}</div>
      </div>
    </div>
  );
};
