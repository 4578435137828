import "./cash-tab-content.scss";
import { Accordion } from "@src/components/atoms/accordion/accordion.component";
import { TotalPriceAccordionContent } from "./total-price-accordion-content/total-price-accordion-content";

export const CashTabContent = () => {
  return (
    <>
      <div className="update-payment-tab update-payment-tab--cash">
        <div className="payment-calculator">
          <div className="payment-calculator__price-card">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Cash offer
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Include taxes & fees, destination
            </p>
          </div>
          {/* This section is for Checkout Pricing Details Sheet */}
          <div className="payment-calculator__price-card payment-calculator__price-card--checkout-pricing">
            <div className="payment-calculator__price-card__inner">
              <div className="payment-calculator__price-card__left">
                <div className="payment-calculator__price-card__pricing">
                  <span className="text--body-2 label">
                    Cash offer
                    <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                  </span>
                  <div className="text--promo-focus-price price">$000,000</div>
                </div>
              </div>
            </div>
            <p className="text--disclaimer">
              Total price includes taxes & fees.
            </p>
          </div>
        </div>
        <div className="pricing-details">
          <div className="pricing-details__heading">
            <div className="text--subsection-1">Pricing details.</div>
          </div>
          <div className="pricing-details__inner">
            <div className="pricing-details-cash-content-box">
              <div className="pricing-details__item pricing-details__item--cash">
                <span className="pricing-details__item__label text--body-1">
                  Total Price
                  <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
                </span>
                <span className="pricing-details__item__price text--body-focus-price-1 fw-bold">
                  $41,972
                </span>
              </div>
              <TotalPriceAccordionContent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
