import { Button, CardSelect, Checkbox } from "@src/components/atoms";
import { useGetDisclaimerByName, useAddCustomerConsent } from "@src/hooks";
import BMWFSLogo from "@src/shared/assets/img/logo.jpg";
import GroupImage from "../../../../../../shared/assets/img/Group.svg";
import { useAppContext } from "@src/context/app-context";
import { getDisclaimerByType } from "@src/helpers";
import {
  updateDisclaimers,
  updateLenderSubmissions,
} from "@src/context/app-actions";
import { FinanceType } from "@src/constants";
import { Disclaimer } from "@src/types";

import "./finance-offer-provider.style.scss";

export const FinanceOfferProvider = ({
  stepFiveDataReceivedEvent,
}: {
  stepFiveDataReceivedEvent: () => void;
}) => {
  const { isPending: loadDisclaimer, mutateAsync: changeDisclaimer } =
    useGetDisclaimerByName();

  const {
    state: {
      user: {
        customer: { reference_id },
      },

      dealer: { dealer_name, disclaimer },
      order: { reference_number, finance_type },
      lender: { defaultLender, lenders, lenderSubmissions },
    },
    dispatch: appDispatch,
  } = useAppContext();

  const { mutate: addConsent } = useAddCustomerConsent();

  const dealDisclaimer = async () => {
    const lenderSubmission = disclaimer.lenderSubmission as Disclaimer | null;

    if (!lenderSubmission?.customerFetch) {
      addConsent({
        customer_reference_id: reference_id,
        disclaimer_name: lenderSubmission?.disclaimer_name,
        disclaimer_version: lenderSubmission?.disclaimer_version,
        order_reference_id: reference_number,
      });
    }
    const creditApplicationDisclaimer =
      disclaimer.customerCreditApplicationDisclaimer;
    if (!creditApplicationDisclaimer.disclaimer_name) {
      await changeDisclaimer(
        { disclaimerName: "customer-credit-application-disclaimer" },
        {
          onSuccess: async (response: any) => {
            if (Array.isArray(response) && response[0]) {
              const creditAppDisclaimer = getDisclaimerByType(
                response,
                dealer_name
              );
              await appDispatch(
                updateDisclaimers({ ...disclaimer, ...creditAppDisclaimer })
              );
            }
            stepFiveDataReceivedEvent();
          },
        }
      );
    } else {
      stepFiveDataReceivedEvent();
    }
  };

  const handleOtherLenderSubmissions = (selected) => {
    if (selected) {
      const newIds = lenders.map((lender) => lender.id);
      const updatedLenderSubmissions = [
        ...new Set([...lenderSubmissions, ...newIds]),
      ];
      appDispatch(updateLenderSubmissions(updatedLenderSubmissions));
    } else {
      appDispatch(updateLenderSubmissions([defaultLender.id]));
    }
  };

  return (
    <div className="select-finance-offer-provider">
      <div className="select-finance-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            05
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Select finance offer provider(s).
          </h3>
        </div>
      </div>
      <div className="select-finance-body">
        <div className="description-container">
          <span className="text--body-2">
            Your credit application will be processed by BMW Financial Services,
            for whom this platform is optimized. You may also request quotes
            from other finance offer providers through {dealer_name}.
          </span>
          <span className="text--body-2 your-finance">
            Your finance offer provider.
          </span>
        </div>
        <div className="card-container">
          {[defaultLender].map((option, index) => (
            <CardSelect
              key={index}
              logo={BMWFSLogo}
              text={option.name}
              defaultCard={option.default_lender}
            />
          ))}
          {finance_type === FinanceType.FINANCE && lenders.length > 1 && (
            <CardSelect
              logo={GroupImage}
              text={`Other Lenders for  ${dealer_name}`}
              hasCheckbox={
                <Checkbox
                  onChange={handleOtherLenderSubmissions}
                  label={
                    lenderSubmissions.length === 1 &&
                    lenderSubmissions.includes(defaultLender.id) ? (
                      <span className="lenders-select-box-text">Select</span>
                    ) : (
                      "Selected"
                    )
                  }
                  isCompact
                />
              }
            />
          )}
        </div>
        {disclaimer?.lenderSubmission && (
          <div
            dangerouslySetInnerHTML={{
              __html: disclaimer?.lenderSubmission?.changesDisclaimer,
            }}
          />
        )}
        <Button
          type="primary"
          htmlType={"button"}
          text="Agree &amp; continue"
          isLoading={loadDisclaimer}
          onClick={dealDisclaimer}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
