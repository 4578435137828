import { Button, Checkbox, Image } from "@src/components/atoms";
import Placeholder from "../../../../../shared/assets/img/id-verification/user-selfie.jpg";
import CardFront from "../../../../../shared/assets/img/id-verification/id-card-front.jpg";
import CardBack from "../../../../../shared/assets/img/id-verification/id-card-back.jpg";
import "./verify-complete-id.scss";

export const VerifyCompleteId = () => {
  return (
    <div className="verifi-id-section start-verification-spacing verify-id-complete-wrap">
      <div className="verifi-id-section__back">
        <Button
          htmlType="button"
          linkButton
          text="Back"
          withIcon
          dataIcon="arrow_chevron_down"
        />
      </div>
      <div className="verifi-id-section__count">
        <p className="text--body-1">Step 5/ 5: Review & Submit</p>
      </div>
      <div className="verifi-id-section__inner">
        <h2 className="text--h2">Please review & submit your photos.</h2>
        <div className="verify-id-complete--images">
          <div className="single-image-wrap">
            <h5 className="text--subsection-2">Front of ID</h5>
            <div className="verifi-id-section__image-holder full-width--image">
              <div className="verification-section__image-placeholder">
                <Image src={CardFront} alt="image" />
              </div>
            </div>
            <Button htmlType={"button"} text="Retake" size="small" />
          </div>
          <div className="single-image-wrap">
            <h5 className="text--subsection-2">Back of ID</h5>
            <div className="verifi-id-section__image-holder full-width--image">
              <div className="verification-section__image-placeholder">
                <Image src={CardBack} alt="image" />
              </div>
            </div>
            <Button htmlType={"button"} text="Retake" size="small" />
          </div>
          <div className="single-image-wrap">
            <h5 className="text--subsection-2">Selfie</h5>
            <div className="verifi-id-section__image-holder">
              <div className="verification-section__image-placeholder">
                <Image src={Placeholder} alt="image" />
              </div>
            </div>
            <Button htmlType={"button"} text="Retake" size="small" />
          </div>
        </div>
      </div>
      <div className="verifi-id-section__bottom verification-hero__bottom--full-width">
        <Button
          htmlType={"button"}
          text="Submit"
          type="primary"
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
