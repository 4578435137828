import { useEffect, useState } from "react";
import { FinancialServiceCard } from "@src/components/molecules";
import { useAppContext } from "@src/context/app-context";
import { Button, Checkbox } from "@src/components/atoms";
import "./select-offer.scss";

import { setOrderInfo } from "@src/context/app-actions";

import {
  useCalculateOrderQuotation,
  useGetAllPrograms,
  useUpdateDeal,
  useUpdateOrderStatusByLender,
} from "@src/hooks";
import { isEmpty } from "lodash";
import {
  getAllProgramPayload,
  getOrderOptionsPayloadMapping,
} from "@src/helpers/payloadHelper";
import { FeeTypes } from "@src/constants";

export interface OrderSubmission {
  monthly_payment: number;
  apr: number;
  down_payment: number;
  provider: string;
  contract_term: number;
  id: number;
  lender_id: number;
  fp_id?: string;
  disabled?: boolean;
}

export const SelectOffer = ({ handleClick }: { handleClick: () => void }) => {
  const [orderSubmissions, setOrderSubmission] = useState<OrderSubmission[]>(
    []
  );

  const [values, setValues] = useState<{
    disclaimerCheckbox: boolean;
    submission: OrderSubmission;
  }>({
    disclaimerCheckbox: false,
    submission: {
      monthly_payment: 0,
      apr: 0,
      down_payment: 0,
      provider: "",
      contract_term: 0,
      id: 0,
      lender_id: 0,
      disabled: false,
    },
  });

  const {
    state: {
      order,
      lender: { lenders, defaultLender },
      tenant: { companyCode, company_id },
      dealer: { dealer_code, disclaimer, dealer_address },
      user: {
        customer: { customer_addresses },
      },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const {
    down_payment,
    order_fees,
    selling_price,
    order_tradein,
    contract_term,
    credit_rating,
    rebate,
    base_rate,
    net_finance_amount,
    order_fnI,
    order_options,
    order_asset,
    annual_usage,
    rental_mode,
    margin,
    finance_type,
  } = order;
  const { mutate: calculateOrder } = useCalculateOrderQuotation();

  const { mutate: updateOrder, isPending } = useUpdateDeal();
  const { mutate: updateOrderByLender } = useUpdateOrderStatusByLender();
  const { mutateAsync: getProgram } = useGetAllPrograms();

  useEffect(() => {
    const fetchProgramData = async () => {
      for (let submission of order.order_submissions) {
        const creditTierMargin = defaultLender.credit_tier_margins.find(
          (margin) =>
            margin.finance_type === order.finance_type &&
            submission.decision?.tier === margin.tier_name
        );
        const lender = lenders.find(
          (lender) => lender.id === submission.lender_id
        );

        try {
          // Wait for the program API to complete before proceeding to the next iteration
          await getProgram(
            {
              ...getAllProgramPayload(
                order.order_asset,
                companyCode,
                lender?.external_code ?? defaultLender.external_code,
                dealer_code,
                order.finance_type,
                order.contract_term,
                order.annual_usage,
                order.credit_rating,
                order.credit_score
              ),
              tier: submission.decision?.tier || "",
            },
            {
              onSuccess: async (programRes: any) => {
                if (!isEmpty(programRes.detail) || programRes.length === 0) {
                  console.error("ERROR: Sorry No Program Found");
                  return;
                } else {
                  // Calculate Order only after receiving the programRes
                  await calculateOrder(
                    {
                      down_payment: (selling_price / 100) * down_payment,
                      dealer_code: dealer_code,
                      per_unit_selling_price: selling_price,
                      fees: order_fees
                        ?.filter((fee: any) => fee.event !== FeeTypes.EOT)
                        .map((orderFee: any) => ({
                          fee_name: orderFee.fee_name,
                          fee_amount: orderFee.default_amount,
                          fee_handling: orderFee.fee_handling,
                          is_taxable: orderFee.taxable,
                        })),
                      insurance: [],
                      trade_in_amount: order_tradein?.trade_in_amount,
                      fni_products: order_fnI.map(
                        (fni: {
                          applied_price?: number;
                          product_name: string;
                          taxable: boolean;
                        }) => ({
                          fni_product_amount: fni.applied_price,
                          fni_product_name: fni.product_name,
                          is_taxable: fni.taxable,
                        })
                      ),
                      options: getOrderOptionsPayloadMapping(order_options),
                      customer_address: !!customer_addresses.garaging
                        ?.entity_type
                        ? {
                            street_address:
                              customer_addresses.mailing.address_line_1,
                            city: customer_addresses.mailing.city,
                            state: customer_addresses.mailing.state_name,
                            zip_code: customer_addresses.mailing.zip_code,
                            address_type: "Customer",
                          }
                        : {
                            street_address:
                              customer_addresses.garaging.address_line_1,
                            city: customer_addresses.garaging.city,
                            state: customer_addresses.garaging.state_name,
                            zip_code: customer_addresses.garaging.zip_code,
                            address_type: "Customer",
                          },
                      dealer_address: {
                        street_address: dealer_address.address_line_1,
                        city: dealer_address.city,
                        state: dealer_address.state_name,
                        zip_code: dealer_address.zip_code,
                        address_type: "Dealer",
                      },
                      vehicle: {
                        vin: order_asset.vin,
                        is_new: order_asset.type === "New",
                        odometer_mileage: order_asset.mileage,
                        msrp: order_asset.msrp,
                        year: order_asset.year,
                        transmission_type: order_asset.transmission_type,
                      },
                      asset_condition: 63,
                      rebate: { price: rebate ?? 0 },
                      credit_rating: credit_rating,
                      terms: contract_term,
                      annual_usage: annual_usage,
                      is_rv_guaranteed: "False",
                      rv_amount: 0,
                      net_financed_amount: net_finance_amount,
                      base_rate: base_rate,
                      apr:
                        programRes.final_customer_rate +
                        (creditTierMargin?.margin_rate ?? 0),
                      finance_type: finance_type,
                      interest_chart_margin: margin,
                      rental_mode: rental_mode,
                      rental_frequency: "Monthly",
                      trade_ins: Array.isArray(order_tradein)
                        ? order_tradein
                        : order_tradein
                        ? [order_tradein]
                        : [],
                      calculation_method: null,
                      rv_value: programRes.assetRvAmount,
                      lender_id: lender?.external_code,
                    },
                    {
                      onSuccess: (v2Results) => {
                        setOrderSubmission((prevSubmissions) => [
                          ...prevSubmissions,
                          {
                            monthly_payment: v2Results.monthly_payment,
                            apr: v2Results.final_rate,
                            down_payment: (selling_price / 100) * down_payment,
                            provider: submission.provider,
                            contract_term: order.contract_term,
                            id: submission.id,
                            lender_id: submission.lender_id,
                            fp_id: programRes.fp_id,
                            disabled: submission.status === "Failed",
                          },
                        ]);
                      },
                      onError: (error) => {
                        console.error("Error fetching pricing:", error);
                      },
                    }
                  );
                }
              },
              onError: (err) => {
                console.error("err", err);
              },
            }
          );
        } catch (error) {
          console.error("Error in API call", error);
        }
      }
    };

    // Run the fetchProgramData function when the component mounts
    fetchProgramData();
  }, []);

  const handleSubmit = () => {
    if (values.disclaimerCheckbox && values.submission.id) {
      updateOrder(
        {
          ...order,
          reference_id: order.customer_info.reference_id,
          company_id: company_id,
          lender_id: values.submission.lender_id,
          apr: values.submission.apr,
          estimated_monthly_payment: values.submission.monthly_payment,
          fp_id: values.submission.fp_id,
        },
        {
          onSuccess: () => {
            appDispatch(
              setOrderInfo({
                ...order,
                status: "Approved",
                lender_id: values.submission.lender_id,
                apr: values.submission.apr,
                estimated_monthly_payment: values.submission.monthly_payment,
                fp_id: values.submission.fp_id,
              })
            );
            updateOrderByLender({
              referenceNumber: order.reference_number,
              lenderId: values.submission.lender_id ?? order.lender_id,
              orderStatus: "Approved",
            });
            handleClick();
          },
          onError: (err) => {
            console.error("err", err);
          },
        }
      );
    }
  };
  return (
    <div className="select-offer-container">
      <div className="offer-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            01
          </h3>
          <h3 className="checkout-title text--subsection-2">Finance offer.</h3>
        </div>
      </div>
      {orderSubmissions.length > 0 && (
        <FinancialServiceCard
          setValues={setValues}
          values={values}
          orderSubmissions={orderSubmissions}
        />
      )}

      {disclaimer.acceptanceOffer?.changesDisclaimer && (
        <div className="offer-checkbox">
          <Checkbox
            id="chk--accept-terms"
            onChange={(e) =>
              setValues((prev) => ({ ...prev, disclaimerCheckbox: e }))
            }
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: disclaimer["acceptanceOffer"].changesDisclaimer,
                }}
              ></div>
            }
            htmlFor="chk--accept-terms"
          />
        </div>
      )}

      <div className="offer-cta-wrapper">
        <Button
          type="default"
          isLoading={isPending}
          disabled={values.submission.id === 0 || !values.disclaimerCheckbox}
          id="acceptoffer"
          htmlType={"button"}
          text="Accept offer"
          fullWidth
          fixed
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
