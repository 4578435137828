import { AppError } from "single-spa";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { getDownPaymentHelper } from "@src/helpers";
import { PROGRAMSPAYLOAD, NFAPAYLOAD } from "@src/types";
import { useAppContext } from "@src/context/app-context";
import { updateProgramerror } from "@src/context/app-actions";
import {
  calculateNFA,
  calculateOrderQuotation,
  getAllPrograms,
} from "@src/services/program";

export const useGetAllPrograms = (): {
  mutateAsync: UseMutateFunction<{}, AppError, any>;
  error: string | undefined;
  isPending: boolean;
} => {
  const { dispatch: appDispatch } = useAppContext();
  const { mutateAsync, error, isPending } = useMutation<
    {},
    AppError,
    PROGRAMSPAYLOAD
  >({
    mutationFn: async (body: any) => {
      let programData: any = [];
      try {
        programData = await getAllPrograms(body);
      } catch (error) {
        appDispatch(updateProgramerror(true));
        console.error("pricing error:", error);
      }

      if (programData.length > 0) {
        let down_payment = 0,
          minimum_down_payment = 0,
          maximum_down_payment = 0;

        if (
          Array.isArray(programData[0].downpayment_chart) &&
          programData[0].downpayment_chart.length
        ) {
          down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].default_down_payment,
            body.selling_price
          );
          minimum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].minimum_down_payment,
            body.selling_price
          );
          maximum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart[0].maximum_down_payment,
            body.selling_price
          );
        } else if ("default_down_payment" in programData[0].downpayment_chart) {
          down_payment = getDownPaymentHelper(
            programData[0]?.downpayment_chart?.default_down_payment,
            body.selling_price
          );
          minimum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart.minimum_down_payment,
            body.selling_price
          );
          maximum_down_payment = getDownPaymentHelper(
            programData[0].downpayment_chart.maximum_down_payment,
            body.selling_price
          );
        }

        return {
          ...programData[0],
          downPayment: down_payment ?? 0,
          minDownPayment: minimum_down_payment ?? 0,
          maxDownPayment: maximum_down_payment ?? 0,
          assetRvAmount:
            programData[0]?.finance_type === "Lease"
              ? (Number(programData[0]?.rv_chart?.[0]?.rv_value ?? 0) /
                  Number(body.selling_price)) *
                100
              : programData[0]?.rv_chart?.[0]?.rv_value,
        };
      } else {
        return programData;
      }
    },
  });
  return { mutateAsync, error: error?.message, isPending };
};

export const useCalculateNfa = (): {
  isPending: boolean;
  error: string | undefined;
  mutate: UseMutateFunction<
    { net_financed_amount: number },
    AppError,
    NFAPAYLOAD
  >;
} => {
  const { mutate, isPending, error } = useMutation<
    { net_financed_amount: number },
    AppError,
    NFAPAYLOAD
  >({
    mutationFn: async (body: any) => {
      return await calculateNFA(body);
    },
  });
  return { mutate, isPending, error: error?.message };
};

export const useCalculateOrderQuotation = (): {
  mutate: UseMutateFunction<any, AppError, any>;
  isPending: boolean;
  error: string | undefined;
} => {
  const { mutate, isPending, error } = useMutation<any, AppError, any>({
    mutationFn: async (body: any) => {
      return await calculateOrderQuotation(body);
    },
  });
  return { mutate, isPending, error: error?.message };
};
