import { Popover } from "@src/components/atoms";

export const SalePriceAccordionContent = () => {
  return (
    <>
      <div className="pricing-details-accordion-content">
        <div className="pricing-details-accordion-content__box">
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Total Financed Amount
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Total Amount Financed represents the amount borrowed from the lender to complete the vehicle purchase, including applicable fees and charges. This figure is for educational purposes only and may vary based on lender terms and individual creditworthiness. Please consult your dealer for precise details."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $44,850
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Financing Charges
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="Financing Charges include the cost of borrowing, such as interest and any applicable fees. This information is for educational purposes only and may differ based on the agreed terms and the borrower’s credit profile. Confirm all financing charges with your dealer."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $2,670
            </span>
          </div>
          <div className="pricing-details__item">
            <span className="pricing-details__item__label text--body-2">
              Down Payment
              <Popover
                trigger={["hover"]}
                placement="top"
                disclaimerText="The Down payment reduces the total amount financed."
              >
                <i className="bmw-icon bmw-icon--after bmw-icon--info-icon"></i>
              </Popover>
            </span>
            <span className="pricing-details__item__price text--body-2">
              $2,670
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
